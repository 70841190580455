<template>
    <div class="page view">
        <h1>19) 추천 기여 매출 통계 (일간)</h1>
        <div id="main-content" class="wiki-content">
            <br>
            <p>전체매출, 기여매출 및 매출 기여도를 일 단위로 제공합니다. 어제 날짜의 데이터까지 제공됩니다.</p>
            <p><span style="color: rgb(0,0,0);">GET 방식으로 요청하실 수 있고 정상적으로 요청이 완료되는 경우는 JSON 형태의 추천리스트가 반환됩니다. callback 파라미터를 명시하는 경우 JSONP 형태로 응답합니다.</span></p>
            <p><span style="color: rgb(0,0,0);">요청에 실패할 경우 상황에 따라 4XX 혹은 5XX형태의 에러코드가 반환됩니다.</span></p>
            <h3 id="id-19)추천기여매출통계(일간)-ResourceURL"><span style="color: rgb(0,0,0);">Resource URL</span></h3>
            <p><span style="color: rgb(0,0,0);"><span><a href="https://api.recopick.com/1/admin/:service_id/feed/dailysalesstat/:max_tracking_hour" class="external-link">https://api.recopick.com/1/admin/:service_id/feed/dailysalesstat/:max_tracking_hour</a></span></span></p>
            <p><br /></p>
            <h3 id="id-19)추천기여매출통계(일간)-Parameters"><span class="mw-headline">Parameters</span></h3>
            <div class="table-wrap">
                <table class="wrapped confluenceTable">
                    <colgroup>
                        <col />
                        <col />
                    </colgroup>
                    <tbody>
                    <tr>
                        <th class="confluenceTh">
                            <p>service_id</p>
                            <p><sup><span style="color: rgb(153,153,153);">(required)</span></sup></p>
                        </th>
                        <td class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">RecoPick에서 발급된 service id를 의미합니다. service id 값은 <a href="http://admin.recopick.com/-/api_info" class="external-link">어드민 대시보드</a> 에서 확인하실 수 있습니다.</span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> 17</span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh">
                            <p>max_tracking_hour</p>
                            <p><sup><span style="color: rgb(153,153,153);">(required)</span></sup></p>
                        </th>
                        <td colspan="1" class="confluenceTd">
                            <p>읽어올 데이터의 계산 기준 시간을 의미합니다.</p>
                            <p>값은 1, 24로 지정할 수 있습니다.</p>
                        </td>
                    </tr>
                    <tr>
                        <th class="confluenceTh">
                            <p>access_key</p>
                            <p><sup><span style="color: rgb(153,153,153);">(required)</span></sup></p>
                        </th>
                        <td class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">보안이 적용된 API를 호출하기 위해 사용되는 비밀키를 의미합니다. 비밀키 값은 <a href="http://admin.recopick.com/-/api_info" class="external-link">어드민 대시보드</a>에서 확인하실 수 있습니다.</span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> 1f385dca73fb935cb11f8a246ea85f94</span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh">
                            <p>ago</p>
                            <p><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></p>
                        </th>
                        <td colspan="1" class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">읽어올 데이터의 항목수를 의미합니다. 기본값은 12입니다.</span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> 12</span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh">
                            <p>skip</p>
                            <p><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></p>
                        </th>
                        <td colspan="1" class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">지정된 값만큼 이전 데이터를 읽어옵니다. 0으로 지정할 경우 어제 데이터까지, 1로 지정할 경우 그저께의 데이터까지 읽어옵니다. 기본값은 0입니다.</span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> 12</span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh">
                            <p style="text-align: left;">offset</p>
                            <p style="text-align: left;"><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></p>
                        </th>
                        <td colspan="1" class="confluenceTd">
                            <p style="text-align: left;"><span style="color: rgb(34,34,34);">시간대(Timezone)의 UTC(협정 세계 표준시)와의 차이를 분 단위로 지정합니다. UTC 대비 9시간 빠른 서울의 경우 -540으로 지정합니다. 기본값은 0입니다.</span></p>
                            <p style="text-align: left;"><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> -540</span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th class="confluenceTh">
                            <p>callback</p>
                            <p><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></p>
                        </th>
                        <td class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);"><span>callback을 입력하시면 JSONP 형태의 응답을 제공합니다.</span></span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> callback=parseResponse<br /></span></code></p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <p><span> </span></p>
            <h3 id="id-19)추천기여매출통계(일간)-Output"><span style="color: rgb(0,0,0);">Output</span></h3>
            <div class="table-wrap">
                <table class="wrapped confluenceTable" style="width: 57.8453%;">
                    <colgroup>
                        <col style="width: 12.2371%;" />
                        <col style="width: 11.1855%;" />
                        <col style="width: 7.55258%;" />
                        <col style="width: 10.6119%;" />
                        <col style="width: 14.4359%;" />
                        <col style="width: 43.9771%;" />
                    </colgroup>
                    <tbody>
                    <tr>
                        <th style="width: 351.0px;" colspan="4" class="confluenceTh">field</th>
                        <th style="text-align: center;width: 130.0px;" class="confluenceTh"><strong>의 미</strong></th>
                        <th style="text-align: center;width: 206.0px;" class="confluenceTh"><strong>비 고</strong></th>
                    </tr>
                    <tr>
                        <th style="width: 107.0px;" class="confluenceTh">YYYYMMDD</th>
                        <th style="width: 96.0px;" class="confluenceTh"><br /></th>
                        <th style="width: 58.0px;" class="confluenceTh"><br /></th>
                        <th style="width: 90.0px;" class="confluenceTh"><br /></th>
                        <td style="width: 130.0px;" class="confluenceTd">년월일</td>
                        <td style="width: 206.0px;" class="confluenceTd">해당 일자의 데이터를 나타냅니다.</td>
                    </tr>
                    <tr>
                        <th style="width: 107.0px;" class="confluenceTh"><br /></th>
                        <th style="width: 96.0px;" class="confluenceTh">total_sales</th>
                        <th style="width: 58.0px;" class="confluenceTh"><br /></th>
                        <th style="width: 90.0px;" class="confluenceTh"><br /></th>
                        <td style="width: 130.0px;" class="confluenceTd">전체매출</td>
                        <td style="width: 206.0px;" class="confluenceTd">해당 일자의 전체매출</td>
                    </tr>
                    <tr>
                        <th style="width: 107.0px;" class="confluenceTh"><br /></th>
                        <th style="width: 96.0px;" class="confluenceTh">click_sales</th>
                        <th style="width: 58.0px;" class="confluenceTh"><br /></th>
                        <th style="width: 90.0px;" class="confluenceTh"><br /></th>
                        <td style="width: 130.0px;" class="confluenceTd">전체 기여매출</td>
                        <td style="width: 206.0px;" class="confluenceTd">해당 일자의 기여매출</td>
                    </tr>
                    <tr>
                        <th style="width: 107.0px;" class="confluenceTh"><br /></th>
                        <th style="width: 96.0px;" class="confluenceTh">sales_rate</th>
                        <th style="width: 58.0px;" class="confluenceTh"><br /></th>
                        <th style="width: 90.0px;" class="confluenceTh"><br /></th>
                        <td style="width: 130.0px;" class="confluenceTd">매출 기여도</td>
                        <td style="width: 206.0px;" class="confluenceTd"><span>해당 일자의 매출 기여도</span></td>
                    </tr>
                    <tr>
                        <th style="width: 107.0px;" class="confluenceTh"><br /></th>
                        <th style="width: 96.0px;" class="confluenceTh">channels</th>
                        <th style="width: 58.0px;" class="confluenceTh"><br /></th>
                        <th style="width: 90.0px;" class="confluenceTh"><br /></th>
                        <td style="width: 130.0px;" class="confluenceTd">개별 채널 별 데이터</td>
                        <td style="width: 206.0px;" class="confluenceTd">해당 일자의 개별 채널 별 데이터</td>
                    </tr>
                    <tr>
                        <th style="width: 107.0px;" class="confluenceTh"><br /></th>
                        <td class="highlight-grey confluenceTd" style="width: 96.0px;" data-highlight-colour="grey"><br /></td>
                        <td class="highlight-grey confluenceTd" style="width: 58.0px;" data-highlight-colour="grey"><strong>채널명</strong></td>
                        <td class="highlight-grey confluenceTd" style="width: 90.0px;" data-highlight-colour="grey"><br /></td>
                        <td style="width: 130.0px;" class="confluenceTd">채널 명</td>
                        <td style="width: 206.0px;" class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <th style="width: 107.0px;" class="confluenceTh"><br /></th>
                        <td class="highlight-grey confluenceTd" style="width: 96.0px;" data-highlight-colour="grey"><br /></td>
                        <td class="highlight-grey confluenceTd" style="width: 58.0px;" data-highlight-colour="grey"><br /></td>
                        <td class="highlight-grey confluenceTd" style="width: 90.0px;" data-highlight-colour="grey"><strong>total_sales</strong></td>
                        <td style="width: 130.0px;" class="confluenceTd">채널 매출</td>
                        <td style="width: 206.0px;" class="confluenceTd">해당 일자의 채널별 매출</td>
                    </tr>
                    <tr>
                        <th style="width: 107.0px;" class="confluenceTh"><br /></th>
                        <td class="highlight-grey confluenceTd" style="width: 96.0px;" data-highlight-colour="grey"><br /></td>
                        <td class="highlight-grey confluenceTd" style="width: 58.0px;" data-highlight-colour="grey"><br /></td>
                        <td class="highlight-grey confluenceTd" style="width: 90.0px;" data-highlight-colour="grey"><strong>click_sales</strong></td>
                        <td style="width: 130.0px;" class="confluenceTd">채널 기여매출</td>
                        <td style="width: 206.0px;" class="confluenceTd">해당 일자의 채널별 기여매출</td>
                    </tr>
                    <tr>
                        <th style="width: 107.0px;" class="confluenceTh"><br /></th>
                        <td class="highlight-grey confluenceTd" style="width: 96.0px;" data-highlight-colour="grey"><br /></td>
                        <td class="highlight-grey confluenceTd" style="width: 58.0px;" data-highlight-colour="grey"><br /></td>
                        <td class="highlight-grey confluenceTd" style="width: 90.0px;" data-highlight-colour="grey"><strong>sales_rate</strong></td>
                        <td style="width: 130.0px;" class="confluenceTd">채널 매출 기여도</td>
                        <td style="width: 206.0px;" class="confluenceTd">해당 일자의 채널별 매출 기여도</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <p><span><br /></span></p>
            <p><span>.</span></p>
        </div>
    </div>
</template>