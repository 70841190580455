<template>
    <div class="page view">
        <h1>09) 추천 서비스 전체 통계 (월간)</h1>
        <div id="main-content" class="wiki-content">
            <br>
            <p>추천 알고리즘별 클릭률 및 구매전환율, 구매자수, 상품수, Active상품수 데이터를 전체 및 추천에 따른 경우로 구분하여 제공합니다. 월 단위로 제공되며, 어제 날짜의 데이터까지 제공됩니다.</p>
            <p><span style="color: rgb(0,0,0);">GET 방식으로 요청하실 수 있고 정상적으로 요청이 완료되는 경우는 JSON 형태의 추천리스트가 반환됩니다. callback 파라미터를 명시하는 경우 JSONP 형태로 응답합니다.</span></p>
            <p><span style="color: rgb(0,0,0);">요청에 실패할 경우 상황에 따라 4XX 혹은 5XX형태의 에러코드가 반환됩니다.</span></p>
            <h3 id="id-09)추천서비스전체통계(월간)-ResourceURL"><span style="color: rgb(0,0,0);">Resource URL</span></h3>
            <p><span style="color: rgb(0,0,0);"><span> <span class="nolink">https://api.recopick.com/1/admin/:service_id/feed/monthlyserviceinfo<a href="https://api.recopick.com/2/recommend/:service_id/:item_id" class="external-link"></a></span></span></span></p>
            <p> </p>
            <h3 id="id-09)추천서비스전체통계(월간)-Parameters"><span class="mw-headline">Parameters</span></h3>
            <div class="table-wrap">
                <table class="confluenceTable">
                    <tbody>
                    <tr>
                        <th class="confluenceTh">
                            <p>service_id</p>
                            <p><sup><span style="color: rgb(153,153,153);">(required)</span></sup></p>
                        </th>
                        <td class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">RecoPick에서 발급된 service id를 의미합니다. service id <span style="color: rgb(34,34,34);">값은 </span><a href="http://admin.recopick.com/-/api_info" class="external-link">어드민 대시보드</a><span style="color: rgb(34,34,34);"> </span><span style="color: rgb(34,34,34);">에서 확인하실 수 있습니다.</span></span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> 17</span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th class="confluenceTh">
                            <p>access_key</p>
                            <p><sup><span style="color: rgb(153,153,153);">(required)</span></sup></p>
                        </th>
                        <td class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">보안이 적용된 API를 호출하기 위해 사용되는 비밀키를 의미합니다. 비밀키 값은 <a href="http://admin.recopick.com/-/api_info" class="external-link">어드민 대시보드</a>에서 확인하실 수 있습니다.</span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> 1f385dca73fb935cb11f8a246ea85f94</span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh">
                            <p>ago</p>
                            <p><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></p>
                        </th>
                        <td colspan="1" class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">읽어올 데이터의 항목수를 의미합니다. 기본값은 7입니다.</span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> 7</span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh">
                            <p>skip</p>
                            <p><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></p>
                        </th>
                        <td colspan="1" class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">지정된 값만큼 이전 데이터를 읽어옵니다. 0으로 지정할 경우 지난달 데이터까지, 1로 지정할 경우 지지난달의 데이터까지 읽어옵니다. 기본값은 0입니다.</span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> 12</span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh">
                            <p>offset</p>
                            <p><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></p>
                        </th>
                        <td colspan="1" class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">시간대(Timezone)의 UTC(협정 세계 표준시)와의 차이를 분 단위로 지정합니다. UTC 대비 9시간 빠른 서울의 경우 -540으로 지정합니다. 기본값은 0입니다.</span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> -540</span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th class="confluenceTh">
                            <p>callback</p>
                            <p><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></p>
                        </th>
                        <td class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);"><span>callback을 입력하시면 JSONP 형태의 응답을 제공합니다.</span></span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> callback=parseResponse<br /></span></code></p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <p><span><br /></span></p>
            <h3 id="id-09)추천서비스전체통계(월간)-ExampleRequest"><span>Example Request</span></h3>
            <hr />
            <p><span><strong>GET   </strong> </span><span class="nolink">https://api.recopick.com/1/admin/2/feed/monthlyserviceinfo?access_key=REPLACE_THIS&amp;ago=12&amp;skip=0&amp;offset=-540</span><br /><span> </span></p>
            <h3 id="id-09)추천서비스전체통계(월간)-Output"><span style="color: rgb(0,0,0);"><a name="Output_5"></a>Output</span></h3>
            <h3 id="id-09)추천서비스전체통계(월간)-반환되는JSON필드설명은아래를참고해주세요."><span style="color: rgb(0,0,0);"> </span><span>반환되는 JSON 필드 설명은 아래를 참고해주세요.</span></h3>
            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                <div class="codeContent panelContent pdl">
            <pre class="syntaxhighlighter-pre" data-syntaxhighlighter-params="brush: js; gutter: false; theme: Eclipse" data-theme="Eclipse">{
  &quot;201302&quot;: {
    &quot;ctr&quot;: {
      &quot;4&quot;: {
        &quot;click_ratio&quot;: 0,
        &quot;click_count&quot;: 0,
        &quot;view_count&quot;: 0
      }
    },
    &quot;item_count&quot;: 0,
    &quot;reco_item_count&quot;: 0,
    &quot;active_item_count&quot;: 0,
    &quot;active_reco_item_count&quot;: 0,
    &quot;view&quot;: 10244,
    &quot;purchase&quot;: 2785,
    &quot;recoclick&quot;: 0,
    &quot;purchase_and_recoclick&quot;: 0,
    &quot;click_ratio&quot;: 0,
    &quot;click_count&quot;: 0,
    &quot;view_count&quot;: 0
  },
  &quot;201303&quot;: {
    &quot;ctr&quot;: {
      &quot;2&quot;: {
        &quot;click_count&quot;: 1,
        &quot;view_count&quot;: 6,
        &quot;click_ratio&quot;: 0.16666666666666666
      },
      &quot;4&quot;: {
        &quot;click_ratio&quot;: 0,
        &quot;click_count&quot;: 0,
        &quot;view_count&quot;: 0
      }
    },
    &quot;item_count&quot;: 0,
    &quot;reco_item_count&quot;: 0,
    &quot;active_item_count&quot;: 0,
    &quot;active_reco_item_count&quot;: 0,
    &quot;view&quot;: 18273,
    &quot;purchase&quot;: 5353,
    &quot;recoclick&quot;: 200,
    &quot;purchase_and_recoclick&quot;: 52,
    &quot;click_ratio&quot;: 0,
    &quot;click_count&quot;: 0,
    &quot;view_count&quot;: 0,
    &quot;diff_item_count&quot;: 0,
    &quot;diff_reco_item_count&quot;: 0,
    &quot;max_method&quot;: 2
  },
  &quot;201304&quot;: {
    &quot;ctr&quot;: {
      &quot;2&quot;: {
        &quot;click_count&quot;: 64,
        &quot;view_count&quot;: 2586,
        &quot;click_ratio&quot;: 0.02474864655839134
      },
      &quot;4&quot;: {
        &quot;click_ratio&quot;: 0.04483430799220273,
        &quot;click_count&quot;: 115,
        &quot;view_count&quot;: 2565
      }
    },
    &quot;item_count&quot;: 383,
    &quot;reco_item_count&quot;: 376,
    &quot;active_item_count&quot;: 0,
    &quot;active_reco_item_count&quot;: 0,
    &quot;view&quot;: 19073,
    &quot;purchase&quot;: 5604,
    &quot;recoclick&quot;: 1451,
    &quot;purchase_and_recoclick&quot;: 418,
    &quot;click_ratio&quot;: 0,
    &quot;click_count&quot;: 0,
    &quot;view_count&quot;: 0,
    &quot;diff_item_count&quot;: 383,
    &quot;diff_reco_item_count&quot;: 376,
    &quot;max_method&quot;: 4,
    &quot;diff_click_ratio&quot;: -0.12183235966666667
  },
  &quot;201305&quot;: {
    &quot;ctr&quot;: {
      &quot;4&quot;: {
        &quot;click_ratio&quot;: 0.05458612975391499,
        &quot;click_count&quot;: 122,
        &quot;view_count&quot;: 2235
      }
    },
    &quot;item_count&quot;: 403,
    &quot;reco_item_count&quot;: 394,
    &quot;active_item_count&quot;: 0,
    &quot;active_reco_item_count&quot;: 0,
    &quot;view&quot;: 16044,
    &quot;purchase&quot;: 5054,
    &quot;recoclick&quot;: 1450,
    &quot;purchase_and_recoclick&quot;: 434,
    &quot;click_ratio&quot;: 0,
    &quot;click_count&quot;: 0,
    &quot;view_count&quot;: 0,
    &quot;diff_item_count&quot;: 20,
    &quot;diff_reco_item_count&quot;: 18,
    &quot;max_method&quot;: 4,
    &quot;diff_click_ratio&quot;: 0.00975182100779727
  },
  &quot;201306&quot;: {
    &quot;ctr&quot;: {
      &quot;3&quot;: {
        &quot;click_count&quot;: 1,
        &quot;view_count&quot;: 6,
        &quot;click_ratio&quot;: 0.16666666666666666
      },
      &quot;4&quot;: {
        &quot;click_ratio&quot;: 0.11981424148606812,
        &quot;click_count&quot;: 387,
        &quot;view_count&quot;: 3230
      }
    },
    &quot;item_count&quot;: 473,
    &quot;reco_item_count&quot;: 447,
    &quot;active_item_count&quot;: 0,
    &quot;active_reco_item_count&quot;: 0,
    &quot;view&quot;: 18965,
    &quot;purchase&quot;: 5627,
    &quot;recoclick&quot;: 1968,
    &quot;purchase_and_recoclick&quot;: 600,
    &quot;click_ratio&quot;: 0,
    &quot;click_count&quot;: 0,
    &quot;view_count&quot;: 0,
    &quot;diff_item_count&quot;: 70,
    &quot;diff_reco_item_count&quot;: 53,
    &quot;max_method&quot;: 4,
    &quot;diff_click_ratio&quot;: 0.06522811124608502
  },
  &quot;201307&quot;: {
    &quot;ctr&quot;: {
      &quot;2&quot;: {
        &quot;click_count&quot;: 1,
        &quot;view_count&quot;: 0,
        &quot;click_ratio&quot;: null
      },
      &quot;3&quot;: {
        &quot;click_count&quot;: 0,
        &quot;view_count&quot;: 16,
        &quot;click_ratio&quot;: 0
      },
      &quot;4&quot;: {
        &quot;click_ratio&quot;: 0.07543103448275862,
        &quot;click_count&quot;: 210,
        &quot;view_count&quot;: 2784
      }
    },
    &quot;item_count&quot;: 524,
    &quot;reco_item_count&quot;: 492,
    &quot;active_item_count&quot;: 0,
    &quot;active_reco_item_count&quot;: 0,
    &quot;view&quot;: 24567,
    &quot;purchase&quot;: 6305,
    &quot;recoclick&quot;: 3071,
    &quot;purchase_and_recoclick&quot;: 785,
    &quot;click_ratio&quot;: 0,
    &quot;click_count&quot;: 0,
    &quot;view_count&quot;: 0,
    &quot;diff_item_count&quot;: 51,
    &quot;diff_reco_item_count&quot;: 45,
    &quot;max_method&quot;: 4,
    &quot;diff_click_ratio&quot;: -0.044383207486068124
  },
  &quot;201308&quot;: {
    &quot;ctr&quot;: {
      &quot;3&quot;: {
        &quot;click_count&quot;: 0,
        &quot;view_count&quot;: 18,
        &quot;click_ratio&quot;: 0
      },
      &quot;4&quot;: {
        &quot;click_ratio&quot;: 0.06497622820919176,
        &quot;click_count&quot;: 246,
        &quot;view_count&quot;: 3786
      }
    },
    &quot;item_count&quot;: 561,
    &quot;reco_item_count&quot;: 523,
    &quot;active_item_count&quot;: 477,
    &quot;active_reco_item_count&quot;: 0,
    &quot;view&quot;: 24140,
    &quot;purchase&quot;: 6923,
    &quot;recoclick&quot;: 2804,
    &quot;purchase_and_recoclick&quot;: 856,
    &quot;click_ratio&quot;: 0,
    &quot;click_count&quot;: 0,
    &quot;view_count&quot;: 0,
    &quot;diff_item_count&quot;: 37,
    &quot;diff_reco_item_count&quot;: 31,
    &quot;max_method&quot;: 4,
    &quot;diff_click_ratio&quot;: -0.010454806482758622
  }
}</pre>
                </div>
            </div>
            <p><span><br /></span></p>
            <p> </p>
            <div class="table-wrap">
                <table class="confluenceTable">
                    <tbody>
                    <tr>
                        <th colspan="4" class="confluenceTh">field</th>
                        <th style="text-align: center;" class="confluenceTh"><strong>의 미</strong></th>
                        <th style="text-align: center;" class="confluenceTh"><strong>비 고</strong></th>
                    </tr>
                    <tr>
                        <th class="confluenceTh">YYYYMM</th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <td class="confluenceTd">년월</td>
                        <td class="confluenceTd">해당 월의 데이터를 나타냅니다.</td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh">ctr</th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <td colspan="1" class="confluenceTd">추천 클릭률</td>
                        <td colspan="1" class="confluenceTd"> </td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh">METHOD</th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <td colspan="1" class="confluenceTd">추천 알고리즘</td>
                        <td colspan="1" class="confluenceTd"><a class="unresolved" href="#">GET 1/admin/fetch_methods</a> 참조</td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh">click_ratio</th>
                        <td colspan="1" class="confluenceTd">추천 클릭률</td>
                        <td colspan="1" class="confluenceTd"><span>해당 월의 추천 클릭률</span></td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh">click_count</th>
                        <td colspan="1" class="confluenceTd">추천 클릭수</td>
                        <td colspan="1" class="confluenceTd">해당 월의 추천 클릭수</td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh">view_count</th>
                        <td colspan="1" class="confluenceTd">추천 조회수</td>
                        <td colspan="1" class="confluenceTd">해당 월의 추천이 제공된 상품 상세 페이지 조회수</td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh">item_count</th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <td colspan="1" class="confluenceTd">상품수</td>
                        <td colspan="1" class="confluenceTd">해당 월에 수집된 전체 상품수</td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh">reco_item_count</th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <td colspan="1" class="confluenceTd">추천가능 상품수</td>
                        <td colspan="1" class="confluenceTd"><span>해당 월에 수집된 추천가능 상품수</span></td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh">active_item_count</th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <td colspan="1" class="confluenceTd">Active 상품수</td>
                        <td colspan="1" class="confluenceTd">해당 월 기준 30일 이내에 한번이라도 방문자가 있었던 상품수</td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh">active_reco_item_count</th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <td colspan="1" class="confluenceTd">Active 추천가능 상품수</td>
                        <td colspan="1" class="confluenceTd"><span>해당 월 기준 30일 이내에 한번이라도 방문자가 있었던 추천가능 상품수</span></td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh">view</th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <td colspan="1" class="confluenceTd">조회수</td>
                        <td colspan="1" class="confluenceTd">해당 월의 상품 상세 페이지 조회수</td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh">purchase</th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <td colspan="1" class="confluenceTd">구매수</td>
                        <td colspan="1" class="confluenceTd">해당 월의 구매 페이지 조회수</td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh">recoclick</th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <td colspan="1" class="confluenceTd">추천 클릭수</td>
                        <td colspan="1" class="confluenceTd">해당 월의 추천 클릭수</td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh">purchase_and_recoclick</th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <td colspan="1" class="confluenceTd">추천 클릭 후 구매수</td>
                        <td colspan="1" class="confluenceTd"><span>해당 월의 구매 페이지 조회수 중 추천 클릭 한 사용자의 조회수</span></td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh">diff_item_count</th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <td colspan="1" class="confluenceTd">상품수 차이</td>
                        <td colspan="1" class="confluenceTd">이전 달과의 item_count 차이</td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh">diff_reco_item_count</th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <td colspan="1" class="confluenceTd">추천가능 상품수 차이</td>
                        <td colspan="1" class="confluenceTd">이전 <span>달과</span>의 reco_item_count 차이</td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh">max_method</th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <td colspan="1" class="confluenceTd">추천 클릭률이 가장 높은 추천 알고리즘</td>
                        <td colspan="1" class="confluenceTd"><a class="unresolved" href="#">GET 1/admin/fetch_methods</a><span> 참조</span></td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh">diff_click_ratio</th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <td colspan="1" class="confluenceTd">추천 클릭률 차이</td>
                        <td colspan="1" class="confluenceTd">이전 달의 가장 높은 추천 클릭률과 해당 월의 가장 높은 추천 클릭률의 차이</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <p><span><br /></span></p>
            <p><span>.</span></p>
        </div>
    </div>
</template>