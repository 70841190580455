<template>
    <div class="page view">
        <h1>2) 실시간 개인화 추천</h1>
        <div id="main-content" class="wiki-content">
            <br>
            <p><span style="color: rgb(0,0,0);">실시간 개인화 추천을 받을 수 있는 API 입니다.</span></p>
            <p><span style="color: rgb(0,0,0);"> GET 방식으로 요청하실 수 있고, <span style="color: rgb(0,0,0);">정상적으로 추천 계산이 완료된 경우에는 JSON 형태의 추천리스트가 반환됩니다.</span></span></p>
          <p><span style="color: rgb(0,0,0);"><span style="color: rgb(0,0,0);"> </span>요청에 실패할 경우 상황에 따라 4XX 혹은 5XX형태의 에러코드가 반환됩니다.</span>
          </p>
          <h3 id="id-2)실시간개인화추천-ResourceURL"><span style="color: rgb(0,0,0);">Resource URL</span></h3>
          <p>
            <a href="javascript:void(0);" class="external-link">
              https://api.recopick.com/v2/recommendations/user/<span style="font-weight: bold; color: rgb(255,0,0)">:service_id</span>/<span
                style="font-weight: bold; color: rgb(255,0,0)">:uid</span>?mid=<span
                style="font-weight: bold; color: rgb(0,0,255)">:mid</span>&limit=<span
                style="font-weight: bold; color: rgb(0,0,255)">:limit</span>&field=<span
                style="font-weight: bold; color: rgb(0,0,255)">:field</span>&channel=<span
                style="font-weight: bold; color: rgb(0,0,255)">:channel</span>&callback=<span
                style="font-weight: bold; color: rgb(0,0,255)">:callback</span>&access_key=<span
                style="font-weight: bold; color: rgb(255,0,0)">:access_key</span>
            </a>
          </p>
          <h3 id="id-2)실시간개인화추천-(GETv1/recommendations/user/:service_id/:user_id)"><span
              style="color: rgb(0,0,0);"><span style="color: rgb(0,0,0);"><span class="nolink"><span
              style="color: rgb(255,0,0);"><span style="color: rgb(255,0,0);"><span style="color: rgb(255,0,0);"><span
              style="color: rgb(0,0,0);">(GET v2/recommendations/user/:service_id/:user_id)</span></span><strong><span
              style="color: rgb(255,0,0);"><strong><span><strong><strong><span><strong><br/></strong></span></strong></strong></span></strong></span></strong></span></span></span></span></span>
          </h3>
          <p><span style="color: rgb(0,0,0);"><span style="color: rgb(0,0,0);"><span class="nolink"><span
              style="color: rgb(255,0,0);"><span style="color: rgb(255,0,0);"><strong><span
              style="color: rgb(255,0,0);"> </span></strong></span></span></span><span style="color: rgb(0,0,0);"><span
              style="color: rgb(0,0,0);"><span style="color: rgb(34,34,34);"><u><span class="nolink"><span
              style="color: rgb(153,153,153);"><span style="color: rgb(0,0,0);"><span class="nolink"
                                                                                      style="color: rgb(0,0,0);"><span
              style="color: rgb(255,0,0);"><em><u><strong><span style="color: rgb(255,0,0);"><span class="nolink">*Native(Hybrid) App에서는 uid 자리에 반드시 GAID 혹은 IDFA값을 넣어서 업로드 해야 합니다.</span></span></strong></u></em></span></span></span></span></span></u></span></span></span><br/></span></span>
          </p>
          <h3 id="id-2)실시간개인화추천-Parameters"><span class="mw-headline">Parameters</span></h3>
          <div class="table-wrap">
                <table class="wrapped confluenceTable">
                    <colgroup>
                        <col />
                        <col />
                    </colgroup>
                    <tbody>
                    <tr>
                        <th class="confluenceTh">
                            <p>service_id</p>
                            <p><sup><span style="color: rgb(250,0,0);">(required)</span></sup></p>
                        </th>
                        <td class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">Recopick에서 발급된 service id를 의미합니다. service id를 모르실 경우 Recopick으로 문의해주시기 바랍니다.</span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> 17</span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th class="confluenceTh">
                            <p>uid</p>
                            <p><sup><span style="color: rgb(255,0,0);">(required)</span></sup></p>
                        </th>
                        <td class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">추천리스트를 가져올 사용자 ID를 의미합니다. 로그 수집에 사용하고 있는 uid를 입력해주시기 바랍니다.</span></p>
                            <p><span style="color: rgb(34,34,34);">uid 값을 생성하는 방법은 <a href="/display/docs/Client+Library+APIs#ClientLibraryAPIs-2.2ClientLibraryAPIs-fetchUID">Client Library APIs</a> 를 참고해주세요.</span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> <span style="color: rgb(0,0,0);">20473629.1550206365737</span></span></code></p>
                        </td>
                    </tr>
                    <tr>
                      <th class="confluenceTh">
                        <p>access_key</p>
                        <p><sup><span style="color: rgb(255,0,0);">(required)</span></sup></p>
                      </th>
                      <td class="confluenceTd">
                        <p><span style="color: rgb(34,34,34);">보안이 적용된 API를 호출하기 위해 사용되는 비밀키를 의미합니다. 비밀키 값은 <a href="http://admin.recopick.com/-/api_info" class="external-link">어드민 대시보드</a>에서 확인하실 수 있습니다.</span></p>
                        <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> 1f385dca73fb935cb11f8a246ea85f94</span></code></p>
                      </td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh">
                            <p>mid</p>
                            <h6 id="id-2)실시간개인화추천-(optional)"><span style="color: rgb(153,153,153);">(</span><span style="color: rgb(153,153,153);">optional</span><span style="color: rgb(153,153,153);">)</span></h6>
                        </th>
                        <td colspan="1" class="confluenceTd">
                            <p>추천리스트를 가져올 고객사 사이트의 회원ID를 의미하며 단방향으로 암호화되어 있어야 합니다. 로그인하지 않은 경우에는 전송할 mid 값이 없으므로, 이 경우에는 mid를 전송하지 않아도 됩니다.</p>
                            <p>암호화된 결과에 특수문자가 들어가지 않는 암호화 알고리즘으로 단방향 암호화 해주세요. (예&gt; SHA-256 등)</p>
                            <p><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> a891cd6a5b84d5a44d1edf17d080f39f</span></p>
                            <p><img class="emoticon emoticon-warning" src="@/assets/images/icons/emoticons/warning.svg" data-emoticon-name="warning" alt="(warning)" /><span style="color: rgb(255,0,0);"> 주의하세요! </span>레코픽 추천을 API 방식으로 사용하는 경우, GET방식으로 API를 호출하게 됩니다.<span style="color: rgb(255,0,0);"><span style="color: rgb(0,0,0);"><br /></span></span>  이 경우 일부 API의 파라미터에 MID가 사용되는데, MID 값에 일부 특수문자(예&gt; &amp;, =) 가 들어가게 되면 정상동작 하지 않을 수 있습니다.<span style="color: rgb(255,0,0);"><span style="color: rgb(0,0,0);"><br /></span></span>  암호화된 결과에 특수문자가 들어가지 않는 암호화 알고리즘으로 단방향 암호화 해주세요. (예&gt; SHA-256, MD5 등)</p>
                            <p>* MID 단방향 암호화 예 : 88020d58ceb8a4ad7f6756021fbc06b8a91f17daad2f04a5d369608d9c308bde (SHA-256으로 암호화), 4509bf9373ff3dca193ba02726c87988 (MD5로 암호화)</p>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh">
                            <p>type</p>
                            <p><sup><span style="color: rgb(153,153,153);">(<span style="color: rgb(153,153,153);">optional</span>)</span></sup></p>
                        </th>
                        <td colspan="1" class="confluenceTd">
                            <p>개인화 추천의 종류입니다. 아래 목록을 참고하여 원하는 값을 지정해주시기 바랍니다. (별도로 지정하지 않는 경우 기본값은 realtime 입니다)</p>
                            <ul>
                                <li>사용자의 최근 행동 기록을 반영한 실시간 개인화 추천 : realtime</li>
                                <li><span style="color: rgb(0,0,0);">사용자의 최근 행동 기록을 반영한 실시간 개인화 추천 (단, 실시간 개인화로 추천된 상품수가 충분하지 않은 경우 부족한 상품 수만큼 많이본상품 추천으로 채워짐) : realtime_api</span></li>
                            </ul>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> type=realtime_api</span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th class="confluenceTh">
                            <p>limit</p>
                            <p><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></p>
                        </th>
                        <td class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">최대 몇 개의 추천을 가져올지를 의미합니다. limit 값이 없으면 계산된 추천 리스트 전체를 가져옵니다. 추천 결과의 개수가 limit보다 적은 경우, 전체 추천 결과가 반환됩니다. <br /></span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> limit=5</span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th class="confluenceTh">
                            <p>field</p>
                            <p><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></p>
                        </th>
                        <td class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">field=meta를 입력하시면</span></p>
                            <ul>
                              <li>품절로 등록되어 있는 상품은 제외하고 추천 결과가 제공됩니다.<span style="color: rgb(255,0,0)"> (품절 상품 미 노출을 원하실 경우 반드시 해당 파라미터를 추가하셔야 합니다.)</span></li>
                              <li>추천 리스트에 상품 meta값이 같이 제공됩니다.</li>
                            </ul>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> field=meta</span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh">
                            <p>channel</p>
                            <p><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></p>
                        </th>
                        <td colspan="1" class="confluenceTd">
                            <p style="text-align: left;">추천클릭률, 추천기여매출을 추적하기 위해 channel을 입력합니다. 추천 노출 영역이 다르거나, A/B test 시에 서로 다른 channel을 부여하시면, channel 별로 성과를 계산하여 보여드립니다.</p>
                            <p style="text-align: left;">채널이란 하나의 사이트에서 여러 개의 추천 알고리즘을 각각 다른 위치에 제공하는 경우,<br />각 페이지에 제공된 추천 알고리즘 별로 추천 성과(추천클릭률 및 추천기여매출)를 확인할 수 있도록 하기 위한 식별자입니다.<br />예를 들어, 실시간 개인화 추천 알고리즘을 메인페이지, 상품상세페이지, 마이페이지에서 사용하는 경우, 각 페이지 별로 추천 성과가 달라집니다.<br />이 때에 각 페이지 별로 채널명을 부여하여 페이지 별로 구분하여 추천 알고리즘 성과를 분석할 수 있습니다.<br />채널명은 아래 예와 같이 공백 없는 영문 명을 사용하시는 걸 권장 드립니다.<br />예&gt; 메인페이지 : main, 상품상세페이지_상단 : detail_top, 장바구니 : basket</p>
                            <p><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> channel=detail_top</span></p>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh">
                            <p>category</p>
                            <h6 id="id-2)실시간개인화추천-(optional).1"><span style="color: rgb(153,153,153);">(optional)</span></h6>
                        </th>
                        <td colspan="1" class="confluenceTd">
                            <p>카테고리 입니다. 최상위 카테고리만 지원 됩니다. 가구 , 여성의류 , 가전제품 ...</p>
                            <p><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> category=아우터</span></p>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh">
                            <p style="text-align: left;">brand</p>
                            <h6 style="text-align: left;" id="id-2)실시간개인화추천-(optional).2"><span style="color: rgb(153,153,153);">(optional)</span> </h6>
                        </th>
                        <td colspan="1" class="confluenceTd">
                            <p style="text-align: left;">브랜드입니다. brand라는 항목으로 수집된 brand명에 한하여 동일 텍스트에 한해 지원됩니다.  </p>
                            <p style="text-align: left;"><strong>Example Values :</strong><span style="color: rgb(34,34,34);"><span> </span>brand=STONE ISLAND</span></p>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh">
                            <p>callback</p>
                            <p><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></p>
                        </th>
                        <td colspan="1" class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);"><span>callback을 입력하시면 JSONP 형태의 응답을 제공합니다.</span></span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> callback=parseResponse</span></code><span style="color: rgb(34,34,34);"> </span></p>
                            <p><code><strong>Example Request :</strong><span style="color: rgb(34,34,34);"> <a
                              href="javascript:void(0);"
                              class="external-link">https://api.recopick.com/v2/recommendations/user/20/20473629.1550206365737?callback=parseResponse&access_key=1f385dca73fb935cb11f8a246ea85f94</a></span></code>
                            </p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <p><span><br /></span></p>
            <h3 id="id-2)실시간개인화추천-ExampleRequest"><span>Example Request </span></h3>
            <hr />
            <p>
              <span><strong>GET   </strong> </span><span class="nolink"> </span><a
                href="javascript:void(0);"
                class="external-link"><span class="nolink">https://api.recopick.com/v2/recommendations/user/<span
                style="font-weight: bold;color: rgb(255,0,0);">20</span>/<span
                style="font-weight: bold; color: rgb(255,0,0);">20473629.1550206365737</span>/?limit=2&<span
                style="font-weight: bold; color: rgb(255,0,0);">access_key=1f385dca73fb935cb11f8a246ea85f94</span></span></a>
              <br/><span> </span>
            </p>
            <h3 id="id-2)실시간개인화추천-Output"><span style="color: rgb(0,0,0);"><span style="color: rgb(0,0,0);">Output</span></span></h3>
            <h3 id="id-2)실시간개인화추천-반환되는JSON필드설명은아래를참고해주세요."><span style="color: rgb(0,0,0);"><span style="color: rgb(0,0,0);"> </span></span><span>반환되는 JSON 필드 설명은 아래를 참고해주세요.</span></h3>
            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                <div class="codeContent panelContent pdl">
            <pre class="syntaxhighlighter-pre" data-syntaxhighlighter-params="brush: js; gutter: false; theme: Eclipse" data-theme="Eclipse">[
  {
    id: '52537301',
    score: 1.718529,
    method: 25,
    clicklog_link: 'https://lc.recopick.com/3/log/click/20?source=18426953&amp;reco_list=%2251387293%22&amp;pick=52537301&amp;method=20',
    clicklog_redirect_link: 'https://lc.recopick.com/1/banner/20/pick?source=18426953&amp;reco_list=%2251387293%22&amp;pick=52537301&amp;method=20'
  },
  {
    id: '51387293',
    score: 1.493298,
    method: 25,
    clicklog_link: 'https://lc.recopick.com/3/log/click/20?source=18426953&amp;reco_list=%2251387293%22&amp;pick=51387293&amp;method=20',
    clicklog_redirect_link: 'https://lc.recopick.com/1/banner/20/pick?source=18426953&amp;reco_list=%2251387293%22&amp;pick=51387293&amp;method=20'
  }
]</pre>
                </div>
            </div>
            <p><br /></p>
            <p><a href="http://docs.recopick.com/pages/viewpage.action?pageId=3735613" name="Output_5" class="external-link">&quot;사용자 기준 추천&quot;을 이용하시지 않는 경우 HTTP 400으로 아래와 같이 응답됩니다. (자세한 내용은 </a><a href="/pages/viewpage.action?pageId=3735613">0.3 과금 정책</a> 을 참고하세요.)</p>
            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                <div class="codeContent panelContent pdl">
            <pre class="syntaxhighlighter-pre" data-syntaxhighlighter-params="brush: js; gutter: false; theme: Eclipse" data-theme="Eclipse">{
	&quot;errors&quot;: [
		{
			&quot;message&quot;: &quot;API call limit exceeded. Request user product to uses this API.&quot;
		}
	]
}</pre>
                </div>
            </div>
            <div class="table-wrap">
                <table class="wrapped confluenceTable">
                    <colgroup>
                        <col />
                        <col />
                        <col />
                    </colgroup>
                    <tbody>
                    <tr>
                        <th style="text-align: left;" class="confluenceTh">field</th>
                        <th style="text-align: center;" class="confluenceTh"><strong>의 미</strong></th>
                        <th style="text-align: center;" class="confluenceTh"><strong>비 고</strong></th>
                    </tr>
                    <tr>
                        <th style="text-align: left;" class="confluenceTh">id</th>
                        <td class="confluenceTd">추천된 상품의 id</td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <th style="text-align: left;" class="confluenceTh">score</th>
                        <td class="confluenceTd"><span>추천의 품질 지표</span></td>
                        <td class="confluenceTd">score가 높은 순으로 추천 리스트를 노출하시면 됩니다. 일반적으로는 score가 높은 순으로 리스트를 드립니다.</td>
                    </tr>
                    <tr>
                        <th style="text-align: left;" class="confluenceTh">method</th>
                        <td class="confluenceTd"><span>추천 계산 알고리즘 id</span></td>
                        <td class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh">category1</th>
                        <td colspan="1" class="confluenceTd">카테고리 정보</td>
                        <td rowspan="3" class="confluenceTd">
                            <p>로그수집시 지정한 카테고리의 정보. (<a style="line-height: 1.4285;" href="http://docs.recopick.com/pages/viewpage.action?pageId=3244497" class="external-link">참고</a>)</p>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"><span>category2</span></th>
                        <td colspan="1" class="confluenceTd"><span>카테고리 정보</span></td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"><strong><span>category3</span></strong></th>
                        <td colspan="1" class="confluenceTd"><span>카테고리 정보</span></td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh">clicklog_link</th>
                        <td colspan="1" class="confluenceTd">클릭로그 저장용 링크 주소</td>
                        <td colspan="1" class="confluenceTd">해당 링크를 방문하거나 Ajax GET, JSONP 등의 방법으로 호출하면 추천 클릭 로그가 전송됩니다.</td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh">clicklog_redirect_link</th>
                        <td colspan="1" class="confluenceTd">클릭로그 저장 및 페이지 이동 링크 주소</td>
                        <td colspan="1" class="confluenceTd">해당 링크를 방문하면, 추천 클릭 로그가 RecoPick 서버에 저장되고, 상품 상세 페이지로 이동됩니다. 이때 이동되는 상품 상세 페이지를 변경하고자 하시면, ?url=<span style="color: rgb(255,0,0);"><strong>:encodedURL </strong><span style="color: rgb(0,0,0);">을 붙여주시면, 클릭 로그를 남긴 다음, 해당 페이지로 이동 시켜 드립니다.</span></span></td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <p><span style="color: rgb(255,0,0);"><br /></span></p>
            <p><span style="color: rgb(255,0,0);"><img class="emoticon emoticon-warning" src="@/assets/images/icons/emoticons/warning.svg" data-emoticon-name="warning" alt="(warning)" /><span> 주의하세요! <span style="color: rgb(0,0,0);">사용자들이 추천 리스트를 클릭하는 경우, clicklog_link 혹은 clicklog_redirect_link를 통해 추천 클릭로그를 남겨주셔야 합니다. 추천 클릭 로그를 제대로 남기지 않을 경우, 추천에 대한 성과를 계산할 수 없으며, 향후 추천 품질이 저하되는 문제가 생깁니다. 브라우저의 경우, 간단하게 clicklog_redirect_link 를 통해 추천 클릭로그를 남기면서, 페이지를 이동하게 하는 것을 추천 드리며, 불가피하게 clicklog_redirect_link를 사용할 수 없을 경우, 서버 혹은 ajax 등 다른 방법으로 clicklog_link를 호출하셔서 추천 클릭 로그를 남겨주시면 됩니다. (참고: <a href="/pages/viewpage.action?pageId=491684">7) 추천상품 클릭 로그</a>)</span></span></span></p>
            <p><span style="color: rgb(255,0,0);"><span style="color: rgb(0,0,0);"><span style="color: rgb(255,0,0);">※ 참고</span><span style="color: rgb(255,0,0);"> : </span></span></span><span style="color: rgb(0,0,0);">추천 상품 클릭 시에 추천 API 결과에 있는 clicklog_link 혹은 clicklog_redirect_link를 반드시 레코픽으로 전달해주셔야 합니다. 그래야 레코픽 추천 성과가 잡힙니다.</span></p>
            <ul>
                <li>상품상세페이지의 URL 패턴이 정형화 되어 있지 않다면 clicklog_redirect_link는 사용하지 못하고, clicklog_link를 사용해주셔야 합니다.</li>
                <li>clicklog_redirect_link : 추천된 상품에 대한 클릭 정보를 레코픽으로 전송한 뒤에 클릭된 상품의 상세페이지로 redirect시켜 드립니다.</li>
            </ul>
            <ul>
                <li>clicklog_link : 추천된 상품에 대한 클릭 정보를 레코픽으로 전송합니다. 이 경우에는 추천된 상품의 상세페이지로 직접 이동해주셔야 합니다.</li>
            </ul>
            <p style="margin-left: 120.0px;"> 또한, API 응답 내에 있는 위의 method 값을 아래 형식으로 상세페이지의 URL 파라미터에 추가적으로 넣어주셔야 하고, 추천 서비스를 사용하는 경우 'product_type=R' 파라미터도 추가적으로 넣어주셔야 합니다.</p>
            <p style="margin-left: 60.0px;">• 형식 : recopick=method 값</p>
            <p style="margin-left: 60.0px;"><span>• 예1 : </span><span class="nolink"><a href="http://aaa.test.co.kr/product/detail.html?product_no=1338&amp;" class="external-link">http://aaa.test.co.kr/product/detail.html?product_no=1338&amp;</a><span style="color: rgb(255,0,0);">recopick=4&amp;product_type=R</span></span></p>
            <p style="margin-left: 60.0px;">• 예2 : <span class="nolink"><a href="http://test.xxx.co.kr/product/product_detail/3395854" class="external-link">http://test.xxx.co.kr/product/product_detail/3395854</a>?<span style="color: rgb(255,0,0);">recopick=25&amp;product_type=R</span></span></p>
            <p><br /></p>
            <ul>
                <li style="list-style-type: none;">
                    <p><br /></p>
                </li>
            </ul>
        </div>
    </div>
</template>
<script setup>
</script>