<template>
    <div class="page view">
        <h1>위사</h1>
        <div id="main-content" class="wiki-content">
            <br>
            <div class="confluence-information-macro confluence-information-macro-note conf-macro output-block" data-hasbody="true" data-macro-name="note">
                <p class="title"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> &nbsp;&nbsp; 본 가이드는 위사에 해당하는 내용입니다.</p>
                <div class="confluence-information-macro-body">
                    <p><br></p>
                    <p> </p>
                    <div class="toc-macro client-side-toc-macro  conf-macro output-block hidden-outline" data-headerelements="H1,H2,H3,H4,H5,H6,H7" data-hasbody="false" data-macro-name="toc">
                        <ul style="">
                            <li><span class="toc-item-body" data-outline="1"><span class="toc-outline">1</span><a href="/pages/viewpage.action?pageId=3244228#id-%EC%9C%84%EC%82%AC-%3C1%EB%8B%A8%EA%B3%84%3E%EB%A0%88%EC%BD%94%ED%94%BD%EB%A1%9C%EA%B7%B8%EC%88%98%EC%A7%91%EC%8A%A4%ED%81%AC%EB%A6%BD%ED%8A%B8%EC%82%BD%EC%9E%85" class="toc-link">&lt;1단계&gt; 레코픽 로그수집 스크립트 삽입</a></span></li>
                            <li><span class="toc-item-body" data-outline="2"><span class="toc-outline">2</span><a href="/pages/viewpage.action?pageId=3244228#id-%EC%9C%84%EC%82%AC-%3C2%EB%8B%A8%EA%B3%84%3E%EC%83%81%ED%92%88%EC%A0%95%EB%B3%B4%EB%A9%94%ED%83%80%ED%83%9C%EA%B7%B8%EC%82%BD%EC%9E%85" class="toc-link">&lt;2단계&gt; 상품정보 메타 태그 삽입</a></span></li>
                            <li><span class="toc-item-body" data-outline="3"><span class="toc-outline">3</span><a href="/pages/viewpage.action?pageId=3244228#id-%EC%9C%84%EC%82%AC-%3C3%EB%8B%A8%EA%B3%84%3E%EC%B6%94%EC%B2%9C%EC%9C%84%EC%A0%AF%EC%82%BD%EC%9E%85" class="toc-link">&lt;3단계&gt; 추천 위젯 삽입</a></span></li>
                            <li><span class="toc-item-body" data-outline="4"><span class="toc-outline">4</span><a href="/pages/viewpage.action?pageId=3244228#id-%EC%9C%84%EC%82%AC-%EC%B6%94%EC%B2%9C%EC%84%9C%EB%B9%84%EC%8A%A4%EC%8B%9C%EC%9E%91(%EC%B6%94%EC%B2%9C%EC%9A%B0%EC%84%A0%EC%88%9C%EC%9C%84%EC%84%A4%EC%A0%95)" class="toc-link">추천서비스 시작 (추천우선 순위 설정)</a></span></li>
                        </ul>
                    </div>
                    <p> </p>
                </div>
            </div>
            <h1 id="id-위사-&lt;1단계&gt;레코픽로그수집스크립트삽입"><strong>&lt;1단계&gt; 레코픽 로그수집 스크립트 삽입</strong></h1>
            <p><br></p>
            <ol>
                <li>
                    <p class="p1"><span class="s1">Wisa 쇼핑몰 관리자 페이지에 로그인한 후 <strong>디자인 관리</strong> &gt; <strong>레이아웃 편집</strong>을 선택합니다.</span></p>
                    <p><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/wisa_pc_01.png" data-image-src="/download/attachments/3244228/wisa_pc_01.png?version=1&amp;modificationDate=1458801057000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="5472310" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="wisa_pc_01.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="3244228" data-linked-resource-container-version="35"></span></p>
                    <p><br></p>
                </li>
                <li>
                    <p class="p1"><strong>레이아웃 편집</strong> &gt; <strong>하단</strong>을 선택, 아래 로그수집 스크립트를 복사후 편집화면 최하단에 삽입하세요!</p>
                    <p class="p1"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/wisa_pc_02.png" data-image-src="/download/attachments/3244228/wisa_pc_02.png?version=1&amp;modificationDate=1458801072000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="5472311" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="wisa_pc_02.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="3244228" data-linked-resource-container-version="35"></span></p>
                    <p><br></p>
                    <div class="panel conf-macro output-block" style="background-color: #ffffff;border-color: #007eff;border-style: solid;border-width: 1px;" data-hasbody="true" data-macro-name="panel">
                        <div class="panelHeader" style="border-bottom-width: 1px;border-bottom-style: solid;border-bottom-color: #007eff;background-color: #007eff;color: #ffffff;"><b>기본 로그수집 스크립트</b></div>
                        <div class="panelContent" style="background-color: #ffffff;">
                            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                                <div class="codeContent panelContent pdl">
                                    <div>
                                        <div id="highlighter_42505" class="syntaxhighlighter sh-midnight nogutter  coldfusion">
                                            <div class="toolbar"><span><a href="/pages/viewpage.action?pageId=3244228#" class="toolbar_item command_help help">?</a></span></div>
                                            <table border="0" cellpadding="0" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="code">
                                                        <div class="container" title="Hint: double-click to select code">
                                                            <div class="line number1 index0 alt2"><code class="coldfusion comments">&lt;!--RecoPick 로그수집 스크립트 --&gt;</code></div>
                                                            <div class="line number2 index1 alt1"><code class="coldfusion plain">&lt;script type=</code><code class="coldfusion string">"text/javascript"</code><code class="coldfusion plain">&gt;</code></div>
                                                            <div class="line number3 index2 alt2"><code class="coldfusion spaces">&nbsp;&nbsp;</code><code class="coldfusion plain">(function(w,d,n,s,e,o) {</code></div>
                                                            <div class="line number4 index3 alt1"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">w[n]=w[n]||function(){(w[n].q=w[n].q||[]).push(arguments)};</code></div>
                                                            <div class="line number5 index4 alt2"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">e=d.createElement(s);e.async=1;e.charset=</code><code class="coldfusion string">'utf-8'</code><code class="coldfusion plain">;e.src=</code><code class="coldfusion string">'//static.recopick.com/dist/production.min.js'</code><code class="coldfusion plain">;</code></div>
                                                            <div class="line number6 index5 alt1"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">o=d.getElementsByTagName(s)[0];o.parentNode.insertBefore(e,o);</code></div>
                                                            <div class="line number7 index6 alt2"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">})(window, document, </code><code class="coldfusion string">'recoPick'</code><code class="coldfusion plain">, </code><code class="coldfusion string">'script'</code><code class="coldfusion plain">);</code></div>
                                                            <div class="line number8 index7 alt1"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">recoPick(</code><code class="coldfusion string">'service'</code><code class="coldfusion plain">, </code><code class="coldfusion string">'사이트 url'</code><code class="coldfusion plain">);</code></div>
                                                            <div class="line number9 index8 alt2"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">recoPick(</code><code class="coldfusion string">'sendLog'</code><code class="coldfusion plain">, </code><code class="coldfusion string">'wisa'</code><code class="coldfusion plain">);</code></div>
                                                            <div class="line number10 index9 alt1"><code class="coldfusion plain">&lt;/script&gt;</code></div>
                                                            <div class="line number11 index10 alt2"><code class="coldfusion comments">&lt;!--RecoPick 로그수집 스크립트 --&gt;</code></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p><img class="emoticon emoticon-warning" src="@/assets/images/warning.svg" data-emoticon-name="warning" alt="(warning)"><span style="color: rgb(255,0,0);">&nbsp;주의하세요!&nbsp;</span>'사이트 url' 은 www를 제외한&nbsp;사이트 주소로 수정합니다.&nbsp;반드시 대소문자 구별을 꼭 해주셔야 합니다.</p>
                    <p><br></p>
                </li>
                <li>
                    <p>공통 헤더용 로그수집 스크립트 삽입후 더욱 특화된 개인화 추천을 원하시면 암호화된 ID, 연령, 성별 데이터를 보내주세요!&nbsp;</p>
                    <p><span style="color: rgb(0,0,0);">*&nbsp;</span>(’service’, ‘ 사이트 url’); 바로 다음에 아래 스크립트를 삽입해 주시면 됩니다. 출생년도, 성별은 예시 참고하셔서 변경해 주시고 MEMBER_ID(MID)는 자체적으로 사용중인 회원 ID를 암호화해서 보내주세요.</p>
                    <p><span style="color: rgb(255,0,0);"><span style="color: rgb(0,0,0);">*&nbsp;레코픽에서 수집하는 암호화된 ID, 연령, 성별 데이터로는 절대 개개인을 식별할 수 없으며, 단순히 사용자를 구분하기 위한 용도로만 사용됩니다.<br></span></span>&nbsp; 예를 들어, 같은 회원이 PC와 모바일을 통해 접속한다면&nbsp;MID를 통해 &nbsp;매핑하면 RecoPick에서도 두 사용자를 같은 회원으로 인식하여 개인화 추천 등의 품질 향상에 도움이 됩니다.</p>
                    <p><img class="emoticon emoticon-warning" src="@/assets/images/warning.svg" data-emoticon-name="warning" alt="(warning)"><span style="color: rgb(255,0,0);">&nbsp;주의하세요!&nbsp;</span>레코픽 추천을 API 방식으로 사용하는 경우, GET방식으로 API를 호출하게 됩니다.<span style="color: rgb(255,0,0);"><span style="color: rgb(0,0,0);"><br></span></span>&nbsp;&nbsp;이 경우 일부 API의 파라미터에&nbsp;MID가 사용되는데,&nbsp;MID&nbsp;값에 일부 특수문자(예&gt; &amp;, =) 가 들어가게 되면 정상동작 하지 않을 수 있습니다.<span style="color: rgb(255,0,0);"><span style="color: rgb(0,0,0);"><br></span></span>&nbsp; 암호화된 결과에 특수문자가 들어가지 않는 암호화 알고리즘으로 단방향 암호화 해주세요. (예&gt; SHA-256, MD5 등)</p>
                    <p>*&nbsp;MID&nbsp;단방향 암호화 예 :&nbsp;88020d58ceb8a4ad7f6756021fbc06b8a91f17daad2f04a5d369608d9c308bde (SHA-256으로 암호화),&nbsp;4509bf9373ff3dca193ba02726c87988 (MD5로 암호화)</p>
                    <div class="panel conf-macro output-block" style="background-color: #ffffff;border-color: #007eff;border-style: solid;border-width: 1px;" data-hasbody="true" data-macro-name="panel">
                        <div class="panelHeader" style="border-bottom-width: 1px;border-bottom-style: solid;border-bottom-color: #007eff;background-color: #007eff;color: #ffffff;"><b>사용자 구분을 위한 로그수집 스크립트</b></div>
                        <div class="panelContent" style="background-color: #ffffff;">
                            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                                <div class="codeContent panelContent pdl">
                                    <div>
                                        <div id="highlighter_718929" class="syntaxhighlighter sh-midnight nogutter  vb">
                                            <div class="toolbar"><span><a href="/pages/viewpage.action?pageId=3244228#" class="toolbar_item command_help help">?</a></span></div>
                                            <table border="0" cellpadding="0" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="code">
                                                        <div class="container" title="Hint: double-click to select code">
                                                            <div class="line number1 index0 alt2"><code class="vb plain">recoPick(</code><code class="vb comments">'setMID',암호화된 MEMBER_ID); </code></div>
                                                            <div class="line number2 index1 alt1"><code class="vb plain">recoPick(</code><code class="vb comments">'setUserInfo',{ birthyear:‘출생연도’, gender:'성별’});</code></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p>&lt;예시&gt;</p>
                    <p>* 출생년도, 성별 정보 (M: 남자, F: 여자, O: 기타)</p>
                    <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                        <div class="codeContent panelContent pdl">
                            <div>
                                <div id="highlighter_869006" class="syntaxhighlighter sh-confluence nogutter  js">
                                    <div class="toolbar"><span><a href="/pages/viewpage.action?pageId=3244228#" class="toolbar_item command_help help">?</a></span></div>
                                    <table border="0" cellpadding="0" cellspacing="0">
                                        <tbody>
                                        <tr>
                                            <td class="code">
                                                <div class="container" title="Hint: double-click to select code">
                                                    <div class="line number1 index0 alt2"><code class="js plain">recoPick(</code><code class="js string">'setUserInfo'</code><code class="js plain">,{ birthyear: ‘1987’, gender: 'M’});</code></div>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p></p>
                </li>
            </ol>
            <h1 id="id-위사-&lt;2단계&gt;상품정보메타태그삽입"><strong>&lt;2단계&gt; 상품정보 메타 태그 삽입</strong></h1>
            <p>&nbsp; &nbsp;1.&nbsp; 디자인관리 &gt; 페이지 편집 &gt; 상품 상세 내용 선택후 편집화면 최상단에 삽입해 주세요!&nbsp;메타태그 정보를 통해 레코픽 추천위젯이 표시되기 때문에 정확하게 넣어주시기 바랍니다.&nbsp;</p>
            <p>&nbsp; &nbsp; &nbsp;<span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/wisa_pc_03.png" data-image-src="/download/attachments/3244228/wisa_pc_03.png?version=1&amp;modificationDate=1458801146000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="5472312" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="wisa_pc_03.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="3244228" data-linked-resource-container-version="35"></span></p>
            <ul>
                <li>
                    <div class="panel conf-macro output-block" style="background-color: #ffffff;border-color: #007eff;border-style: solid;border-width: 1px;" data-hasbody="true" data-macro-name="panel">
                        <div class="panelHeader" style="border-bottom-width: 1px;border-bottom-style: solid;border-bottom-color: #007eff;background-color: #007eff;color: #ffffff;"><b>기본 메타 태그</b></div>
                        <div class="panelContent" style="background-color: #ffffff;">
                            <p>&lt;! -- RecoPick 메타 태그 --&gt;<br>&lt;meta property="recopick:title" content="{{$상품명}}"&gt;<br>&lt;meta property="<span>recopick</span>:image" content=”{{$상품이미지}}”&gt;<br>&lt;meta property="<span>recopick</span>:price" content="{$상품가격}"&gt;<br>&lt;meta property="recopick:price:currency" content=”KRW"&gt;<br>&lt;! -- RecoPick 메타 태그 --&gt;</p>
                        </div>
                    </div>
                </li>
            </ul>
            <p>2.&nbsp; 기본 메타 태그 삽입후 상황에 따라 아래 태그를 추가해주세요!</p>
            <ul>
                <li>
                    <div class="panel conf-macro output-block" style="background-color: #ffffff;border-color: #007eff;border-style: solid;border-width: 1px;" data-hasbody="true" data-macro-name="panel">
                        <div class="panelHeader" style="border-bottom-width: 1px;border-bottom-style: solid;border-bottom-color: #007eff;background-color: #007eff;color: #ffffff;"><b>할인 가격이 있을 경우</b></div>
                        <div class="panelContent" style="background-color: #ffffff;">
                            <p>&lt;meta property="recopick:sale_price:amount" content="<strong>{</strong>{<strong>$할인</strong><strong>가격}}</strong>"&gt;</p>
                            <p>&lt;meta property="recopick:sale_price:currency" content="<strong style="line-height: 1.42857;">KRW</strong>"&gt;</p>
                        </div>
                    </div>
                </li>
            </ul>
            <ul>
                <li>현재 가격, 할인 가격을 둘 다 표시할 수 있습니다.</li>
                <li>
                    <div class="panel conf-macro output-block" style="background-color: #ffffff;border-color: #007eff;border-style: solid;border-width: 1px;" data-hasbody="true" data-macro-name="panel">
                        <div class="panelHeader" style="border-bottom-width: 1px;border-bottom-style: solid;border-bottom-color: #007eff;background-color: #007eff;color: #ffffff;"><b>상품이 품절 상태인 경우</b></div>
                        <div class="panelContent" style="background-color: #ffffff;">
                            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                                <div class="codeContent panelContent pdl">
                                    <div>
                                        <div id="highlighter_449571" class="syntaxhighlighter sh-eclipse nogutter  actionscript3">
                                            <div class="toolbar"><span><a href="/pages/viewpage.action?pageId=3244228#" class="toolbar_item command_help help">?</a></span></div>
                                            <table border="0" cellpadding="0" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="code">
                                                        <div class="container" title="Hint: double-click to select code">
                                                            <div class="line number1 index0 alt2"><code class="actionscript3 plain">&lt;!--{ ? runout}--&gt;</code></div>
                                                            <div class="line number2 index1 alt1"><code class="actionscript3 plain">&lt;meta property=</code><code class="actionscript3 string">"recopick:availability"</code> <code class="actionscript3 plain">content=</code><code class="actionscript3 string">"oos"</code><code class="actionscript3 plain">&gt;</code></div>
                                                            <div class="line number3 index2 alt2"><code class="actionscript3 plain">&lt;!--{ / }--&gt;</code></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p>&nbsp;&nbsp;</p>
                </li>
            </ul>
            <p>3. Content 블루텍스트 부분은 실제 사용하는 변수로 수정해 주셔야 하며 반드시 큰따옴표(double-quotes)로 묶여야 합니다.</p>
            <p><strong>&lt; 메타 태그 설명 &gt;</strong></p>
            <div class="table-wrap">
                <table class="confluenceTable tablesorter tablesorter-default" role="grid">
                    <colgroup>
                        <col>
                        <col>
                    </colgroup>
                    <thead>
                    <tr role="row" class="tablesorter-headerRow">
                        <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="Property: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                            <div class="tablesorter-header-inner">Property</div>
                        </th>
                        <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="1" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="Content: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                            <div class="tablesorter-header-inner">Content</div>
                        </th>
                    </tr>
                    </thead>
                    <tbody aria-live="polite" aria-relevant="all">
                    <tr role="row">
                        <td class="confluenceTd">
                            <p><strong>recopick:title</strong> (상품명)</p>
                            <p>*필수 메타 입니다.</p>
                        </td>
                        <td class="confluenceTd">
                            <p><span style="color: rgb(51,102,255);"><strong>{</strong><strong>{</strong><strong>$상품명}}</strong></span></p>&lt;예시&gt; “ 클래식 체크 스커트”
                        </td>
                    </tr>
                    <tr role="row">
                        <td colspan="1" class="confluenceTd">
                            <p><strong>recopick:image</strong> (상품이미지)</p>
                            <p><span>*필수 메타 입니다.</span></p>
                        </td>
                        <td colspan="1" class="confluenceTd">
                            <p><span style="color: rgb(51,102,255);">&nbsp;</span></p>
                            <p><span style="color: rgb(51,102,255);"><strong>{</strong>{<strong>$</strong><strong>상품이미지}}</strong></span></p>
                            <p><span style="color: rgb(0,0,0);">* 추천위젯에 이미지가 제대로 보일 수 있도록 하려면 가로/세로 사이즈 비율이 동일한 이미지를 사용하세요!</span></p>
                        </td>
                    </tr>
                    <tr role="row">
                        <td class="confluenceTd">
                            <p><strong>recopick:price</strong> (정상가격)</p>
                            <p><span>*필수 메타 입니다.</span></p>
                        </td>
                        <td class="confluenceTd"><span style="color: rgb(51,102,255);"> <strong>{$소비자가격}</strong></span></td>
                    </tr>
                    <tr role="row">
                        <td class="confluenceTd">
                            <p><strong>recopick:price:currency</strong> (가격표시)</p>
                            <p><span>*필수 메타 입니다.</span></p>
                        </td>
                        <td class="confluenceTd">
                            <p><span style="color: rgb(51,102,255);"><strong>KRW</strong></span></p>
                            <p>&lt;예시&gt; 원화인 경우 “KRW,” 달러화인 경우 “USD”, 엔화인 경우 “JPY”</p>
                        </td>
                    </tr>
                    <tr role="row">
                        <td colspan="1" class="confluenceTd">
                            <p><strong>recopick:description</strong> (상품설명)</p>
                            <p><br></p>
                        </td>
                        <td colspan="1" class="confluenceTd"><strong>{$상품요약설명}</strong></td>
                    </tr>
                    <tr role="row">
                        <td class="confluenceTd"><strong>recopick:author</strong> (상품의 브랜드 or 판매자 정보)</td>
                        <td class="confluenceTd"><span style="color: rgb(51,102,255);"><strong>브랜드 or 판매자 정보</strong></span>
                            <p>&lt;예시&gt; “ Nike”</p>
                        </td>
                    </tr>
                    <tr role="row">
                        <td class="confluenceTd">
                            <p><strong>recopick:sale_price</strong> (할인가격)</p>
                            <p><br></p>
                        </td>
                        <td class="confluenceTd">
                            <p><span style="color: rgb(51,102,255);"><strong>{$판매가격}</strong></span></p>
                        </td>
                    </tr>
                    <tr role="row">
                        <td colspan="1" class="confluenceTd">
                            <p><strong>recopick:sale_price:currency</strong> (가격표시)</p>
                            <p>* 할인가격 여부를 체크하는 if 조건문 내에 위치시켜 주세요.</p>
                        </td>
                        <td colspan="1" class="confluenceTd">
                            <p><span style="color: rgb(51,102,255);"><strong>KRW</strong></span></p>
                            <p>&lt;예시&gt; 원화인 경우 “KRW,” 달러화인 경우 “USD”, 엔화인 경우 “JPY”</p>
                        </td>
                    </tr>
                    <tr role="row">
                        <td class="confluenceTd">
                            <p><strong>recopick:availability</strong> (상품 품절 표시)</p>
                            <p>* 상품 품절인 경우에만 사용될 수 있도록 상품 품절여부를 체크하는 if 조건문 내에 위치시켜 주세요.</p>
                        </td>
                        <td class="confluenceTd">
                            <p><strong>OOS</strong> (out of service 약자)</p>
                        </td>
                    </tr>
                    <tr role="row">
                        <td colspan="1" class="confluenceTd"><strong>recopick:c1</strong>&nbsp;(대분류)</td>
                        <td colspan="1" class="confluenceTd"><strong>대분류 상품 카테고리</strong></td>
                    </tr>
                    <tr role="row">
                        <td colspan="1" class="confluenceTd"><strong>recopick:c2</strong>&nbsp;(중분류)</td>
                        <td colspan="1" class="confluenceTd"><strong>중분류 상품 카테고리</strong></td>
                    </tr>
                    <tr role="row">
                        <td colspan="1" class="confluenceTd"><strong>recopick:c3</strong>&nbsp;(소분류)</td>
                        <td colspan="1" class="confluenceTd"><strong>소분류 상품 카테고리</strong></td>
                    </tr>
                    <tr role="row">
                        <td colspan="1" class="confluenceTd"><strong>recopick:brand</strong>&nbsp;(브랜드 카테고리)</td>
                        <td colspan="1" class="confluenceTd"><strong>브랜드 카테고리</strong></td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <h1 id="id-위사-&lt;3단계&gt;추천위젯삽입"><strong><br>&lt;3단계&gt; 추천 위젯 삽입</strong></h1>
            <p><br></p>
            <p><strong>&nbsp;<a href="http://admin.recopick.com/" class="external-link">Admin Dashboard</a></strong>&nbsp;&gt;&nbsp;<strong>추천 위젯 설정</strong>에 들어가셔서 아래 가이드에 따라 추천위젯을 설정합니다.</p>
            <p>&nbsp;<strong>상품상세, 메인, 장바구니, 구매완료, 마이페이지</strong>&nbsp;등 추가로 생성하신 후 생성된 스크립트를 고객님 쇼핑몰 페이지별로 적용해 주세요.</p>
            <p><br></p>
            <p><span style="color: rgb(255,0,0);"><span style="color: rgb(0,0,0);">*</span>추천 위젯을 삽입후 사이트에서 추천결과를 바로 확인하실 수 없습니다.</span></p>
            <p>추천계산을 위해 데이터 수집 후, 2~3일이 지나서 추천결과를 확인하실 수 있는데 추천결과 반영은 메일을 통해 안내해 드립니다. 안내에 따라 노출을 원하시는 시점에 추천타입을 설정한 후 저장하시면 사이트에서 반영하실 수 있습니다.</p>
            <p><br></p>
            <p><strong>1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong><strong>상품 상세 페이지&nbsp;<span style="color: rgb(255,102,0);">상단</span>&nbsp;( 함께 본 상품 추천)</strong></p>
            <ol>
                <li>위젯 관리가 용의하도록 위젯명을&nbsp;<strong>상품상세_상단</strong>으로 입력합니다.</li>
                <li>채널별 성과분석시(추천클릭률, 추천경유매출 등) 비교가 용이하도록&nbsp;<strong>detail_top</strong>으로 입력합니다.&nbsp;</li>
                <li>추천타입은 우선 사용안함으로 설정합니다. (데이터 수집후, 2~3일이 지나서 설정합니다.)</li>
                <li>
                    <p>그 외 원하시는 대로 위젯 스타일을 변경하실 수 있으며 할인가 표시를 원하시면 표시여부를 체크해 주세요.&nbsp;&nbsp;</p>
                    <div class="table-wrap">
                        <table class="confluenceTable tablesorter tablesorter-default" role="grid">
                            <thead>
                            <tr role="row" class="tablesorter-headerRow">
                                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;위젯설정 예시&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                    <div class="tablesorter-header-inner">&lt;위젯설정 예시&gt;</div>
                                </th>
                            </tr>
                            </thead>
                            <tbody aria-live="polite" aria-relevant="all">
                            <tr role="row">
                                <td class="confluenceTd"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/widget_001.png" data-image-src="/download/attachments/491543/widget_001.png?version=1&amp;modificationDate=1461817382000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127617" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="widget_001.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="491543" data-linked-resource-container-version="264"></span></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </li>
                <li>
                    <p>모든 설정을 마친 후에는 꼭&nbsp;<strong>저장</strong>&nbsp;버튼을 클릭하시고 아래 위젯 적용 부분의 스크립트를 복사해서 고객님 사이트의&nbsp;<strong>상품 상세 페이지 상단</strong>에 삽입해 주세요. &lt;삽입 위치 참고&gt;</p>
                    <div class="table-wrap">
                        <table class="confluenceTable tablesorter tablesorter-default" role="grid">
                            <thead>
                            <tr role="row" class="tablesorter-headerRow">
                                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;위젯 적용&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                    <div class="tablesorter-header-inner">&lt;위젯 적용&gt;</div>
                                </th>
                                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="1" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;삽입 위치&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                    <div class="tablesorter-header-inner">&lt;삽입 위치&gt;</div>
                                </th>
                            </tr>
                            </thead>
                            <tbody aria-live="polite" aria-relevant="all">
                            <tr role="row">
                                <td class="confluenceTd"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/widget_02.png" data-image-src="/download/attachments/491543/widget_02.png?version=2&amp;modificationDate=1458716445000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="5472259" data-linked-resource-version="2" data-linked-resource-type="attachment" data-linked-resource-default-alias="widget_02.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="491543" data-linked-resource-container-version="264"></span></td>
                                <td class="confluenceTd"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/widget_003.png" data-image-src="/download/attachments/3244203/widget_003.png?version=3&amp;modificationDate=1505123441000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127754" data-linked-resource-version="3" data-linked-resource-type="attachment" data-linked-resource-default-alias="widget_003.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="3244203" data-linked-resource-container-version="57"></span></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </li>
            </ol>
            <p><strong><br></strong></p>
            <p><strong>2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong><strong>상품 상세 페이지&nbsp;<span style="color: rgb(255,102,0);">하</span><span style="color: rgb(255,102,0);">단</span>&nbsp;( 함께 구매한 상품 추천)</strong></p>
            <ol>
                <li>
                    <p class="p1"><span class="s1">새 스타일 추가 버튼을 클릭해서 새로운 탭이 생성되면 위젯명을&nbsp;<strong>상품상세_하단</strong>으로 입력합니다.</span></p>
                </li>
                <li>
                    <p class="p1"><span class="s1">채널 별 성과 분석 시(추천클릭률, 추천경유매출 등) 비교가 용이하도록&nbsp;<strong>detail_bottom</strong>으로 입력합니다.&nbsp;</span></p>
                </li>
                <li>
                    <p class="p1">추천타입은 우선 사용안함으로 설정합니다. (데이터 수집후, 2~3일이 지나서 설정합니다.)</p>
                </li>
                <li>
                    <p>그 외 원하시는 대로 위젯 스타일을 변경하실 수 있으며 할인가 표시를 원하시면 표시여부를 체크해 주세요.&nbsp;</p>
                    <div class="table-wrap">
                        <table class="confluenceTable tablesorter tablesorter-default" role="grid">
                            <thead>
                            <tr role="row" class="tablesorter-headerRow">
                                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;위젯설정 예시&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                    <div class="tablesorter-header-inner">&lt;위젯설정 예시&gt;</div>
                                </th>
                            </tr>
                            </thead>
                            <tbody aria-live="polite" aria-relevant="all">
                            <tr role="row">
                                <td class="confluenceTd"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/widget_002.png" data-image-src="/download/attachments/491543/widget_002.png?version=1&amp;modificationDate=1461817694000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127618" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="widget_002.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="491543" data-linked-resource-container-version="264"></span></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </li>
                <li>
                    <p>모든 설정을 마친 후에는 꼭&nbsp;<strong>저장</strong>&nbsp;버튼을 클릭하시고 아래 위젯 적용 부분의 스크립트를 복사해서 고객님 사이트의&nbsp;<strong>상품 상세 페이지 하단</strong>에 삽입해 주세요. &lt;삽입 위치 참고&gt;</p>
                    <div class="table-wrap">
                        <table class="confluenceTable tablesorter tablesorter-default" role="grid">
                            <thead>
                            <tr role="row" class="tablesorter-headerRow">
                                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;위젯 적용&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                    <div class="tablesorter-header-inner">&lt;위젯 적용&gt;</div>
                                </th>
                                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="1" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;삽입 위치&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                    <div class="tablesorter-header-inner">&lt;삽입 위치&gt;</div>
                                </th>
                            </tr>
                            </thead>
                            <tbody aria-live="polite" aria-relevant="all">
                            <tr role="row">
                                <td class="confluenceTd"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/widget_02.png" data-image-src="/download/attachments/491543/widget_02.png?version=2&amp;modificationDate=1458716445000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="5472259" data-linked-resource-version="2" data-linked-resource-type="attachment" data-linked-resource-default-alias="widget_02.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="491543" data-linked-resource-container-version="264"></span></td>
                                <td class="confluenceTd"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/widget_006.png" data-image-src="/download/attachments/3244203/widget_006.png?version=3&amp;modificationDate=1505123472000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127755" data-linked-resource-version="3" data-linked-resource-type="attachment" data-linked-resource-default-alias="widget_006.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="3244203" data-linked-resource-container-version="57"></span></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </li>
            </ol>
            <p><strong><br></strong></p>
            <p><strong>3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong><strong>메인 페이지&nbsp;&nbsp;(실시간 개인화 추천)</strong></p>
            <ol>
                <li>
                    <p class="p1"><span class="s1">새 스타일 추가 버튼을 클릭해서 새로운 탭이 생성되면 위젯명을&nbsp;<strong>메인</strong>으로 입력합니다.</span></p>
                </li>
                <li>
                    <p class="p1"><span class="s1">채널 별 성과분석시(추천클릭률, 추천경유매출 등) 비교가 용이하도록&nbsp;<strong>main</strong>으로 입력합니다.&nbsp;</span></p>
                </li>
                <li>
                    <p class="p1">추천타입은 우선 사용안함으로 설정합니다. (데이터 수집후, 2~3일이 지나서 설정합니다.)</p>
                </li>
                <li>
                    <p>그 외 원하시는 대로 위젯 스타일을 변경하실 수 있으며 할인가 표시를 원하시면 표시여부를 체크해 주세요.&nbsp;</p>
                    <div class="table-wrap">
                        <table class="confluenceTable tablesorter tablesorter-default" role="grid">
                            <thead>
                            <tr role="row" class="tablesorter-headerRow">
                                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;위젯설정 예시&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                    <div class="tablesorter-header-inner">&lt;위젯설정 예시&gt;</div>
                                </th>
                            </tr>
                            </thead>
                            <tbody aria-live="polite" aria-relevant="all">
                            <tr role="row">
                                <td class="confluenceTd"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/widget_0031.png" data-image-src="/download/attachments/3244203/widget_0031.png?version=2&amp;modificationDate=1505123497000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127762" data-linked-resource-version="2" data-linked-resource-type="attachment" data-linked-resource-default-alias="widget_0031.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="3244203" data-linked-resource-container-version="57"></span></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </li>
                <li>
                    <p>모든 설정을 마친 후에는 꼭&nbsp;<strong>저장</strong>&nbsp;버튼을 클릭하시고 아래 위젯 적용 부분의 스크립트를 복사해서 고객님 사이트의&nbsp;<strong>메인 페이지</strong>에 삽입해 주세요. &lt;삽입 위치 참고&gt;</p>
                    <div class="table-wrap">
                        <table class="confluenceTable tablesorter tablesorter-default" role="grid">
                            <thead>
                            <tr role="row" class="tablesorter-headerRow">
                                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;위젯 적용&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                    <div class="tablesorter-header-inner">&lt;위젯 적용&gt;</div>
                                </th>
                                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="1" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;삽입 위치&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                    <div class="tablesorter-header-inner">&lt;삽입 위치&gt;</div>
                                </th>
                            </tr>
                            </thead>
                            <tbody aria-live="polite" aria-relevant="all">
                            <tr role="row">
                                <td class="confluenceTd">&nbsp;<span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/widget_02.png" data-image-src="/download/attachments/491543/widget_02.png?version=2&amp;modificationDate=1458716445000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="5472259" data-linked-resource-version="2" data-linked-resource-type="attachment" data-linked-resource-default-alias="widget_02.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="491543" data-linked-resource-container-version="264"></span></td>
                                <td class="confluenceTd"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/widget_009.png" data-image-src="/download/attachments/3244203/widget_009.png?version=3&amp;modificationDate=1505123514000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127761" data-linked-resource-version="3" data-linked-resource-type="attachment" data-linked-resource-default-alias="widget_009.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="3244203" data-linked-resource-container-version="57"></span></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </li>
            </ol>
            <p><strong><br></strong></p>
            <p><strong>4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong><strong>장바구니 페이지&nbsp;&nbsp;( 함께 구매한 상품 추천)</strong></p>
            <ol>
                <li>
                    <p class="p1"><span class="s1">새 스타일 추가 버튼을 클릭해서 새로운 탭이 생성되면 위젯명을&nbsp;<strong>장바구니</strong>로 입력합니다.</span></p>
                </li>
                <li>
                    <p class="p1"><span class="s1">채널 별 성과분석시(추천클릭률, 추천경유매출 등) 비교가 용이하도록&nbsp;<strong>basket</strong>으로 입력합니다.&nbsp;</span></p>
                </li>
                <li>
                    <p class="p1">추천타입은 우선 사용안함으로 설정합니다. (데이터 수집후, 2~3일이 지나서 설정합니다.)</p>
                </li>
                <li>
                    <p>그 외 원하시는 대로 위젯 스타일을 변경하실 수 있으며 할인가 표시를 원하시면 표시여부를 체크해 주세요.&nbsp;</p>
                    <div class="table-wrap">
                        <table class="confluenceTable tablesorter tablesorter-default" role="grid">
                            <thead>
                            <tr role="row" class="tablesorter-headerRow">
                                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;위젯설정 예시&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                    <div class="tablesorter-header-inner">&lt;위젯설정 예시&gt;</div>
                                </th>
                            </tr>
                            </thead>
                            <tbody aria-live="polite" aria-relevant="all">
                            <tr role="row">
                                <td class="confluenceTd"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/widget_004.png" data-image-src="/download/attachments/491543/widget_004.png?version=1&amp;modificationDate=1461818275000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127620" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="widget_004.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="491543" data-linked-resource-container-version="264"></span></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </li>
                <li>
                    <p>모든 설정을 마친 후에는 꼭&nbsp;<strong>저장</strong>&nbsp;버튼을 클릭하시고 아래 위젯 적용 부분의 스크립트를 복사해서 고객님 사이트의&nbsp;<strong>장바구니 페이지</strong>에 삽입해 주세요. &lt;삽입 위치 참고&gt;</p>
                    <div class="table-wrap">
                        <table class="confluenceTable tablesorter tablesorter-default" role="grid">
                            <thead>
                            <tr role="row" class="tablesorter-headerRow">
                                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;위젯 적용&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                    <div class="tablesorter-header-inner">&lt;위젯 적용&gt;</div>
                                </th>
                                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="1" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;삽입 위치&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                    <div class="tablesorter-header-inner">&lt;삽입 위치&gt;</div>
                                </th>
                            </tr>
                            </thead>
                            <tbody aria-live="polite" aria-relevant="all">
                            <tr role="row">
                                <td class="confluenceTd">&nbsp;<span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/widget_02.png" data-image-src="/download/attachments/491543/widget_02.png?version=2&amp;modificationDate=1458716445000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="5472259" data-linked-resource-version="2" data-linked-resource-type="attachment" data-linked-resource-default-alias="widget_02.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="491543" data-linked-resource-container-version="264"></span></td>
                                <td class="confluenceTd"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/cafe_pc_100.png" data-image-src="/download/attachments/3244203/cafe_pc_100.png?version=2&amp;modificationDate=1505123540000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127764" data-linked-resource-version="2" data-linked-resource-type="attachment" data-linked-resource-default-alias="cafe_pc_100.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="3244203" data-linked-resource-container-version="57"></span></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </li>
            </ol>
            <p><strong><br></strong></p>
            <p><strong>5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong><strong>주문완료 페이지&nbsp;&nbsp;(실시간 개인화 추천)</strong></p>
            <ol>
                <li>
                    <p class="p1"><span class="s1">새 스타일 추가 버튼을 클릭해서 새로운 탭이 생성되면 위젯명을&nbsp;<strong>주문완료</strong>로 입력합니다.</span></p>
                </li>
                <li>
                    <p class="p1"><span class="s1">채널 별 성과분석시(추천클릭률, 추천경유매출 등) 비교가 용이하도록&nbsp;</span><strong>order_result</strong>으로 입력합니다.&nbsp;</p>
                </li>
                <li>
                    <p class="p1">추천타입은 우선 사용안함으로 설정합니다. (데이터 수집후, 2~3일이 지나서 설정합니다.)</p>
                </li>
                <li>
                    <p>그 외 원하시는 대로 위젯 스타일을 변경하실 수 있으며 할인가 표시를 원하시면 표시여부를 체크해 주세요.&nbsp;</p>
                    <div class="table-wrap">
                        <table class="confluenceTable tablesorter tablesorter-default" role="grid">
                            <thead>
                            <tr role="row" class="tablesorter-headerRow">
                                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;위젯설정 예시&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                    <div class="tablesorter-header-inner">&lt;위젯설정 예시&gt;</div>
                                </th>
                            </tr>
                            </thead>
                            <tbody aria-live="polite" aria-relevant="all">
                            <tr role="row">
                                <td class="confluenceTd"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/widget_005.png" data-image-src="/download/attachments/491543/widget_005.png?version=1&amp;modificationDate=1461818315000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127621" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="widget_005.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="491543" data-linked-resource-container-version="264"></span></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </li>
                <li>
                    <p>모든 설정을 마친 후에는 꼭&nbsp;<strong>저장</strong>&nbsp;버튼을 클릭하시고 아래 위젯 적용 부분의 스크립트를 복사해서 고객님 사이트의&nbsp;<strong>주문완료 페이지</strong>에 삽입해 주세요. &lt;삽입 위치 참고&gt;</p>
                    <div class="table-wrap">
                        <table class="confluenceTable tablesorter tablesorter-default" role="grid">
                            <thead>
                            <tr role="row" class="tablesorter-headerRow">
                                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;위젯 적용&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                    <div class="tablesorter-header-inner">&lt;위젯 적용&gt;</div>
                                </th>
                                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="1" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;삽입 위치&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                    <div class="tablesorter-header-inner">&lt;삽입 위치&gt;</div>
                                </th>
                            </tr>
                            </thead>
                            <tbody aria-live="polite" aria-relevant="all">
                            <tr role="row">
                                <td class="confluenceTd">&nbsp;<span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/widget_02.png" data-image-src="/download/attachments/491543/widget_02.png?version=2&amp;modificationDate=1458716445000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="5472259" data-linked-resource-version="2" data-linked-resource-type="attachment" data-linked-resource-default-alias="widget_02.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="491543" data-linked-resource-container-version="264"></span></td>
                                <td class="confluenceTd"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/widget_018.png" data-image-src="/download/attachments/3244203/widget_018.png?version=1&amp;modificationDate=1505123552000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127773" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="widget_018.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="3244203" data-linked-resource-container-version="57"></span></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </li>
            </ol>
            <p><strong>6. &nbsp; &nbsp; 마이</strong><strong>&nbsp;페이지&nbsp;&nbsp;(실시간 개인화 추천)</strong></p>
            <ol>
                <li>
                    <p class="p1"><span class="s1">새 스타일 추가 버튼을 클릭해서 새로운 탭이 생성되면 위젯명을&nbsp;<strong>마이페이지</strong>로 입력합니다.</span></p>
                </li>
                <li>
                    <p class="p1"><span class="s1">채널 별 성과분석시(추천클릭률, 추천경유매출 등) 비교가 용이하도록&nbsp;</span><strong>mypage</strong>으로 입력합니다.&nbsp;</p>
                </li>
                <li>
                    <p class="p1">추천타입은 우선 사용안함으로 설정합니다. (데이터 수집후, 2~3일이 지나서 설정합니다.)</p>
                </li>
                <li>
                    <p>그 외 원하시는 대로 위젯 스타일을 변경하실 수 있으며 할인가 표시를 원하시면 표시여부를 체크해 주세요.&nbsp;</p>
                    <div class="table-wrap">
                        <table class="confluenceTable tablesorter tablesorter-default" role="grid">
                            <thead>
                            <tr role="row" class="tablesorter-headerRow">
                                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;위젯설정 예시&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                    <div class="tablesorter-header-inner">&lt;위젯설정 예시&gt;</div>
                                </th>
                            </tr>
                            </thead>
                            <tbody aria-live="polite" aria-relevant="all">
                            <tr role="row">
                                <td class="confluenceTd"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/widget_0061.png" data-image-src="/download/attachments/3244203/widget_0061.png?version=2&amp;modificationDate=1505123571000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127766" data-linked-resource-version="2" data-linked-resource-type="attachment" data-linked-resource-default-alias="widget_0061.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="3244203" data-linked-resource-container-version="57"></span></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </li>
                <li>
                    <p>모든 설정을 마친 후에는 꼭&nbsp;<strong>저장</strong>&nbsp;버튼을 클릭하시고 아래 위젯 적용 부분의 스크립트를 복사해서 고객님 사이트의&nbsp;<strong>마이 페이지</strong>에 삽입해 주세요. &lt;삽입 위치 참고&gt;</p>
                    <div class="table-wrap">
                        <table class="confluenceTable tablesorter tablesorter-default" role="grid">
                            <thead>
                            <tr role="row" class="tablesorter-headerRow">
                                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;위젯 적용&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                    <div class="tablesorter-header-inner">&lt;위젯 적용&gt;</div>
                                </th>
                                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="1" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;삽입 위치&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                    <div class="tablesorter-header-inner">&lt;삽입 위치&gt;</div>
                                </th>
                            </tr>
                            </thead>
                            <tbody aria-live="polite" aria-relevant="all">
                            <tr role="row">
                                <td class="confluenceTd"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/widget_02.png" data-image-src="/download/attachments/491543/widget_02.png?version=2&amp;modificationDate=1458716445000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="5472259" data-linked-resource-version="2" data-linked-resource-type="attachment" data-linked-resource-default-alias="widget_02.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="491543" data-linked-resource-container-version="264"></span></td>
                                <td class="confluenceTd"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/widget_0150.png" data-image-src="/download/attachments/3244203/widget_0150.png?version=1&amp;modificationDate=1505123587000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127775" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="widget_0150.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="3244203" data-linked-resource-container-version="57"></span></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </li>
            </ol>
          <p><strong><br></strong></p>
          <p><strong>7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong><strong>장바구니 팝업 </strong></p>
          <ol>
            <li>
              <p class="p1"><span class="s1">위젯 추가 버튼 클릭 후 전체 위젯List에 신규위젯이 생성되면 신규위젯을 클릭을 하여 위젯명을 “장바구니 팝업”으로 입력합니다.</span></p>
            </li>
            <li>
              <p class="p1"><span class="s1">채널 별 성과분석시(추천클릭률, 추천경유매출 등) 비교가 용이하도록 “장바구니팝업” 으로 입력합니다. </span></p>
            </li>
            <li>
              <p class="p1"><span class="s1">추천타입은 우선 사용안함으로 설정합니다. (데이터 수집후, 2~3일이 지나서 설정합니다.)</span></p>
              <div class="table-wrap">
                <table class="wrapped confluenceTable tablesorter tablesorter-default" role="grid">
                  <thead>
                  <tr role="row" class="tablesorter-headerRow">
                    <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0"
                        tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none"
                        aria-label="<위젯설정 예시>: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                      <div class="tablesorter-header-inner">&lt;위젯설정 예시&gt;</div>
                    </th>
                  </tr>
                  </thead>
                  <tbody aria-live="polite" aria-relevant="all">
                  <tr role="row">
                    <td class="confluenceTd">
                      <div class="content-wrapper"><span class="confluence-embedded-file-wrapper">
            <img src="@/assets/images/BasketPopupWidget/settingWidget.png"/></span>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </li>
            <li>
              <p class="p1"><span class="s1">그 외 원하시는 대로 위젯 스타일을 변경하실 수 있으며 할인가 표시를 원하시면 표시여부를 체크해 주세요.</span></p>
            </li>
            <li>
              <p class="p1"><span class="s1">	모든 설정을 마친 후에는 꼭 저장 버튼을 클릭하시고 아래 위젯 적용 부분의 스크립트를 복사해서 고객님 사이트의 장바구니팝업에 삽입해 주세요. </span></p>
              <div class="table-wrap">
                <table class="wrapped confluenceTable tablesorter tablesorter-default" role="grid">
                  <thead>
                  <tr role="row" class="tablesorter-headerRow">
                    <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0"
                        tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none"
                        aria-label="<위젯 적용>" style="user-select: none;">
                      <div class="tablesorter-header-inner">&lt;위젯 적용&gt; - 아래 위젯 소스를 장바구니 팝업 화면에 Copy&Paste </div>
                    </th>
                  </tr>
                  </thead>
                  <tbody aria-live="polite" aria-relevant="all">
                  <tr role="row">
                    <td class="confluenceTd">
                      <div class="content-wrapper"><span class="confluence-embedded-file-wrapper">
            <img src="@/assets/images/BasketPopupWidget/scriptWidget.png"/></span>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </li>
            <li>
              <p class="p1"><span class="s1">적용 예시 (PC& Mobile)</span></p>
              <div class="table-wrap">
                <table class="wrapped confluenceTable tablesorter tablesorter-default" role="grid">
                  <thead>
                  <tr role="row" class="tablesorter-headerRow">
                    <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0"
                        tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none"
                        aria-label="위젯적용예시" style="user-select: none;">
                      <div class="tablesorter-header-inner">&lt;위젯적용예시&gt;</div>
                    </th>
                  </tr>
                  </thead>
                  <tbody aria-live="polite" aria-relevant="all">
                  <tr role="row">
                    <td class="confluenceTd">
                      <div class="content-wrapper"><span class="confluence-embedded-file-wrapper">
            <img src="@/assets/images/BasketPopupWidget/widget_PC.png"/>
            <img src="@/assets/images/BasketPopupWidget/widget_M.png"/>
          </span>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </li>
          </ol>
            <h1 id="id-위사-추천서비스시작(추천우선순위설정)"><strong>추천서비스 시작 (추천우선 순위 설정)</strong></h1>
            <p><strong><span style="color: rgb(0,0,0);">로그수집 스크립트, 메타태그, 추천위젯 삽입까지 모두 완료하셨나요?</span></strong></p>
            <p><strong><span style="color: rgb(0,0,0);">제대로 설치하셨다면 데이터 수집된후, 2~3일이 지나서 추천결과를 반영할 수 있습니다.&nbsp;&nbsp;</span></strong></p>
            <p><br></p>
            <p>추천결과 반영시점은 메일을 통해 알려드립니다. 메일을 받으신 후 안내에 따라<strong>&nbsp;<strong>&nbsp;<a href="http://admin.recopick.com/" class="external-link">Admin Dashboard</a></strong>&nbsp;</strong>&nbsp;&gt;&nbsp;<strong>추천 위젯 설정</strong>에 들어가셔서</p>
            <p>사용안함으로 설정된 추천타입을 아래 가이드에 따라 설정한 후 저장하시면 사이트에서 추천결과를 확인 하실 수 있습니다.<strong>&nbsp;</strong></p>
            <p><strong><br></strong></p>
            <p>1.<strong>상품상세, 메인, 장바구니, 주문완료, 마이페이지&nbsp;</strong>등에 아래 예제처럼 추천타입을 설정합니다.</p>
            <p>2. 설정된 추천타입에 따라 그에 매치되는 타이틀로 변경합니다. (개인화 추천 예시 : ‘고객님을 위한 추천’, ‘고객님의 성향에 맞는 추천’ 등)</p>
            <p>3. 모든 설정을 마친 후에는 꼭 저장버튼을 클릭하셔야 사이트에 반영됩니다.&nbsp;</p>
            <p><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/widget_0007.png" data-image-src="/download/attachments/3244203/widget_0007.png?version=1&amp;modificationDate=1505123620000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127776" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="widget_0007.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="3244203" data-linked-resource-container-version="57"></span></p>
            <p>* 추천 설정은 가장 추천효과가 좋았던 best practice기반으로 안내하고 있으며 1순위 추천결과가 나타나지 않는 경우 다음 순위 추천결과를 노출합니다.</p>
            <div class="table-wrap">
                <table class="confluenceTable tablesorter tablesorter-default" role="grid">
                    <colgroup>
                        <col>
                        <col>
                        <col>
                        <col>
                        <col>
                        <col>
                        <col>
                        <col>
                    </colgroup>
                    <thead>
                    <tr role="row" class="tablesorter-headerRow">
                        <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="추천 설정: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                            <div class="tablesorter-header-inner">추천 설정</div>
                        </th>
                        <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="1" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="상품상세_상단: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                            <div class="tablesorter-header-inner">상품상세_상단</div>
                        </th>
                        <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="2" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="상품상세_하단: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                            <div class="tablesorter-header-inner">상품상세_하단</div>
                        </th>
                        <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="3" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="메인  페이지: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                            <div class="tablesorter-header-inner">메인&nbsp; 페이지</div>
                        </th>
                        <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="4" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="장바구니: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                            <div class="tablesorter-header-inner">
                                <p>장바구니</p>
                            </div>
                        </th>
                        <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="5" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="주문완료: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                            <div class="tablesorter-header-inner">주문완료</div>
                        </th>
                        <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="6" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="마이페이지: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                            <div class="tablesorter-header-inner">마이페이지</div>
                        </th>
                        <th colspan="1" class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="7" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="그외 페이지: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                            <div class="tablesorter-header-inner">그외 페이지</div>
                        </th>
                    </tr>
                    </thead>
                    <tbody aria-live="polite" aria-relevant="all">
                    <tr role="row">
                        <td class="confluenceTd">1순위</td>
                        <td class="confluenceTd">함께 본 상품 추천</td>
                        <td class="confluenceTd">실시간 개인화 추천</td>
                        <td class="confluenceTd">실시간 개인화 추천</td>
                        <td class="confluenceTd">함께 구매한 상품 추천</td>
                        <td class="confluenceTd"><p>통계형 추천</p>(장바구니 TOP 100)</td>
                        <td class="confluenceTd">실시간 개인화 추천</td>
                        <td colspan="1" class="confluenceTd">실시간 개인화 추천</td>
                    </tr>
                    <tr role="row">
                        <td class="confluenceTd">2순위</td>
                        <td class="confluenceTd">실시간 개인화 추천</td>
                        <td class="confluenceTd">함께 구매한 상품 추천</td>
                        <td class="confluenceTd"><p>통계형 추천</p>(Longtail TOP 100)</td>
                        <td class="confluenceTd">
                            <p>실시간 개인화 추천</p>
                        </td>
                        <td class="confluenceTd">함께 구매한 상품 추천</td>
                        <td class="confluenceTd">
                            <p>통계형 추천</p>(구매전환율 TOP 100)
                        </td>
                        <td colspan="1" class="confluenceTd">
                            <p>통계형 추천</p>(구매전환율 TOP 100)
                        </td>
                    </tr>
                    <tr role="row">
                        <td class="confluenceTd">3순위</td>
                        <td class="confluenceTd">
                            <p>통계형 추천</p>(구매전환율 TOP 100)
                        </td>
                        <td class="confluenceTd">
                            <p>통계형 추천</p>(구매전환율 TOP 100)
                        </td>
                        <td class="confluenceTd">사용안함</td>
                        <td class="confluenceTd">
                            <p>통계형 추천</p>(많이 팔린 상품 TOP 100)
                        </td>
                        <td class="confluenceTd">
                            <p>통계형 추천</p>(많이 팔린 상품 TOP 100)
                        </td>
                        <td class="confluenceTd">사용안함</td>
                        <td colspan="1" class="confluenceTd">사용안함</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>