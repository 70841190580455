<template>
    <div class="page view">
        <h1>Admin Dashboard 활용 안내</h1>
        <div id="main-content" class="wiki-content">
            <br>
            <h2 id="AdminDashboard활용안내-1.A/B테스트활용"><span style="color: rgb(51,51,153);">1. A/B 테스트 활용</span></h2>
            <p class="p1"><span class="s1">A/B&nbsp;테스트를 통해 추천 최적화 작업이 가능하며, 최적화된 추천을 통한 트래픽,&nbsp;매출 증가등의 성과를 확인하실 수 있습니다.</span></p>
            <p class="p1">아래 가이드에 따라&nbsp;원하는 비율, 원하는 구성대로 간편하게&nbsp;A/B테스트 생성이 가능합니다.</p>
            <p class="p1">&nbsp;</p>
            <ul>
                <li>추천위젯 설정의 하단 메뉴 A/B테스트 설정을 누르시면, A/B 테스트를 위한 설정을 하실 수 있습니다.</li>
            </ul>
            <p>&nbsp; &nbsp; &nbsp;<span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/api_img03.png" data-image-src="/download/attachments/3735648/api_img03.png?version=1&amp;modificationDate=1416465721000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="3932411" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="api_img03.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="3735648" data-linked-resource-container-version="5"></span></p>
            <ol>
                <li>테스트명: 이 테스트의 이름입니다. 적절히 작성하시면 테스트 중 혹은 테스트 후 결과를 확인하실 때 입력하신 테스트명으로 표시되어 편리합니다.</li>
                <li>테스트 시작일/종료일: 테스트를 특정 기간동안에만 수행하려고 하신다면 시작일과 종료일을 입력해주세요. 지정 안함을 선택하시면 기간과 상관없이 테스트가 진행됩니다.</li>
                <li>위젯: 해당 그룹에게 노출될 위젯입니다. 위젯은 추천 위젯 설정 페이지(&nbsp;<a href="https://admin.recopick.com/-/widget" class="external-link">https://admin.recopick.com/-/widget</a>&nbsp;)에서 추가/수정하실 수 있습니다.</li>
                <li>채널명: 해당 그룹의 테스트 결과를 통계로 제공해드리는 식별자입니다. 채널명을 "test"라고 입력하시면 클릭률, 경유매출 등의 통계를 "abtest_test"라는 채널명으로 제공해드립니다.</li>
                <li>노출설정: 전체 사용자를 10개의 그룹으로 나누어 각 그룹별로 노출할 위젯을 선택합니다. 그룹1과 그룹2를 5:5로 테스트 하시려면 그룹0~4는 그룹1, 5~9는 그룹2에 체크해주시면 됩니다.</li>
                <li>그룹 추가 버튼을 통해 3개 이상의 그룹(최대 5개)으로 테스트 설정이 가능합니다.</li>
            </ol>
            <ul>
                <li>이제 A/B 테스트를 위한 위젯을 &nbsp;코드가져오기로&nbsp;기존에 위젯을 넣는 것과 같은 방법으로 쇼핑몰 내 원하시는 위치에 넣어주시면 됩니다.<br><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/api_img04.png" data-image-src="/download/attachments/3735648/api_img04.png?version=1&amp;modificationDate=1416465884000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="3932412" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="api_img04.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="3735648" data-linked-resource-container-version="5"></span><br><br></li>
                <li>A/B 테스트 결과는, 추천성과분석의 하단 A/B 테스트 통계에서 언제든지 확인하실 수 있습니다.<br>
                    <p><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/api_img05.png" data-image-src="/download/attachments/3735648/api_img05.png?version=1&amp;modificationDate=1416466029000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="3932413" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="api_img05.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="3735648" data-linked-resource-container-version="5"></span></p>
                    <ol>
                        <li>통계의 사용자 기준입니다. 전체사용자의 데이터를 볼지, 구매자의 데이터를 볼지, 추천 클릭 사용자의 데이터를 볼지 선택합니다.</li>
                        <li>각 사용자 기준의 상세 데이터입니다. (1)에서 추천 클릭 사용자, (2)에서 그룹별 평균 매출을 선택했다면 각 그룹의 추천 클릭 사용자에 대한 평균 매출 데이터가 표시됩니다.</li>
                        <li>기준 A/B 테스트입니다. "A/B 테스트 적용" 단계에서 테스트 조건을 입력하셨을 때의 각 그룹별로 데이터가 제공됩니다.<br>가령, 선택한 기준 A/B 테스트에 테스트그룹0은 사용자그룹0~8까지, 테스트그룹1은 사용자그룹9가 지정되어 있었다면 테스트그룹0의 데이터는 그룹0~8의 평균, 테스트그룹1의 데이터는 그룹9의 데이터가 됩니다.</li>
                        <li>&nbsp;"사용자 그룹별" 탭을 선택하면 사용자그룹0~9까지 각 그룹의 데이터를 모두 확인하실 수 있습니다.</li>
                    </ol>
                </li>
            </ul>
            <h2 id="AdminDashboard활용안내-2.추천통계/데이터활용"><span style="color: rgb(51,51,153);">2. 추천 통계/데이터 활용</span></h2>
            <p><span style="color: rgb(0,0,0);">&nbsp;Admin Dashboard 페이지에서 11개의 추천 성과지표를 제공하고 있으며, 주요 지표에 대해 추천 영역별, 추천서비스별 성과 분석을 확인하실 수 있습니다.</span></p>
            <p><span style="color: rgb(0,0,0);"><br></span></p>
            <ol>
                <li><strong>추천성과분석 메뉴에서&nbsp;추천 성과 확인하기</strong><br>인당 페이지뷰의 상승, 인당 구매 건수의 상승, 추천 노출 영역별 사용자들의 클릭률,&nbsp;추천을 통해 발생한 매출 비율&nbsp;등을 통해&nbsp;추천의 성과를 직접 확인 해 보세요<br>추천이 적용된 이후, 추천 클릭사용자와 추천 비클릭 사용자 간의 활동성을 보실 수 있으시며,&nbsp;구매자수&amp;추천구매자수를 통해 전체 구매자수 중&nbsp;<br>추천위젯을 Navigator로 활용한 비중을 확인하실 수 있고,&nbsp;추천 경유 구매상품수/매출을 통해 직접적으로 추천을 통해 발생한 매출을 확인하실 수 있습니다.<br><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/admin_img02.png" data-image-src="/download/attachments/4718691/admin_img02.png?version=1&amp;modificationDate=1444978891000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="4849705" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="admin_img02.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="4718691" data-linked-resource-container-version="40"></span><br><br></li>
                <li><strong>추천전시관리 메뉴에서&nbsp;상품 통계 활용하기</strong><br>추천전시관리 메뉴를 통해, 구매전환율 Top 100과 같은 기존 솔루션에서 제공하지 못하던 상품 통계를 보실 수 있습니다.<br>구매전환율 같은 경우 노출된 수 대비 구매한 비율로, 잘 노출되지는 않았지만 고객들의 구매를 자극하는 좋은 상품들을 확인하실 수 있습니다.<br>이런 상품들의 노출을 높일 경우 큰 효과를 보실 수 있습니다.<strong><br><br></strong>&nbsp;&nbsp;<span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/doc_brief_img06.png" data-image-src="/download/attachments/4718691/doc_brief_img06.png?version=1&amp;modificationDate=1444978448000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="4849703" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="doc_brief_img06.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="4718691" data-linked-resource-container-version="40"></span><br><br><br></li>
                <li><strong>상품별 연관상품 활용하기</strong><br>우측 상단 검색 서식에 특정 상품 ID를 입력하시면, 해당 상품과 연관된 상품 리스트를 보실 수 있습니다.<br>이런 상품 리스트를 통해 관련성이 높은 상품들을 묶어서 기획전 또는 프로모션을 준비하실 수도 있습니다.<br><br><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/doc_guide01_1.png" data-image-src="/download/attachments/4718691/doc_guide01_1.png?version=1&amp;modificationDate=1444980716000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="4849711" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="doc_guide01_1.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="4718691" data-linked-resource-container-version="40"></span></li>
            </ol>




        </div>
    </div>
</template>