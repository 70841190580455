<template>
    <div class="page view">
        <h1>로그수집 API</h1>
        <div id="main-content" class="wiki-content">
            <h1 id="id-로그수집API-LogCollecting"><strong>Log Collecting</strong></h1>
            <p><span style="color: rgb(153,153,153);">RecoPick 서비스를 이용하시기 위해서 추천을 계산하기 위한 데이터를 보낼 수 있는 API입니다.</span></p>
            <p><em><u><span style="color: rgb(255,0,0);"> </span></u></em></p>
            <p><span style="color: rgb(153,153,153);"><em><u><strong><span style="color: rgb(255,0,0);"><span class="nolink">*Native(Hybrid) App에서 행동로그를 업로드 할 경우에는 uid 자리에 반드시 GAID 혹은 IDFA값을 넣어서 업로드 해야 합니다.</span></span></strong></u></em></span></p>
            <p><span style="color: rgb(153,153,153);"><a href="/pages/viewpage.action?pageId=6553731">참고 : Hybrid app에서 ADID(gaid,idfa)획득방법</a> </span></p>
            <div class="table-wrap">
                <table class="confluenceTable">
                    <tbody>
                    <tr>
                        <th class="confluenceTh"><strong>Resource</strong></th>
                        <th class="confluenceTh"><strong>Description</strong></th>
                    </tr>
                    <tr>
                        <td colspan="1" class="confluenceTd"><a href="/pages/viewpage.action?pageId=3735912">View 로그 (상품조회)</a></td>
                        <td colspan="1" class="confluenceTd">사용자가 상품을 조회한 경우 (상품상세 페이지)</td>
                    </tr>
                    <tr>
                        <td colspan="1" class="confluenceTd"><a href="/pages/viewpage.action?pageId=3735921">Basket 로그 (장바구니)</a></td>
                        <td colspan="1" class="confluenceTd">사용자가 상품을 장바구니에 담은 경우 (장바구니 페이지)</td>
                    </tr>
                    <tr>
                        <td colspan="1" class="confluenceTd"><a href="/pages/viewpage.action?pageId=3735916">Order 로그 (구매완료)</a></td>
                        <td colspan="1" class="confluenceTd">사용자가 상품을 구매한 경우 (구매완료 페이지)</td>
                    </tr>
                    <tr>
                        <td colspan="1" class="confluenceTd"><a href="/pages/viewpage.action?pageId=3735931">Search 로그 (검색)</a></td>
                        <td colspan="1" class="confluenceTd"><span>사용자가 검색한 경우 (검색결과 페이지)</span></td>
                    </tr>
                    <tr>
                        <td colspan="1" class="confluenceTd"><a href="/pages/viewpage.action?pageId=3735923">Visit 로그 (기타)</a></td>
                        <td colspan="1" class="confluenceTd"><span>사용자가 기타 페이지를 방문한 경우 (메인 / 카테고리 / 마이 페이지 등)</span></td>
                    </tr>
                    <tr>
                        <td colspan="1" class="confluenceTd"><a href="/pages/viewpage.action?pageId=3735929">Like 로그 (좋아요)</a></td>
                        <td colspan="1" class="confluenceTd">사용자가 상품을 좋아요(찜) 한 경우</td>
                    </tr>
                    <tr>
                        <td colspan="1" class="confluenceTd"><a href="/pages/viewpage.action?pageId=491684">추천상품 클릭 로그</a></td>
                        <td colspan="1" class="confluenceTd">사용자가 추천 상품을 클릭한 경우</td>
                    </tr>
                    <tr>
                        <td colspan="1" class="confluenceTd"><a href="/pages/viewpage.action?pageId=3244525">사용자 행동로그 확인</a></td>
                        <td colspan="1" class="confluenceTd">사이트 방문자의 최근 행동 로그 확인</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <h1 id="id-로그수집API-Redirect"><strong>Redirect</strong></h1>
            <p><span style="color: rgb(153,153,153);">추천 리스트에 있는 상품을 사용자가 클릭했을 때 RecoPick 서버에 로그를 남기고 해당 상품으로 redirect 하는 API입니다.</span></p>
            <p><span style="color: rgb(153,153,153);">이 API의 목적은 추천 클릭률을 정확히 계산하고 사용자의 반응을 고려하여 추천 로직 계산에 반영하기 위함입니다.</span></p>
            <p><span style="color: rgb(153,153,153);">JSON 형태의 추천 리스트에서 각각의 상품 링크를 생성 하실 때 RecoPick Redirect API를 이용해서 생성해주세요.</span></p>
            <p><span style="color: rgb(153,153,153);">이렇게 하면, 보다 정확한 추천 계산이 가능하며, <a href="http://admin.recopick.com/" class="external-link">레코픽 대시보드</a>에서 좀더 정확한 추천 상품 클릭률을 볼 수 있습니다.</span><span style="color: rgb(153,153,153);"> </span></p>
            <div class="table-wrap">
                <table class="confluenceTable">
                    <tbody>
                    <tr>
                        <th class="confluenceTh"><strong>Resource</strong></th>
                        <th class="confluenceTh"><strong>Description</strong></th>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><a href="/pages/viewpage.action?pageId=491684">7) 추천상품 클릭 로그</a></td>
                        <td class="confluenceTd"><span style="color: rgb(0,0,0);">클릭 로그를 남기고 입력하신 URL로 redirect를 합니다. </span></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>