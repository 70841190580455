<template>
    <div class="page view">
        <h1>REST API를 이용한 A/B 테스트</h1>
        <div id="main-content" class="wiki-content">
            <br>
            <h2 id="RESTAPI를이용한A/B테스트-API를이용한A/B테스트">API 를 이용한 A/B 테스트</h2>
            <p>Widget을 이용한 A/B 테스트가 아닌, REST API를 이용한 A/B 테스트는 다음 순서로 진행합니다.</p>
            <ol>
                <li>사용자 UID 확인 : <a href="/display/docs/Client+Library+APIs">fetchUID</a> 를 이용해 사용자 UID를 얻어옵니다.</li>
                <li>테스트 그룹 확인 : UID의 끝자리를 기준으로 테스트 그룹이 정해집니다. 예를 들어, UID 가 12345.6789 인 경우 테스트그룹 9번으로 지정됩니다. 123456789 와 같이 점(.)이 없는 경우에도 가장 끝자리를 기준으로 하여 테스트그룹 9번으로 지정됩니다.</li>
                <li>테스트 그룹별 노출 : 특정 테스트 그룹에 대해 <a href="http://docs.recopick.com/pages/viewpage.action?pageId=3244098" class="external-link">노출 API</a>를 호출하지 않도록 설정합니다. 예를 들어, 1:9 A/B 테스트인 경우 9번 , 5.5 A/B 테스트인 경우 6-9 번 테스트 그룹에는 노출 API를 호출하지 않도록 합니다.</li>
                <li>테스트 결과 확인 : Admin Dashboard의 추천성과분석의 A/B 테스트 통계에서 결과를 확인합니다.</li>
            </ol>
        </div>
    </div>
</template>