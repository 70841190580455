<template>
    <div class="page view">
        <h1>독립형 쇼핑몰인 경우 (자체 제작 쇼핑몰)</h1>
        <div id="main-content" class="wiki-content">
            <br>
            <div class="confluence-information-macro confluence-information-macro-note conf-macro output-block" data-hasbody="true" data-macro-name="note">
                <p class="title"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> &nbsp;&nbsp; 본 가이드는 독립형 쇼핑몰(자체 제작 쇼핑몰)에 해당하는 내용입니다.</p>
                <div class="confluence-information-macro-body">
                    <p><br></p>
                    <p> </p>
                    <div class="toc-macro client-side-toc-macro  conf-macro output-block hidden-outline" data-preseparator="{ " data-midseparator=" } { " data-postseparator=" }" data-headerelements="H1,H2,H3" data-hasbody="false" data-macro-name="toc">
                        <ul style="">
                            <li><span class="toc-item-body" data-outline="1"><span class="toc-outline">1</span><a href="/pages/viewpage.action?pageId=491543#id-%EB%8F%85%EB%A6%BD%ED%98%95%EC%87%BC%ED%95%91%EB%AA%B0%EC%9D%B8%EA%B2%BD%EC%9A%B0(%EC%9E%90%EC%B2%B4%EC%A0%9C%EC%9E%91%EC%87%BC%ED%95%91%EB%AA%B0)-%EA%B0%9C%EB%B0%9C(%EC%8A%A4%ED%85%8C%EC%9D%B4%EC%A7%95)%EC%84%9C%EB%B2%84%EC%99%80%EC%83%81%EC%9A%A9%EC%84%9C%EB%B2%84%EA%B0%84sid,domain%EC%9D%80%EB%B6%84%EB%A6%AC%ED%95%98%EC%97%AC%EC%82%AC%EC%9A%A9%ED%95%98%EC%8B%9C%EA%B8%B0%EB%B0%94%EB%9E%8D%EB%8B%88%EB%8B%A4." class="toc-link">개발(스테이징)서버와 상용서버간 sid, domain은 분리하여 사용하시기 바랍니다.</a></span></li>
                            <li><span class="toc-item-body" data-outline="2"><span class="toc-outline">2</span><a href="/pages/viewpage.action?pageId=491543#id-%EB%8F%85%EB%A6%BD%ED%98%95%EC%87%BC%ED%95%91%EB%AA%B0%EC%9D%B8%EA%B2%BD%EC%9A%B0(%EC%9E%90%EC%B2%B4%EC%A0%9C%EC%9E%91%EC%87%BC%ED%95%91%EB%AA%B0)-%EB%84%A4%EC%9D%B4%EB%B2%84%ED%8E%98%EC%9D%B4%EC%9D%B4%EC%9A%A9%EC%8B%9C,%EC%8B%A0%EA%B7%9CSDK(Ver.2.1%EC%9D%B4%EC%83%81)%EC%A0%81%EC%9A%A9%ED%95%98%EC%8B%9C%EA%B8%B0%EB%B0%94%EB%9E%8D%EB%8B%88%EB%8B%A4." class="toc-link">네이버페이 이용 시,&nbsp;신규 SDK(Ver. 2.1 이상) 적용하시기 바랍니다.</a></span>
                                <ul style="">
                                    <li><span class="toc-item-body" data-outline="2.1"><span class="toc-outline">2.1</span><a href="/pages/viewpage.action?pageId=491543#id-%EB%8F%85%EB%A6%BD%ED%98%95%EC%87%BC%ED%95%91%EB%AA%B0%EC%9D%B8%EA%B2%BD%EC%9A%B0(%EC%9E%90%EC%B2%B4%EC%A0%9C%EC%9E%91%EC%87%BC%ED%95%91%EB%AA%B0)-%EA%B2%B0%EC%A0%9C%ED%94%8C%EB%9E%AB%ED%8F%BC%EC%A4%91%E2%80%98%EB%84%A4%EC%9D%B4%EB%B2%84%ED%8E%98%EC%9D%B4%E2%80%99%EB%A5%BC%EC%82%AC%EC%9A%A9%ED%95%98%EC%8B%A0%EB%8B%A4%EB%A9%B4,%EC%A0%95%ED%99%95%ED%95%9C%EB%A7%A4%EC%B6%9C%EC%82%B0%EC%A0%95%EC%9D%84%EC%9C%84%ED%95%B4&#39;%EB%84%A4%EC%9D%B4%EB%B2%84%ED%8E%98%EC%9D%B4SDKVer.2.1&#39;%EC%9D%B4%EC%83%81%EC%9C%BC%EB%A1%9C%EC%97%85%EB%8D%B0%EC%9D%B4%ED%8A%B8%ED%95%98%EC%85%94%EC%95%BC%ED%95%A9%EB%8B%88%EB%8B%A4." class="toc-link">결제 플랫폼 중 ‘네이버 페이’를 사용하신다면, 정확한 매출 산정을 위해 '네이버페이 SDK Ver. 2.1'&nbsp;이상으로 업데이트 하셔야 합니다.</a></span></li>
                                    <li><span class="toc-item-body" data-outline="2.2"><span class="toc-outline">2.2</span><a href="/pages/viewpage.action?pageId=491543#id-%EB%8F%85%EB%A6%BD%ED%98%95%EC%87%BC%ED%95%91%EB%AA%B0%EC%9D%B8%EA%B2%BD%EC%9A%B0(%EC%9E%90%EC%B2%B4%EC%A0%9C%EC%9E%91%EC%87%BC%ED%95%91%EB%AA%B0)-&#39;%EB%84%A4%EC%9D%B4%EB%B2%84%ED%8E%98%EC%9D%B4SDKVer.2.1&#39;%EC%9D%80SDK%EB%A5%BC%EC%9D%B4%EC%9A%A9%ED%95%98%EC%97%AC%EA%B2%B0%EC%A0%9C%EC%B0%BD%EC%9D%84%EC%86%90%EC%89%BD%EA%B2%8C%EB%9D%84%EC%9A%B8%EC%88%98%EC%9E%88%EC%8A%B5%EB%8B%88%EB%8B%A4." class="toc-link">'네이버페이 SDK Ver. 2.1'은&nbsp; SDK를 이용하여 결제창을 손쉽게 띄울 수 있습니다.</a></span></li>
                                    <li><span class="toc-item-body" data-outline="2.3"><span class="toc-outline">2.3</span><a href="/pages/viewpage.action?pageId=491543#id-%EB%8F%85%EB%A6%BD%ED%98%95%EC%87%BC%ED%95%91%EB%AA%B0%EC%9D%B8%EA%B2%BD%EC%9A%B0(%EC%9E%90%EC%B2%B4%EC%A0%9C%EC%9E%91%EC%87%BC%ED%95%91%EB%AA%B0)-&#39;%EB%84%A4%EC%9D%B4%EB%B2%84%ED%8E%98%EC%9D%B4SDK&#39;%EC%97%90%EC%84%9C%E2%80%98return-url%E2%80%98%EC%9D%84%EC%9E%85%EB%A0%A5%ED%95%98%EC%97%AC,%EC%82%AC%EC%9A%A9%EC%9E%90%EA%B2%B0%EC%A0%9C%EC%99%84%EB%A3%8C%ED%9B%84%EA%B2%B0%EC%A0%9C%EA%B2%B0%EA%B3%BC%EB%A5%BC%EB%B0%9B%EC%9D%84%EC%88%98%EC%9E%88%EB%8F%84%EB%A1%9D%ED%95%B4%EC%A3%BC%EC%84%B8%EC%9A%94." class="toc-link">'네이버페이 SDK'에서 ‘return-url ‘을 입력하여, 사용자 결제 완료 후 결제 결과를 받을 수 있도록 해 주세요.</a></span></li>
                                    <li><span class="toc-item-body" data-outline="2.4"><span class="toc-outline">2.4</span><a href="/pages/viewpage.action?pageId=491543#id-%EB%8F%85%EB%A6%BD%ED%98%95%EC%87%BC%ED%95%91%EB%AA%B0%EC%9D%B8%EA%B2%BD%EC%9A%B0(%EC%9E%90%EC%B2%B4%EC%A0%9C%EC%9E%91%EC%87%BC%ED%95%91%EB%AA%B0)-%EC%9E%90%EC%84%B8%ED%95%9C%EB%82%B4%EC%9A%A9%EC%9D%80https://developer.pay.naver.com/docs/v2/api%EC%97%90%EC%84%9C%ED%99%95%EC%9D%B8%ED%95%98%EC%84%B8%EC%9A%94." class="toc-link">자세한 내용은 https://developer.pay.naver.com/docs/v2/api 에서 확인하세요.</a></span></li>
                                </ul>
                            </li>
                            <li><span class="toc-item-body" data-outline="3"><span class="toc-outline">3</span><a href="/pages/viewpage.action?pageId=491543#id-%EB%8F%85%EB%A6%BD%ED%98%95%EC%87%BC%ED%95%91%EB%AA%B0%EC%9D%B8%EA%B2%BD%EC%9A%B0(%EC%9E%90%EC%B2%B4%EC%A0%9C%EC%9E%91%EC%87%BC%ED%95%91%EB%AA%B0)-%3C1%EB%8B%A8%EA%B3%84%3E%EB%A0%88%EC%BD%94%ED%94%BD%EB%A1%9C%EA%B7%B8%EC%88%98%EC%A7%91%EC%8A%A4%ED%81%AC%EB%A6%BD%ED%8A%B8%EC%82%BD%EC%9E%85" class="toc-link">&lt;1단계&gt; 레코픽 로그수집 스크립트 삽입</a></span>
                                <ul style="">
                                    <li><span class="toc-item-body" data-outline="3.1"><span class="toc-outline">3.1</span><a href="/pages/viewpage.action?pageId=491543#id-%EB%8F%85%EB%A6%BD%ED%98%95%EC%87%BC%ED%95%91%EB%AA%B0%EC%9D%B8%EA%B2%BD%EC%9A%B0(%EC%9E%90%EC%B2%B4%EC%A0%9C%EC%9E%91%EC%87%BC%ED%95%91%EB%AA%B0)-%EA%B3%B5%ED%86%B5%ED%97%A4%EB%8D%94%ED%8C%8C%EC%9D%BC%EC%9D%B4%EC%9E%88%EB%8A%94%EA%B2%BD%EC%9A%B0" class="toc-link">공통 헤더 파일이 있는 경우</a></span></li>
                                    <li><span class="toc-item-body" data-outline="3.2"><span class="toc-outline">3.2</span><a href="/pages/viewpage.action?pageId=491543#id-%EB%8F%85%EB%A6%BD%ED%98%95%EC%87%BC%ED%95%91%EB%AA%B0%EC%9D%B8%EA%B2%BD%EC%9A%B0(%EC%9E%90%EC%B2%B4%EC%A0%9C%EC%9E%91%EC%87%BC%ED%95%91%EB%AA%B0)-%EC%A0%81%EC%9A%A9%ED%9B%84%EA%B2%80%EC%A6%9D%EB%B0%A9%EB%B2%95" class="toc-link">적용 후 검증 방법</a></span></li>
                                </ul>
                            </li>
                            <li class="toc-empty-item"></li>
                            <li><span class="toc-item-body" data-outline="5"><span class="toc-outline">5</span><a href="/pages/viewpage.action?pageId=491543#id-%EB%8F%85%EB%A6%BD%ED%98%95%EC%87%BC%ED%95%91%EB%AA%B0%EC%9D%B8%EA%B2%BD%EC%9A%B0(%EC%9E%90%EC%B2%B4%EC%A0%9C%EC%9E%91%EC%87%BC%ED%95%91%EB%AA%B0)-%3C2%EB%8B%A8%EA%B3%84%3E%EC%83%81%ED%92%88%EB%A9%94%ED%83%80%ED%83%9C%EA%B7%B8%EC%82%BD%EC%9E%85" class="toc-link">&lt;2단계&gt; 상품&nbsp;메타 태그 삽입</a></span>
                                <ul style="">
                                    <li><span class="toc-item-body" data-outline="5.1"><span class="toc-outline">5.1</span><a href="/pages/viewpage.action?pageId=491543#id-%EB%8F%85%EB%A6%BD%ED%98%95%EC%87%BC%ED%95%91%EB%AA%B0%EC%9D%B8%EA%B2%BD%EC%9A%B0(%EC%9E%90%EC%B2%B4%EC%A0%9C%EC%9E%91%EC%87%BC%ED%95%91%EB%AA%B0)-%EC%A0%81%EC%9A%A9%ED%9B%84%EA%B2%80%EC%A6%9D%EB%B0%A9%EB%B2%95.1" class="toc-link">적용 후 검증 방법</a></span></li>
                                </ul>
                            </li>
                            <li><span class="toc-item-body" data-outline="6"><span class="toc-outline">6</span><a href="/pages/viewpage.action?pageId=491543#id-%EB%8F%85%EB%A6%BD%ED%98%95%EC%87%BC%ED%95%91%EB%AA%B0%EC%9D%B8%EA%B2%BD%EC%9A%B0(%EC%9E%90%EC%B2%B4%EC%A0%9C%EC%9E%91%EC%87%BC%ED%95%91%EB%AA%B0)-%3C3%EB%8B%A8%EA%B3%84%3E%EC%B6%94%EC%B2%9C%EC%83%81%ED%92%88%EB%85%B8%EC%B6%9C" class="toc-link">&lt;3단계&gt; 추천 상품 노출</a></span></li>
                            <li><span class="toc-item-body" data-outline="7"><span class="toc-outline">7</span><a href="/pages/viewpage.action?pageId=491543#id-%EB%8F%85%EB%A6%BD%ED%98%95%EC%87%BC%ED%95%91%EB%AA%B0%EC%9D%B8%EA%B2%BD%EC%9A%B0(%EC%9E%90%EC%B2%B4%EC%A0%9C%EC%9E%91%EC%87%BC%ED%95%91%EB%AA%B0)-%3C3-1%3E%EC%B6%94%EC%B2%9C%EC%9C%84%EC%A0%AF%EC%9D%B4%EC%9A%A9%EB%B0%A9%EC%8B%9D" class="toc-link">&lt;3-1&gt; 추천 위젯 이용 방식</a></span></li>
                            <li><span class="toc-item-body" data-outline="8"><span class="toc-outline">8</span><a href="/pages/viewpage.action?pageId=491543#id-%EB%8F%85%EB%A6%BD%ED%98%95%EC%87%BC%ED%95%91%EB%AA%B0%EC%9D%B8%EA%B2%BD%EC%9A%B0(%EC%9E%90%EC%B2%B4%EC%A0%9C%EC%9E%91%EC%87%BC%ED%95%91%EB%AA%B0)-%3C3-2%3EAPI%EB%B0%A9%EC%8B%9D" class="toc-link">&lt;3-2&gt; API 방식</a></span></li>
                            <li><span class="toc-item-body" data-outline="9"><span class="toc-outline">9</span><a href="/pages/viewpage.action?pageId=491543#id-%EB%8F%85%EB%A6%BD%ED%98%95%EC%87%BC%ED%95%91%EB%AA%B0%EC%9D%B8%EA%B2%BD%EC%9A%B0(%EC%9E%90%EC%B2%B4%EC%A0%9C%EC%9E%91%EC%87%BC%ED%95%91%EB%AA%B0)-%3C4%EB%8B%A8%EA%B3%84%3E%EC%B6%94%EC%B2%9C%EC%84%B1%EA%B3%BC%EB%B6%84%EC%84%9D" class="toc-link">&lt;4 단계&gt; 추천성과 분석&nbsp;</a></span>
                                <ul style="">
                                    <li><span class="toc-item-body" data-outline="9.1"><span class="toc-outline">9.1</span><a href="/pages/viewpage.action?pageId=491543#id-%EB%8F%85%EB%A6%BD%ED%98%95%EC%87%BC%ED%95%91%EB%AA%B0%EC%9D%B8%EA%B2%BD%EC%9A%B0(%EC%9E%90%EC%B2%B4%EC%A0%9C%EC%9E%91%EC%87%BC%ED%95%91%EB%AA%B0)-%EC%A0%81%EC%9A%A9%EB%B0%A9%EB%B2%951" class="toc-link">적용 방법 1</a></span>
                                        <ul style="">
                                            <li><span class="toc-item-body" data-outline="9.1.1"><span class="toc-outline">9.1.1</span><a href="/pages/viewpage.action?pageId=491543#id-%EB%8F%85%EB%A6%BD%ED%98%95%EC%87%BC%ED%95%91%EB%AA%B0%EC%9D%B8%EA%B2%BD%EC%9A%B0(%EC%9E%90%EC%B2%B4%EC%A0%9C%EC%9E%91%EC%87%BC%ED%95%91%EB%AA%B0)-%EC%A0%81%EC%9A%A9%EB%B0%A9%EB%B2%952" class="toc-link">적용 방법2&nbsp;&nbsp;</a></span></li>
                                            <li><span class="toc-item-body" data-outline="9.1.2"><span class="toc-outline">9.1.2</span><a href="/pages/viewpage.action?pageId=491543#id-%EB%8F%85%EB%A6%BD%ED%98%95%EC%87%BC%ED%95%91%EB%AA%B0%EC%9D%B8%EA%B2%BD%EC%9A%B0(%EC%9E%90%EC%B2%B4%EC%A0%9C%EC%9E%91%EC%87%BC%ED%95%91%EB%AA%B0)-%EC%B6%94%EC%B2%9CAPI(%EC%98%88%EC%8B%9C:viewtogetherAPI)%ED%98%B8%EC%B6%9C%EC%8B%9C%EC%95%84%EB%9E%98%EC%99%80%EA%B0%99%EC%9D%80%EA%B2%B0%EA%B3%BC%EB%A5%BCresponse%EB%A1%9C%EB%B0%9B%EA%B2%8C%EB%90%A8" class="toc-link">추천 API(예시: view together API) 호출 시 아래와 같은 결과를 response로 받게 됨&nbsp;</a></span></li>
                                            <li><span class="toc-item-body" data-outline="9.1.3"><span class="toc-outline">9.1.3</span><a href="/pages/viewpage.action?pageId=491543#id-%EB%8F%85%EB%A6%BD%ED%98%95%EC%87%BC%ED%95%91%EB%AA%B0%EC%9D%B8%EA%B2%BD%EC%9A%B0(%EC%9E%90%EC%B2%B4%EC%A0%9C%EC%9E%91%EC%87%BC%ED%95%91%EB%AA%B0)-.1" class="toc-link">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</a></span></li>
                                            <li><span class="toc-item-body" data-outline="9.1.4"><span class="toc-outline">9.1.4</span><a href="/pages/viewpage.action?pageId=491543#id-%EB%8F%85%EB%A6%BD%ED%98%95%EC%87%BC%ED%95%91%EB%AA%B0%EC%9D%B8%EA%B2%BD%EC%9A%B0(%EC%9E%90%EC%B2%B4%EC%A0%9C%EC%9E%91%EC%87%BC%ED%95%91%EB%AA%B0)-%EC%83%81%EA%B8%B0%ED%95%84%EB%93%9C%EC%A4%91%EC%97%90clicklog_link%EB%98%90%EB%8A%94clicklog_redirect_link%EC%97%90%EC%9E%88%EB%8A%94URL%EA%B0%92%EC%9D%84%EC%B6%94%EC%B2%9C%EC%83%81%ED%92%88%EC%9D%84%ED%81%B4%EB%A6%AD%ED%96%88%EC%9D%84%EB%95%8C%ED%98%B8%EC%B6%9C%ED%95%98%EB%A9%B4%EB%90%A8." class="toc-link">상기&nbsp; 필드 중에&nbsp; clicklog_link 또는 clicklog_redirect_link에 있는 URL값을 추천 상품을 클릭했을 때 호출하면 됨.</a></span></li>
                                        </ul>
                                    </li>
                                    <li><span class="toc-item-body" data-outline="9.2"><span class="toc-outline">9.2</span><a href="/pages/viewpage.action?pageId=491543#id-%EB%8F%85%EB%A6%BD%ED%98%95%EC%87%BC%ED%95%91%EB%AA%B0%EC%9D%B8%EA%B2%BD%EC%9A%B0(%EC%9E%90%EC%B2%B4%EC%A0%9C%EC%9E%91%EC%87%BC%ED%95%91%EB%AA%B0)-%EA%B2%80%EC%A6%9D%EB%B0%A9%EB%B2%95" class="toc-link">검증방법</a></span></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <p> </p>
                </div>
            </div>
            <h1 id="id-독립형쇼핑몰인경우(자체제작쇼핑몰)-개발(스테이징)서버와상용서버간sid,domain은분리하여사용하시기바랍니다."><strong style="font-family: monospace;font-size: 24.0px;line-height: 1.25;">개발(스테이징)서버와 상용서버간 sid, domain은 분리하여 사용하시기 바랍니다.</strong></h1>
            <p><br></p>
            <h1 id="id-독립형쇼핑몰인경우(자체제작쇼핑몰)-네이버페이이용시,신규SDK(Ver.2.1이상)적용하시기바랍니다."><strong>네이버페이 </strong><strong>이용 시</strong><strong>,</strong><strong>&nbsp;신규 </strong><strong>SDK(Ver. 2.1 </strong><strong>이상</strong><strong>)</strong><strong> 적용하시기 바랍니다</strong><strong>.</strong></h1>
            <div class="table-wrap">
                <table class="wrapped confluenceTable">
                    <colgroup>
                        <col>
                    </colgroup>
                    <tbody>
                    <tr>
                        <th class="confluenceTh">
                            <h3 id="id-독립형쇼핑몰인경우(자체제작쇼핑몰)-결제플랫폼중‘네이버페이’를사용하신다면,정확한매출산정을위해&#39;네이버페이SDKVer.2.1&#39;이상으로업데이트하셔야합니다."><strong>결제 플랫폼 중 </strong><strong>‘</strong><strong>네이버 페이</strong><strong>’</strong><strong>를 사용하신다면</strong><strong>,</strong><strong><u><span style="color: rgb(0,51,102);"> 정확한 매출 산정</span></u>을 위해 '네이버페이 </strong><strong>SDK Ver. 2.1'&nbsp;</strong><strong>이상으로 업데이트 하셔야 합니다</strong><strong>.</strong></h3>
                            <h3 id="id-독립형쇼핑몰인경우(자체제작쇼핑몰)-&#39;네이버페이SDKVer.2.1&#39;은SDK를이용하여결제창을손쉽게띄울수있습니다."><strong>'네이버페이 </strong><strong>SDK Ver. </strong><strong>2.1'</strong><strong>은&nbsp; </strong><strong>SDK</strong><strong>를 이용하여 결제창을 손쉽게 띄울 수 있습니다</strong><strong>.</strong></h3>
                            <h3 id="id-독립형쇼핑몰인경우(자체제작쇼핑몰)-&#39;네이버페이SDK&#39;에서‘return-url‘을입력하여,사용자결제완료후결제결과를받을수있도록해주세요."><strong>'네이버페이 </strong><strong>SDK'</strong><strong>에서 </strong><strong>‘return-</strong><strong>url</strong> <strong>‘</strong><strong>을 입력하여</strong><strong>,</strong><strong> 사용자 결제 완료 후 결제 결과를 받을 수 있도록 해 주세요</strong><strong>.</strong></h3>
                            <h3 id="id-독립형쇼핑몰인경우(자체제작쇼핑몰)-자세한내용은https://developer.pay.naver.com/docs/v2/api에서확인하세요."><strong>자세한 내용은 </strong><a href="https://developer.pay.naver.com/docs/v2/api" class="external-link">https://</a><a href="https://developer.pay.naver.com/docs/v2/api" class="external-link">developer.pay.naver.com/docs/v2/api</a> 에서 확인하세요.</h3>
                        </th>
                    </tr>
                    </tbody>
                </table>
            </div>
            <h1 id="id-독립형쇼핑몰인경우(자체제작쇼핑몰)-&lt;1단계&gt;레코픽로그수집스크립트삽입"><strong style="font-family: monospace;font-size: 24.0px;line-height: 1.25;">&lt;1단계&gt; 레코픽 로그수집 스크립트 삽입</strong></h1>
            <p><span style="color: rgb(0,0,0);">공통 헤더 파일이 있는 경우는 공통 헤더용 로그수집 스크립트를 삽입한 후 페이지별로 해당 ‘sendLog’를 추가 삽입해 주시고</span></p>
            <p><span style="color: rgb(0,0,0);">공통 헤더 파일이 없는 경우</span> 페이지별로 ‘sendLog’를 포함한 로그수집 스크립트를 삽입해주세요!<strong style="color: rgb(51,102,255);line-height: 1.42857;">&nbsp;</strong></p>
            <p><span style="color: rgb(51,102,255);"><img class="emoticon emoticon-warning" src="@/assets/images/warning.svg" data-emoticon-name="warning" alt="(warning)"><span style="color: rgb(255,0,0);">&nbsp;주의하세요!&nbsp;&nbsp;</span><span style="color: rgb(0,0,0);">상품상세페이지에서 상품메타태그는 sendLog보다 앞에 위치해야 합니다. 그래야 사용자행동로그와 함께 상품메타정보도 같이 레코픽으로 전송됩니다.</span></span></p>
            <h2 id="id-독립형쇼핑몰인경우(자체제작쇼핑몰)-공통헤더파일이있는경우"><strong style="color: rgb(51,102,255);font-size: 20.0px;">공통 헤더 파일이 있는 경우</strong></h2>
            <h4 id="id-독립형쇼핑몰인경우(자체제작쇼핑몰)-1.공통헤더파일에로그수집스크립트삽입하기"><strong style="line-height: 1.42857;">1.&nbsp;공통 헤더 파일에 로그수집 스크립트 삽입하기</strong><span style="color: rgb(51,102,255);"><strong><br></strong></span></h4>
            <ol>
                <li>
                    <p>모든 페이지의 공통 헤더 파일 &lt;head&gt; … &lt;/head&gt;태그 사이에 아래 스크립트를 넣어주세요.&nbsp;스크립트 관리가 용이하도록 &lt;/head&gt;태그 바로 앞에 넣기를 권장합니다.</p>
                    <p><img class="emoticon emoticon-warning" src="@/assets/images/warning.svg" data-emoticon-name="warning" alt="(warning)"><span style="color: rgb(255,0,0);">&nbsp;주의하세요! </span>'사이트 url' 에는 레코픽 회원 가입 시에 등록하신 사이트 URL과 동일한 URL을 넣어주셔야 합니다. 회원 가입 시 사이트 URL을 등록하실 때에 www 는 제외해 주십시오.&nbsp;또한, 대소문자 구별을 꼭 해주셔야 합니다.</p>
                    <div class="panel conf-macro output-block" style="background-color: #ffffff;border-color: #007eff;border-style: solid;border-width: 1px;" data-hasbody="true" data-macro-name="panel">
                        <div class="panelHeader" style="border-bottom-width: 1px;border-bottom-style: solid;border-bottom-color: #007eff;background-color: #007eff;color: #ffffff;"><b>공통 헤더용 로그수집 스크립트</b></div>
                        <div class="panelContent" style="background-color: #ffffff;">
                            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                                <div class="codeContent panelContent pdl">
                                    <div>
                                        <div id="highlighter_667113" class="syntaxhighlighter sh-midnight nogutter  coldfusion">
                                            <div class="toolbar"><span><a href="/pages/viewpage.action?pageId=491543#" class="toolbar_item command_help help">?</a></span></div>
                                            <table border="0" cellpadding="0" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="code">
                                                        <div class="container" title="Hint: double-click to select code">
                                                            <div class="line number1 index0 alt2"><code class="coldfusion comments">&lt;!--RecoPick 로그수집 스크립트 --&gt;</code></div>
                                                            <div class="line number2 index1 alt1"><code class="coldfusion plain">&lt;script type=</code><code class="coldfusion string">"text/javascript"</code><code class="coldfusion plain">&gt;</code></div>
                                                            <div class="line number3 index2 alt2"><code class="coldfusion spaces">&nbsp;&nbsp;</code><code class="coldfusion plain">(function(w,d,n,s,e,o) {</code></div>
                                                            <div class="line number4 index3 alt1"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">w[n]=w[n]||function(){(w[n].q=w[n].q||[]).push(arguments)};</code></div>
                                                            <div class="line number5 index4 alt2"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">e=d.createElement(s);e.async=1;e.charset=</code><code class="coldfusion string">'utf-8'</code><code class="coldfusion plain">;e.src=</code><code class="coldfusion string">'//static.recopick.com/dist/production.min.js'</code><code class="coldfusion plain">;</code></div>
                                                            <div class="line number6 index5 alt1"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">o=d.getElementsByTagName(s)[0];o.parentNode.insertBefore(e,o);</code></div>
                                                            <div class="line number7 index6 alt2"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">})(window, document, </code><code class="coldfusion string">'recoPick'</code><code class="coldfusion plain">, </code><code class="coldfusion string">'script'</code><code class="coldfusion plain">);</code></div>
                                                            <div class="line number8 index7 alt1"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">recoPick(</code><code class="coldfusion string">'service'</code><code class="coldfusion plain">, </code><code class="coldfusion string">'사이트 url'</code><code class="coldfusion plain">);&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </code></div>
                                                            <div class="line number9 index8 alt2"><code class="coldfusion plain">&lt;/script&gt;</code></div>
                                                            <div class="line number10 index9 alt1"><code class="coldfusion comments">&lt;!--RecoPick 로그수집 스크립트 --&gt;</code></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ol>
            <ul>
                <li>
                    <p>공통 헤더용 로그수집 스크립트 삽입후 더욱 특화된 개인화 추천을 원하시면 암호화된 ID, 연령, 성별 데이터를 보내주세요!&nbsp;</p>
                    <p><span style="color: rgb(0,0,0);">*&nbsp;</span>(’service’, ‘ 사이트 url’); 바로 다음에 아래 스크립트를 삽입해 주시면 됩니다. 출생년도, 성별은 예시 참고하셔서 변경해 주시고 MEMBER_ID(MID)는 자체적으로 사용중인 회원 ID를 암호화해서 보내주세요.<br><span style="color: rgb(255,0,0);"><span style="color: rgb(0,0,0);">* <span style="color: rgb(0,0,0);">레코픽에서 수집하는 암호화된 ID, 연령, 성별 데이터로는 절대 개개인을 식별할 수 없으며, 단순히 사용자를 구분하기 위한 용도로만 사용됩니다.<br></span></span></span>&nbsp; 예를 들어, 같은 회원이 PC와 모바일을 통해 접속한다면 MID를 통해 &nbsp;매핑하면 RecoPick에서도 두 사용자를 같은 회원으로 인식하여 개인화 추천 등의 품질 향상에 도움이 됩니다.</p>
                    <p><img class="emoticon emoticon-warning" src="@/assets/images/warning.svg" data-emoticon-name="warning" alt="(warning)"><span style="color: rgb(255,0,0);">&nbsp;주의하세요!&nbsp;</span>레코픽 추천을 API 방식으로 사용하는 경우, GET방식으로 API를 호출하게 됩니다.<span style="color: rgb(255,0,0);"><span style="color: rgb(0,0,0);"><span><br></span></span></span>&nbsp;&nbsp;이 경우 일부 API의 파라미터에 MID가 사용되는데, MID 값에 일부 특수문자(예&gt; &amp;, =) 가 들어가게 되면 정상동작 하지 않을 수 있습니다.<span style="color: rgb(255,0,0);"><span style="color: rgb(0,0,0);"><br></span></span>&nbsp; 암호화된 결과에 특수문자가 들어가지 않는 암호화 알고리즘으로 단방향 암호화 해주세요. (예&gt; SHA-256, MD5 등)</p>
                    <p>* MID 단방향 암호화 예 :&nbsp;88020d58ceb8a4ad7f6756021fbc06b8a91f17daad2f04a5d369608d9c308bde (SHA-256으로 암호화),&nbsp;4509bf9373ff3dca193ba02726c87988 (MD5로 암호화)</p>
                    <div class="panel conf-macro output-block" style="background-color: #ffffff;border-color: #007eff;border-style: solid;border-width: 1px;" data-hasbody="true" data-macro-name="panel">
                        <div class="panelHeader" style="border-bottom-width: 1px;border-bottom-style: solid;border-bottom-color: #007eff;background-color: #007eff;color: #ffffff;"><b>사용자 구분을 위한 로그수집 스크립트</b></div>
                        <div class="panelContent" style="background-color: #ffffff;">
                            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                                <div class="codeContent panelContent pdl">
                                    <div>
                                        <div id="highlighter_815185" class="syntaxhighlighter sh-midnight nogutter  vb">
                                            <div class="toolbar"><span><a href="/pages/viewpage.action?pageId=491543#" class="toolbar_item command_help help">?</a></span></div>
                                            <table border="0" cellpadding="0" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="code">
                                                        <div class="container" title="Hint: double-click to select code">
                                                            <div class="line number1 index0 alt2"><code class="vb plain">recoPick(</code><code class="vb comments">'setMID',암호화된 MEMBER_ID); </code></div>
                                                            <div class="line number2 index1 alt1"><code class="vb plain">recoPick(</code><code class="vb comments">'setUserInfo',{ birthyear:‘출생연도’, gender:'성별’});</code></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p>&lt;예시&gt;</p>
                    <p>* 출생년도, 성별 정보 (M: 남자, F: 여자)</p>
                    <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                        <div class="codeContent panelContent pdl">
                            <div>
                                <div id="highlighter_801561" class="syntaxhighlighter sh-confluence nogutter  js">
                                    <div class="toolbar"><span><a href="/pages/viewpage.action?pageId=491543#" class="toolbar_item command_help help">?</a></span></div>
                                    <table border="0" cellpadding="0" cellspacing="0">
                                        <tbody>
                                        <tr>
                                            <td class="code">
                                                <div class="container" title="Hint: double-click to select code">
                                                    <div class="line number1 index0 alt2"><code class="js plain">recoPick(</code><code class="js string">'setUserInfo'</code><code class="js plain">,{ birthyear: ‘1987’, gender: 'M’});</code></div>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p></p>
                </li>
            </ul>
            <h4 id="id-독립형쇼핑몰인경우(자체제작쇼핑몰)-2.페이지별‘sendLog’추가삽입하기"><strong>2.&nbsp; <strong>페이지별 ‘sendLog’ 추가 삽입하기</strong></strong></h4>
            <ol>
                <li>각각의 페이지에 들어가시면 공통 헤더용 로그수집 스크립트가 삽입되어 있습니다. &nbsp;공통헤더용 로그수집 스크립트 안에 ‘sendLog’ 를 삽입해 주세요. &lt; 그림 1: 삽입위치 참고&gt;<br>‘sendLog’는 실제 데이터를 전송하는 역할을 하기 때문에 각 페이지에 맞게 수정해 주셔야 합니다.&nbsp;</li>
            </ol>
            <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &lt;그림 1 : 삽입위치 참고&gt;</p>
            <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/indep.png" data-image-src="/download/attachments/491543/%E1%84%83%E1%85%A9%E1%86%A8%E1%84%85%E1%85%B5%E1%86%B8%E1%84%86%E1%85%A9%E1%86%AF_01_%E1%84%8C%E1%85%A5%E1%84%8B%E1%85%AD%E1%86%BC%E1%84%85%E1%85%A3%E1%86%BC.png?version=1&amp;modificationDate=1458631667000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="5472257" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="독립몰_01_저용량.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="491543" data-linked-resource-container-version="264"></span></p>
            <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;* ‘sendLog’ 삽입 위치는 위처럼 공통 헤더용 로그수집 스크립트 안에 삽입하셔도 되고, &nbsp;&lt;script&gt;...&lt;script&gt; 태그에 넣어 공통헤더용 로그수집 스크립트와 별도로 삽입하셔도 됩니다.&nbsp;</p>
            <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 그럴 경우 공통 헤더용 로그수집 스크립트보다 하단에 위치하도록 해야 합니다.</p>
            <p><br></p>
            <p><span style="color: rgb(0,0,255);"><span style="color: rgb(0,0,0);">&nbsp; &nbsp; &nbsp;2.</span></span><span style="color: rgb(0,0,255);">&nbsp;</span><strong><span style="color: rgb(0,0,255);">상품 상세 페이지</span></strong>에 들어가셔서 아래 스크립트를 삽입해주세요. &lt;그림1 삽입위치 참고&gt;</p>
            <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;아래 스트립트를 삽입 후 <strong><span style="color: rgb(255,102,0);">상품id, 카테고리</span></strong>는 실제 사용하시는 변수로 수정해 주셔야 하며 상품id, 카테고리는 문자열로 전달되어야 합니다.</p>
            <p style="margin-left: 60.0px;">ex) 상품id가 12345, 카테고리가 여성의류 일때, {id:'12345', c1:'여성의류'}</p>
            <ul>
                <li>‘카테고리(대)’, ‘카테고리(중)’, ‘카테고리(소)’에 실제 사용하시는 변수로 수정해서 넣어주시면 보다 고품질의&nbsp;추천을 제공해 드립니다.</li>
                <li>카테고리는 최대 세 단계(대/중/소) 카테고리가 지원되며 c1, c2, c3의 값으로 설정합니다.</li>
                <li>카테고리 정보가 최소한 1개(c1 : 대 카테고리) 이상 들어가야 합니다.</li>
                <li>
                    <p><span>카테고리 정보는 카테고리ID가 아닌 카테고리명으로 넣어주십시오. 카테고리ID 사용 시에 오류가 발생할 수 있습니다.</span></p>
                    <ul>
                        <li>
                            <p><span style="letter-spacing: 0.0px;">자켓, 아우터, 스커트 등 (O)</span></p>
                        </li>
                        <li>
                            <p><span style="letter-spacing: 0.0px;">23851, 24A831T04C (X)</span></p>
                        </li>
                    </ul>
                </li>
                <li>
                    <div class="panel conf-macro output-block" style="background-color: #ffffff;border-color: #007eff;border-style: solid;border-width: 1px;" data-hasbody="true" data-macro-name="panel">
                        <div class="panelHeader" style="border-bottom-width: 1px;border-bottom-style: solid;border-bottom-color: #007eff;background-color: #007eff;color: #ffffff;"><b>sendLog_view (카테고리 포함)</b></div>
                        <div class="panelContent" style="background-color: #ffffff;">
                            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                                <div class="codeContent panelContent pdl">
                                    <div>
                                        <div id="highlighter_82121" class="syntaxhighlighter sh-midnight nogutter  vb">
                                            <div class="toolbar"><span><a href="/pages/viewpage.action?pageId=491543#" class="toolbar_item command_help help">?</a></span></div>
                                            <table border="0" cellpadding="0" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="code">
                                                        <div class="container" title="Hint: double-click to select code">
                                                            <div class="line number1 index0 alt2"><code class="vb plain">recoPick(</code><code class="vb comments">'sendLog','view', {id:'상품id', c1:'카테고리(대)', c2:'카테고리(중)', c3:'카테고리(소)'}); </code></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            <p>&nbsp; &nbsp; &nbsp; &nbsp;&lt;예시&gt;</p>
            <ul>
                <li>
                    <p>사이트 방문고객이 123번 상품의 상세 페이지를 방문 시 (해당상품의 카테고리 정보 포함)</p>
                    <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                        <div class="codeContent panelContent pdl">
                            <div>
                                <div id="highlighter_947011" class="syntaxhighlighter sh-confluence nogutter  js">
                                    <div class="toolbar"><span><a href="/pages/viewpage.action?pageId=491543#" class="toolbar_item command_help help">?</a></span></div>
                                    <table border="0" cellpadding="0" cellspacing="0">
                                        <tbody>
                                        <tr>
                                            <td class="code">
                                                <div class="container" title="Hint: double-click to select code">
                                                    <div class="line number1 index0 alt2"><code class="js plain">recoPick(</code><code class="js string">'sendLog'</code><code class="js plain">,</code><code class="js string">'view'</code><code class="js plain">, {id:</code><code class="js string">'123'</code><code class="js plain">, c1:</code><code class="js string">'여성의류'</code><code class="js plain">, c2:</code><code class="js string">'자켓/코트'</code><code class="js plain">, c3:</code><code class="js string">'무스탕/가죽자켓'</code><code class="js plain">});</code></div>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p></p>
                </li>
                <li>
                    <p>사이트 방문고객이 133번 상품의 상세 페이지를 방문시 (해당상품의 카테고리 정보 포함)</p>
                    <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                        <div class="codeContent panelContent pdl">
                            <div>
                                <div id="highlighter_134795" class="syntaxhighlighter sh-confluence nogutter  js">
                                    <div class="toolbar"><span><a href="/pages/viewpage.action?pageId=491543#" class="toolbar_item command_help help">?</a></span></div>
                                    <table border="0" cellpadding="0" cellspacing="0">
                                        <tbody>
                                        <tr>
                                            <td class="code">
                                                <div class="container" title="Hint: double-click to select code">
                                                    <div class="line number1 index0 alt2"><code class="js plain">recoPick(</code><code class="js string">'sendLog'</code><code class="js plain">,</code><code class="js string">'view'</code><code class="js plain">, {id:</code><code class="js string">'133'</code><code class="js plain">,c1:</code><code class="js string">'가방/패션잡화'</code><code class="js plain">, c2:</code><code class="js string">'지갑'</code><code class="js plain">, c3:</code><code class="js string">'여성반지갑'</code><code class="js plain">});</code></div>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p></p>
                </li>
            </ul>
            <p><br></p>
            <p>&nbsp; &nbsp;3.&nbsp;<strong><span style="color: rgb(0,0,255);">장바구니 페이지</span></strong>에 들어가셔서 아래 스크립트를 삽입해주세요. &lt;그림1 삽입위치 참고&gt;</p>
            <p>&nbsp; &nbsp; &nbsp; &nbsp; 아래 스트립트를 삽입후&nbsp;<span style="color: rgb(255,102,0);"><strong>상품id, 상품개수</strong></span>는 실제 사용하시는 변수로 수정해 주셔야 하며 상품id는 문자열로 전달되어야 합니다.</p>
            <ul>
                <li>
                    <div class="panel conf-macro output-block" style="background-color: #ffffff;border-color: #007eff;border-style: solid;border-width: 1px;" data-hasbody="true" data-macro-name="panel">
                        <div class="panelHeader" style="border-bottom-width: 1px;border-bottom-style: solid;border-bottom-color: #007eff;background-color: #007eff;color: #ffffff;"><b>sendLog_basket</b></div>
                        <div class="panelContent" style="background-color: #ffffff;">
                            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                                <div class="codeContent panelContent pdl">
                                    <div>
                                        <div id="highlighter_207084" class="syntaxhighlighter sh-midnight nogutter  vb">
                                            <div class="toolbar"><span><a href="/pages/viewpage.action?pageId=491543#" class="toolbar_item command_help help">?</a></span></div>
                                            <table border="0" cellpadding="0" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="code">
                                                        <div class="container" title="Hint: double-click to select code">
                                                            <div class="line number1 index0 alt2"><code class="vb plain">recoPick(</code><code class="vb comments">'sendLog','basket', {id:'상품id', count:상품개수}); </code></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p>&lt;예시&gt;</p>
                </li>
            </ul>
            <ul>
                <li>
                    <p>사이트 방문고객이 123번 상품을 2개, 133번 상품을 1개 장바구니에 담았을 시</p>
                    <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                        <div class="codeContent panelContent pdl">
                            <div>
                                <div id="highlighter_233172" class="syntaxhighlighter sh-confluence nogutter  js">
                                    <div class="toolbar"><span><a href="/pages/viewpage.action?pageId=491543#" class="toolbar_item command_help help">?</a></span></div>
                                    <table border="0" cellpadding="0" cellspacing="0">
                                        <tbody>
                                        <tr>
                                            <td class="code">
                                                <div class="container" title="Hint: double-click to select code">
                                                    <div class="line number1 index0 alt2"><code class="js plain">recoPick(</code><code class="js string">'sendLog'</code><code class="js plain">,</code><code class="js string">'basket'</code><code class="js plain">, {id:</code><code class="js string">'123'</code><code class="js plain">, count:2},{id:</code><code class="js string">'133'</code><code class="js plain">, count:1});</code></div>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p></p>
                </li>
            </ul>
            <p><br></p>
            <p><span style="line-height: 1.42857;">&nbsp; &nbsp; 4.&nbsp;</span><strong style="line-height: 1.42857;"><span style="color: rgb(0,0,255);">구매완료 페이지</span></strong><span style="line-height: 1.42857;">에 들어가셔서 아래 스크립트를 삽입해주세요. &lt;그림1 삽입위치 참고&gt;</span></p>
            <p>&nbsp; &nbsp; &nbsp; &nbsp; 아래 스트립트를 삽입후 <span style="color: rgb(255,102,0);"><strong>상품id, 상품개수, 상품별총가격</strong></span>은 실제 사용하시는 변수로 수정해 주셔야 하며 상품id는 문자열로 전달되어야 합니다.</p>
            <p style="margin-left: 60.0px;">ex) 상품id가 12345 일때, {id:'12345'}</p>
            <ul>
                <li>&nbsp; &nbsp; &nbsp; 주문서에 담긴 각 상품의 <span>sale_price </span>의 합계를 넣어주시면 됩니다. 할인가격으로 구매할 경우 할인가격을 넣어주시면 됩니다.&nbsp; 단, 사용한 쿠폰과 포인트는 제외 해주시면 됩니다.</li>
                <li>
                    <div class="panel conf-macro output-block" style="background-color: #ffffff;border-color: #007eff;border-style: solid;border-width: 1px;" data-hasbody="true" data-macro-name="panel">
                        <div class="panelHeader" style="border-bottom-width: 1px;border-bottom-style: solid;border-bottom-color: #007eff;background-color: #007eff;color: #ffffff;"><b>sendLog_order</b></div>
                        <div class="panelContent" style="background-color: #ffffff;">
                            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                                <div class="codeContent panelContent pdl">
                                    <div>
                                        <div id="highlighter_598187" class="syntaxhighlighter sh-midnight nogutter  vb">
                                            <div class="toolbar"><span><a href="/pages/viewpage.action?pageId=491543#" class="toolbar_item command_help help">?</a></span></div>
                                            <table border="0" cellpadding="0" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="code">
                                                        <div class="container" title="Hint: double-click to select code">
                                                            <div class="line number1 index0 alt2"><code class="vb plain">recoPick(</code><code class="vb comments">'sendLog','order', {id:'상품id', count:상품개수, total_sales:상품별총가격, order_no:'주문번호'}); </code></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p>&lt;예시&gt;</p>
                </li>
                <li>
                    <p>사이트 방문고객이 123번 상품 2개를 12000원(개당 6000원)에, 133번 상품 1개를 6000원에 구매시</p>
                    <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                        <div class="codeContent panelContent pdl">
                            <div>
                                <div id="highlighter_224251" class="syntaxhighlighter sh-confluence nogutter  js">
                                    <div class="toolbar"><span><a href="/pages/viewpage.action?pageId=491543#" class="toolbar_item command_help help">?</a></span></div>
                                    <table border="0" cellpadding="0" cellspacing="0">
                                        <tbody>
                                        <tr>
                                            <td class="code">
                                                <div class="container" title="Hint: double-click to select code">
                                                    <div class="line number1 index0 alt2"><code class="js plain">recoPick(</code><code class="js string">'sendLog'</code><code class="js plain">,</code><code class="js string">'order'</code><code class="js plain">, {id:</code><code class="js string">'123'</code><code class="js plain">, count:2, total_sales:12000, order_no:</code><code class="js string">'201511143187710'</code><code class="js plain">},{id:</code><code class="js string">'133'</code><code class="js plain">, count:1, total_sales:6000, order_no:</code><code class="js string">'201511143187711'</code><code class="js plain">});</code></div>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p></p>
                </li>
            </ul>
            <p><br></p>
            <p>&nbsp; &nbsp; &nbsp;5. <span style="color: rgb(0,0,255);"><strong>검색 페이지</strong></span>에 들어가셔서 아래 스크립트를 삽입해주세요. &lt;그림1 삽입위치 참고&gt;</p>
            <p>&nbsp; &nbsp; &nbsp; &nbsp; 아래 스트립트를 삽입후 <strong><span style="color: rgb(255,102,0);">keyword</span></strong>는 사용자의 검색어(쇼핑몰 내부 검색어, 검색광고 유입 검색어)로 수정해 주셔야 합니다.</p>
            <ul>
                <li>
                    <div class="panel conf-macro output-block" style="background-color: #ffffff;border-color: #007eff;border-style: solid;border-width: 1px;" data-hasbody="true" data-macro-name="panel">
                        <div class="panelHeader" style="border-bottom-width: 1px;border-bottom-style: solid;border-bottom-color: #007eff;background-color: #007eff;color: #ffffff;"><b>sendLog_search</b></div>
                        <div class="panelContent" style="background-color: #ffffff;">
                            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                                <div class="codeContent panelContent pdl">
                                    <div>
                                        <div id="highlighter_722231" class="syntaxhighlighter sh-midnight nogutter  vb">
                                            <div class="toolbar"><span><a href="/pages/viewpage.action?pageId=491543#" class="toolbar_item command_help help">?</a></span></div>
                                            <table border="0" cellpadding="0" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="code">
                                                        <div class="container" title="Hint: double-click to select code">
                                                            <div class="line number1 index0 alt2"><code class="vb plain">recoPick(</code><code class="vb comments">'sendLog','search', 'keyword'); </code></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            <p>&nbsp; &nbsp; &nbsp; &nbsp;&lt;예시&gt;</p>
            <ul>
                <li>
                    <p>사이트 방문고객이 쇼핑몰내에서 ‘청바지’ 검색시</p>
                    <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                        <div class="codeContent panelContent pdl">
                            <div>
                                <div id="highlighter_496759" class="syntaxhighlighter sh-confluence nogutter  js">
                                    <div class="toolbar"><span><a href="/pages/viewpage.action?pageId=491543#" class="toolbar_item command_help help">?</a></span></div>
                                    <table border="0" cellpadding="0" cellspacing="0">
                                        <tbody>
                                        <tr>
                                            <td class="code">
                                                <div class="container" title="Hint: double-click to select code">
                                                    <div class="line number1 index0 alt2"><code class="js plain">recoPick(</code><code class="js string">'sendLog'</code><code class="js plain">,</code><code class="js string">'search'</code><code class="js plain">, </code><code class="js string">'청바지'</code><code class="js plain">);</code></div>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p></p>
                </li>
            </ul>
            <p><br></p>
            <p><span style="color: rgb(0,0,0);">&nbsp; &nbsp; &nbsp;6.<span style="color: rgb(0,0,255);"><strong>&nbsp;메인페이지</strong></span></span><span>에만 아래 스크립트를 삽입해주세요.</span></p>
            <ul>
                <li>
                    <div class="panel conf-macro output-block" style="background-color: #ffffff;border-color: #007eff;border-style: solid;border-width: 1px;" data-hasbody="true" data-macro-name="panel">
                        <div class="panelHeader" style="border-bottom-width: 1px;border-bottom-style: solid;border-bottom-color: #007eff;background-color: #007eff;color: #ffffff;"><b>sendLog_visit</b></div>
                        <div class="panelContent" style="background-color: #ffffff;">
                            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                                <div class="codeContent panelContent pdl">
                                    <div>
                                        <div id="highlighter_49170" class="syntaxhighlighter sh-midnight nogutter  vb">
                                            <div class="toolbar"><span><a href="/pages/viewpage.action?pageId=491543#" class="toolbar_item command_help help">?</a></span></div>
                                            <table border="0" cellpadding="0" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="code">
                                                        <div class="container" title="Hint: double-click to select code">
                                                            <div class="line number1 index0 alt2"><code class="vb plain">recoPick(</code><code class="vb comments">'sendLog','visit'); </code></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            <p><br></p>
            <p><span style="color: rgb(0,0,0);">&nbsp; &nbsp; &nbsp;7. 사용자가 특정 상품에 대해 <span style="color: rgb(0,0,255);"><strong>"좋아요", "즐겨찾기", "찜"</strong></span></span><span>등의 행동을 한 경우 아래 스크립트를 삽입해요 주세요.</span></p>
            <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;아래 스트립트를 삽입 후 <strong><span style="color: rgb(255,102,0);">상품id, 카테고리</span></strong>는 실제 사용하시는 변수로 수정해 주셔야 하며 상품id는 필수 값입니다.</p>
            <ul>
              <li>
                <div class="panel conf-macro output-block" style="background-color: #ffffff;border-color: #007eff;border-style: solid;border-width: 1px;" data-hasbody="true" data-macro-name="panel">
                  <div class="panelHeader" style="border-bottom-width: 1px;border-bottom-style: solid;border-bottom-color: #007eff;background-color: #007eff;color: #ffffff;"><b>sendLog_like</b></div>
                  <div class="panelContent" style="background-color: #ffffff;">
                    <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                      <div class="codeContent panelContent pdl">
                        <div>
                          <div class="syntaxhighlighter sh-midnight nogutter  vb">
                            <div class="toolbar"><span><a href="/pages/viewpage.action?pageId=491543#" class="toolbar_item command_help help">?</a></span></div>
                            <table border="0" cellpadding="0" cellspacing="0">
                              <tbody>
                              <tr>
                                <td class="code">
                                  <div class="container" title="Hint: double-click to select code">
                                    <div class="line number1 index0 alt2"><code class="vb plain">recoPick(</code><code class="vb comments">'sendLog','like',{id:'상품id', c1:'카테고리(대)', c2:'카테고리(중)', c3:'카테고리(소)'}); </code></div>
                                  </div>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <p><br></p>
            <hr>
            <p><br></p>
            <p>&nbsp;<strong style="color: rgb(51,102,255);font-size: 20.0px;">공통 헤더 파일이 없는 경우</strong></p>
            <h4 id="id-독립형쇼핑몰인경우(자체제작쇼핑몰)-1.메인및마이페이지등상품ID가없는모든페이지에삽입하기(단,상품상세페이지,장바구니페이지,구매완료페이지,검색결과페이지는제외)"><strong>1.&nbsp;</strong><strong style="line-height: 1.42857;">메인페이지에 삽입하기</strong></h4>
            <ol>
                <li>
                    <p>메인페이지에 아래 스트크립트를 삽입해주세요.<br><img class="emoticon emoticon-warning" src="@/assets/images/warning.svg" data-emoticon-name="warning" alt="(warning)"><span style="color: rgb(255,0,0);">&nbsp;주의하세요!&nbsp;</span>'사이트 url' 에는 레코픽 회원 가입 시에 등록하신 사이트 URL과 동일한 URL을 넣어주셔야 합니다.&nbsp;회원 가입 시&nbsp;사이트 URL을 등록하실 때에 www 는 제외해 주십시오.&nbsp;또한, 대소문자 구별을 꼭 해주셔야 합니다.</p>
                    <div class="panel conf-macro output-block" style="background-color: #ffffff;border-color: #007eff;border-style: solid;border-width: 1px;" data-hasbody="true" data-macro-name="panel">
                        <div class="panelHeader" style="border-bottom-width: 1px;border-bottom-style: solid;border-bottom-color: #007eff;background-color: #007eff;color: #ffffff;"><b>로그수집 스크립트 (sendLog_visit)</b></div>
                        <div class="panelContent" style="background-color: #ffffff;">
                            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                                <div class="codeContent panelContent pdl">
                                    <div>
                                        <div id="highlighter_681758" class="syntaxhighlighter sh-midnight nogutter  coldfusion">
                                            <div class="toolbar"><span><a href="/pages/viewpage.action?pageId=491543#" class="toolbar_item command_help help">?</a></span></div>
                                            <table border="0" cellpadding="0" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="code">
                                                        <div class="container" title="Hint: double-click to select code">
                                                            <div class="line number1 index0 alt2"><code class="coldfusion comments">&lt;!--RecoPick 로그수집 스크립트 --&gt;</code></div>
                                                            <div class="line number2 index1 alt1"><code class="coldfusion plain">&lt;script type=</code><code class="coldfusion string">"text/javascript"</code><code class="coldfusion plain">&gt;</code></div>
                                                            <div class="line number3 index2 alt2"><code class="coldfusion spaces">&nbsp;&nbsp;</code><code class="coldfusion plain">(function(w,d,n,s,e,o) {</code></div>
                                                            <div class="line number4 index3 alt1"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">w[n]=w[n]||function(){(w[n].q=w[n].q||[]).push(arguments)};</code></div>
                                                            <div class="line number5 index4 alt2"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">e=d.createElement(s);e.async=1;e.charset=</code><code class="coldfusion string">'utf-8'</code><code class="coldfusion plain">;e.src=</code><code class="coldfusion string">'//static.recopick.com/dist/production.min.js'</code><code class="coldfusion plain">;</code></div>
                                                            <div class="line number6 index5 alt1"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">o=d.getElementsByTagName(s)[0];o.parentNode.insertBefore(e,o);</code></div>
                                                            <div class="line number7 index6 alt2"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">})(window, document, </code><code class="coldfusion string">'recoPick'</code><code class="coldfusion plain">, </code><code class="coldfusion string">'script'</code><code class="coldfusion plain">);</code></div>
                                                            <div class="line number8 index7 alt1"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">recoPick(</code><code class="coldfusion string">'service'</code><code class="coldfusion plain">, </code><code class="coldfusion string">'사이트 url'</code><code class="coldfusion plain">);</code></div>
                                                            <div class="line number9 index8 alt2"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">recoPick(</code><code class="coldfusion string">'sendLog'</code><code class="coldfusion plain">,</code><code class="coldfusion string">'visit'</code><code class="coldfusion plain">);&nbsp;&nbsp;&nbsp;&nbsp; </code></div>
                                                            <div class="line number10 index9 alt1"><code class="coldfusion plain">&lt;/script&gt;</code></div>
                                                            <div class="line number11 index10 alt2"><code class="coldfusion comments">&lt;!--RecoPick 로그수집 스크립트 --&gt;</code></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <p>&nbsp;더욱 특화된 개인화 추천을 원하시면 암호화된 ID, 연령, 성별 데이터를 보내주세요!&nbsp;</p>
                    <p><span style="color: rgb(0,0,0);">*&nbsp;</span>(’service’, ‘ 사이트 url’); 바로 다음에 아래 스크립트를 삽입해 주시면 됩니다. 출생년도, 성별은 예시 참고하셔서 변경해 주시고 MEMBER_ID(MID)는 자체적으로 사용중인 회원 ID를 암호화해서 보내주세요.<br><span style="color: rgb(255,0,0);"><span style="color: rgb(0,0,0);">*&nbsp;<span>레코픽에서 수집하는 암호화된 ID, 연령, 성별 데이터로는 절대 개개인을 식별할 수 없으며, 단순히 사용자를 구분하기 위한 용도로만 사용됩니다.<br></span></span></span>&nbsp; 예를 들어, 같은 회원이 PC와 모바일을 통해 접속한다면 MID를 통해 &nbsp;매핑하면 RecoPick에서도 두 사용자를 같은 회원으로 인식하여 개인화 추천 등의 품질 향상에 도움이 됩니다.</p>
                    <p><img class="emoticon emoticon-warning" src="@/assets/images/warning.svg" data-emoticon-name="warning" alt="(warning)"><span style="color: rgb(255,0,0);">&nbsp;주의하세요!&nbsp;</span>레코픽 추천을 API 방식으로 사용하는 경우, GET방식으로 API를 호출하게 됩니다.<span style="color: rgb(255,0,0);"><span style="color: rgb(0,0,0);"><br></span></span>&nbsp;&nbsp;이 경우 일부 API의 파라미터에 MID가 사용되는데, MID 값에 일부 특수문자(예&gt; &amp;, =) 가 들어가게 되면 정상동작 하지 않을 수 있습니다.<span style="color: rgb(255,0,0);"><span style="color: rgb(0,0,0);"><br></span></span>&nbsp; 암호화된 결과에 특수문자가 들어가지 않는 암호화 알고리즘으로 단방향 암호화 해주세요. (예&gt; SHA-256, MD5 등)</p>
                    <p>* MID 단방향 암호화 예 :&nbsp;88020d58ceb8a4ad7f6756021fbc06b8a91f17daad2f04a5d369608d9c308bde (SHA-256으로 암호화),&nbsp;4509bf9373ff3dca193ba02726c87988 (MD5로 암호화)</p>
                    <div class="panel conf-macro output-block" style="background-color: #ffffff;border-color: #007eff;border-style: solid;border-width: 1px;" data-hasbody="true" data-macro-name="panel">
                        <div class="panelHeader" style="border-bottom-width: 1px;border-bottom-style: solid;border-bottom-color: #007eff;background-color: #007eff;color: #ffffff;"><b>사용자 구분을 위한 로그수집 스크립트</b></div>
                        <div class="panelContent" style="background-color: #ffffff;">
                            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                                <div class="codeContent panelContent pdl">
                                    <div>
                                        <div id="highlighter_985351" class="syntaxhighlighter sh-midnight nogutter  vb">
                                            <div class="toolbar"><span><a href="/pages/viewpage.action?pageId=491543#" class="toolbar_item command_help help">?</a></span></div>
                                            <table border="0" cellpadding="0" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="code">
                                                        <div class="container" title="Hint: double-click to select code">
                                                            <div class="line number1 index0 alt2"><code class="vb plain">recoPick(</code><code class="vb comments">'setMID',암호화된 MEMBER_ID); </code></div>
                                                            <div class="line number2 index1 alt1"><code class="vb plain">recoPick(</code><code class="vb comments">'setUserInfo',{ birthyear:‘출생연도’, gender:'성별’});</code></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p>&lt;예시&gt;</p>
                    <p>* 출생년도, 성별 정보 (M: 남자, F: 여자)</p>
                    <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                        <div class="codeContent panelContent pdl">
                            <div>
                                <div id="highlighter_590677" class="syntaxhighlighter sh-confluence nogutter  js">
                                    <div class="toolbar"><span><a href="/pages/viewpage.action?pageId=491543#" class="toolbar_item command_help help">?</a></span></div>
                                    <table border="0" cellpadding="0" cellspacing="0">
                                        <tbody>
                                        <tr>
                                            <td class="code">
                                                <div class="container" title="Hint: double-click to select code">
                                                    <div class="line number1 index0 alt2"><code class="js plain">recoPick(</code><code class="js string">'setUserInfo'</code><code class="js plain">,{ birthyear: ‘1987’, gender: 'M’});</code></div>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p></p>
                </li>
            </ol>
            <h4 id="id-독립형쇼핑몰인경우(자체제작쇼핑몰)-2.상품상세페이지에삽입하기"><strong>2.&nbsp;</strong><strong><span style="color: rgb(0,0,0);">상품 상세 페이지</span>에 삽입하기</strong></h4>
            <ol>
                <li>
                    <p><span style="color: rgb(0,0,0);">상품 상세 페이지</span>에 들어가셔서 아래 스크립트를 삽입해주세요.&nbsp;<br>아래 스트립트를 삽입후&nbsp;<strong><span style="color: rgb(255,102,0);">상품id, 카테고리</span></strong>는 실제 사용하시는 변수로 수정해 주셔야 하며 상품id, 카테고리는 문자열로 전달되어야 합니다.</p>
                    <p style="margin-left: 60.0px;">ex) 상품id가 12345, 카테고리가 여성의류 일때, {id:'12345', c1:'여성의류'}</p>
                    <ul>
                        <li>‘카테고리(대)’, ‘카테고리(중)’, ‘카테고리(소)’에 실제 사용하시는 변수로 수정해서 넣어주시면 보다 고품질의&nbsp;추천을 제공해 드립니다.</li>
                        <li>카테고리는 최대 세 단계(대/중/소) 카테고리가 지원되며 c1, c2, c3를 key로 사용합니다.</li>
                        <li>카테고리 정보가 최소한 1개(c1 : 대 카테고리) 이상 들어가야 합니다.</li>
                        <li>
                            <p><span>카테고리 정보는 카테고리ID가 아닌 카테고리명으로 넣어주십시오. 카테고리ID 사용 시에 오류가 발생할 수 있습니다.</span></p>
                            <ul>
                                <li>
                                    <p><span>자켓, 아우터, 스커트 등 (O)</span></p>
                                </li>
                                <li>
                                    <p><span>23851, 24A831T04C (X)</span></p>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <p>&nbsp; &nbsp; &nbsp; &nbsp;<img class="emoticon emoticon-warning" src="@/assets/images/warning.svg" data-emoticon-name="warning" alt="(warning)"><span style="color: rgb(255,0,0);">&nbsp;주의하세요!&nbsp;</span>'사이트 url' 에는 레코픽 회원 가입 시에 등록하신 사이트 URL과 동일한 URL을 넣어주셔야 합니다.&nbsp;회원 가입 시&nbsp;사이트 URL을 등록하실 때에 www 는 제외해 주십시오.&nbsp;또한, 대소문자 구별을 꼭 해주셔야 합니다.</p>
                    <div class="panel conf-macro output-block" style="background-color: #ffffff;border-color: #007eff;border-style: solid;border-width: 1px;" data-hasbody="true" data-macro-name="panel">
                        <div class="panelHeader" style="border-bottom-width: 1px;border-bottom-style: solid;border-bottom-color: #007eff;background-color: #007eff;color: #ffffff;"><b>로그수집 스크립트 (sendLog_view)</b></div>
                        <div class="panelContent" style="background-color: #ffffff;">
                            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                                <div class="codeContent panelContent pdl">
                                    <div>
                                        <div id="highlighter_73462" class="syntaxhighlighter sh-midnight nogutter  coldfusion">
                                            <div class="toolbar"><span><a href="/pages/viewpage.action?pageId=491543#" class="toolbar_item command_help help">?</a></span></div>
                                            <table border="0" cellpadding="0" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="code">
                                                        <div class="container" title="Hint: double-click to select code">
                                                            <div class="line number1 index0 alt2"><code class="coldfusion comments">&lt;!--RecoPick 로그수집 스크립트 --&gt;</code></div>
                                                            <div class="line number2 index1 alt1"><code class="coldfusion plain">&lt;script type=</code><code class="coldfusion string">"text/javascript"</code><code class="coldfusion plain">&gt;</code></div>
                                                            <div class="line number3 index2 alt2"><code class="coldfusion spaces">&nbsp;&nbsp;</code><code class="coldfusion plain">(function(w,d,n,s,e,o) {</code></div>
                                                            <div class="line number4 index3 alt1"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">w[n]=w[n]||function(){(w[n].q=w[n].q||[]).push(arguments)};</code></div>
                                                            <div class="line number5 index4 alt2"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">e=d.createElement(s);e.async=1;e.charset=</code><code class="coldfusion string">'utf-8'</code><code class="coldfusion plain">;e.src=</code><code class="coldfusion string">'//static.recopick.com/dist/production.min.js'</code><code class="coldfusion plain">;</code></div>
                                                            <div class="line number6 index5 alt1"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">o=d.getElementsByTagName(s)[0];o.parentNode.insertBefore(e,o);</code></div>
                                                            <div class="line number7 index6 alt2"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">})(window, document, </code><code class="coldfusion string">'recoPick'</code><code class="coldfusion plain">, </code><code class="coldfusion string">'script'</code><code class="coldfusion plain">);</code></div>
                                                            <div class="line number8 index7 alt1"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">recoPick(</code><code class="coldfusion string">'service'</code><code class="coldfusion plain">, </code><code class="coldfusion string">'사이트 url'</code><code class="coldfusion plain">);</code></div>
                                                            <div class="line number9 index8 alt2"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">recoPick(</code><code class="coldfusion string">'sendLog'</code><code class="coldfusion plain">,</code><code class="coldfusion string">'view'</code><code class="coldfusion plain">, {id:</code><code class="coldfusion string">'상품id'</code><code class="coldfusion plain">, c1:</code><code class="coldfusion string">'카테고리(대)'</code><code class="coldfusion plain">, c2:</code><code class="coldfusion string">'카테고리(중)'</code><code class="coldfusion plain">, c3:</code><code class="coldfusion string">'카테고리(소)'</code><code class="coldfusion plain">});&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </code></div>
                                                            <div class="line number10 index9 alt1"><code class="coldfusion plain">&lt;/script&gt;</code></div>
                                                            <div class="line number11 index10 alt2"><code class="coldfusion comments">&lt;!--RecoPick 로그수집 스크립트 --&gt;</code></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p>&nbsp;&lt;예시&gt;</p>
                    <ul>
                        <li>
                            <p>사이트 방문고객이 123번 상품의 상세 페이지를 방문시 (해당상품의 카테고리 정보 포함)</p>
                            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                                <div class="codeContent panelContent pdl">
                                    <div>
                                        <div id="highlighter_628777" class="syntaxhighlighter sh-confluence nogutter  js">
                                            <div class="toolbar"><span><a href="/pages/viewpage.action?pageId=491543#" class="toolbar_item command_help help">?</a></span></div>
                                            <table border="0" cellpadding="0" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="code">
                                                        <div class="container" title="Hint: double-click to select code">
                                                            <div class="line number1 index0 alt2"><code class="js plain">recoPick(</code><code class="js string">'sendLog'</code><code class="js plain">,</code><code class="js string">'view'</code><code class="js plain">, {id:</code><code class="js string">'123'</code><code class="js plain">, c1:</code><code class="js string">'여성의류'</code><code class="js plain">, c2:</code><code class="js string">'자켓/코트'</code><code class="js plain">, c3:</code><code class="js string">'무스탕/가죽자켓'</code><code class="js plain">});</code></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p></p>
                        </li>
                        <li>
                            <p>사이트 방문고객이 133번 상품의 상세 페이지를 방문시 (해당상품의 카테고리 정보 포함)</p>
                            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                                <div class="codeContent panelContent pdl">
                                    <div>
                                        <div id="highlighter_38123" class="syntaxhighlighter sh-confluence nogutter  js">
                                            <div class="toolbar"><span><a href="/pages/viewpage.action?pageId=491543#" class="toolbar_item command_help help">?</a></span></div>
                                            <table border="0" cellpadding="0" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="code">
                                                        <div class="container" title="Hint: double-click to select code">
                                                            <div class="line number1 index0 alt2"><code class="js plain">recoPick(</code><code class="js string">'sendLog'</code><code class="js plain">,</code><code class="js string">'view'</code><code class="js plain">, {id:</code><code class="js string">'133'</code><code class="js plain">,c1:</code><code class="js string">'가방/패션잡화'</code><code class="js plain">, c2:</code><code class="js string">'지갑'</code><code class="js plain">, c3:</code><code class="js string">'여성반지갑'</code><code class="js plain">});</code></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p></p>
                        </li>
                    </ul>
                    <p>2. 더욱 특화된 개인화 추천을 원하시면 암호화된 ID, 연령, 성별 데이터를 보내주세요!&nbsp;</p>
                    <p><span style="color: rgb(0,0,0);">*&nbsp;</span>(’service’, ‘ 사이트 url’); 바로 다음에 아래 스크립트를 삽입해 주시면 됩니다. 출생년도, 성별은 예시 참고하셔서 변경해 주시고 MEMBER_ID(MID)는 자체적으로 사용중인 회원 ID를 암호화해서 보내주세요.<br><span style="color: rgb(255,0,0);"><span style="color: rgb(0,0,0);">*&nbsp;레코픽에서 수집하는 암호화된 ID, 연령, 성별 데이터로는 절대 개개인을 식별할 수 없으며, 단순히 사용자를 구분하기 위한 용도로만 사용됩니다.<br></span></span>&nbsp; 예를 들어, 같은 회원이 PC와 모바일을 통해 접속한다면 MID를 통해 &nbsp;매핑하면 RecoPick에서도 두 사용자를 같은 회원으로 인식하여 개인화 추천 등의 품질 향상에 도움이 됩니다.</p>
                    <p><img class="emoticon emoticon-warning" src="@/assets/images/warning.svg" data-emoticon-name="warning" alt="(warning)"><span style="color: rgb(255,0,0);">&nbsp;주의하세요!&nbsp;</span>레코픽 추천을 API 방식으로 사용하는 경우, GET방식으로 API를 호출하게 됩니다.<span style="color: rgb(255,0,0);"><span style="color: rgb(0,0,0);"><br></span></span>&nbsp;&nbsp;이 경우 일부 API의 파라미터에 MID가 사용되는데, MID 값에 일부 특수문자(예&gt; &amp;, =) 가 들어가게 되면 정상동작 하지 않을 수 있습니다.<span style="color: rgb(255,0,0);"><span style="color: rgb(0,0,0);"><br></span></span>&nbsp; 암호화된 결과에 특수문자가 들어가지 않는 암호화 알고리즘으로 단방향 암호화 해주세요. (예&gt; SHA-256, MD5 등)</p>
                    <p>* MID 단방향 암호화 예 :&nbsp;88020d58ceb8a4ad7f6756021fbc06b8a91f17daad2f04a5d369608d9c308bde (SHA-256으로 암호화),&nbsp;4509bf9373ff3dca193ba02726c87988 (MD5로 암호화)</p>
                    <div class="panel conf-macro output-block" style="background-color: #ffffff;border-color: #007eff;border-style: solid;border-width: 1px;" data-hasbody="true" data-macro-name="panel">
                        <div class="panelHeader" style="border-bottom-width: 1px;border-bottom-style: solid;border-bottom-color: #007eff;background-color: #007eff;color: #ffffff;"><b>사용자 구분을 위한 로그수집 스크립트</b></div>
                        <div class="panelContent" style="background-color: #ffffff;">
                            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                                <div class="codeContent panelContent pdl">
                                    <div>
                                        <div id="highlighter_236453" class="syntaxhighlighter sh-midnight nogutter  vb">
                                            <div class="toolbar"><span><a href="/pages/viewpage.action?pageId=491543#" class="toolbar_item command_help help">?</a></span></div>
                                            <table border="0" cellpadding="0" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="code">
                                                        <div class="container" title="Hint: double-click to select code">
                                                            <div class="line number1 index0 alt2"><code class="vb plain">recoPick(</code><code class="vb comments">'setMID',암호화된 MEMBER_ID); </code></div>
                                                            <div class="line number2 index1 alt1"><code class="vb plain">recoPick(</code><code class="vb comments">'setUserInfo',{ birthyear:‘출생연도’, gender:'성별’});</code></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p>&lt;예시&gt;</p>
                    <p>* 출생년도, 성별 정보 (M: 남자, F: 여자)</p>
                    <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                        <div class="codeContent panelContent pdl">
                            <div>
                                <div id="highlighter_842269" class="syntaxhighlighter sh-confluence nogutter  js">
                                    <div class="toolbar"><span><a href="/pages/viewpage.action?pageId=491543#" class="toolbar_item command_help help">?</a></span></div>
                                    <table border="0" cellpadding="0" cellspacing="0">
                                        <tbody>
                                        <tr>
                                            <td class="code">
                                                <div class="container" title="Hint: double-click to select code">
                                                    <div class="line number1 index0 alt2"><code class="js plain">recoPick(</code><code class="js string">'setUserInfo'</code><code class="js plain">,{ birthyear: ‘1987’, gender: 'M’});</code></div>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p></p>
                </li>
            </ol>
            <h4 id="id-독립형쇼핑몰인경우(자체제작쇼핑몰)-3.장바구니페이지에삽입하기"><strong>3. 장바구니</strong><strong><span style="color: rgb(0,0,0);">&nbsp;페이지</span>에 삽입하기</strong></h4>
            <ol>
                <li>
                    <p><span style="color: rgb(0,0,0);">장바구니 페이지에 아래 스트립트를 삽입해 주세요.&nbsp;</span><br>아래 스트립트를 삽입후&nbsp;<strong><span style="color: rgb(255,102,0);">상품id, 카테고리</span></strong>는 실제 사용하시는 변수로 수정해 주셔야 하며 상품id, 카테고리는 문자열로 전달되어야 합니다.</p>
                    <p style="margin-left: 60.0px;">ex) 상품id가 12345, 카테고리가 여성의류 일때, {id:'12345', c1:'여성의류'}</p>
                    <ul>
                        <li><img class="emoticon emoticon-warning" src="@/assets/images/warning.svg" data-emoticon-name="warning" alt="(warning)"><span style="color: rgb(255,0,0);">&nbsp;주의하세요!&nbsp;</span>'사이트 url' 에는 레코픽 회원 가입 시에 등록하신 사이트 URL과 동일한 URL을 넣어주셔야 합니다.&nbsp;회원 가입 시&nbsp;사이트 URL을 등록하실 때에 www 는 제외해 주십시오.&nbsp;또한, 대소문자 구별을 꼭 해주셔야 합니다.</li>
                    </ul>
                    <div class="panel conf-macro output-block" style="background-color: #ffffff;border-color: #007eff;border-style: solid;border-width: 1px;" data-hasbody="true" data-macro-name="panel">
                        <div class="panelHeader" style="border-bottom-width: 1px;border-bottom-style: solid;border-bottom-color: #007eff;background-color: #007eff;color: #ffffff;"><b>로그수집 스크립트 (sendLog_basket)</b></div>
                        <div class="panelContent" style="background-color: #ffffff;">
                            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                                <div class="codeContent panelContent pdl">
                                    <div>
                                        <div id="highlighter_54612" class="syntaxhighlighter sh-midnight nogutter  coldfusion">
                                            <div class="toolbar"><span><a href="/pages/viewpage.action?pageId=491543#" class="toolbar_item command_help help">?</a></span></div>
                                            <table border="0" cellpadding="0" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="code">
                                                        <div class="container" title="Hint: double-click to select code">
                                                            <div class="line number1 index0 alt2"><code class="coldfusion comments">&lt;!--RecoPick 로그수집 스크립트 --&gt;</code></div>
                                                            <div class="line number2 index1 alt1"><code class="coldfusion plain">&lt;script type=</code><code class="coldfusion string">"text/javascript"</code><code class="coldfusion plain">&gt;</code></div>
                                                            <div class="line number3 index2 alt2"><code class="coldfusion spaces">&nbsp;&nbsp;</code><code class="coldfusion plain">(function(w,d,n,s,e,o) {</code></div>
                                                            <div class="line number4 index3 alt1"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">w[n]=w[n]||function(){(w[n].q=w[n].q||[]).push(arguments)};</code></div>
                                                            <div class="line number5 index4 alt2"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">e=d.createElement(s);e.async=1;e.charset=</code><code class="coldfusion string">'utf-8'</code><code class="coldfusion plain">;e.src=</code><code class="coldfusion string">'//static.recopick.com/dist/production.min.js'</code><code class="coldfusion plain">;</code></div>
                                                            <div class="line number6 index5 alt1"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">o=d.getElementsByTagName(s)[0];o.parentNode.insertBefore(e,o);</code></div>
                                                            <div class="line number7 index6 alt2"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">})(window, document, </code><code class="coldfusion string">'recoPick'</code><code class="coldfusion plain">, </code><code class="coldfusion string">'script'</code><code class="coldfusion plain">);</code></div>
                                                            <div class="line number8 index7 alt1"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">recoPick(</code><code class="coldfusion string">'service'</code><code class="coldfusion plain">, </code><code class="coldfusion string">'사이트 url'</code><code class="coldfusion plain">);</code></div>
                                                            <div class="line number9 index8 alt2"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">recoPick(</code><code class="coldfusion string">'sendLog'</code><code class="coldfusion plain">,</code><code class="coldfusion string">'basket'</code><code class="coldfusion plain">, {id:</code><code class="coldfusion string">'상품id'</code><code class="coldfusion plain">, count:상품개수});&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </code></div>
                                                            <div class="line number10 index9 alt1"><code class="coldfusion plain">&lt;/script&gt;</code></div>
                                                            <div class="line number11 index10 alt2"><code class="coldfusion comments">&lt;!--RecoPick 로그수집 스크립트 --&gt;</code></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p>&nbsp;&lt;예시&gt;</p>
                    <ul>
                        <li>
                            <p>사이트 방문고객이 123번 상품을 2개, 133번 상품을 1개 장바구니에 담았을시</p>
                            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                                <div class="codeContent panelContent pdl">
                                    <div>
                                        <div id="highlighter_955477" class="syntaxhighlighter sh-confluence nogutter  js">
                                            <div class="toolbar"><span><a href="/pages/viewpage.action?pageId=491543#" class="toolbar_item command_help help">?</a></span></div>
                                            <table border="0" cellpadding="0" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="code">
                                                        <div class="container" title="Hint: double-click to select code">
                                                            <div class="line number1 index0 alt2"><code class="js plain">recoPick(</code><code class="js string">'sendLog'</code><code class="js plain">,</code><code class="js string">'basket'</code><code class="js plain">, {id:</code><code class="js string">'123'</code><code class="js plain">, count:2},{id:</code><code class="js string">'133'</code><code class="js plain">, count:1});</code></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p></p>
                        </li>
                    </ul>
                    <p>2. 더욱 특화된 개인화 추천을 원하시면 암호화된 ID, 연령, 성별 데이터를 보내주세요!&nbsp;</p>
                    <p><span style="color: rgb(0,0,0);">*&nbsp;</span>(’service’, ‘ 사이트 url’); 바로 다음에 아래 스크립트를 삽입해 주시면 됩니다. 출생년도, 성별은 예시 참고하셔서 변경해 주시고 MEMBER_ID(MID)는 자체적으로 사용중인 회원 ID를 암호화해서 보내주세요.<br><span style="color: rgb(255,0,0);"><span style="color: rgb(0,0,0);">*&nbsp;레코픽에서 수집하는 암호화된 ID, 연령, 성별 데이터로는 절대 개개인을 식별할 수 없으며, 단순히 사용자를 구분하기 위한 용도로만 사용됩니다.<br></span></span>&nbsp; 예를 들어, 같은 회원이 PC와 모바일을 통해 접속한다면 MID를 통해 &nbsp;매핑하면 RecoPick에서도 두 사용자를 같은 회원으로 인식하여 개인화 추천 등의 품질 향상에 도움이 됩니다.</p>
                    <p><img class="emoticon emoticon-warning" src="@/assets/images/warning.svg" data-emoticon-name="warning" alt="(warning)"><span style="color: rgb(255,0,0);">&nbsp;주의하세요!&nbsp;</span>레코픽 추천을 API 방식으로 사용하는 경우, GET방식으로 API를 호출하게 됩니다.<span style="color: rgb(255,0,0);"><span style="color: rgb(0,0,0);"><br></span></span>&nbsp;&nbsp;이 경우 일부 API의 파라미터에 MID가 사용되는데, MID 값에 일부 특수문자(예&gt; &amp;, =) 가 들어가게 되면 정상동작 하지 않을 수 있습니다.<span style="color: rgb(255,0,0);"><span style="color: rgb(0,0,0);"><br></span></span>&nbsp; 암호화된 결과에 특수문자가 들어가지 않는 암호화 알고리즘으로 단방향 암호화 해주세요. (예&gt; SHA-256, MD5 등)</p>
                    <p>* MID 단방향 암호화 예 :&nbsp;88020d58ceb8a4ad7f6756021fbc06b8a91f17daad2f04a5d369608d9c308bde (SHA-256으로 암호화),&nbsp;4509bf9373ff3dca193ba02726c87988 (MD5로 암호화)</p>
                    <div class="panel conf-macro output-block" style="background-color: #ffffff;border-color: #007eff;border-style: solid;border-width: 1px;" data-hasbody="true" data-macro-name="panel">
                        <div class="panelHeader" style="border-bottom-width: 1px;border-bottom-style: solid;border-bottom-color: #007eff;background-color: #007eff;color: #ffffff;"><b>사용자 구분을 위한 로그수집 스크립트</b></div>
                        <div class="panelContent" style="background-color: #ffffff;">
                            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                                <div class="codeContent panelContent pdl">
                                    <div>
                                        <div id="highlighter_106668" class="syntaxhighlighter sh-midnight nogutter  vb">
                                            <div class="toolbar"><span><a href="/pages/viewpage.action?pageId=491543#" class="toolbar_item command_help help">?</a></span></div>
                                            <table border="0" cellpadding="0" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="code">
                                                        <div class="container" title="Hint: double-click to select code">
                                                            <div class="line number1 index0 alt2"><code class="vb plain">recoPick(</code><code class="vb comments">'setMID',암호화된 MEMBER_ID); </code></div>
                                                            <div class="line number2 index1 alt1"><code class="vb plain">recoPick(</code><code class="vb comments">'setUserInfo',{ birthyear:‘출생연도’, gender:'성별’});</code></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p>&lt;예시&gt;</p>
                    <p>* 출생년도, 성별 정보 (M: 남자, F: 여자)</p>
                    <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                        <div class="codeContent panelContent pdl">
                            <div>
                                <div id="highlighter_741924" class="syntaxhighlighter sh-confluence nogutter  js">
                                    <div class="toolbar"><span><a href="/pages/viewpage.action?pageId=491543#" class="toolbar_item command_help help">?</a></span></div>
                                    <table border="0" cellpadding="0" cellspacing="0">
                                        <tbody>
                                        <tr>
                                            <td class="code">
                                                <div class="container" title="Hint: double-click to select code">
                                                    <div class="line number1 index0 alt2"><code class="js plain">recoPick(</code><code class="js string">'setUserInfo'</code><code class="js plain">,{ birthyear: ‘1987’, gender: 'M’});</code></div>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p></p>
                </li>
            </ol>
            <h4 id="id-독립형쇼핑몰인경우(자체제작쇼핑몰)-4.구매완료페이지에삽입하기"><strong>4. 구매완료</strong><strong><span style="color: rgb(0,0,0);">&nbsp;페이지</span>에 삽입하기</strong></h4>
            <ol>
                <li>
                    <p><span style="color: rgb(0,0,0);">구매완료 페이지</span><span>에 들어가셔서 아래 스크립트를 삽입해주세요.&nbsp;</span></p>
                    <p>아래 스트립트를 삽입후&nbsp;<span style="color: rgb(255,102,0);"><strong>상품id, 상품개수, 상품별총가격</strong></span>은 실제 사용하시는 변수로 수정해 주셔야 하며 상품id는 문자열로 전달되어야 합니다.</p>
                    <p style="margin-left: 60.0px;">ex) 상품id가 12345 일때, {id:'12345'}</p>
                    <ul>
                        <li><img class="emoticon emoticon-warning" src="@/assets/images/warning.svg" data-emoticon-name="warning" alt="(warning)"><span style="color: rgb(255,0,0);">&nbsp;주의하세요!&nbsp;</span>'사이트 url' 에는 레코픽 회원 가입 시에 등록하신 사이트 URL과 동일한 URL을 넣어주셔야 합니다.&nbsp;회원 가입 시&nbsp;사이트 URL을 등록하실 때에 www 는 제외해 주십시오.&nbsp;또한, 대소문자 구별을 꼭 해주셔야 합니다.</li>
                    </ul>
                    <div class="panel conf-macro output-block" style="background-color: #ffffff;border-color: #007eff;border-style: solid;border-width: 1px;" data-hasbody="true" data-macro-name="panel">
                        <div class="panelHeader" style="border-bottom-width: 1px;border-bottom-style: solid;border-bottom-color: #007eff;background-color: #007eff;color: #ffffff;"><b>로그수집 스크립트 (sendLog_order)</b></div>
                        <div class="panelContent" style="background-color: #ffffff;">
                            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                                <div class="codeContent panelContent pdl">
                                    <div>
                                        <div id="highlighter_476612" class="syntaxhighlighter sh-midnight nogutter  coldfusion">
                                            <div class="toolbar"><span><a href="/pages/viewpage.action?pageId=491543#" class="toolbar_item command_help help">?</a></span></div>
                                            <table border="0" cellpadding="0" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="code">
                                                        <div class="container" title="Hint: double-click to select code">
                                                            <div class="line number1 index0 alt2"><code class="coldfusion comments">&lt;!--RecoPick 로그수집 스크립트 --&gt;</code></div>
                                                            <div class="line number2 index1 alt1"><code class="coldfusion plain">&lt;script type=</code><code class="coldfusion string">"text/javascript"</code><code class="coldfusion plain">&gt;</code></div>
                                                            <div class="line number3 index2 alt2"><code class="coldfusion spaces">&nbsp;&nbsp;</code><code class="coldfusion plain">(function(w,d,n,s,e,o) {</code></div>
                                                            <div class="line number4 index3 alt1"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">w[n]=w[n]||function(){(w[n].q=w[n].q||[]).push(arguments)};</code></div>
                                                            <div class="line number5 index4 alt2"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">e=d.createElement(s);e.async=1;e.charset=</code><code class="coldfusion string">'utf-8'</code><code class="coldfusion plain">;e.src=</code><code class="coldfusion string">'//static.recopick.com/dist/production.min.js'</code><code class="coldfusion plain">;</code></div>
                                                            <div class="line number6 index5 alt1"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">o=d.getElementsByTagName(s)[0];o.parentNode.insertBefore(e,o);</code></div>
                                                            <div class="line number7 index6 alt2"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">})(window, document, </code><code class="coldfusion string">'recoPick'</code><code class="coldfusion plain">, </code><code class="coldfusion string">'script'</code><code class="coldfusion plain">);</code></div>
                                                            <div class="line number8 index7 alt1"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">recoPick(</code><code class="coldfusion string">'service'</code><code class="coldfusion plain">, </code><code class="coldfusion string">'사이트 url'</code><code class="coldfusion plain">);</code></div>
                                                            <div class="line number9 index8 alt2"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">recoPick(</code><code class="coldfusion string">'sendLog'</code><code class="coldfusion plain">,</code><code class="coldfusion string">'order'</code><code class="coldfusion plain">, {id:</code><code class="coldfusion string">'상품id'</code><code class="coldfusion plain">, count:상품개수, total_sales:상품별총가격});&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </code></div>
                                                            <div class="line number10 index9 alt1"><code class="coldfusion plain">&lt;/script&gt;</code></div>
                                                            <div class="line number11 index10 alt2"><code class="coldfusion comments">&lt;!--RecoPick 로그수집 스크립트 --&gt;</code></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p>&nbsp;&lt;예시&gt;</p>
                    <ul>
                        <li>
                            <p>사이트 방문고객이 123번 상품 2개를 12000원(개당 6000원)에, 133번 상품 1개를 6000원에 구매시</p>
                        </li>
                        <li>
                            <p><br></p>
                            <p class="auto-cursor-target">POST방식으로 전송 할 경우 상품 개수에는 제약 사항이 없으나, GET방식으로 전송할 경우 브라우저에 따른 제약 사항이 있으니 참고하시기 바랍니다.&nbsp;</p>
                            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                                <div class="codeContent panelContent pdl">
                                    <div>
                                        <div id="highlighter_740161" class="syntaxhighlighter sh-confluence nogutter  js">
                                            <div class="toolbar"><span><a href="/pages/viewpage.action?pageId=491543#" class="toolbar_item command_help help">?</a></span></div>
                                            <table border="0" cellpadding="0" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="code">
                                                        <div class="container" title="Hint: double-click to select code">
                                                            <div class="line number1 index0 alt2"><code class="js plain">recoPick(</code><code class="js string">'sendLog'</code><code class="js plain">,</code><code class="js string">'order'</code><code class="js plain">, {id:</code><code class="js string">'123'</code><code class="js plain">, count:2, total_sales:12000},{id:</code><code class="js string">'133'</code><code class="js plain">, count:1, total_sales:6000});</code></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p></p>
                        </li>
                    </ul>
                    <p>2. 더욱 특화된 개인화 추천을 원하시면 암호화된 ID, 연령, 성별 데이터를 보내주세요!&nbsp;</p>
                    <p><span style="color: rgb(0,0,0);">*&nbsp;</span>(’service’, ‘ 사이트 url’); 바로 다음에 아래 스크립트를 삽입해 주시면 됩니다. 출생년도, 성별은 예시 참고하셔서 변경해 주시고 MEMBER_ID(MID)는 자체적으로 사용중인 회원 ID를 암호화해서 보내주세요.<br><span style="color: rgb(255,0,0);"><span style="color: rgb(0,0,0);">*&nbsp;레코픽에서 수집하는 암호화된 ID, 연령, 성별 데이터로는 절대 개개인을 식별할 수 없으며, 단순히 사용자를 구분하기 위한 용도로만 사용됩니다.<br></span></span>&nbsp; 예를 들어, 같은 회원이 PC와 모바일을 통해 접속한다면 MID를 통해 &nbsp;매핑하면 RecoPick에서도 두 사용자를 같은 회원으로 인식하여 개인화 추천 등의 품질 향상에 도움이 됩니다.</p>
                    <p><img class="emoticon emoticon-warning" src="@/assets/images/warning.svg" data-emoticon-name="warning" alt="(warning)"><span style="color: rgb(255,0,0);">&nbsp;주의하세요!&nbsp;</span>레코픽 추천을 API 방식으로 사용하는 경우, GET방식으로 API를 호출하게 됩니다.<span style="color: rgb(255,0,0);"><span style="color: rgb(0,0,0);"><br></span></span>&nbsp;&nbsp;이 경우 일부 API의 파라미터에 MID가 사용되는데, MID 값에 일부 특수문자(예&gt; &amp;, =) 가 들어가게 되면 정상동작 하지 않을 수 있습니다.<span style="color: rgb(255,0,0);"><span style="color: rgb(0,0,0);"><br></span></span>&nbsp; 암호화된 결과에 특수문자가 들어가지 않는 암호화 알고리즘으로 단방향 암호화 해주세요. (예&gt; SHA-256, MD5 등)</p>
                    <p>* MID 단방향 암호화 예 :&nbsp;88020d58ceb8a4ad7f6756021fbc06b8a91f17daad2f04a5d369608d9c308bde (SHA-256으로 암호화),&nbsp;4509bf9373ff3dca193ba02726c87988 (MD5로 암호화)</p>
                    <div class="panel conf-macro output-block" style="background-color: #ffffff;border-color: #007eff;border-style: solid;border-width: 1px;" data-hasbody="true" data-macro-name="panel">
                        <div class="panelHeader" style="border-bottom-width: 1px;border-bottom-style: solid;border-bottom-color: #007eff;background-color: #007eff;color: #ffffff;"><b>사용자 구분을 위한 로그수집 스크립트</b></div>
                        <div class="panelContent" style="background-color: #ffffff;">
                            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                                <div class="codeContent panelContent pdl">
                                    <div>
                                        <div id="highlighter_182055" class="syntaxhighlighter sh-midnight nogutter  vb">
                                            <div class="toolbar"><span><a href="/pages/viewpage.action?pageId=491543#" class="toolbar_item command_help help">?</a></span></div>
                                            <table border="0" cellpadding="0" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="code">
                                                        <div class="container" title="Hint: double-click to select code">
                                                            <div class="line number1 index0 alt2"><code class="vb plain">recoPick(</code><code class="vb comments">'setMID',암호화된 MEMBER_ID); </code></div>
                                                            <div class="line number2 index1 alt1"><code class="vb plain">recoPick(</code><code class="vb comments">'setUserInfo',{ birthyear:‘출생연도’, gender:'성별’});</code></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p>&lt;예시&gt;</p>
                    <p>* 출생년도, 성별 정보 (M: 남자, F: 여자)</p>
                    <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                        <div class="codeContent panelContent pdl">
                            <div>
                                <div id="highlighter_42352" class="syntaxhighlighter sh-confluence nogutter  js">
                                    <div class="toolbar"><span><a href="/pages/viewpage.action?pageId=491543#" class="toolbar_item command_help help">?</a></span></div>
                                    <table border="0" cellpadding="0" cellspacing="0">
                                        <tbody>
                                        <tr>
                                            <td class="code">
                                                <div class="container" title="Hint: double-click to select code">
                                                    <div class="line number1 index0 alt2"><code class="js plain">recoPick(</code><code class="js string">'setUserInfo'</code><code class="js plain">,{ birthyear: ‘1987’, gender: 'M’});</code></div>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p></p>
                </li>
            </ol>
            <h4 id="id-독립형쇼핑몰인경우(자체제작쇼핑몰)-5.검색페이지에삽입하기"><strong>5. 검색</strong><strong><span style="color: rgb(0,0,0);">&nbsp;페이지</span>에 삽입하기</strong></h4>
            <ol>
                <li>
                    <p><span style="color: rgb(0,0,0);">검색 페이지</span>에 들어가셔서 아래 스크립트를 삽입해주세요.&nbsp;</p>
                    <p>아래 스트립트를 삽입후&nbsp;<span style="color: rgb(255,102,0);"><strong>keyword</strong></span>는 실제 사용하시는 변수로 수정해 주셔야 하며 상품id는 문자열로 전달되어야 합니다.</p>
                    <p style="margin-left: 60.0px;">ex) 상품id가 12345 일때, {id:'12345'}</p>
                    <ul>
                        <li><img class="emoticon emoticon-warning" src="@/assets/images/warning.svg" data-emoticon-name="warning" alt="(warning)"><span style="color: rgb(255,0,0);">&nbsp;주의하세요!&nbsp;</span>'사이트 url' 에는 레코픽 회원 가입 시에 등록하신 사이트 URL과 동일한 URL을 넣어주셔야 합니다.&nbsp;회원 가입 시&nbsp;사이트 URL을 등록하실 때에 www 는 제외해 주십시오.&nbsp;또한, 대소문자 구별을 꼭 해주셔야 합니다.</li>
                    </ul>
                    <div class="panel conf-macro output-block" style="background-color: #ffffff;border-color: #007eff;border-style: solid;border-width: 1px;" data-hasbody="true" data-macro-name="panel">
                        <div class="panelHeader" style="border-bottom-width: 1px;border-bottom-style: solid;border-bottom-color: #007eff;background-color: #007eff;color: #ffffff;"><b>로그수집 스크립트 (sendLog_search)</b></div>
                        <div class="panelContent" style="background-color: #ffffff;">
                            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                                <div class="codeContent panelContent pdl">
                                    <div>
                                        <div id="highlighter_867278" class="syntaxhighlighter sh-midnight nogutter  coldfusion">
                                            <div class="toolbar"><span><a href="/pages/viewpage.action?pageId=491543#" class="toolbar_item command_help help">?</a></span></div>
                                            <table border="0" cellpadding="0" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="code">
                                                        <div class="container" title="Hint: double-click to select code">
                                                            <div class="line number1 index0 alt2"><code class="coldfusion comments">&lt;!--RecoPick 로그수집 스크립트 --&gt;</code></div>
                                                            <div class="line number2 index1 alt1"><code class="coldfusion plain">&lt;script type=</code><code class="coldfusion string">"text/javascript"</code><code class="coldfusion plain">&gt;</code></div>
                                                            <div class="line number3 index2 alt2"><code class="coldfusion spaces">&nbsp;&nbsp;</code><code class="coldfusion plain">(function(w,d,n,s,e,o) {</code></div>
                                                            <div class="line number4 index3 alt1"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">w[n]=w[n]||function(){(w[n].q=w[n].q||[]).push(arguments)};</code></div>
                                                            <div class="line number5 index4 alt2"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">e=d.createElement(s);e.async=1;e.charset=</code><code class="coldfusion string">'utf-8'</code><code class="coldfusion plain">;e.src=</code><code class="coldfusion string">'//static.recopick.com/dist/production.min.js'</code><code class="coldfusion plain">;</code></div>
                                                            <div class="line number6 index5 alt1"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">o=d.getElementsByTagName(s)[0];o.parentNode.insertBefore(e,o);</code></div>
                                                            <div class="line number7 index6 alt2"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">})(window, document, </code><code class="coldfusion string">'recoPick'</code><code class="coldfusion plain">, </code><code class="coldfusion string">'script'</code><code class="coldfusion plain">);</code></div>
                                                            <div class="line number8 index7 alt1"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">recoPick(</code><code class="coldfusion string">'service'</code><code class="coldfusion plain">, </code><code class="coldfusion string">'사이트 url'</code><code class="coldfusion plain">);</code></div>
                                                            <div class="line number9 index8 alt2"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">recoPick(</code><code class="coldfusion string">'sendLog'</code><code class="coldfusion plain">,</code><code class="coldfusion string">'search'</code><code class="coldfusion plain">, </code><code class="coldfusion string">'keyword'</code><code class="coldfusion plain">);&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </code></div>
                                                            <div class="line number10 index9 alt1"><code class="coldfusion plain">&lt;/script&gt;</code></div>
                                                            <div class="line number11 index10 alt2"><code class="coldfusion comments">&lt;!--RecoPick 로그수집 스크립트 --&gt;</code></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p>&nbsp;&lt;예시&gt;</p>
                    <ul>
                        <li>
                            <p>사이트 방문고객이 쇼핑몰내에서 ‘청바지’ 검색시</p>
                            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                                <div class="codeContent panelContent pdl">
                                    <div>
                                        <div id="highlighter_275136" class="syntaxhighlighter sh-confluence nogutter  js">
                                            <div class="toolbar"><span><a href="/pages/viewpage.action?pageId=491543#" class="toolbar_item command_help help">?</a></span></div>
                                            <table border="0" cellpadding="0" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="code">
                                                        <div class="container" title="Hint: double-click to select code">
                                                            <div class="line number1 index0 alt2"><code class="js plain">recoPick(</code><code class="js string">'sendLog'</code><code class="js plain">,</code><code class="js string">'search'</code><code class="js plain">, </code><code class="js string">'청바지'</code><code class="js plain">);</code></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p></p>
                        </li>
                    </ul>
                    <p>2. 더욱 특화된 개인화 추천을 원하시면 암호화된 ID, 연령, 성별 데이터를 보내주세요!&nbsp;</p>
                    <p><span style="color: rgb(0,0,0);">*&nbsp;</span>(’service’, ‘ 사이트 url’); 바로 다음에 아래 스크립트를 삽입해 주시면 됩니다. 출생년도, 성별은 예시 참고하셔서 변경해 주시고 MEMBER_ID(MID)는 자체적으로 사용중인 회원 ID를 암호화해서 보내주세요.<br><span style="color: rgb(255,0,0);"><span style="color: rgb(0,0,0);">*&nbsp;레코픽에서 수집하는 암호화된 ID, 연령, 성별 데이터로는 절대 개개인을 식별할 수 없으며, 단순히 사용자를 구분하기 위한 용도로만 사용됩니다.<br></span></span>&nbsp; 예를 들어, 같은 회원이 PC와 모바일을 통해 접속한다면 MID를 통해 &nbsp;매핑하면 RecoPick에서도 두 사용자를 같은 회원으로 인식하여 개인화 추천 등의 품질 향상에 도움이 됩니다.</p>
                    <p><img class="emoticon emoticon-warning" src="@/assets/images/warning.svg" data-emoticon-name="warning" alt="(warning)"><span style="color: rgb(255,0,0);">&nbsp;주의하세요!&nbsp;</span>레코픽 추천을 API 방식으로 사용하는 경우, GET방식으로 API를 호출하게 됩니다.<span style="color: rgb(255,0,0);"><span style="color: rgb(0,0,0);"><br></span></span>&nbsp;&nbsp;이 경우 일부 API의 파라미터에 MID가 사용되는데, MID 값에 일부 특수문자(예&gt; &amp;, =) 가 들어가게 되면 정상동작 하지 않을 수 있습니다.<span style="color: rgb(255,0,0);"><span style="color: rgb(0,0,0);"><br></span></span>&nbsp; 암호화된 결과에 특수문자가 들어가지 않는 암호화 알고리즘으로 단방향 암호화 해주세요. (예&gt; SHA-256, MD5 등)</p>
                    <p>* MID 단방향 암호화 예 :&nbsp;88020d58ceb8a4ad7f6756021fbc06b8a91f17daad2f04a5d369608d9c308bde (SHA-256으로 암호화),&nbsp;4509bf9373ff3dca193ba02726c87988 (MD5로 암호화)</p>
                    <div class="panel conf-macro output-block" style="background-color: #ffffff;border-color: #007eff;border-style: solid;border-width: 1px;" data-hasbody="true" data-macro-name="panel">
                        <div class="panelHeader" style="border-bottom-width: 1px;border-bottom-style: solid;border-bottom-color: #007eff;background-color: #007eff;color: #ffffff;"><b>사용자 구분을 위한 로그수집 스크립트</b></div>
                        <div class="panelContent" style="background-color: #ffffff;">
                            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                                <div class="codeContent panelContent pdl">
                                    <div>
                                        <div id="highlighter_961531" class="syntaxhighlighter sh-midnight nogutter  vb">
                                            <div class="toolbar"><span><a href="/pages/viewpage.action?pageId=491543#" class="toolbar_item command_help help">?</a></span></div>
                                            <table border="0" cellpadding="0" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="code">
                                                        <div class="container" title="Hint: double-click to select code">
                                                            <div class="line number1 index0 alt2"><code class="vb plain">recoPick(</code><code class="vb comments">'setMID',암호화된 MEMBER_ID); </code></div>
                                                            <div class="line number2 index1 alt1"><code class="vb plain">recoPick(</code><code class="vb comments">'setUserInfo',{ birthyear:‘출생연도’, gender:'성별’});</code></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p>&lt;예시&gt;</p>
                    <p>* 출생년도, 성별 정보 (M: 남자, F: 여자)</p>
                    <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                        <div class="codeContent panelContent pdl">
                            <div>
                                <div id="highlighter_584416" class="syntaxhighlighter sh-confluence nogutter  js">
                                    <div class="toolbar"><span><a href="/pages/viewpage.action?pageId=491543#" class="toolbar_item command_help help">?</a></span></div>
                                    <table border="0" cellpadding="0" cellspacing="0">
                                        <tbody>
                                        <tr>
                                            <td class="code">
                                                <div class="container" title="Hint: double-click to select code">
                                                    <div class="line number1 index0 alt2"><code class="js plain">recoPick(</code><code class="js string">'setUserInfo'</code><code class="js plain">,{ birthyear: ‘1987’, gender: 'M’});</code></div>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p></p>
                </li>
            </ol>
            <ul>
                <li>
                    <p><strong>Device&nbsp;Identification API(<span style="color: rgb(255,0,0);">*Hybrid(Native) App 사용시 필수 적용</span>)</strong></p>
                    <ul>
                        <li><span style="color: rgb(0,0,0);">본 API는 플랫폼에서 제공하는 Device의 고유 식별 값을 지정하기 위한 API입니다.</span></li>
                        <li><span style="color: rgb(0,0,0);">RecoPick의 UID를 통한 사용자 별 구분은 한 사용자가 여러 페이지를 방문했을 때, 이 사용자가 방문한 페이지들을 추적할 수 있으며 이를 기반으로 고품질의 추천을 계산할 수 있게 합니다.</span></li>
                        <li>Mobile 환경에서 제공하는 고객사 사이트의 형태가 Hybrid App 이거나 Native App Mobile 일 경우 recopick_uid가 아닌 단말 플랫폼에서 제공하는 고유식별 값을 ID로 사용하여야 합니다.</li>
                        <li>Android에서는 GAID(Google Advertising Identifier) 또는 AAID(Android Advertising Identifier) 으로 제공되고, IOS는 IDFA(Identifier For Advertising) 또는 IFA 라는 이름으로 제공 합니다.</li>
                        <li><span style="color: rgb(0,0,0);">단말 플랫폼으로부터 GAID와 IDFA를 획득하는 경우에 대해서는 setUID에 GAID혹은&nbsp; IDFA값을 넣어야 하고, 추가로 아래와 같이 setGAID, setIDFA도 같이 실행해 해주셔야 합니다.</span></li>
                        <li><span style="color: rgb(0,0,0);">행동 로그 수집 시 GAID/IDFA를 사용하셨다면 추천 API 호출 시에도 동일한 GAID/IDFA를 이용하여 추천 API를 호출하셔야 합니다.</span></li>
                        <li><span style="color: rgb(0,0,0);">적용 가이드</span>
                            <ul>
                                <li><span style="color: rgb(0,0,0);"><a href="/display/docs/Client+Library+APIs#ClientLibraryAPIs-UserIdentificationAPI">Client Library APIs</a></span></li>
                            </ul>
                        </li>
                        <li><span style="color: rgb(0,0,0);">Hybrid(Native) App에서 ADID 획득 하는 방법</span>
                            <ul>
                                <li><span style="color: rgb(0,0,0);"><a href="/pages/viewpage.action?pageId=6553731">Hybrid app에서 ADID(gaid,idfa)획득방법</a></span></li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
            <h4 id="id-독립형쇼핑몰인경우(자체제작쇼핑몰)-6.기타페이지에삽입하기"><strong>6. 메인페이지에 삽입하기</strong></h4>
            <ol>
              <li>
                <p><span style="color: rgb(0,0,0);">메인 페이지</span>에 들어가셔서 아래 스크립트를 삽입해 주세요.</p>
                <div class="panel conf-macro output-block" style="background-color: #ffffff;border-color: #007eff;border-style: solid;border-width: 1px;" data-hasbody="true" data-macro-name="panel">
                  <div class="panelHeader" style="border-bottom-width: 1px;border-bottom-style: solid;border-bottom-color: #007eff;background-color: #007eff;color: #ffffff;"><b>로그수집 스크립트 (sendLog_view)</b></div>
                  <div class="panelContent" style="background-color: #ffffff;">
                    <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                      <div class="codeContent panelContent pdl">
                        <div>
                          <div class="syntaxhighlighter sh-midnight nogutter  coldfusion">
                            <div class="toolbar"><span><a href="/pages/viewpage.action?pageId=491543#" class="toolbar_item command_help help">?</a></span></div>
                            <table border="0" cellpadding="0" cellspacing="0">
                              <tbody>
                              <tr>
                                <td class="code">
                                  <div class="container" title="Hint: double-click to select code">
                                    <div class="line number1 index0 alt2"><code class="coldfusion comments">&lt;!--RecoPick 로그수집 스크립트 --&gt;</code></div>
                                    <div class="line number2 index1 alt1"><code class="coldfusion plain">&lt;script type=</code><code class="coldfusion string">"text/javascript"</code><code class="coldfusion plain">&gt;</code></div>
                                    <div class="line number3 index2 alt2"><code class="coldfusion spaces">&nbsp;&nbsp;</code><code class="coldfusion plain">(function(w,d,n,s,e,o) {</code></div>
                                    <div class="line number4 index3 alt1"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">w[n]=w[n]||function(){(w[n].q=w[n].q||[]).push(arguments)};</code></div>
                                    <div class="line number5 index4 alt2"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">e=d.createElement(s);e.async=1;e.charset=</code><code class="coldfusion string">'utf-8'</code><code class="coldfusion plain">;e.src=</code><code class="coldfusion string">'//static.recopick.com/dist/production.min.js'</code><code class="coldfusion plain">;</code></div>
                                    <div class="line number6 index5 alt1"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">o=d.getElementsByTagName(s)[0];o.parentNode.insertBefore(e,o);</code></div>
                                    <div class="line number7 index6 alt2"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">})(window, document, </code><code class="coldfusion string">'recoPick'</code><code class="coldfusion plain">, </code><code class="coldfusion string">'script'</code><code class="coldfusion plain">);</code></div>
                                    <div class="line number8 index7 alt1"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">recoPick(</code><code class="coldfusion string">'service'</code><code class="coldfusion plain">, </code><code class="coldfusion string">'사이트 url'</code><code class="coldfusion plain">);</code></div>
                                    <div class="line number9 index8 alt2"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">recoPick(</code><code class="coldfusion string">'sendLog'</code><code class="coldfusion plain">, </code><code class="coldfusion string">'visit'</code><code class="coldfusion plain">);&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </code></div>
                                    <div class="line number10 index9 alt1"><code class="coldfusion plain">&lt;/script&gt;</code></div>
                                    <div class="line number11 index10 alt2"><code class="coldfusion comments">&lt;!--RecoPick 로그수집 스크립트 --&gt;</code></div>
                                  </div>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ol>
            <h4 id="id-독립형쇼핑몰인경우(자체제작쇼핑몰)-7.Like삽입하기"><strong>7. 사용자가 특정 상품에 대해 "좋아요", "즐겨찾기", "찜"등의 행동을 한 경우</strong></h4>
            <ol>
              <li>
                <p>
                  <span style="color: rgb(0,0,0);">사용자가 특정 상품에 대해 "좋아요", "즐겨찾기", "찜"등의 행동을 한 경우</span> 아래 스크립트를 삽입해 주세요.&nbsp;<br><span style="color: rgb(255,102,0);"><strong>상품id, 카테고리</strong></span>는 실제 사용하시는 변수로 수정해 주셔야 합니다.
                  <br>
                  <img class="emoticon emoticon-warning" src="@/assets/images/warning.svg" data-emoticon-name="warning" alt="(warning)"><span style="color: rgb(255,0,0);">&nbsp;주의하세요!&nbsp;</span>상품id는 필수 값입니다.
                </p>
                <div class="panel conf-macro output-block" style="background-color: #ffffff;border-color: #007eff;border-style: solid;border-width: 1px;" data-hasbody="true" data-macro-name="panel">
                  <div class="panelHeader" style="border-bottom-width: 1px;border-bottom-style: solid;border-bottom-color: #007eff;background-color: #007eff;color: #ffffff;"><b>로그수집 스크립트 (sendLog_like)</b></div>
                  <div class="panelContent" style="background-color: #ffffff;">
                    <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                      <div class="codeContent panelContent pdl">
                        <div>
                          <div class="syntaxhighlighter sh-midnight nogutter  coldfusion">
                            <div class="toolbar"><span><a href="/pages/viewpage.action?pageId=491543#" class="toolbar_item command_help help">?</a></span></div>
                            <table border="0" cellpadding="0" cellspacing="0">
                              <tbody>
                              <tr>
                                <td class="code">
                                  <div class="container" title="Hint: double-click to select code">
                                    <div class="line number1 index0 alt2"><code class="coldfusion comments">&lt;!--RecoPick 로그수집 스크립트 --&gt;</code></div>
                                    <div class="line number2 index1 alt1"><code class="coldfusion plain">&lt;script type=</code><code class="coldfusion string">"text/javascript"</code><code class="coldfusion plain">&gt;</code></div>
                                    <div class="line number3 index2 alt2"><code class="coldfusion spaces">&nbsp;&nbsp;</code><code class="coldfusion plain">(function(w,d,n,s,e,o) {</code></div>
                                    <div class="line number4 index3 alt1"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">w[n]=w[n]||function(){(w[n].q=w[n].q||[]).push(arguments)};</code></div>
                                    <div class="line number5 index4 alt2"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">e=d.createElement(s);e.async=1;e.charset=</code><code class="coldfusion string">'utf-8'</code><code class="coldfusion plain">;e.src=</code><code class="coldfusion string">'//static.recopick.com/dist/production.min.js'</code><code class="coldfusion plain">;</code></div>
                                    <div class="line number6 index5 alt1"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">o=d.getElementsByTagName(s)[0];o.parentNode.insertBefore(e,o);</code></div>
                                    <div class="line number7 index6 alt2"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">})(window, document, </code><code class="coldfusion string">'recoPick'</code><code class="coldfusion plain">, </code><code class="coldfusion string">'script'</code><code class="coldfusion plain">);</code></div>
                                    <div class="line number8 index7 alt1"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">recoPick(</code><code class="coldfusion string">'service'</code><code class="coldfusion plain">, </code><code class="coldfusion string">'사이트 url'</code><code class="coldfusion plain">);</code></div>
                                    <div class="line number9 index8 alt2"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">recoPick(</code><code class="coldfusion string">'sendLog'</code><code class="coldfusion plain">,</code><code class="coldfusion string">'like'</code><code class="coldfusion plain">, {id:</code><code class="coldfusion string">'상품id'</code><code class="coldfusion plain">, c1:</code><code class="coldfusion string">'카테고리(대)'</code><code class="coldfusion plain">, c2:</code><code class="coldfusion string">'카테고리(중)'</code><code class="coldfusion plain">, c3:</code><code class="coldfusion string">'카테고리(소)'</code><code class="coldfusion plain">});&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </code></div>
                                    <div class="line number10 index9 alt1"><code class="coldfusion plain">&lt;/script&gt;</code></div>
                                    <div class="line number11 index10 alt2"><code class="coldfusion comments">&lt;!--RecoPick 로그수집 스크립트 --&gt;</code></div>
                                  </div>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ol>
            <pre><br><br><hr><br></pre>
            <ul>
                <li>
                    <h2 id="id-독립형쇼핑몰인경우(자체제작쇼핑몰)-적용후검증방법"><strong>적용 후 검증 방법</strong></h2>
                    <ul>
                        <li>
                            <p>대시보드 접속 후&nbsp;</p>
                        </li>
                        <ul>
                          <li>
                            아래와 같이 유형별(Visit, View, Order... 등) 행동 로그가 유입되는지 확인 가능
                            <br>
                            <span class="confluence-embedded-file-wrapper">
                            <img class="confluence-embedded-image"
                                 src="@/assets/images/action.png">
                            </span>
                          </li>
                          <li>로그수집 현황에서 실시간 로그 수집현황을 보시면 로그가 정상적으로 유입되는지 확인 가능.</li>
                          <li>
                            레코픽&nbsp; script 적용 후 발급되는 recopick_uid(recopick cookies) 를 입력하여 실시간 로그 수집 현황 확인 가능
                            <br>
                            <span class="confluence-embedded-file-wrapper">
                            <img class="confluence-embedded-image"
                                 src="@/assets/images/realtime_log.png">
                            </span>
                          </li>
                        </ul>
                    </ul>
                </li>
            </ul>
            <h1 id="id-독립형쇼핑몰인경우(자체제작쇼핑몰)-&lt;2단계&gt;상품메타태그삽입"><strong style="font-size: 24.0px;line-height: 1.25;">&lt;2단계&gt; 상품&nbsp;메타 태그 삽입</strong></h1>
            <p>1. 상품 메타 태그는 스크립트 관리를 위해 상품상세페이지의 &lt;head&gt; … &lt;/head&gt; 태그 사이&nbsp;레코픽 로그수집 스크립트 바로 뒤에 삽입하길 권장합니다. 만약 헤더파일이 없는 경우 html 최상단에 삽입해 주세요!</p>
            <p><img class="emoticon emoticon-warning" src="@/assets/images/warning.svg" data-emoticon-name="warning" alt="(warning)"><span style="color: rgb(255,0,0);">&nbsp;주의하세요!&nbsp;&nbsp;</span><span style="color: rgb(0,0,0);">상품상세페이지에서 상품메타태그는 sendLog보다 앞에 위치해야 합니다. 그래야 사용자행동로그와 함께 상품메타정보도 같이 레코픽으로 전송됩니다.</span></p>
            <ul>
                <li>
                    <div class="panel conf-macro output-block" style="background-color: #ffffff;border-color: #007eff;border-style: solid;border-width: 1px;" data-hasbody="true" data-macro-name="panel">
                        <div class="panelHeader" style="border-bottom-width: 1px;border-bottom-style: solid;border-bottom-color: #007eff;background-color: #007eff;color: #ffffff;"><b>기본 메타 태그</b></div>
                        <div class="panelContent" style="background-color: #ffffff;">
                            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                                <div class="codeContent panelContent pdl">
                                    <div>
                                        <div id="highlighter_570915" class="syntaxhighlighter sh-eclipse nogutter  actionscript3">
                                            <div class="toolbar"><span><a href="/pages/viewpage.action?pageId=491543#" class="toolbar_item command_help help">?</a></span></div>
                                            <table border="0" cellpadding="0" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="code">
                                                        <div class="container" title="Hint: double-click to select code">
                                                            <div class="line number1 index0 alt2"><code class="actionscript3 plain">&lt;meta property=</code><code class="actionscript3 string">"recopick:title"</code> <code class="actionscript3 plain">content=</code><code class="actionscript3 string">"상품 이름"</code><code class="actionscript3 plain">&gt;</code></div>
                                                            <div class="line number2 index1 alt1"><code class="actionscript3 plain">&lt;meta property=</code><code class="actionscript3 string">"recopick:image"</code> <code class="actionscript3 plain">content=</code><code class="actionscript3 string">"상품 이미지 url"</code><code class="actionscript3 plain">&gt;</code></div>
                                                            <div class="line number3 index2 alt2"><code class="actionscript3 plain">&lt;meta property=</code><code class="actionscript3 string">"recopick:price"</code> <code class="actionscript3 plain">content=</code><code class="actionscript3 string">"상품가격"</code><code class="actionscript3 plain">&gt;</code></div>
                                                            <div class="line number4 index3 alt1"><code class="actionscript3 plain">&lt;meta property=</code><code class="actionscript3 string">"recopick:price:currency"</code> <code class="actionscript3 plain">content=</code><code class="actionscript3 string">"가격 통화단위"</code><code class="actionscript3 plain">&gt;</code></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            <p><span style="line-height: 1.4285715;">&nbsp;</span></p>
            <p>2.&nbsp; 기본 메타 태그 삽입후 상황에 따라 아래 태그를 추가해주세요!</p>
            <ul>
                <li>
                    <p>현재 가격, 할인 가격을 둘 다 표시할 수 있습니다.</p>
                    <div class="panel conf-macro output-block" style="background-color: #ffffff;border-color: #007eff;border-style: solid;border-width: 1px;" data-hasbody="true" data-macro-name="panel">
                        <div class="panelHeader" style="border-bottom-width: 1px;border-bottom-style: solid;border-bottom-color: #007eff;background-color: #007eff;color: #ffffff;"><b>할인 가격이 있을 경우</b></div>
                        <div class="panelContent" style="background-color: #ffffff;">
                            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                                <div class="codeContent panelContent pdl">
                                    <div>
                                        <div id="highlighter_321989" class="syntaxhighlighter sh-eclipse nogutter  actionscript3">
                                            <div class="toolbar"><span><a href="/pages/viewpage.action?pageId=491543#" class="toolbar_item command_help help">?</a></span></div>
                                            <table border="0" cellpadding="0" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="code">
                                                        <div class="container" title="Hint: double-click to select code">
                                                            <div class="line number1 index0 alt2"><code class="actionscript3 plain">&lt;meta property=</code><code class="actionscript3 string">"recopick:sale_price"</code> <code class="actionscript3 plain">content=</code><code class="actionscript3 string">"할인가격"</code><code class="actionscript3 plain">&gt;</code></div>
                                                            <div class="line number2 index1 alt1"><code class="actionscript3 plain">&lt;meta property=</code><code class="actionscript3 string">"recopick:sale_price:currency"</code> <code class="actionscript3 plain">content=</code><code class="actionscript3 string">"할인가격 통화단위"</code><code class="actionscript3 plain">&gt;</code></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            <ul>
                <li>
                    <p>상품 품절 시 지정합니다.</p>
                    <div class="panel conf-macro output-block" style="background-color: #ffffff;border-color: #007eff;border-style: solid;border-width: 1px;" data-hasbody="true" data-macro-name="panel">
                        <div class="panelHeader" style="border-bottom-width: 1px;border-bottom-style: solid;border-bottom-color: #007eff;background-color: #007eff;color: #ffffff;"><b>상품이 품절 상태인 경우</b></div>
                        <div class="panelContent" style="background-color: #ffffff;">
                            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                                <div class="codeContent panelContent pdl">
                                    <div>
                                        <div id="highlighter_877844" class="syntaxhighlighter sh-eclipse nogutter  actionscript3">
                                            <div class="toolbar"><span><a href="/pages/viewpage.action?pageId=491543#" class="toolbar_item command_help help">?</a></span></div>
                                            <table border="0" cellpadding="0" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="code">
                                                        <div class="container" title="Hint: double-click to select code">
                                                            <div class="line number1 index0 alt2"><code class="actionscript3 plain">&lt;!--/</code><code class="actionscript3 keyword">if</code> <code class="actionscript3 plain">soldout/--&gt;</code></div>
                                                            <div class="line number2 index1 alt1"><code class="actionscript3 plain">&lt;meta property=</code><code class="actionscript3 string">"recopick:availability"</code> <code class="actionscript3 plain">content=</code><code class="actionscript3 string">"oos"</code><code class="actionscript3 plain">&gt;</code></div>
                                                            <div class="line number3 index2 alt2"><code class="actionscript3 plain">&lt;!--/end </code><code class="actionscript3 keyword">if</code><code class="actionscript3 plain">/--&gt;</code></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p><span style="line-height: 1.42857;">&nbsp;</span>&nbsp;</p>
                </li>
            </ul>
            <p>3. Content 블루텍스트 부분은 실제 사용하는 변수로 수정해 주셔야 하며 반드시 큰따옴표(double-quotes)로 묶여야 합니다.</p>
            <p><br></p>
            <pre style="text-align: left;"><strong>&lt; 메타 태그 설명 &gt;</strong></pre>
            <div class="table-wrap">
                <table class="wrapped confluenceTable tablesorter tablesorter-default" style="line-height: 1.42857;" role="grid">
                    <colgroup>
                        <col>
                        <col>
                    </colgroup>
                    <thead>
                    <tr role="row" class="tablesorter-headerRow">
                        <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="Property: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                            <div class="tablesorter-header-inner">Property</div>
                        </th>
                        <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="1" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="Content: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                            <div class="tablesorter-header-inner">Content</div>
                        </th>
                    </tr>
                    </thead>
                    <tbody aria-live="polite" aria-relevant="all">
                    <tr role="row">
                        <td class="confluenceTd">
                            <p><strong><span style="color: rgb(0,0,0);">recopick:title</span></strong> (상품명)</p>
                            <p>* 필수 메타 입니다.</p>
                        </td>
                        <td class="confluenceTd">
                            <p><span style="color: rgb(51,102,255);"><strong>상품 이름</strong></span></p>&lt;예시&gt; “ 클래식 체크 스커트”
                        </td>
                    </tr>
                    <tr role="row">
                        <td class="confluenceTd">
                            <p><strong>recopick:image</strong> (상품이미지)</p>
                            <p><span>* 필수 메타 입니다.</span></p>
                        </td>
                        <td class="confluenceTd">
                            <p><span style="color: rgb(51,102,255);"><strong>상품 이미지 URL</strong>&nbsp;</span></p>
                            <p>&lt;예시&gt; <a href="http://newmedia.thehandsome.com/MN/1I/SS/MN1I5ASZ581W_WT_T01.jpg" class="external-link">http://www.recopick.com/abc/def/abc001.jpg</a></p>
                            <p><span style="color: rgb(0,0,0);">※ 팁 : 상품 이미지의 가로/세로 비율을 동일하게 해주셔야, 추천 상품 영역의 룩앤필이 좋아집니다.</span></p>
                            <p>※ 레코픽은 상품이미지 파일을 저장하지 않고, URL 정보만 저장합니다.</p>
                        </td>
                    </tr>
                    <tr role="row">
                        <td class="confluenceTd"><strong>recopick:price</strong> (정상가격) <br><span> * 필수 메타 입니다.</span></td>
                        <td class="confluenceTd">
                            <p><span style="color: rgb(51,102,255);"><strong>상품 가격</strong></span></p>
                            <p>&lt;예시&gt; "55800"</p>
                        </td>
                    </tr>
                    <tr role="row">
                        <td class="confluenceTd">
                            <p><strong>recopick:price:currency</strong> (정상가격 표시)</p>
                            <p><span>* 필수 메타 입니다.</span></p>
                        </td>
                        <td class="confluenceTd">
                            <p><span style="color: rgb(51,102,255);"><strong>통화 단위</strong></span></p>
                            <p>&lt;예시&gt; 원화인 경우 “KRW,” 달러화인 경우 “USD”, 엔화인 경우 “JPY”</p>
                        </td>
                    </tr>
                    <tr role="row">
                        <td class="confluenceTd"><strong>recopick:sale_price</strong> (할인가격)</td>
                        <td class="confluenceTd"><span style="color: rgb(51,102,255);"><span style="color: rgb(51,102,255);">&nbsp;<strong style="line-height: 1.42857;">할인 가격</strong></span></span><span style="color: rgb(51,102,255);"><span style="color: rgb(51,102,255);">&nbsp;</span></span>
                            <p>&lt;예시&gt; "54600"</p>
                        </td>
                    </tr>
                    <tr role="row">
                        <td class="confluenceTd"><strong>recopick:sale_price:currency</strong> (할인가격 표시)</td>
                        <td class="confluenceTd">
                            <p><span style="color: rgb(51,102,255);"><strong>할인</strong></span><span style="color: rgb(51,102,255);"><strong> 가격</strong></span><span style="color: rgb(51,102,255);"><strong> 통화 단위</strong></span></p>
                            <p>&lt;예시&gt; 원화인 경우 “KRW,” 달러화인 경우 “USD”, 엔화인 경우 “JPY”</p>
                        </td>
                    </tr>
                    <tr role="row">
                        <td class="confluenceTd">
                            <p><strong>recopick:availability</strong> (상품 품절 표시 : 추천 상품 제외)</p>
                            <p>* 상품 품절인 경우에만 사용될 수 있도록 상품 품절여부를 <span style="line-height: 1.42857;">체크하는 if 조건문 내에 위치시켜 주세요.</span></p>
                        </td>
                        <td class="confluenceTd">
                            <p><strong>OOS</strong> (out of service 약자)</p>
                            <p>※ 추천 상품에서 제외됩니다.</p>
                        </td>
                    </tr>
                    <tr role="row">
                        <td colspan="1" class="confluenceTd"><strong>recopick:brand</strong><span> (브랜드)</span></td>
                        <td colspan="1" class="confluenceTd"><span style="color: rgb(51,102,255);"><span style="color: rgb(51,102,255);"><strong>브랜드명 </strong></span></span><span style="color: rgb(0,0,0);">&lt;예시&gt; “ Nike”</span><span style="color: rgb(51,102,255);">&nbsp;</span></td>
                    </tr>
                    <tr role="row">
                        <td colspan="1" class="confluenceTd"><strong>recopick:author</strong><span> (판매자 or 제조사)</span></td>
                        <td colspan="1" class="confluenceTd">
                            <p><span style="color: rgb(51,102,255);"><strong style="color: rgb(51,102,255);">판매자 or 제조사 정보</strong><span style="color: rgb(51,102,255);">&nbsp;</span><span style="color: rgb(0,0,0);">&lt;예시&gt; "Ode"</span></span></p>
                        </td>
                    </tr>
                    <tr role="row">
                        <td colspan="1" class="confluenceTd"><strong>recopick:description</strong><span> (상품설명)</span></td>
                        <td colspan="1" class="confluenceTd"><span style="color: rgb(51,102,255);"><strong>상품에 대한 설명</strong></span>
                            <p><span>※ </span>상품에 대한 간단한 설명을 넣어주세요.</p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <p><br></p>
            <ul>
                <li>
                    <h2 id="id-독립형쇼핑몰인경우(자체제작쇼핑몰)-적용후검증방법.1"><strong>적용 후 검증 방법</strong></h2>
                    <ul>
                        <li>
                            <p>대시보드 접속 후&nbsp;</p>
                        </li>
                        <li>
                          많이 본 상품 Top 100등의 메뉴에 들어갔을 때 상품별로 상품 상세정보(title, 금액, 이미지 등..)이 정상적인지 확인
                          <br>
                          <span class="confluence-embedded-file-wrapper">
                            <img class="confluence-embedded-image"
                               src="@/assets/images/metaImage_01.png">
                          </span>
                        </li>
                        <li>
                          우측 상단에 검색할 상품 ID를 직접 입력하여 해당 상품검색과 상품 메타 정보가 정상적인지 확인&nbsp;
                          <br>
                          <span class="confluence-embedded-file-wrapper">
                            <img class="confluence-embedded-image"
                                 src="@/assets/images/metaImage_02.png">
                          </span>
                        </li>
                    </ul>
                </li>
            </ul>
            <p><br></p>
            <h1 id="id-독립형쇼핑몰인경우(자체제작쇼핑몰)-&lt;3단계&gt;추천상품노출"><strong>&lt;3단계&gt; 추천 상품 노출</strong></h1>
            <p>추천 상품을 노출 하기 위하여 <strong>추천 위젯</strong>과 <strong>추천 API</strong> 2가지 방식을 이용하여 선택적으로 상품 노출이 가능합니다.&nbsp;</p>
            <p>추천위젯의 경우 적용기간이 빠르긴 하지만 저희가 제공해 드리는 몇 개 template 내에서 선택하여 사용해야 하므로 고객사 사이트의 UX 에 맞지 않을 수 있습니다.&nbsp;</p>
            <p>이경우 API방식을 통하여 추천데이터만 받으셔서 자체적으로 화면을 디자인 하셔서 노출 하실 수 있습니다.</p>
            <h1 id="id-독립형쇼핑몰인경우(자체제작쇼핑몰)-&lt;3-1&gt;추천위젯이용방식"><strong>&lt;3-1&gt; 추천 위젯 이용 방식</strong></h1>
            <p><span style="line-height: 1.42857;">&nbsp;</span></p>
            <p><strong>&nbsp;<a style="text-decoration: underline;" href="http://admin.recopick.com/" class="external-link">Admin Dashboard</a></strong>&nbsp;&gt;&nbsp;<strong>추천 위젯 설정</strong>에 들어가셔서 아래 가이드에 따라 추천위젯을 설정합니다.</p>
            <p><span style="line-height: 1.42857;">&nbsp;</span><strong style="line-height: 1.42857;">상품상세, 메인, 장바구니, 구매완료, 마이페이지</strong>&nbsp;등 추가로 생성하신 후 생성된 스크립트를 고객님 쇼핑몰 페이지별로 적용해 주세요.</p>
            <p><br></p>
            <p><span style="color: rgb(255,0,0);line-height: 1.42857;"><span style="color: rgb(0,0,0);">*</span>추천 위젯을 삽입후 사이트에서 추천결과를 바로 확인하실 수 없습니다.</span></p>
            <p><span style="line-height: 1.42857;">추천계산을 위해 데이터 수집 후, 2~3일이 지나서 추천결과를 확인하실 수 있는데 추천결과 반영은 메일을 통해 안내해 드립니다. 안내에 따라 노출을 원하시는 시점에 추천타입을 설정한 후 저장하시면 사이트에서 반영하실 수 있습니다.</span></p>
            <p><br></p>
            <p><strong style="line-height: 1.42857;">1.&nbsp;&nbsp;&nbsp;&nbsp; </strong><strong style="line-height: 1.42857;">상품 상세 페이지 <span style="color: rgb(255,102,0);">상단</span> (함께 본 상품 추천)</strong></p>
            <ol>
                <li><span style="line-height: 1.42857;">위젯 관리가 용의하도록 위젯명을 </span><strong style="line-height: 1.42857;">상품상세_상단</strong><span style="line-height: 1.42857;">으로 입력합니다.</span></li>
                <li><span style="line-height: 1.42857;">채널별 성과분석시(추천클릭률, 추천경유매출 등) 비교가 용이하도록&nbsp;<strong>detail_top</strong>으로 입력합니다.&nbsp;</span></li>
                <li>추천타입은 우선 사용안함으로 설정합니다. (데이터 수집후, 2~3일이 지나서 설정합니다.)</li>
                <li>
                    <p>그 외 원하시는 대로 위젯 스타일을 변경하실 수 있으며 할인가 표시를 원하시면 표시여부를 체크해 주세요.&nbsp;&nbsp;</p>
                    <div class="table-wrap">
                        <table class="wrapped confluenceTable tablesorter tablesorter-default" role="grid">
                            <colgroup>
                                <col>
                            </colgroup>
                            <thead>
                            <tr role="row" class="tablesorter-headerRow">
                                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;위젯설정 예시&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                    <div class="tablesorter-header-inner">&lt;위젯설정 예시&gt;</div>
                                </th>
                            </tr>
                            </thead>
                            <tbody aria-live="polite" aria-relevant="all">
                            <tr role="row">
                                <td class="confluenceTd">
                                    <div class="content-wrapper"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/widget_001.png" data-image-src="/download/attachments/491543/widget_001.png?version=1&amp;modificationDate=1461817382000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127617" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="widget_001.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="491543" data-linked-resource-container-version="264"></span></div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <p><span style="line-height: 1.42857;"><br></span></p>
                </li>
                <li>
                    <p>모든 설정을 마친 후에는 꼭 <strong>저장</strong> 버튼을 클릭하시고 아래 위젯 적용 부분의 스크립트를 복사해서 고객님 사이트의 <strong>상품 상세 페이지 상단</strong>에 삽입해 주세요. &lt;삽입 위치 참고&gt;</p>
                    <div class="table-wrap">
                        <table class="wrapped confluenceTable tablesorter tablesorter-default" role="grid">
                            <colgroup>
                                <col>
                                <col>
                            </colgroup>
                            <thead>
                            <tr role="row" class="tablesorter-headerRow">
                                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;위젯 적용&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                    <div class="tablesorter-header-inner">&lt;위젯 적용&gt;</div>
                                </th>
                                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="1" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;삽입 위치&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                    <div class="tablesorter-header-inner">&lt;삽입 위치&gt;</div>
                                </th>
                            </tr>
                            </thead>
                            <tbody aria-live="polite" aria-relevant="all">
                            <tr role="row">
                                <td class="confluenceTd">
                                    <div class="content-wrapper"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/widget_02.png" data-image-src="/download/attachments/491543/widget_02.png?version=2&amp;modificationDate=1458716445000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="5472259" data-linked-resource-version="2" data-linked-resource-type="attachment" data-linked-resource-default-alias="widget_02.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="491543" data-linked-resource-container-version="264"></span></div>
                                </td>
                                <td class="confluenceTd">
                                    <div class="content-wrapper"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/widget_003.png" data-image-src="/download/attachments/491543/widget_003.png?version=2&amp;modificationDate=1505118684000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127619" data-linked-resource-version="2" data-linked-resource-type="attachment" data-linked-resource-default-alias="widget_003.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="491543" data-linked-resource-container-version="264"></span></div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </li>
            </ol>
            <p><strong><br></strong></p>
            <p><strong>2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong><strong>상품 상세 페이지 <span style="color: rgb(255,102,0);">하</span><span style="color: rgb(255,102,0);">단</span>&nbsp;(함께 구매한 상품&nbsp;<strong>추천</strong>)</strong></p>
            <ol>
                <li>
                    <p class="p1"><span class="s1">새 스타일 추가 버튼을 클릭해서 새로운 탭이 생성되면 위젯명을 <strong>상품상세_하단</strong>으로 입력합니다.</span></p>
                </li>
                <li>
                    <p class="p1"><span class="s1">채널 별 성과 분석 시(추천클릭률, 추천경유매출 등) 비교가 용이하도록 <strong>detail_bottom</strong>으로 입력합니다.&nbsp;</span></p>
                </li>
                <li>
                    <p class="p1"><span style="line-height: 1.42857;">추천타입은 우선 사용안함으로 설정합니다. (데이터 수집후, 2~3일이 지나서 설정합니다.)</span></p>
                </li>
                <li>
                    <p>그 외 원하시는 대로 위젯 스타일을 변경하실 수 있으며 할인가 표시를 원하시면 표시여부를 체크해 주세요.&nbsp;</p>
                    <div class="table-wrap">
                        <table class="wrapped confluenceTable tablesorter tablesorter-default" role="grid">
                            <colgroup>
                                <col>
                            </colgroup>
                            <thead>
                            <tr role="row" class="tablesorter-headerRow">
                                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;위젯설정 예시&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                    <div class="tablesorter-header-inner">&lt;위젯설정 예시&gt;</div>
                                </th>
                            </tr>
                            </thead>
                            <tbody aria-live="polite" aria-relevant="all">
                            <tr role="row">
                                <td class="confluenceTd">
                                    <div class="content-wrapper"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/widget_002.png" data-image-src="/download/attachments/491543/widget_002.png?version=1&amp;modificationDate=1461817694000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127618" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="widget_002.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="491543" data-linked-resource-container-version="264"></span></div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <p><span><br></span></p>
                </li>
                <li>
                    <p>모든 설정을 마친 후에는 꼭&nbsp;<strong>저장</strong>&nbsp;버튼을 클릭하시고 아래 위젯 적용 부분의 스크립트를 복사해서 고객님 사이트의&nbsp;<strong>상품 상세 페이지 하단</strong>에 삽입해 주세요. &lt;삽입 위치 참고&gt;</p>
                    <div class="table-wrap">
                        <table class="wrapped confluenceTable tablesorter tablesorter-default" role="grid">
                            <colgroup>
                                <col>
                                <col>
                            </colgroup>
                            <thead>
                            <tr role="row" class="tablesorter-headerRow">
                                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;위젯 적용&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                    <div class="tablesorter-header-inner">&lt;위젯 적용&gt;</div>
                                </th>
                                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="1" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;삽입 위치&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                    <div class="tablesorter-header-inner">&lt;삽입 위치&gt;</div>
                                </th>
                            </tr>
                            </thead>
                            <tbody aria-live="polite" aria-relevant="all">
                            <tr role="row">
                                <td class="confluenceTd">
                                    <div class="content-wrapper"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/widget_02.png" data-image-src="/download/attachments/491543/widget_02.png?version=2&amp;modificationDate=1458716445000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="5472259" data-linked-resource-version="2" data-linked-resource-type="attachment" data-linked-resource-default-alias="widget_02.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="491543" data-linked-resource-container-version="264"></span></div>
                                </td>
                                <td class="confluenceTd">
                                    <div class="content-wrapper"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/widget_006.png" data-image-src="/download/attachments/491543/widget_006.png?version=2&amp;modificationDate=1505118730000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127622" data-linked-resource-version="2" data-linked-resource-type="attachment" data-linked-resource-default-alias="widget_006.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="491543" data-linked-resource-container-version="264"></span></div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </li>
            </ol>
            <p><strong><br></strong></p>
            <p><strong>3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong><strong>메인 페이지&nbsp;&nbsp;(실시간 개인화 추천)</strong></p>
            <ol>
                <li>
                    <p class="p1"><span class="s1">새 스타일 추가 버튼을 클릭해서 새로운 탭이 생성되면 위젯명을 <strong>메인</strong>으로 입력합니다.</span></p>
                </li>
                <li>
                    <p class="p1"><span class="s1">채널 별 성과분석시(추천클릭률, 추천경유매출 등) 비교가 용이하도록 <strong>main</strong>으로 입력합니다.&nbsp;</span></p>
                </li>
                <li>
                    <p class="p1">추천타입은 우선 사용안함으로 설정합니다. (데이터 수집후, 2~3일이 지나서 설정합니다.)</p>
                </li>
                <li>
                    <p>그 외 원하시는 대로 위젯 스타일을 변경하실 수 있으며 할인가 표시를 원하시면 표시여부를 체크해 주세요.&nbsp;</p>
                    <div class="table-wrap">
                        <table class="wrapped confluenceTable tablesorter tablesorter-default" role="grid">
                            <colgroup>
                                <col>
                            </colgroup>
                            <thead>
                            <tr role="row" class="tablesorter-headerRow">
                                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;위젯설정 예시&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                    <div class="tablesorter-header-inner">&lt;위젯설정 예시&gt;</div>
                                </th>
                            </tr>
                            </thead>
                            <tbody aria-live="polite" aria-relevant="all">
                            <tr role="row">
                                <td class="confluenceTd">
                                    <div class="content-wrapper"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/widget_0031.png" data-image-src="/download/attachments/491543/widget_0031.png?version=1&amp;modificationDate=1505120505000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127757" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="widget_0031.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="491543" data-linked-resource-container-version="264"></span></div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </li>
                <li>
                    <p>모든 설정을 마친 후에는 꼭&nbsp;<strong>저장</strong>&nbsp;버튼을 클릭하시고 아래 위젯 적용 부분의 스크립트를 복사해서 고객님 사이트의&nbsp;<strong>메인 페이지</strong>에 삽입해 주세요. &lt;삽입 위치 참고&gt;</p>
                    <div class="table-wrap">
                        <table class="wrapped confluenceTable tablesorter tablesorter-default" role="grid">
                            <thead>
                            <tr role="row" class="tablesorter-headerRow">
                                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;위젯 적용&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                    <div class="tablesorter-header-inner">&lt;위젯 적용&gt;</div>
                                </th>
                                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="1" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;삽입 위치&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                    <div class="tablesorter-header-inner">&lt;삽입 위치&gt;</div>
                                </th>
                            </tr>
                            </thead>
                            <tbody aria-live="polite" aria-relevant="all">
                            <tr role="row">
                                <td class="confluenceTd">
                                    <div class="content-wrapper">&nbsp;<span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/widget_02.png" data-image-src="/download/attachments/491543/widget_02.png?version=2&amp;modificationDate=1458716445000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="5472259" data-linked-resource-version="2" data-linked-resource-type="attachment" data-linked-resource-default-alias="widget_02.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="491543" data-linked-resource-container-version="264"></span></div>
                                </td>
                                <td class="confluenceTd">
                                    <div class="content-wrapper"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/widget_009.png" data-image-src="/download/attachments/491543/widget_009.png?version=2&amp;modificationDate=1505119479000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127748" data-linked-resource-version="2" data-linked-resource-type="attachment" data-linked-resource-default-alias="widget_009.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="491543" data-linked-resource-container-version="264"></span></div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </li>
            </ol>
            <p><strong><br></strong></p>
            <p><strong>4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong><strong>장바구니 페이지&nbsp;&nbsp;(함께 구매한 상품&nbsp;<strong>추천</strong>)</strong></p>
            <ol>
                <li>
                    <p class="p1"><span class="s1">새 스타일 추가 버튼을 클릭해서 새로운 탭이 생성되면 위젯명을 <strong>장바구니</strong>로 입력합니다.</span></p>
                </li>
                <li>
                    <p class="p1"><span class="s1">채널 별 성과분석시(추천클릭률, 추천경유매출 등) 비교가 용이하도록 <strong>basket</strong>으로 입력합니다.&nbsp;</span></p>
                </li>
                <li>
                    <p class="p1">추천타입은 우선 사용안함으로 설정합니다. (데이터 수집후, 2~3일이 지나서 설정합니다.)</p>
                </li>
                <li>
                    <p>그 외 원하시는 대로 위젯 스타일을 변경하실 수 있으며 할인가 표시를 원하시면 표시여부를 체크해 주세요.&nbsp;</p>
                    <div class="table-wrap">
                        <table class="wrapped confluenceTable tablesorter tablesorter-default" role="grid">
                            <colgroup>
                                <col>
                            </colgroup>
                            <thead>
                            <tr role="row" class="tablesorter-headerRow">
                                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;위젯설정 예시&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                    <div class="tablesorter-header-inner">&lt;위젯설정 예시&gt;</div>
                                </th>
                            </tr>
                            </thead>
                            <tbody aria-live="polite" aria-relevant="all">
                            <tr role="row">
                                <td class="confluenceTd">
                                    <div class="content-wrapper"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/widget_004.png" data-image-src="/download/attachments/491543/widget_004.png?version=1&amp;modificationDate=1461818275000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127620" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="widget_004.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="491543" data-linked-resource-container-version="264"></span></div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </li>
                <li>
                    <p>모든 설정을 마친 후에는 꼭&nbsp;<strong>저장</strong>&nbsp;버튼을 클릭하시고 아래 위젯 적용 부분의 스크립트를 복사해서 고객님 사이트의&nbsp;<strong>장바구니 페이지</strong>에 삽입해 주세요. &lt;삽입 위치 참고&gt;</p>
                    <div class="table-wrap">
                        <table class="wrapped confluenceTable tablesorter tablesorter-default" role="grid">
                            <colgroup>
                                <col>
                                <col>
                            </colgroup>
                            <thead>
                            <tr role="row" class="tablesorter-headerRow">
                                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;위젯 적용&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                    <div class="tablesorter-header-inner">&lt;위젯 적용&gt;</div>
                                </th>
                                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="1" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;삽입 위치&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                    <div class="tablesorter-header-inner">&lt;삽입 위치&gt;</div>
                                </th>
                            </tr>
                            </thead>
                            <tbody aria-live="polite" aria-relevant="all">
                            <tr role="row">
                                <td class="confluenceTd">
                                    <div class="content-wrapper">&nbsp;<span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/widget_02.png" data-image-src="/download/attachments/491543/widget_02.png?version=2&amp;modificationDate=1458716445000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="5472259" data-linked-resource-version="2" data-linked-resource-type="attachment" data-linked-resource-default-alias="widget_02.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="491543" data-linked-resource-container-version="264"></span></div>
                                </td>
                                <td class="confluenceTd">
                                    <div class="content-wrapper"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/cafe_pc_100.png" data-image-src="/download/attachments/491543/cafe_pc_100.png?version=1&amp;modificationDate=1505118779000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127749" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="cafe_pc_100.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="491543" data-linked-resource-container-version="264"></span></div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </li>
            </ol>
            <p><strong><br></strong></p>
            <p><strong>5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong><strong>주문완료 페이지&nbsp;&nbsp;(실시간 개인화 추천)</strong></p>
            <ol>
                <li>
                    <p class="p1"><span class="s1">새 스타일 추가 버튼을 클릭해서 새로운 탭이 생성되면 위젯명을&nbsp;<strong>주문완료</strong>로 입력합니다.</span></p>
                </li>
                <li>
                    <p class="p1"><span class="s1">채널 별 성과분석시(추천클릭률, 추천경유매출 등) 비교가 용이하도록&nbsp;</span><strong style="line-height: 1.42857;">order_result</strong>으로 입력합니다.&nbsp;</p>
                </li>
                <li>
                    <p class="p1">추천타입은 우선 사용안함으로 설정합니다. (데이터 수집후, 2~3일이 지나서 설정합니다.)</p>
                </li>
                <li>
                    <p>그 외 원하시는 대로 위젯 스타일을 변경하실 수 있으며 할인가 표시를 원하시면 표시여부를 체크해 주세요.&nbsp;</p>
                    <div class="table-wrap">
                        <table class="wrapped confluenceTable tablesorter tablesorter-default" role="grid">
                            <colgroup>
                                <col>
                            </colgroup>
                            <thead>
                            <tr role="row" class="tablesorter-headerRow">
                                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;위젯설정 예시&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                    <div class="tablesorter-header-inner">&lt;위젯설정 예시&gt;</div>
                                </th>
                            </tr>
                            </thead>
                            <tbody aria-live="polite" aria-relevant="all">
                            <tr role="row">
                                <td class="confluenceTd">
                                    <div class="content-wrapper"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/widget_005.png" data-image-src="/download/attachments/491543/widget_005.png?version=1&amp;modificationDate=1461818315000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127621" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="widget_005.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="491543" data-linked-resource-container-version="264"></span></div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </li>
                <li>
                    <p>모든 설정을 마친 후에는 꼭&nbsp;<strong>저장</strong>&nbsp;버튼을 클릭하시고 아래 위젯 적용 부분의 스크립트를 복사해서 고객님 사이트의&nbsp;<strong>주문완료 페이지</strong>에 삽입해 주세요. &lt;삽입 위치 참고&gt;</p>
                    <div class="table-wrap">
                        <table class="wrapped confluenceTable tablesorter tablesorter-default" role="grid">
                            <colgroup>
                                <col>
                                <col>
                            </colgroup>
                            <thead>
                            <tr role="row" class="tablesorter-headerRow">
                                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;위젯 적용&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                    <div class="tablesorter-header-inner">&lt;위젯 적용&gt;</div>
                                </th>
                                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="1" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;삽입 위치&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                    <div class="tablesorter-header-inner">&lt;삽입 위치&gt;</div>
                                </th>
                            </tr>
                            </thead>
                            <tbody aria-live="polite" aria-relevant="all">
                            <tr role="row">
                                <td class="confluenceTd">
                                    <div class="content-wrapper">&nbsp;<span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/widget_02.png" data-image-src="/download/attachments/491543/widget_02.png?version=2&amp;modificationDate=1458716445000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="5472259" data-linked-resource-version="2" data-linked-resource-type="attachment" data-linked-resource-default-alias="widget_02.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="491543" data-linked-resource-container-version="264"></span></div>
                                </td>
                                <td class="confluenceTd">
                                    <div class="content-wrapper"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/widget_015.png" data-image-src="/download/attachments/491543/widget_015.png?version=1&amp;modificationDate=1505118847000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127750" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="widget_015.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="491543" data-linked-resource-container-version="264"></span></div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </li>
            </ol>
            <p><strong>6. &nbsp; &nbsp; 마이</strong><strong>&nbsp;페이지&nbsp;&nbsp;(실시간 개인화 추천)</strong></p>
            <ol>
                <li>
                    <p class="p1"><span class="s1">새 스타일 추가 버튼을 클릭해서 새로운 탭이 생성되면 위젯명을&nbsp;<strong>마이페이지</strong>로 입력합니다.</span></p>
                </li>
                <li>
                    <p class="p1"><span class="s1">채널 별 성과분석시(추천클릭률, 추천경유매출 등) 비교가 용이하도록&nbsp;</span><strong>mypage</strong>으로 입력합니다.&nbsp;</p>
                </li>
                <li>
                    <p class="p1">추천타입은 우선 사용안함으로 설정합니다. (데이터 수집후, 2~3일이 지나서 설정합니다.)</p>
                </li>
                <li>
                    <p>그 외 원하시는 대로 위젯 스타일을 변경하실 수 있으며 할인가 표시를 원하시면 표시여부를 체크해 주세요.&nbsp;</p>
                    <div class="table-wrap">
                        <table class="wrapped confluenceTable tablesorter tablesorter-default" role="grid">
                            <colgroup>
                                <col>
                            </colgroup>
                            <thead>
                            <tr role="row" class="tablesorter-headerRow">
                                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;위젯설정 예시&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                    <div class="tablesorter-header-inner">&lt;위젯설정 예시&gt;</div>
                                </th>
                            </tr>
                            </thead>
                            <tbody aria-live="polite" aria-relevant="all">
                            <tr role="row">
                                <td class="confluenceTd">
                                    <div class="content-wrapper"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/widget_0061.png" data-image-src="/download/attachments/491543/widget_0061.png?version=1&amp;modificationDate=1505120528000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127758" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="widget_0061.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="491543" data-linked-resource-container-version="264"></span></div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </li>
                <li>
                    <p>모든 설정을 마친 후에는 꼭&nbsp;<strong>저장</strong>&nbsp;버튼을 클릭하시고 아래 위젯 적용 부분의 스크립트를 복사해서 고객님 사이트의&nbsp;<strong>마이 페이지</strong>에 삽입해 주세요. &lt;삽입 위치 참고&gt;</p>
                    <div class="table-wrap">
                        <table class="wrapped confluenceTable tablesorter tablesorter-default" role="grid">
                            <colgroup>
                                <col>
                                <col>
                            </colgroup>
                            <thead>
                            <tr role="row" class="tablesorter-headerRow">
                                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;위젯 적용&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                    <div class="tablesorter-header-inner">&lt;위젯 적용&gt;</div>
                                </th>
                                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="1" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;삽입 위치&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                    <div class="tablesorter-header-inner">&lt;삽입 위치&gt;</div>
                                </th>
                            </tr>
                            </thead>
                            <tbody aria-live="polite" aria-relevant="all">
                            <tr role="row">
                                <td class="confluenceTd">
                                    <div class="content-wrapper"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/widget_02.png" data-image-src="/download/attachments/491543/widget_02.png?version=2&amp;modificationDate=1458716445000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="5472259" data-linked-resource-version="2" data-linked-resource-type="attachment" data-linked-resource-default-alias="widget_02.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="491543" data-linked-resource-container-version="264"></span></div>
                                </td>
                                <td class="confluenceTd">
                                    <div class="content-wrapper"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/widget_0150.png" data-image-src="/download/attachments/491543/widget_0150.png?version=1&amp;modificationDate=1505123163000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127767" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="widget_0150.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="491543" data-linked-resource-container-version="264"></span></div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </li>
            </ol>
            <p><strong><br></strong></p>
            <p><strong>7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong><strong>장바구니 팝업 </strong></p>
            <ol>
              <li>
                <p class="p1"><span class="s1">위젯 추가 버튼 클릭 후 전체 위젯List에 신규위젯이 생성되면 신규위젯을 클릭을 하여 위젯명을 “장바구니 팝업”으로 입력합니다.</span></p>
              </li>
              <li>
                <p class="p1"><span class="s1">채널 별 성과분석시(추천클릭률, 추천경유매출 등) 비교가 용이하도록 “장바구니팝업” 으로 입력합니다. </span></p>
              </li>
              <li>
                <p class="p1"><span class="s1">추천타입은 우선 사용안함으로 설정합니다. (데이터 수집후, 2~3일이 지나서 설정합니다.)</span></p>
                <div class="table-wrap">
                  <table class="wrapped confluenceTable tablesorter tablesorter-default" role="grid">
                    <thead>
                    <tr role="row" class="tablesorter-headerRow">
                      <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0"
                          tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none"
                          aria-label="<위젯설정 예시>: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                        <div class="tablesorter-header-inner">&lt;위젯설정 예시&gt;</div>
                      </th>
                    </tr>
                    </thead>
                    <tbody aria-live="polite" aria-relevant="all">
                    <tr role="row">
                      <td class="confluenceTd">
                        <div class="content-wrapper"><span class="confluence-embedded-file-wrapper">
              <img src="@/assets/images/BasketPopupWidget/settingWidget.png"/></span>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </li>
              <li>
                <p class="p1"><span class="s1">그 외 원하시는 대로 위젯 스타일을 변경하실 수 있으며 할인가 표시를 원하시면 표시여부를 체크해 주세요.</span></p>
              </li>
              <li>
                <p class="p1"><span class="s1">	모든 설정을 마친 후에는 꼭 저장 버튼을 클릭하시고 아래 위젯 적용 부분의 스크립트를 복사해서 고객님 사이트의 장바구니팝업에 삽입해 주세요. </span></p>
                <div class="table-wrap">
                  <table class="wrapped confluenceTable tablesorter tablesorter-default" role="grid">
                    <thead>
                    <tr role="row" class="tablesorter-headerRow">
                      <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0"
                          tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none"
                          aria-label="<위젯 적용>" style="user-select: none;">
                        <div class="tablesorter-header-inner">&lt;위젯 적용&gt; - 아래 위젯 소스를 장바구니 팝업 화면에 Copy&Paste </div>
                      </th>
                    </tr>
                    </thead>
                    <tbody aria-live="polite" aria-relevant="all">
                    <tr role="row">
                      <td class="confluenceTd">
                        <div class="content-wrapper"><span class="confluence-embedded-file-wrapper">
              <img src="@/assets/images/BasketPopupWidget/scriptWidget.png"/></span>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </li>
              <li>
                <p class="p1"><span class="s1">적용 예시 (PC& Mobile)</span></p>
                <div class="table-wrap">
                  <table class="wrapped confluenceTable tablesorter tablesorter-default" role="grid">
                    <thead>
                    <tr role="row" class="tablesorter-headerRow">
                      <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0"
                          tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none"
                          aria-label="위젯적용예시" style="user-select: none;">
                        <div class="tablesorter-header-inner">&lt;위젯적용예시&gt; </div>
                      </th>
                    </tr>
                    </thead>
                    <tbody aria-live="polite" aria-relevant="all">
                    <tr role="row">
                      <td class="confluenceTd">
                        <div class="content-wrapper"><span class="confluence-embedded-file-wrapper">
              <img src="@/assets/images/BasketPopupWidget/widget_PC.png"/>
              <img src="@/assets/images/BasketPopupWidget/widget_M.png"/>
            </span>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </li>
            </ol>
            <ul>
                <li><strong>위젯을 이용한 추천서비스 시작 (추천우선 순위 설정)</strong>
                    <ul>
                        <li><strong>로그수집 스크립트, 메타태그, 추천위젯 삽입까지 모두 완료하셨나요?</strong></li>
                        <li><strong>제대로 설치하셨다면 데이터 수집된후, 2~3일이 지나서 추천결과를 반영할 수 있습니다.&nbsp;&nbsp;</strong></li>
                    </ul>
                </li>
            </ul>
            <p><br></p>
            <p>추천결과 반영시점은 메일을 통해 알려드립니다. 메일을 받으신 후 안내에 따라<strong> <strong>&nbsp;<a style="text-decoration: underline;" href="http://admin.recopick.com/" class="external-link">Admin Dashboard</a></strong>&nbsp;</strong>&nbsp;&gt; <strong>추천 위젯 설정</strong>에 들어가셔서</p>
            <p>사용안함으로 설정된 추천타입을 아래 가이드에 따라 설정한 후 저장하시면 사이트에서 추천결과를 확인 하실 수 있습니다.<strong style="line-height: 1.42857;">&nbsp;</strong></p>
            <p><strong style="line-height: 1.42857;"><br></strong></p>
            <p>1.<strong>상품상세, 메인, 장바구니, 주문완료, 마이페이지&nbsp;</strong>등에 아래 예제처럼 추천타입을 설정합니다.</p>
            <p>2. 설정된 추천타입에 따라 그에 매치되는 타이틀로 변경합니다. (개인화 추천 예시 : ‘고객님을 위한 추천’, ‘고객님의 성향에 맞는 추천’ 등)</p>
            <p>3. 모든 설정을 마친 후에는 꼭 저장버튼을 클릭하셔야 사이트에 반영됩니다.&nbsp;</p>
            <p><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/widget_0007.png" data-image-src="/download/attachments/491543/widget_0007.png?version=1&amp;modificationDate=1505118910000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127753" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="widget_0007.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="491543" data-linked-resource-container-version="264"></span></p>
            <p>* 추천 설정은 가장 추천효과가 좋았던 best practice기반으로 안내하고 있으며 1순위 추천결과가 나타나지 않는 경우 다음 순위 추천결과를 노출합니다.</p>
            <div class="table-wrap">
                <table class="wrapped confluenceTable tablesorter tablesorter-default" style="line-height: 1.42857;" role="grid">
                    <colgroup>
                        <col>
                        <col>
                        <col>
                        <col>
                        <col>
                        <col>
                        <col>
                        <col>
                    </colgroup>
                    <thead>
                    <tr role="row" class="tablesorter-headerRow">
                        <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="추천 설정: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                            <div class="tablesorter-header-inner">추천 설정</div>
                        </th>
                        <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="1" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="상품상세_상단: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                            <div class="tablesorter-header-inner">상품상세_상단</div>
                        </th>
                        <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="2" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="상품상세_하단: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                            <div class="tablesorter-header-inner">상품상세_하단</div>
                        </th>
                        <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="3" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="메인  페이지: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                            <div class="tablesorter-header-inner">메인&nbsp; 페이지</div>
                        </th>
                        <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="4" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="장바구니: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                            <div class="tablesorter-header-inner">
                                <p>장바구니</p>
                            </div>
                        </th>
                        <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="5" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="주문완료: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                            <div class="tablesorter-header-inner">주문완료</div>
                        </th>
                        <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="6" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="마이페이지: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                            <div class="tablesorter-header-inner">마이페이지</div>
                        </th>
                        <th colspan="1" class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="7" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="그외 페이지: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                            <div class="tablesorter-header-inner">그외 페이지</div>
                        </th>
                    </tr>
                    </thead>
                    <tbody aria-live="polite" aria-relevant="all">
                    <tr role="row">
                        <td class="confluenceTd">1순위</td>
                        <td class="confluenceTd">함께 본 상품 추천</td>
                        <td class="confluenceTd">실시간 개인화 추천</td>
                        <td class="confluenceTd"><span>실시간 개인화 추천</span></td>
                        <td class="confluenceTd">함께 구매한 상품 <span>추천</span></td>
                        <td class="confluenceTd"><p>통계형 추천</p>(장바구니 TOP 100)</td>
                        <td class="confluenceTd"><span>실시간 개인화 추천</span></td>
                        <td colspan="1" class="confluenceTd"><span>실시간 개인화 추천</span></td>
                    </tr>
                    <tr role="row">
                        <td class="confluenceTd">2순위</td>
                        <td class="confluenceTd"><span>실시간 개인화 추천</span></td>
                        <td class="confluenceTd">함께 구매한 상품 <span>추천</span></td>
                        <td class="confluenceTd"><p>통계형 추천</p>(Longtail TOP 100)</td>
                        <td class="confluenceTd">
                            <p><span>실시간 개인화 추천</span></p>
                        </td>
                        <td class="confluenceTd">함께 구매한 상품 <span>추천</span></td>
                        <td class="confluenceTd">
                            <p>통계형 추천</p>(구매전환율 TOP 100)
                        </td>
                        <td colspan="1" class="confluenceTd">
                            <p>통계형 추천</p>(구매전환율 TOP 100)
                        </td>
                    </tr>
                    <tr role="row">
                        <td class="confluenceTd">3순위</td>
                        <td class="confluenceTd">
                            <p>통계형 추천</p>(구매전환율 TOP 100)
                        </td>
                        <td class="confluenceTd">
                            <p>통계형 추천</p>(구매전환율 TOP 100)
                        </td>
                        <td class="confluenceTd">사용안함</td>
                        <td class="confluenceTd">
                            <p>통계형 추천</p>(많이 팔린 상품 TOP 100)
                        </td>
                        <td class="confluenceTd">
                            <p>통계형 추천</p>(<span>많이 팔린 상품 TOP 100</span>)
                        </td>
                        <td class="confluenceTd">사용안함</td>
                        <td colspan="1" class="confluenceTd">사용안함</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <p><br></p>
            <h1 id="id-독립형쇼핑몰인경우(자체제작쇼핑몰)-&lt;3-2&gt;API방식"><strong>&lt;3-2&gt; API 방식</strong></h1>
            <ul>
                <li><strong>적용 방법</strong>
                    <ul>
                        <li><strong><a href="/pages/viewpage.action?pageId=3244098">클릭하고 이동하기 →&nbsp;&nbsp; &nbsp;&nbsp;</a></strong><u><strong><a href="/pages/viewpage.action?pageId=3244098">추천 API</a></strong></u></li>
                    </ul>
                </li>
            </ul>
            <p>&nbsp; &nbsp;</p>
            <h1 id="id-독립형쇼핑몰인경우(자체제작쇼핑몰)-&lt;4단계&gt;추천성과분석"><strong>&lt;4 단계&gt; 추천성과 분석&nbsp;</strong></h1>
            <ul>
                <li>추천 상품이 노출된 이후 해당 상품을 클릭하고 구매까지 이뤄지는 경우를 수집하여 추천서비스가 매출에 기여한 정도를 확인 할 수 있음</li>
                <li>적용 방법 1은 API를 통한 방식이고 적용방법 2는 추천 상품 노출 후 제공되는 click_log_ling 혹은 click_log_redirect_link값을 링크시켜 결과값을 전송하는 방식으로 선택적으로 사용 가능 함.</li>
            </ul>
            <p><br></p>
            <ul>
                <li>
                    <h2 id="id-독립형쇼핑몰인경우(자체제작쇼핑몰)-적용방법1"><strong>적용 방법 1</strong></h2>
                    <ul>
                        <li><strong><a href="/pages/viewpage.action?pageId=491684">클릭 하고 이동하기 →&nbsp; 7) 추천상품 클릭 로그</a></strong></li>
                    </ul>
                </li>
            </ul>
            <p><br></p>
            <ul>
                <li>
                    <h3 id="id-독립형쇼핑몰인경우(자체제작쇼핑몰)-적용방법2"><strong>적용 방법2&nbsp;&nbsp;</strong></h3>
                    <ul>
                        <li>
                            <h3 id="id-독립형쇼핑몰인경우(자체제작쇼핑몰)-추천API(예시:viewtogetherAPI)호출시아래와같은결과를response로받게됨">추천 API(예시: view together API) 호출 시 아래와 같은 결과를 response로 받게 됨&nbsp;</h3>
                        </li>
                    </ul>
                </li>
            </ul>
            <h3 id="id-독립형쇼핑몰인경우(자체제작쇼핑몰)-.1">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<span class="confluence-embedded-file-wrapper confluence-embedded-manual-size"><img class="confluence-embedded-image" width="1300" src="@/assets/images/api_response.png" data-image-src="/download/attachments/491543/api_response.png?version=2&amp;modificationDate=1569459474000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="14876712" data-linked-resource-version="2" data-linked-resource-type="attachment" data-linked-resource-default-alias="api_response.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="491543" data-linked-resource-container-version="264"></span></h3>
            <ul>
                <li style="list-style-type: none;">
                    <ul>
                        <li style="list-style-type: none;">
                            <ul>
                                <li>
                                    <h3 id="id-독립형쇼핑몰인경우(자체제작쇼핑몰)-상기필드중에clicklog_link또는clicklog_redirect_link에있는URL값을추천상품을클릭했을때호출하면됨.">상기&nbsp; 필드 중에&nbsp; clicklog_link 또는 clicklog_redirect_link에 있는 URL값을 추천 상품을 클릭했을 때 호출하면 됨.</h3>
                                    <ul>
                                        <li>상품 이미지 클릭 시 clicklog_redirect_link 주소를 호출할 수 있어야 함. 구현은 개발사에서 편한 방식으로 구현하면 됨.<ul>
                                            <li>예1) &lt;img src="<a href="http://www.xxxx.co.kr/lib/upload/product/haelimcall/2019/05/38ab1ba4c7c69d79f9dbf62cd5025e8e.jpg/_dims_/resize/250x250/extent/250x250" class="external-link">http://www.xxxx.co.kr/lib/upload/product/haelimcall/2019/05/38ab1ba4c7c69d79f9dbf62cd5025e8e.jpg/_dims_/resize/250x250/extent/250x250</a>" class="cursor" alt="상품이미지" <br>onclick="window.open("<span style="color: rgb(255,0,0);"><a href="https://lc.recopick.com/1/banner/1213/pick?uid=86239897.1563843911724&amp;source=6973320&amp;pick=6299536&amp;method=4&amp;channel=empty&amp;reco_type=item-item&amp;product_type=R&amp;reco_list=%5B%226299536%22%2C%226676824%22%5D&amp;tag=V" style="color: rgb(255,0,0);" class="external-link">https://lc.recopick.com/1/banner/1213/pick?uid=86239897.1563843911724&amp;source=6973320&amp;pick=6299536&amp;method=4&amp;channel=empty&amp;reco_type=item-item&amp;product_type=R&amp;reco_list=%5B%226299536%22%2C%226676824%22%5D&amp;tag=V</a></span>");"&gt;</li>
                                            <li>예2)<ul>
                                                <li>&nbsp;&lt;div&gt;</li>
                                                <li>&lt;a href ="<span style="color: rgb(255,0,0);"><a href="https://lc.recopick.com/1/banner/1213/pick?uid=86239897.1563843911724&amp;source=6973320&amp;pick=6299536&amp;method=4&amp;channel=empty&amp;reco_type=item-item&amp;product_type=R&amp;reco_list=%5B%226299536%22%2C%226676824%22%5D&amp;tag=V" class="external-link">https://lc.recopick.com/1/banner/1213/pick?uid=86239897.1563843911724&amp;source=6973320&amp;pick=6299536&amp;method=4&amp;channel=empty&amp;reco_type=item-item&amp;product_type=R&amp;reco_list=%5B%226299536%22%2C%226676824%22%5D&amp;tag=V</a></span>"&gt; title.... &lt;/a&gt;</li>
                                                <li>&lt;/div&gt;</li>
                                            </ul>
                                            </li>
                                        </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li>여기서 clicklog_link의 경우 recopick log collector로 클릭 이벤트 정보만 전달 하게 되고, clicklog_redirect_link의 경우 클릭 이벤트 정보를 전달 한 이후 원하는 페이지로 redirect 됨.</li>
                                <li>clicklog_link를 이용하면서&nbsp; REST API로 행동로그(예, view...)를 전송할 경우 해당 규격의 URL 파라미터의 값으로 클릭 후 이동한 상세페이지 URL과 recopick, product_type 값을 append해서 전송 하면 됨.&nbsp;</li>
                                <li>해당 부분을 구현 한 후 추천 상품을 클릭할 경우 아래와 같이 브라우저 URL입력 창에 recopick =4( 상기 method설정 값과 동일, 여기서 4는 view together 용 api를 호출한 경우&nbsp; 표시되면 됨(clicklog_redirect_link의 경우)</li>
                                <li><a href="http://mustit.co.kr/product/product_detail/6299536?recopick=4&amp;product_type=R" class="external-link">http://www.xxxx.co.kr/product/product_detail/6299536?recopick=4&amp;product_type=R</a></li>
                                <li>추가로 channel 값은 해당 api가 적용된 페이지를 구분 하는 기준으로 고객사가 임의로 설정(예, main_best) 을 하게 되면 대시보드 상에서 채널별로 api 호출 통계지표를 확인 할 수 있음. 설정하지 않을 경우 empty로 해서 표시됨</li>
                                <li>
                                    <p><span style="color: rgb(255,0,0);"><img class="emoticon emoticon-warning" src="@/assets/images/warning.svg" data-emoticon-name="warning" alt="(warning)"><span>&nbsp;주의하세요!&nbsp;<span style="color: rgb(0,0,0);">사용자들이 추천 리스트를 클릭하는 경우, clicklog_link 혹은 clicklog_redirect_link를 통해 추천 클릭로그를 남겨주셔야 합니다. 추천 클릭 로그를 제대로 남기지 않을 경우, 추천에 대한 성과를 계산할 수 없으며, 향후 추천 품질이 저하되는 문제가 생깁니다. 브라우저의 경우, 간단하게 clicklog_redirect_link 를 통해 추천 클릭로그를 남기면서, 페이지를 이동하게 하는 것을 추천 드리며, 불가피하게 clicklog_redirect_link를 사용할 수 없을 경우, 서버 혹은 ajax 등 다른 방법으로 clicklog_link를 호출하셔서 추천 클릭 로그를 남겨주시면 됩니다. (참고:&nbsp;<a href="/pages/viewpage.action?pageId=491684" style="text-decoration: none;">7) 추천상품 클릭 로그</a>&nbsp; → 추천상품 클릭로그는 clicklog_redirect_link를 API로 호출 하실 경우에 대한 정의가 되어 있음. )</span></span></span></p>
                                </li>
                                <li>
                                    <p><br></p>
                                    <p><span style="color: rgb(255,0,0);"><span style="color: rgb(0,0,0);">※ 참고&nbsp;:&nbsp;</span></span><span style="color: rgb(0,0,0);">추천 상품 클릭 시에 추천 API 결과에 있는 clicklog_link 혹은 clicklog_redirect_link를 반드시 레코픽으로 전달해주셔야 합니다. 그래야 레코픽 추천 성과가 잡힙니다.</span></p>
                                    <ul>
                                        <li>상품상세페이지의 URL 패턴이 정형화 되어 있지 않다면 clicklog_redirect_link는 사용하지 못하고, clicklog_link를 사용해주셔야 합니다.</li>
                                        <li>clicklog_redirect_link : 추천된 상품에 대한 클릭 정보를 레코픽으로 전송한 뒤에 클릭된 상품의 상세페이지로 redirect시켜 드립니다.</li>
                                    </ul>
                                    <ul>
                                        <li>clicklog_link : 추천된 상품에 대한 클릭 정보를 레코픽으로 전송합니다. 이 경우에는 추천된 상품의 상세페이지로 직접 이동해주셔야 합니다.</li>
                                    </ul>
                                    <p>&nbsp;또한, API 응답 내에 있는 위의 method 값을 아래 형식으로 상세페이지의 URL 파라미터에 추가적으로 넣어주셔야 하고, 추천 서비스를 사용하는 경우 'product_type=R' 파라미터도 추가적으로 넣어주셔야 합니다.</p>
                                    <p>• 형식 : recopick=method 값 &amp;&nbsp;<span class="nolink"><span style="color: rgb(255,0,0);">product_type=R</span></span></p>
                                    <p><span>• 예1 :&nbsp;</span><span class="nolink"><a style="text-decoration: none;" href="http://aaa.test.co.kr/product/detail.html?product_no=1338&amp;" class="external-link">http://aaa.test.co.kr/product/detail.html?product_no=1338&amp;</a><span style="color: rgb(255,0,0);">recopick=4&amp;product_type=R</span></span></p>
                                    <p>• 예2 :&nbsp;<span class="nolink"><a class="external-link" href="http://test.xxx.co.kr/product/product_detail/3395854" style="text-decoration: none;">http://test.xxx.co.kr/product/product_detail/3395854</a>?<span style="color: rgb(255,0,0);">recopick=25&amp;product_type=R</span></span></p>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <h2 id="id-독립형쇼핑몰인경우(자체제작쇼핑몰)-검증방법">검증방법</h2>
                            <ul>
                                <li>좌측 추천 성과분석 하기 메뉴에서 각각 결과 값이 제공되는지 확인 할 수 있음</li>
                                <li>추천성과분석\추천기여매출 페이지상에 파란색 막대가 생기면 정상적으로 추천상품을 클릭한 로그가 입수 됨을 확인 할 수 있음</li>
                                <li><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/image2019-9-26_9-59-43.png" data-image-src="/download/attachments/491543/image2019-9-26_9-59-43.png?version=1&amp;modificationDate=1569459584000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="17858564" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="image2019-9-26_9-59-43.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="491543" data-linked-resource-container-version="264"></span></li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</template>
