<template>
    <div class="page view">
        <h1>Error responses</h1>
        <div id="main-content" class="wiki-content">
            <br>
            <p>RecoPick API를 호출할 때 HTTP status code를 통해 실행 결과를 반환하게 됩니다. HTTP status code에 대한 자세한 설명은 다음과 같습니다.</p>
            <p><br /></p>
            <div class="table-wrap">
                <table class="wrapped confluenceTable">
                    <colgroup>
                        <col />
                        <col />
                    </colgroup>
                    <tbody>
                    <tr>
                        <th class="confluenceTh">Status Code</th>
                        <th class="confluenceTh">설명</th>
                    </tr>
                    <tr>
                        <td class="confluenceTd">200</td>
                        <td class="confluenceTd">요청이 성공적으로 수행되었습니다.</td>
                    </tr>
                    <tr>
                        <td class="confluenceTd">400</td>
                        <td class="confluenceTd">요청이 잘못된 parameter를 포함하고 있습니다. 자세한 사항은 response body에 있는 errors를 참조하세요.</td>
                    </tr>
                    <tr>
                        <td colspan="1" class="confluenceTd">401</td>
                        <td colspan="1" class="confluenceTd">인증되지 않은 요청입니다. 올바른 인증값과 함께 요청해주세요.</td>
                    </tr>
                    <tr>
                        <td class="confluenceTd">404</td>
                        <td class="confluenceTd">요청한 resource가 존재하지 않습니다. 보통은 URL에 오타가 포함되어 있는 경우 발생합니다.</td>
                    </tr>
                    <tr>
                        <td colspan="1" class="confluenceTd">405</td>
                        <td colspan="1" class="confluenceTd"><span style="color: rgb(0,0,0);">API call limit exceeded. Request item product to uses this API.</span></td>
                    </tr>
                    <tr>
                        <td colspan="1" class="confluenceTd">500</td>
                        <td colspan="1" class="confluenceTd">
                            <p>RecoPick 서버 내부 오류가 발생했습니다. 만약 서버가 계속해서 500을 반환하는 경우, RecoPick 서비스 관리자(recopick@sk.com)에게 이 사실을 빨리 알려주세요!</p>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="1" class="confluenceTd">700</td>
                        <td colspan="1" class="confluenceTd">유효하지 않은 고객사입니다. API 사용을 원하시면 RecoPick 서비스 관리자(recopick@<a href="http://sk.com" class="external-link">sk.com</a>)에게 연락주세요.</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <p><br /></p>
            <p>Status Code가 400인 경우, Response body에 추가적인 설명이 포함됩니다. 이 response body에는 JSON 형태로 error의 내용이 포함되게 됩니다. 예제 response body는 다음과 같습니다.</p>
            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                <div class="codeContent panelContent pdl">
                    <pre class="syntaxhighlighter-pre" data-syntaxhighlighter-params="brush: javafx; gutter: false; theme: Eclipse" data-theme="Eclipse">{&quot;errors&quot;:[{&quot;message&quot;:&quot;invalid parameter uid&quot;}]}</pre>
                </div>
            </div>
        </div>
    </div>
</template>