<template>
  <div class="page view">
    <h1>Recopick 설치 가이드 (new)</h1>
    <div id="main-content" class="wiki-content">
      <br>
      <p></p>
      <p>레코픽 설치 가이드는 독립형 쇼핑몰(자체 제작)인 경우, 임대형 쇼핑몰 솔루션을 이용하는 경우 등 고객님의 쇼핑몰 타입에 따라 가이드가 조금씩 다릅니다.</p>
      <p>아래 타입별로 상세 가이드 안내에 따라 레코픽 추천을 설치해 주세요.<br /><br /></p>
      <ol>
        <li class="O1"><a href="/pages/viewpage.action?pageId=491543">독립형 쇼핑몰일 경우 (자체 제작 쇼핑몰)</a><br /><br /></li>
        <li class="O1">임대형 쇼핑몰 솔루션을 이용하는 경우 (임대몰)<ul>
          <li><a href="/pages/viewpage.action?pageId=3244203">Cafe&nbsp;</a><a href="/pages/viewpage.action?pageId=3244203">24&nbsp;</a></li>
          <li><a href="/pages/viewpage.action?pageId=3244221">Cafe 24 모바일</a></li>
          <li><a href="/pages/viewpage.action?pageId=3244217">메이크샵&nbsp;</a></li>
          <li><a href="/pages/viewpage.action?pageId=3244223">메이크샵 모바일</a></li>
          <li><a href="/pages/viewpage.action?pageId=3244210">고도몰&nbsp;</a></li>
          <li><a href="/pages/viewpage.action?pageId=3244225">고도몰 모바일</a></li>
          <li><a href="/pages/viewpage.action?pageId=3244228">위사</a></li>
          <li><a href="/pages/viewpage.action?pageId=3244230">위사 모바일</a></li>
        </ul><br /><br /></li>
        <li class="O1">레코픽 추천 사용 방식</li>
      </ol>
      <div class="O1">
        <ul>
          <li style="list-style-type: none;background-image: none;">
            <ul>
              <li>추천 위젯 방식<ul>
                <li>추천 상품들이 노출될 영역을 잡아 추천 위젯 스크립트만 넣어주시면, 각 추천 알고리즘에 맞는 상품들이 노출됩니다.</li>
                <li>레코픽 추천 위젯 스크립트를 단순히 copy-paste 하는 정도로 레코픽 추천 상품 노출이 가능합니다.</li>
                <li>사이트 내 추천 적용이 매우 쉽기 때문에 개발 기간이 짧습니다. (약 1일)</li>
                <li>추천 상품 결과에 대한 필터링이나 노출 UX를 자유롭게 통제할 수 없습니다. (제한적인 범위 안에서 통제 가능합니다)</li>
                <li>레코픽 대시보드 내 추천 위젯 설정 메뉴나 Custom CSS를 적용하여 레코픽 추천 위젯 UX를 일부 수정할 수 있습니다.</li>
                <li>레코픽 대시보드 상에서 특정 상품을 고정 노출될 수 있도록 처리할 수 있습니다. (단, 이렇게 하는 경우 데이터를 기반한 상품 노출이 아니므로, 추천 성과는 하락할 수 있습니다)</li>
              </ul>
              </li>
              <li>추천 API 방식<ul>
                <li>레코픽 추천 API를 통해 상품 추천을 요청하여 그에 대한 결과를 JSON 형태로 응답 받습니다.</li>
                <li>오로지 데이터만 주고 받는 방식으로, 추천 노출 결과에 대한 필터링이나 노출 UX를 자유롭게 하실 수 있습니다.</li>
                <li>추천 상품 노출과 관련한 모든 것을 직접 개발해주셔야 하므로, 상대적으로 개발 기간이 좀더 소요됩니다 (약 3일~2주 정도 소요)</li>
                <li>추천 상품 노출과 관련한 모든 것을 직접 개발함으로써, 특정 상품을 고정하여 노출하는 것도 자유롭게 할 수 있습니다. (단, 이렇게 하는 경우 데이터를 기반한 상품 노출이 아니므로, 추천 성과는 하락할 수 있습니다)</li>
                <li>추천 상품 클릭 시에 추천 API 결과에 있는 clicklog_link 혹은 clicklog_redirect_link를 반드시 레코픽으로 전달해주셔야 합니다. 그래야 레코픽 추천 성과가 잡힙니다.<ul>
                  <li>상품상세페이지의 URL 패턴이 정형화 되어 있지 않다면 clicklog_redirect_link는 사용하지 못하고, clicklog_link를 사용해주셔야 합니다.</li>
                  <li>clicklog_redirect_link : 추천된 상품에 대한 클릭 정보를 레코픽으로 전송한 뒤에 클릭된 상품의 상세페이지로 redirect시켜 드립니다.</li>
                  <li>clicklog_link : 추천된 상품에 대한 클릭 정보를 레코픽으로 전송합니다.<br /><br /><br /></li>
                </ul>
                </li>
              </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
