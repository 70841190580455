<template>
    <div class="page view">
        <h1>[참고] iOS에서 WebView 로 token 값 전달 하는 방법</h1>
        <div id="main-content" class="wiki-content">
            <br>
            <p class="auto-cursor-target">하이브리드 앱에서 네이티브와 WebView의 html 환경과 값 전달이 필요 할 때가 있다.</p>
            <p class="auto-cursor-target">예를들어 로그를 남길때 로그ID 값 같은 것은 네이티브에서도 필요하고</p>
            <p class="auto-cursor-target">웹뷰에서도 해당 값을 공유해서 로그를 쌓을때 같은 key 값으로 로그를 쌓는 서버쪽에 전달을 해야 한다.</p>
            <p class="auto-cursor-target">아래 방법은 javascript 를 통해서 accessToken 을 네이티브에서 web 쪽으로 전달하는 예이다.</p>
            <h3 class="auto-cursor-target" id="id-[참고]iOS에서WebView로token값전달하는방법-1.myAppWebView에서test.html호출">1. myAppWebView 에서 test.html 호출</h3>
            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                <div class="codeHeader panelHeader pdl" style="border-bottom-width: 1px;"><b>App #1</b></div>
                <div class="codeContent panelContent pdl">
                    <pre class="syntaxhighlighter-pre" data-syntaxhighlighter-params="brush: cpp; gutter: false; theme: Emacs" data-theme="Emacs">[self.myAppWebView loadRequest:[NSURLRequest requestWithURL:[NSURL fileURLWithPath:[[NSBundle mainBundle] pathForResource:@&quot;test&quot; ofType:@&quot;html&quot;]isDirectory:NO]]];</pre>
                </div>
            </div>
            <h3 class="auto-cursor-target" id="id-[참고]iOS에서WebView로token값전달하는방법-2.myAppWebView가생성/로딩이끝났을때useAccessToken()javascript함수에accessToken값을전달.">2. myAppWebView 가 생성/로딩이 끝났을때 useAccessToken() javascript 함수에 accessToken 값을 전달.</h3>
            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                <div class="codeHeader panelHeader pdl" style="border-bottom-width: 1px;"><b>App #2</b></div>
                <div class="codeContent panelContent pdl">
            <pre class="syntaxhighlighter-pre" data-syntaxhighlighter-params="brush: cpp; gutter: false; theme: Emacs" data-theme="Emacs">- (void)webViewDidFinishLoad:(UIWebView *)webView {
    NSString *accessToken = [FBSDKAccessToken currentAccessToken].tokenString;
    NSString *functionToCall = [NSString stringWithFormat:@&quot;usesAccessToken('%@')&quot;, accessToken];
    [self.myAppWebView stringByEvaluatingJavaScriptFromString:functionToCall];
}</pre>
                </div>
            </div>
            <p><br /></p>
            <h3 id="id-[참고]iOS에서WebView로token값전달하는방법-3.test.html의javascript에서accessToken값을받아서팝업으로띄움.">3. test.html 의 javascript 에서 accessToken 값을 받아서 팝업으로 띄움.</h3>
            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                <div class="codeHeader panelHeader pdl" style="border-bottom-width: 1px;"><b>test.html</b></div>
                <div class="codeContent panelContent pdl">
            <pre class="syntaxhighlighter-pre" data-syntaxhighlighter-params="brush: xml; gutter: false; theme: Emacs" data-theme="Emacs">    &lt;html&gt;
      &lt;head&gt;&lt;/head&gt;
      &lt;body&gt;
          &lt;script type=&quot;text/javascript&quot;&gt;
            function usesAccessToken(access_token_string) {
             // use access token
              alert(access_token_string);
            }
            &lt;/script&gt;
      &lt;/body&gt;
    &lt;/html&gt;</pre>
                </div>
            </div>
            <p><br /></p>
            <p>출처 : <a href="https://stackoverflow.com/questions/30108265/use-facebook-ios-sdk-auth-token-in-webview" class="external-link">https://stackoverflow.com/questions/30108265/use-facebook-ios-sdk-auth-token-in-webview</a></p>
            <p><br /></p>
            <p>- 끝 -</p>
            <p><br /></p>
            <p><br /></p>
        </div>
    </div>
</template>