<template>
    <div class="page view">
        <h1>상품 API</h1>
        <div id="main-content" class="wiki-content">
            <br>
            <h1 id="id-상품API-Meta">Meta </h1>
            <p><span style="color: rgb(153,153,153);">상품의 메타정보를 JSON 형태로 가져갈 수 있는 API입니다. (상품의 정확한 메타정보를 가져가시기 위해서는 <a href="/pages/viewpage.action?pageId=491543">메타정보확인</a>을 참고하셔서 메타 태그를 넣어주세요.)</span><span style="color: rgb(153,153,153);"> </span></p>
            <div class="table-wrap">
                <table class="confluenceTable">
                    <tbody>
                    <tr>
                        <th class="confluenceTh"><strong>Resource</strong></th>
                        <th class="confluenceTh"><strong>Description</strong></th>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><a href="/pages/viewpage.action?pageId=491668">1) 상품 메타정보 조회</a></td>
                        <td class="confluenceTd">
                            <p><span style="color: rgb(0,0,0);">메타정보에는 수집되는 정보에 따라 title, tag, description, 상품 이미지 url, 상품 링크등을 반환합니다. </span></p>
                            <p><span style="color: rgb(0,0,0);">정상적으로 호출할 경우 메타정보 JSON을 반환하며 비정상적인 호출일 경우 { title : &quot;fail&quot;, description : &quot;fail to fetch product info&quot; } 라는 JSON을 반환합니다.</span></p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <h1 id="id-상품API-MassHiding">Mass Hiding</h1>
            <p><span style="color: rgb(153,153,153);">품절된 상품을 관리하는 API입니다. 상품이 품절되었을 경우 API를 호출하여 품절 리스트에 추가하여 추천 리스트에서 품절 상품을 제거할 수 있습니다.</span></p>
            <p><span style="color: rgb(153,153,153);">상품이 재입고되었을 경우 추천 리스트에서 다시 추가할 수도 있습니다.</span><span style="color: rgb(153,153,153);"> </span></p>
            <div class="table-wrap">
                <table class="confluenceTable">
                    <tbody>
                    <tr>
                        <th class="confluenceTh"><strong>Resource</strong></th>
                        <th class="confluenceTh"><strong>Description</strong></th>
                    </tr>
                    <tr>
                        <td colspan="1" class="confluenceTd"><a href="/pages/viewpage.action?pageId=491671">2) 상품 품절 처리 (추천 제외)</a></td>
                        <td colspan="1" class="confluenceTd">
                            <p>품절된 상품을 추가하는 API입니다. 한 번 품절 상품으로 등록되면 품절 리스트에서 제거될 때 까지 추천 리스트에 해당 상품이 노출되지 않습니다.</p>
                        </td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><a href="/pages/viewpage.action?pageId=491681">3) 상품 품절 해제 (추천 재등록)</a></td>
                        <td class="confluenceTd">품절된 상품을 제거하는 API입니다. 품절 리스트에서 제거되면 추천 리스트에 해당 상품이 노출될 수 있습니다</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <p><span style="color: rgb(153,153,153);"> </span></p>
        </div>
    </div>
</template>