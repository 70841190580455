<template>
    <div class="page view">
        <h1>Client Library APIs</h1>
        <div id="main-content" class="wiki-content">
            <br>
            <p>RecoPick 클라이언트 라이브러리는 웹 서비스를 운영하는 고객들이 쉽고 빠르게 RecoPick 서비스를 사용할 수 있도록 돕는 Javascript 라이브러리입니다.</p>
            <p>RecoPick 서비스는 사용자가 상품 페이지를 방문했을 때의 로그를 수집하여 추천 계산에 활용합니다.&nbsp;</p>
            <p>클라이언트 라이브러리를 사용하면 RecoPick 서비스에서 바로 로그 수집이 가능하기 때문에, 고객사에서 서버 로그를 전송하는 등의 추가적인 부담이 전혀 없습니다.&nbsp;</p>
            <p>뿐만 아니라 RecoPick 클라이언트 라이브러리 중 Widget API를 사용하시면 쉽게 추천을 적용할 수도 있습니다.</p>
            <p>클라이언트 라이브러리는 기존 고객 사이트 이용자들이 불편해하는 일이 없도록 사이트 로딩속도에 가능한 한 영향을 주지 않게(비동기 방식으로 처리함) 세심하게 설계되었습니다.</p>
            <p><br></p>
            <p><strong>목차</strong></p>
            <p><strong style="line-height: 1.5;font-size: 20.0px;"></strong></p>
            <div class="toc-macro client-side-toc-macro  conf-macro output-block hidden-outline" data-headerelements="H1,H2,H3,H4,H5,H6,H7" data-hasbody="false" data-macro-name="toc">
                <ul style="">
                    <li><span class="toc-item-body" data-outline="1"><span class="toc-outline">1</span><a href="/display/docs/Client+Library+APIs#ClientLibraryAPIs-%EB%9D%BC%EC%9D%B4%EB%B8%8C%EB%9F%AC%EB%A6%AC%EB%A1%9C%EB%93%9C%ED%95%98%EA%B8%B0" class="toc-link">라이브러리 로드하기</a></span></li>
                    <li><span class="toc-item-body" data-outline="2"><span class="toc-outline">2</span><a href="/display/docs/Client+Library+APIs#ClientLibraryAPIs-LoggingAPI" class="toc-link">Logging API</a></span>
                        <ul style="">
                            <li><span class="toc-item-body" data-outline="2.1"><span class="toc-outline">2.1</span><a href="/display/docs/Client+Library+APIs#ClientLibraryAPIs-Example" class="toc-link">Example</a></span></li>
                        </ul>
                    </li>
                    <li><span class="toc-item-body" data-outline="3"><span class="toc-outline">3</span><a href="/display/docs/Client+Library+APIs#ClientLibraryAPIs-WidgetAPI" class="toc-link">Widget API</a></span>
                        <ul style="">
                            <li><span class="toc-item-body" data-outline="3.1"><span class="toc-outline">3.1</span><a href="/display/docs/Client+Library+APIs#ClientLibraryAPIs-Example.1" class="toc-link">Example</a></span></li>
                            <li><span class="toc-item-body" data-outline="3.2"><span class="toc-outline">3.2</span><a href="/display/docs/Client+Library+APIs#ClientLibraryAPIs-%EC%9C%84%EC%A0%AF%EC%84%A4%EC%A0%95" class="toc-link">위젯 설정</a></span></li>
                            <li><span class="toc-item-body" data-outline="3.3"><span class="toc-outline">3.3</span><a href="/display/docs/Client+Library+APIs#ClientLibraryAPIs-%ED%95%9C%ED%8E%98%EC%9D%B4%EC%A7%80%EC%97%90%EC%97%AC%EB%9F%AC%EC%9C%84%EC%A0%AF%EB%85%B8%EC%B6%9C%ED%95%98%EA%B8%B0" class="toc-link">한 페이지에 여러 위젯 노출하기</a></span></li>
                            <li><span class="toc-item-body" data-outline="3.4"><span class="toc-outline">3.4</span><a href="/display/docs/Client+Library+APIs#ClientLibraryAPIs-%EA%B0%9C%EC%9D%B8%ED%99%94%EC%B6%94%EC%B2%9C,%ED%86%B5%EA%B3%84%ED%98%95%EC%B6%94%EC%B2%9C%EB%93%B1%EC%B6%94%EC%B2%9C%EC%95%8C%EA%B3%A0%EB%A6%AC%EC%A6%98%EB%B3%84%EC%9C%84%EC%A0%AF%EB%85%B8%EC%B6%9C" class="toc-link">개인화 추천, 통계형 추천 등 추천 알고리즘별 위젯 노출</a></span>
                                <ul style="">
                                    <li><span class="toc-item-body" data-outline="3.4.1"><span class="toc-outline">3.4.1</span><a href="/display/docs/Client+Library+APIs#ClientLibraryAPIs-%ED%95%A8%EA%BB%98%EB%B3%B8%EC%83%81%ED%92%88(%EA%B8%B0%EB%B3%B8%EA%B0%92,%EC%83%9D%EB%9E%B5%EA%B0%80%EB%8A%A5)" class="toc-link">함께 본 상품 (기본값, 생략가능)</a></span></li>
                                    <li><span class="toc-item-body" data-outline="3.4.2"><span class="toc-outline">3.4.2</span><a href="/display/docs/Client+Library+APIs#ClientLibraryAPIs-%ED%95%A8%EA%BB%98%EA%B5%AC%EB%A7%A4%ED%95%9C%EC%83%81%ED%92%88" class="toc-link">함께 구매한 상품</a></span></li>
                                    <li><span class="toc-item-body" data-outline="3.4.3"><span class="toc-outline">3.4.3</span><a href="/display/docs/Client+Library+APIs#ClientLibraryAPIs-%EA%B0%9C%EC%9D%B8%ED%99%94%EC%B6%94%EC%B2%9C" class="toc-link">개인화 추천</a></span></li>
                                    <li><span class="toc-item-body" data-outline="3.4.4"><span class="toc-outline">3.4.4</span><a href="/display/docs/Client+Library+APIs#ClientLibraryAPIs-%ED%86%B5%EA%B3%84%ED%98%95%EC%B6%94%EC%B2%9C-%ED%81%B4%EB%A6%AD%EB%A5%A0" class="toc-link">통계형 추천 - 클릭률</a></span></li>
                                    <li><span class="toc-item-body" data-outline="3.4.5"><span class="toc-outline">3.4.5</span><a href="/display/docs/Client+Library+APIs#ClientLibraryAPIs-%ED%86%B5%EA%B3%84%ED%98%95%EC%B6%94%EC%B2%9C-%EA%B5%AC%EB%A7%A4%EC%A0%84%ED%99%98%EC%9C%A8" class="toc-link">통계형 추천 - 구매전환율</a></span></li>
                                    <li><span class="toc-item-body" data-outline="3.4.6"><span class="toc-outline">3.4.6</span><a href="/display/docs/Client+Library+APIs#ClientLibraryAPIs-%ED%86%B5%EA%B3%84%ED%98%95%EC%B6%94%EC%B2%9C-%EC%B6%94%EC%B2%9C%EC%9C%A0%EC%9E%85%EB%A5%A0" class="toc-link">통계형 추천 - 추천유입률</a></span></li>
                                    <li><span class="toc-item-body" data-outline="3.4.7"><span class="toc-outline">3.4.7</span><a href="/display/docs/Client+Library+APIs#ClientLibraryAPIs-%ED%86%B5%EA%B3%84%ED%98%95%EC%B6%94%EC%B2%9C-%EA%B0%80%EC%9E%A5%EB%A7%8E%EC%9D%B4%EB%B3%B8%EC%83%81%ED%92%88" class="toc-link">통계형 추천 - 가장 많이 본 상품</a></span></li>
                                </ul>
                            </li>
                            <li><span class="toc-item-body" data-outline="3.5"><span class="toc-outline">3.5</span><a href="/display/docs/Client+Library+APIs#ClientLibraryAPIs-%EC%B6%94%EC%B2%9C%EA%B2%B0%EA%B3%BC%EA%B0%80%EC%97%86%EC%9D%84%EB%95%8C%EB%8B%A4%EB%A5%B8%EC%B6%94%EC%B2%9C%EC%95%8C%EA%B3%A0%EB%A6%AC%EC%A6%98%EC%9D%84%EC%82%AC%EC%9A%A9%ED%95%98%EA%B8%B0" class="toc-link">추천 결과가 없을 때 다른 추천 알고리즘을 사용하기</a></span></li>
                        </ul>
                    </li>
                    <li><span class="toc-item-body" data-outline="4"><span class="toc-outline">4</span><a href="/display/docs/Client+Library+APIs#ClientLibraryAPIs-ABtestAPI" class="toc-link">AB test API</a></span></li>
                    <li><span class="toc-item-body" data-outline="5"><span class="toc-outline">5</span><a href="/display/docs/Client+Library+APIs#ClientLibraryAPIs-A/B%ED%85%8C%EC%8A%A4%ED%8A%B8%EC%A0%81%EC%9A%A9" class="toc-link">A/B 테스트 적용</a></span></li>
                    <li><span class="toc-item-body" data-outline="6"><span class="toc-outline">6</span><a href="/display/docs/Client+Library+APIs#ClientLibraryAPIs-A/B%ED%85%8C%EC%8A%A4%ED%8A%B8%EA%B2%B0%EA%B3%BC%ED%99%95%EC%9D%B8" class="toc-link">A/B 테스트 결과 확인</a></span></li>
                    <li><span class="toc-item-body" data-outline="7"><span class="toc-outline">7</span><a href="/display/docs/Client+Library+APIs#ClientLibraryAPIs-UserIdentificationAPI" class="toc-link">User Identification API</a></span>
                        <ul style="">
                            <li><span class="toc-item-body" data-outline="7.1"><span class="toc-outline">7.1</span><a href="/display/docs/Client+Library+APIs#ClientLibraryAPIs-setUID" class="toc-link">setUID</a></span></li>
                            <li><span class="toc-item-body" data-outline="7.2"><span class="toc-outline">7.2</span><a href="/display/docs/Client+Library+APIs#ClientLibraryAPIs-fetchUID" class="toc-link">fetchUID</a></span></li>
                            <li><span class="toc-item-body" data-outline="7.3"><span class="toc-outline">7.3</span><a href="/display/docs/Client+Library+APIs#ClientLibraryAPIs-setMID" class="toc-link">setMID</a></span></li>
                            <li><span class="toc-item-body" data-outline="7.4"><span class="toc-outline">7.4</span><a href="/display/docs/Client+Library+APIs#ClientLibraryAPIs-setUserInfo" class="toc-link">setUserInfo</a></span></li>
                        </ul>
                    </li>
                    <li><span class="toc-item-body" data-outline="8"><span class="toc-outline">8</span><a href="/display/docs/Client+Library+APIs#ClientLibraryAPIs-DeviceIdentificationAPI" class="toc-link">Device&nbsp;Identification API</a></span>
                        <ul style="">
                            <li><span class="toc-item-body" data-outline="8.1"><span class="toc-outline">8.1</span><a href="/display/docs/Client+Library+APIs#ClientLibraryAPIs-setGAID" class="toc-link">setGAID</a></span></li>
                            <li><span class="toc-item-body" data-outline="8.2"><span class="toc-outline">8.2</span><a href="/display/docs/Client+Library+APIs#ClientLibraryAPIs-setIDFA" class="toc-link">setIDFA</a></span></li>
                        </ul>
                    </li>
                    <li><span class="toc-item-body" data-outline="9"><span class="toc-outline">9</span><a href="/display/docs/Client+Library+APIs#ClientLibraryAPIs-AdvancedUsageExample" class="toc-link">Advanced Usage Example</a></span></li>
                </ul>
            </div><strong style="line-height: 1.5;font-size: 20.0px;"><br></strong>
            <p></p>
            <h2 id="ClientLibraryAPIs-라이브러리로드하기"><strong style="line-height: 1.5;font-size: 20.0px;">라이브러리 로드하기</strong></h2>
            <div>
                <p><br></p>
            </div>
            <div class="confluence-information-macro confluence-information-macro-tip conf-macro output-block" data-hasbody="true" data-macro-name="tip"><span class="aui-icon aui-icon-small aui-iconfont-approve confluence-information-macro-icon"> </span>
                <div class="confluence-information-macro-body">
                    <p>RecoPick 클라이언트 라이브러리를 사용하기 위해서는 먼저 아래 코드를 HTML 페이지의 <span style="color: rgb(255,0,0);">&lt;/head&gt; 태그 앞에 삽입</span>해야 합니다. 아래 코드에 recoPick 함수를 추가하셔서 API들을 활용할 수 있습니다.</p>
                    <p>각 API에 상세한 설명은 아래를 참고해주세요.</p>
                </div>
            </div>
            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                <div class="codeContent panelContent pdl">
                    <div>
                        <div id="highlighter_63615" class="syntaxhighlighter sh-confluence  actionscript3">
                            <div class="toolbar"><span><a href="/display/docs/Client+Library+APIs#" class="toolbar_item command_help help">?</a></span></div>
                            <table border="0" cellpadding="0" cellspacing="0">
                                <tbody>
                                <tr>
                                    <td class="gutter">
                                        <div class="line number1 index0 alt2">1</div>
                                        <div class="line number2 index1 alt1">2</div>
                                        <div class="line number3 index2 alt2">3</div>
                                        <div class="line number4 index3 alt1">4</div>
                                        <div class="line number5 index4 alt2">5</div>
                                        <div class="line number6 index5 alt1">6</div>
                                        <div class="line number7 index6 alt2">7</div>
                                        <div class="line number8 index7 alt1">8</div>
                                        <div class="line number9 index8 alt2">9</div>
                                        <div class="line number10 index9 alt1">10</div>
                                        <div class="line number11 index10 alt2">11</div>
                                        <div class="line number12 index11 alt1">12</div>
                                    </td>
                                    <td class="code">
                                        <div class="container" title="Hint: double-click to select code">
                                            <div class="line number1 index0 alt2"><code class="actionscript3 plain">&lt;script type=</code><code class="actionscript3 string">"text/javascript"</code><code class="actionscript3 plain">&gt;</code></div>
                                            <div class="line number2 index1 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;</code><code class="actionscript3 plain">(</code><code class="actionscript3 color3">function</code><code class="actionscript3 plain">(w,d,n,s,e,o) {</code></div>
                                            <div class="line number3 index2 alt2"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">w[n]=w[n]||</code><code class="actionscript3 color3">function</code><code class="actionscript3 plain">(){(w[n].q=w[n].q||[]).push(arguments)};</code></div>
                                            <div class="line number4 index3 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">e=d.createElement(s);e.async=</code><code class="actionscript3 value">1</code><code class="actionscript3 plain">;e.charset=</code><code class="actionscript3 string">'utf-8'</code><code class="actionscript3 plain">;e.src=</code><code class="actionscript3 string">'//static.recopick.com/dist/production.min.js'</code><code class="actionscript3 plain">;</code></div>
                                            <div class="line number5 index4 alt2"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">o=d.getElementsByTagName(s)[</code><code class="actionscript3 value">0</code><code class="actionscript3 plain">];o.parentNode.insertBefore(e,o);</code></div>
                                            <div class="line number6 index5 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">})(window, document, </code><code class="actionscript3 string">'recoPick'</code><code class="actionscript3 plain">, </code><code class="actionscript3 string">'script'</code><code class="actionscript3 plain">);</code></div>
                                            <div class="line number7 index6 alt2"><code class="actionscript3 spaces">&nbsp;</code>&nbsp;</div>
                                            <div class="line number8 index7 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 comments">// 이 코드는 반드시 필요한 부분이며 'SERVICE_NAME'은 RecoPick 가입 시 지정하신 사이트명으로 수정해주셔야 합니다. 예: example.com</code></div>
                                            <div class="line number9 index8 alt2"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">recoPick(</code><code class="actionscript3 string">'service'</code><code class="actionscript3 plain">, </code><code class="actionscript3 string">'SERVICE_NAME'</code><code class="actionscript3 plain">);</code></div>
                                            <div class="line number10 index9 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 comments">// 여기에 필요한 함수를 추가하시면 됩니다.</code></div>
                                            <div class="line number11 index10 alt2"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 comments">// 예: recoPick('sendLog', 'view', '123');&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </code></div>
                                            <div class="line number12 index11 alt1"><code class="actionscript3 plain">&lt;/script&gt;</code></div>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <p><br></p>
            <p>에서 'SERVICE_NAME'은 사이트주소에 맞게 변경해주셔야 합니다.</p>
            <ul>
                <li>예를 들어, 쇼핑몰 주소가&nbsp;<a href="http://www.example.com/" class="external-link">www.example.com</a>&nbsp;일 경우 "www"를 제외한<span style="color: rgb(0,0,0);"><strong>&nbsp;recoPick('service', 'example.com')</strong></span>로 변경해주세요.</li>
                <li>이때, recoPick은 대소문자 구별을 꼭 해주셔야 합니다. (recopick이라고 쓰시면 안됩니다.)</li>
                <li>사이트 주소가 수정이 되어야, 데이터를 수집할 수 있습니다.&nbsp;</li>
            </ul>
            <h2 id="ClientLibraryAPIs-LoggingAPI"><strong>Logging API</strong></h2>
            <div>
                <p>Logging API는 고객사의 사이트 방문객이 사이트에 접속하여 상품을 클릭하여 상세정보를 보았는지, 관심상품을 장바구니에 담았는지, 구매를 했는지, 혹은 아무런 행위도 하지 않고 메인페이지만 보고 사이트를 떠났는지 등의 활동들을 분석하여 로그를 수집하는 스크립트입니다.</p>
                <p>sendLog 함수를 통해 사용자 로그를 RecoPick 서비스에 전송할 수 있습니다.&nbsp;사용법은 아래의 스크립트처럼 위의 기본 스크립트에 해당 API를 추가하시면 됩니다.</p>
                <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                    <div class="codeContent panelContent pdl">
                        <div>
                            <div id="highlighter_793102" class="syntaxhighlighter sh-confluence  actionscript3">
                                <div class="toolbar"><span><a href="/display/docs/Client+Library+APIs#" class="toolbar_item command_help help">?</a></span></div>
                                <table border="0" cellpadding="0" cellspacing="0">
                                    <tbody>
                                    <tr>
                                        <td class="gutter">
                                            <div class="line number1 index0 alt2">1</div>
                                            <div class="line number2 index1 alt1">2</div>
                                            <div class="line number3 index2 alt2">3</div>
                                            <div class="line number4 index3 alt1">4</div>
                                            <div class="line number5 index4 alt2">5</div>
                                            <div class="line number6 index5 alt1">6</div>
                                            <div class="line number7 index6 alt2">7</div>
                                            <div class="line number8 index7 alt1">8</div>
                                            <div class="line number9 index8 alt2">9</div>
                                        </td>
                                        <td class="code">
                                            <div class="container" title="Hint: double-click to select code">
                                                <div class="line number1 index0 alt2"><code class="actionscript3 plain">&lt;script type=</code><code class="actionscript3 string">"text/javascript"</code><code class="actionscript3 plain">&gt;</code></div>
                                                <div class="line number2 index1 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;</code><code class="actionscript3 plain">(</code><code class="actionscript3 color3">function</code><code class="actionscript3 plain">(w,d,n,s,e,o) {</code></div>
                                                <div class="line number3 index2 alt2"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">w[n]=w[n]||</code><code class="actionscript3 color3">function</code><code class="actionscript3 plain">(){(w[n].q=w[n].q||[]).push(arguments)};</code></div>
                                                <div class="line number4 index3 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">e=d.createElement(s);e.async=</code><code class="actionscript3 value">1</code><code class="actionscript3 plain">;e.charset=</code><code class="actionscript3 string">'utf-8'</code><code class="actionscript3 plain">;e.src=</code><code class="actionscript3 string">'//static.recopick.com/dist/production.min.js'</code><code class="actionscript3 plain">;</code></div>
                                                <div class="line number5 index4 alt2"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">o=d.getElementsByTagName(s)[</code><code class="actionscript3 value">0</code><code class="actionscript3 plain">];o.parentNode.insertBefore(e,o);</code></div>
                                                <div class="line number6 index5 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">})(window, document, </code><code class="actionscript3 string">'recoPick'</code><code class="actionscript3 plain">, </code><code class="actionscript3 string">'script'</code><code class="actionscript3 plain">);</code></div>
                                                <div class="line number7 index6 alt2"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">recoPick(</code><code class="actionscript3 string">'service'</code><code class="actionscript3 plain">, </code><code class="actionscript3 string">'SERVICE_NAME'</code><code class="actionscript3 plain">);&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </code><code class="actionscript3 comments">// 반드시 필요한 부분이며,'SERVICE_NAME'은 수정해주셔야 합니다.</code></div>
                                                <div class="line number8 index7 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">recoPick(</code><code class="actionscript3 string">'sendLog'</code><code class="actionscript3 plain">, ACTION_TYPE, ITEM_ID);&nbsp;&nbsp; </code><code class="actionscript3 comments">// 반드시 필요한 부분이며, ACTION_TYPE, ITEM_ID은 아래의 설명에 따라 상황에 맞게 수정해주셔야 합니다.</code></div>
                                                <div class="line number9 index8 alt2"><code class="actionscript3 plain">&lt;/script&gt;</code></div>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="table-wrap">
                    <table class="wrapped confluenceTable">
                        <colgroup>
                            <col>
                            <col>
                            <col>
                        </colgroup>
                        <tbody>
                        <tr>
                            <th rowspan="7" class="confluenceTh">
                                <p>ACTION_TYPE</p>
                                <p><sup><span style="color: rgb(153,153,153);">(Required)</span></sup></p>
                            </th>
                            <td colspan="2" class="confluenceTd">이 페이지의 용도를 입력해주시면 됩니다. 다음 ACTION_TYPE들이 지원됩니다.</td>
                        </tr>
                        <tr>
                            <td colspan="1" class="confluenceTd"><strong>visit</strong></td>
                            <td colspan="1" class="confluenceTd">view/basket/order/search 이외의 페이지인 경우 사용하시면 됩니다.</td>
                        </tr>
                        <tr>
                            <td colspan="1" class="confluenceTd"><strong>view</strong></td>
                            <td class="confluenceTd">상품 상세정보를 보는 등의 단순 조회의 경우 view를 사용하시면 됩니다.</td>
                        </tr>
                        <tr>
                            <td colspan="1" class="confluenceTd"><strong>basket</strong></td>
                            <td class="confluenceTd">장바구니 페이지에 해당합니다. 이 값은 추천계산시 view보다 가치가 높습니다. 단, 장바구니 기능이 없는 경우 사용하지 않으셔도 됩니다.</td>
                        </tr>
                        <tr>
                            <td colspan="1" class="confluenceTd"><strong>order</strong></td>
                            <td colspan="1" class="confluenceTd"><span>구매 완료 페이지에 해당합니다. 추천계산시 view, basket보다 가치가 높습니다. 구매 로그를 보내주셔야 조금 더 정확한 추천 계산 및 성과 평가가 이루어지게 됩니다.</span></td>
                        </tr>
                        <tr>
                            <td colspan="1" class="confluenceTd"><strong>search</strong></td>
                            <td class="confluenceTd">검색 페이지에서 사용자가 검색어를 입력한 경우, 사용하시면 됩니다. 이 데이터를 보내주시면, 개인화 추천의 품질이 더 올라가게 되며, 향후 검색어에 관련된 추천 서비스를 제공 받을 수 있습니다.</td>
                        </tr>
                        <tr>
                          <td colspan="1" class="confluenceTd"><strong>like</strong></td>
                          <td class="confluenceTd">사용자가 특정 상품에 대해 "좋아요", "즐겨찾기", "찜" 등의 행동을 한 경우 전송해주시면 됩니다. </td>
                        </tr>
                        <tr>
                            <th class="confluenceTh">
                                <p>ITEM_ID</p>
                            </th>
                            <td colspan="2" class="confluenceTd">
                                <p>ACTION_TYPE이 view/basket/order인 경우 반드시 지정되어야 합니다.</p>
                                <p>ACTION_TYPE이 search인 경우 검색어에 해당하는 값이 반드시 지정되어야 합니다.</p>
                                <p>0개 또는 1개 이상의 ITEM_ID가 허용됩니다. 자체적으로 사용하시는 ITEM_ID를 적어주시면 됩니다. ITEM_ID는 문자열, 숫자, Object 모두 허용합니다.</p>
                                <p>item_id는 URL 패턴을 통해 상품 상세 페이지에 접근할 수 있는 값으로 지정해주셔야 합니다.</p>
                                <ul>
                                    <li>예1: 상품URL이&nbsp;<a href="http://recopick.com/product/123," class="external-link">http://recopick.com/product/123</a>,<a href="http://recopick.com/product/124" class="external-link">http://recopick.com/product/124</a>&nbsp;와 같이 구성되는 경우 item_id는 각각 "123", "124"로 지정합니다.</li>
                                    <li>예2: 상품URL이&nbsp;<a href="http://recopick.com/accessory/123," class="external-link">http://recopick.com/accessory/123</a>&nbsp;,&nbsp;&nbsp;<a href="http://recopick.com/mobile/124" class="external-link">http://recopick.com/mobile/124</a>&nbsp;와 같이 구성되는 경우 item_id는 "accessory/123", "mobile/124"와 같이 지정합니다.</li>
                                    <li>예3: 상품URL이&nbsp;<a href="http://recopick.com/product/view.php?product_id=123" class="external-link">http://recopick.com/product/view.php?product_id=123</a>&nbsp;,&nbsp;<a href="http://recopick.com/product/view.php?product_id=124" class="external-link">http://recopick.com/product/view.php?product_id=124</a>&nbsp;와 같이 구성되는 경우&nbsp;item_id는 각각 "123", "124"로 지정합니다.</li>
                                    <li>예4: 상품URL이&nbsp;<a href="http://recopick.com/product/view.php?category=accessory&amp;product_id=123" class="external-link">http://recopick.com/product/view.php?category=accessory&amp;product_id=123</a>,&nbsp;<a href="http://recopick.com/product/view.php?category=mobile&amp;product_id=124" class="external-link">http://recopick.com/product/view.php?category=mobile&amp;product_id=124</a>&nbsp;와 같이 구성되는 경우&nbsp;item_id는 각각 "category=accessory&amp;product_id=123", "category=mobile&amp;product_id=124"로 지정합니다.<br>만약, category 파라미터가 없어도 상품 상세 페이지로 이동이 가능한 경우 예3과 같이 지정하시면 됩니다.</li>
                                </ul>
                                <p>아래의 Example을 참고해주세요.&nbsp;</p>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <h3 id="ClientLibraryAPIs-Example"><span class="mw-headline">Example</span></h3>
                <p><span class="mw-headline"><br></span></p>
                <div>
                    <ul>
                        <li>
                            <p>예를 들어, 사이트 방문객이 133번 상품 상세 보기 페이지를 방문 했을 경우 :&nbsp;</p>
                            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                                <div class="codeContent panelContent pdl">
                                    <div>
                                        <div id="highlighter_743592" class="syntaxhighlighter sh-confluence nogutter  applescript">
                                            <div class="toolbar"><span><a href="/display/docs/Client+Library+APIs#" class="toolbar_item command_help help">?</a></span></div>
                                            <table border="0" cellpadding="0" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="code">
                                                        <div class="container" title="Hint: double-click to select code">
                                                            <div class="line number1 index0 alt2"><code class="applescript plain">recoPick</code><code class="applescript color1">(</code><code class="applescript plain">'sendLog'</code><code class="applescript color1">,</code> <code class="applescript plain">'</code><code class="applescript color3">view</code><code class="applescript plain">'</code><code class="applescript color1">,</code> <code class="applescript plain">'</code><code class="applescript color1">133</code><code class="applescript plain">'</code><code class="applescript color1">)</code><code class="applescript plain">;</code></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p></p>
                        </li>
                        <li>
                            <p>사이트 방문객이 133번 상품을 구매할 때 :&nbsp;</p>
                            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                                <div class="codeContent panelContent pdl">
                                    <div>
                                        <div id="highlighter_410516" class="syntaxhighlighter sh-eclipse nogutter  applescript">
                                            <div class="toolbar"><span><a href="/display/docs/Client+Library+APIs#" class="toolbar_item command_help help">?</a></span></div>
                                            <table border="0" cellpadding="0" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="code">
                                                        <div class="container" title="Hint: double-click to select code">
                                                            <div class="line number1 index0 alt2"><code class="applescript plain">recoPick</code><code class="applescript color1">(</code><code class="applescript plain">'sendLog'</code><code class="applescript color1">,</code> <code class="applescript plain">'order'</code><code class="applescript color1">,</code> <code class="applescript plain">'</code><code class="applescript color1">133</code><code class="applescript plain">'</code><code class="applescript color1">)</code><code class="applescript plain">;</code></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p></p>
                        </li>
                        <li>
                            <p>사이트 방문객이 133번 상품을 1개, 345번 상품을 2개 장바구니에 담았을 때:</p>
                            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                                <div class="codeContent panelContent pdl">
                                    <div>
                                        <div id="highlighter_580090" class="syntaxhighlighter sh-eclipse nogutter  applescript">
                                            <div class="toolbar"><span><a href="/display/docs/Client+Library+APIs#" class="toolbar_item command_help help">?</a></span></div>
                                            <table border="0" cellpadding="0" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="code">
                                                        <div class="container" title="Hint: double-click to select code">
                                                            <div class="line number1 index0 alt2"><code class="applescript plain">recoPick</code><code class="applescript color1">(</code><code class="applescript plain">'sendLog'</code><code class="applescript color1">,</code> <code class="applescript plain">'basket'</code><code class="applescript color1">,</code> <code class="applescript color1">{</code><code class="applescript color3">id</code><code class="applescript color1">:</code><code class="applescript plain">'</code><code class="applescript color1">133</code><code class="applescript plain">'</code><code class="applescript color1">,</code> <code class="applescript plain">count</code><code class="applescript color1">:</code><code class="applescript color1">1</code><code class="applescript color1">}</code><code class="applescript color1">,</code> <code class="applescript color1">{</code><code class="applescript color3">id</code><code class="applescript color1">:</code><code class="applescript plain">'</code><code class="applescript color1">345</code><code class="applescript plain">'</code><code class="applescript color1">,</code> <code class="applescript plain">count</code><code class="applescript color1">:</code><code class="applescript color1">2</code><code class="applescript color1">}</code><code class="applescript color1">)</code><code class="applescript plain">;</code></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p></p>
                        </li>
                        <li>
                            <p>사이트 방문객이 133번 상품을 장바구니에 담았을 때 :</p>
                            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                                <div class="codeContent panelContent pdl">
                                    <div>
                                        <div id="highlighter_485493" class="syntaxhighlighter sh-eclipse nogutter  applescript">
                                            <div class="toolbar"><span><a href="/display/docs/Client+Library+APIs#" class="toolbar_item command_help help">?</a></span></div>
                                            <table border="0" cellpadding="0" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="code">
                                                        <div class="container" title="Hint: double-click to select code">
                                                            <div class="line number1 index0 alt2"><code class="applescript plain">recoPick</code><code class="applescript color1">(</code><code class="applescript plain">'sendLog'</code><code class="applescript color1">,</code> <code class="applescript plain">'basket'</code><code class="applescript color1">,</code> <code class="applescript plain">'</code><code class="applescript color1">133</code><code class="applescript plain">'</code><code class="applescript color1">)</code><code class="applescript plain">;</code></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p></p>
                        </li>
                        <li>
                            <p>사이트 방문객이 133번 상품을 1개, 345번 상품을 2개 구매할 때:&nbsp;</p>
                            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                                <div class="codeContent panelContent pdl">
                                    <div>
                                        <div id="highlighter_449580" class="syntaxhighlighter sh-eclipse nogutter  applescript">
                                            <div class="toolbar"><span><a href="/display/docs/Client+Library+APIs#" class="toolbar_item command_help help">?</a></span></div>
                                            <table border="0" cellpadding="0" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="code">
                                                        <div class="container" title="Hint: double-click to select code">
                                                            <div class="line number1 index0 alt2"><code class="applescript plain">recoPick</code><code class="applescript color1">(</code><code class="applescript plain">'sendLog'</code><code class="applescript color1">,</code> <code class="applescript plain">'basket'</code><code class="applescript color1">,</code> <code class="applescript color1">{</code><code class="applescript color3">id</code><code class="applescript color1">:</code><code class="applescript plain">'</code><code class="applescript color1">133</code><code class="applescript plain">'</code><code class="applescript color1">,</code> <code class="applescript plain">count</code><code class="applescript color1">:</code><code class="applescript color1">1</code><code class="applescript color1">,</code> <code class="applescript plain">total_sales</code><code class="applescript color1">:</code> <code class="applescript color1">1000</code><code class="applescript color1">}</code><code class="applescript color1">,</code> <code class="applescript color1">{</code><code class="applescript color3">id</code><code class="applescript color1">:</code><code class="applescript plain">'</code><code class="applescript color1">345</code><code class="applescript plain">'</code><code class="applescript color1">,</code> <code class="applescript plain">count</code><code class="applescript color1">:</code><code class="applescript color1">2</code><code class="applescript color1">,</code> <code class="applescript plain">total_sales</code><code class="applescript color1">:</code> <code class="applescript color1">4000</code><code class="applescript color1">}</code><code class="applescript color1">)</code><code class="applescript plain">;</code></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p></p>
                        </li>
                        <li>
                            <p>구매금액을 로그로 전송해주시면 추천 경유 매출 등의 통계를 보다 정확하게 제공해드립니다.<br>사이트 방문객이 1000원짜리 133번 상품을 1개, 2000원짜리 345번 상품을 2개 구매할 때: (total_sales는 구매한 상품의 가격 X 갯수에 해당하는 금액을 입력해주세요.)&nbsp;</p>
                            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                                <div class="codeContent panelContent pdl">
                                    <div>
                                        <div id="highlighter_773064" class="syntaxhighlighter sh-eclipse nogutter  applescript">
                                            <div class="toolbar"><span><a href="/display/docs/Client+Library+APIs#" class="toolbar_item command_help help">?</a></span></div>
                                            <table border="0" cellpadding="0" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="code">
                                                        <div class="container" title="Hint: double-click to select code">
                                                            <div class="line number1 index0 alt2"><code class="applescript plain">recoPick</code><code class="applescript color1">(</code><code class="applescript plain">'sendLog'</code><code class="applescript color1">,</code> <code class="applescript plain">'basket'</code><code class="applescript color1">,</code> <code class="applescript color1">{</code><code class="applescript color3">id</code><code class="applescript color1">:</code><code class="applescript plain">'</code><code class="applescript color1">133</code><code class="applescript plain">'</code><code class="applescript color1">,</code> <code class="applescript plain">count</code><code class="applescript color1">:</code><code class="applescript color1">1</code><code class="applescript color1">,</code> <code class="applescript plain">total_sales</code><code class="applescript color1">:</code> <code class="applescript color1">1000</code><code class="applescript color1">}</code><code class="applescript color1">,</code> <code class="applescript color1">{</code><code class="applescript color3">id</code><code class="applescript color1">:</code><code class="applescript plain">'</code><code class="applescript color1">345</code><code class="applescript plain">'</code><code class="applescript color1">,</code> <code class="applescript plain">count</code><code class="applescript color1">:</code><code class="applescript color1">2</code><code class="applescript color1">,</code> <code class="applescript plain">total_sales</code><code class="applescript color1">:</code> <code class="applescript color1">4000</code><code class="applescript color1">}</code><code class="applescript color1">)</code><code class="applescript plain">;</code></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p></p>
                        </li>
                        <li>
                            <p>메인페이지나 상품 리스트페이지, 검색페이지와 같이 상품 ID가 존재하지 않는 페이지에 방문했을 경우 :</p>
                            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                                <div class="codeContent panelContent pdl">
                                    <div>
                                        <div id="highlighter_278585" class="syntaxhighlighter sh-eclipse nogutter  applescript">
                                            <div class="toolbar"><span><a href="/display/docs/Client+Library+APIs#" class="toolbar_item command_help help">?</a></span></div>
                                            <table border="0" cellpadding="0" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="code">
                                                        <div class="container" title="Hint: double-click to select code">
                                                            <div class="line number1 index0 alt2"><code class="applescript plain">recoPick</code><code class="applescript color1">(</code><code class="applescript plain">'sendLog'</code><code class="applescript color1">,</code> <code class="applescript plain">'visit'</code><code class="applescript color1">)</code><code class="applescript plain">;</code></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p></p>
                        </li>
                        <li>
                            <p>여러 개의 상품을 한꺼 번에 넣을 때 (주문 페이지의 묶음배송같이 여러개의 상품에 대해 기술해야 할 때 효과적입니다.) :</p>
                            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                                <div class="codeContent panelContent pdl">
                                    <div>
                                        <div id="highlighter_353896" class="syntaxhighlighter sh-confluence nogutter  applescript">
                                            <div class="toolbar"><span><a href="/display/docs/Client+Library+APIs#" class="toolbar_item command_help help">?</a></span></div>
                                            <table border="0" cellpadding="0" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="code">
                                                        <div class="container" title="Hint: double-click to select code">
                                                            <div class="line number1 index0 alt2"><code class="applescript plain">recoPick</code><code class="applescript color1">(</code><code class="applescript plain">'sendLog'</code><code class="applescript color1">,</code> <code class="applescript plain">'basket'</code><code class="applescript color1">,</code> <code class="applescript plain">'</code><code class="applescript color1">123</code><code class="applescript plain">'</code><code class="applescript color1">,</code> <code class="applescript color1">256</code><code class="applescript color1">,</code> <code class="applescript color1">{</code><code class="applescript color3">id</code><code class="applescript color1">:</code> <code class="applescript plain">'</code><code class="applescript color1">133</code><code class="applescript plain">'</code><code class="applescript color1">,</code> <code class="applescript plain">category</code><code class="applescript color1">:</code> <code class="applescript plain">'fantasy' </code><code class="applescript color1">}</code><code class="applescript color1">)</code><code class="applescript plain">;</code></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p></p>
                        </li>
                        <li>
                            <p>상품 ID뿐만 아니라 가격, 카테고리 등의 기타 정보를 추가도 가능합니다.&nbsp;(단, JavaScript object 형식을 사용할 때에는 id가 꼭 들어있어야 합니다.)</p>
                            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                                <div class="codeContent panelContent pdl">
                                    <div>
                                        <div id="highlighter_473035" class="syntaxhighlighter sh-eclipse nogutter  applescript">
                                            <div class="toolbar"><span><a href="/display/docs/Client+Library+APIs#" class="toolbar_item command_help help">?</a></span></div>
                                            <table border="0" cellpadding="0" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="code">
                                                        <div class="container" title="Hint: double-click to select code">
                                                            <div class="line number1 index0 alt2"><code class="applescript plain">recoPick</code><code class="applescript color1">(</code><code class="applescript plain">'sendLog'</code><code class="applescript color1">,</code> <code class="applescript plain">'</code><code class="applescript color3">view</code><code class="applescript plain">'</code><code class="applescript color1">,</code> <code class="applescript color1">{</code><code class="applescript color3">id</code><code class="applescript color1">:</code> <code class="applescript plain">'</code><code class="applescript color1">133</code><code class="applescript plain">'</code><code class="applescript color1">,</code> <code class="applescript plain">price</code><code class="applescript color1">:</code> <code class="applescript plain">'</code><code class="applescript color1">15000</code><code class="applescript plain">' </code><code class="applescript color1">}</code><code class="applescript color1">)</code><code class="applescript plain">;</code></div>
                                                            <div class="line number2 index1 alt1"><code class="applescript plain">recoPick</code><code class="applescript color1">(</code><code class="applescript plain">'sendLog'</code><code class="applescript color1">,</code> <code class="applescript plain">'</code><code class="applescript color3">view</code><code class="applescript plain">'</code><code class="applescript color1">,</code> <code class="applescript color1">{</code><code class="applescript color3">id</code><code class="applescript color1">:</code> <code class="applescript plain">'</code><code class="applescript color1">133</code><code class="applescript plain">'</code><code class="applescript color1">,</code> <code class="applescript plain">category</code><code class="applescript color1">:</code> <code class="applescript plain">'ticket' </code><code class="applescript color1">}</code><code class="applescript color1">)</code><code class="applescript plain">;</code></div>
                                                            <div class="line number3 index2 alt2"><code class="applescript plain">recoPick</code><code class="applescript color1">(</code><code class="applescript plain">'sendLog'</code><code class="applescript color1">,</code> <code class="applescript plain">'</code><code class="applescript color3">view</code><code class="applescript plain">'</code><code class="applescript color1">,</code> <code class="applescript color1">{</code><code class="applescript color3">id</code><code class="applescript color1">:</code> <code class="applescript plain">'</code><code class="applescript color1">133</code><code class="applescript plain">'</code><code class="applescript color1">,</code> <code class="applescript plain">price</code><code class="applescript color1">:</code> <code class="applescript plain">'</code><code class="applescript color1">15000</code><code class="applescript plain">'</code><code class="applescript color1">,</code> <code class="applescript plain">category</code><code class="applescript color1">:</code><code class="applescript plain">'ticket' </code><code class="applescript color1">}</code><code class="applescript color1">)</code><code class="applescript plain">;</code></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p></p>
                        </li>
                        <li>
                            <p>item_id는 문자열, 숫자 혹은 문자와 숫자 조합이 될 수 있습니다.&nbsp;</p>
                            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                                <div class="codeContent panelContent pdl">
                                    <div>
                                        <div id="highlighter_231924" class="syntaxhighlighter sh-eclipse nogutter  applescript">
                                            <div class="toolbar"><span><a href="/display/docs/Client+Library+APIs#" class="toolbar_item command_help help">?</a></span></div>
                                            <table border="0" cellpadding="0" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="code">
                                                        <div class="container" title="Hint: double-click to select code">
                                                            <div class="line number1 index0 alt2"><code class="applescript plain">recoPick</code><code class="applescript color1">(</code><code class="applescript plain">'sendLog'</code><code class="applescript color1">,</code> <code class="applescript plain">'order'</code><code class="applescript color1">,</code> <code class="applescript plain">'</code><code class="applescript color1">123</code><code class="applescript plain">'</code><code class="applescript color1">)</code><code class="applescript plain">;</code></div>
                                                            <div class="line number2 index1 alt1"><code class="applescript plain">recoPick</code><code class="applescript color1">(</code><code class="applescript plain">'sendLog'</code><code class="applescript color1">,</code> <code class="applescript plain">'order'</code><code class="applescript color1">,</code> <code class="applescript color1">123</code><code class="applescript color1">)</code><code class="applescript plain">;</code></div>
                                                            <div class="line number3 index2 alt2"><code class="applescript plain">recoPick</code><code class="applescript color1">(</code><code class="applescript plain">'sendLog'</code><code class="applescript color1">,</code> <code class="applescript plain">'order'</code><code class="applescript color1">,</code> <code class="applescript plain">'</code><code class="applescript color1">123</code><code class="applescript plain">ITEM'</code><code class="applescript color1">)</code><code class="applescript plain">;</code></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p></p>
                        </li>
                        <li>
                            <p>count의 기본 값은 1입니다. 아래는 모두 같은 의미를 지닌 구문입니다.</p>
                            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                                <div class="codeContent panelContent pdl">
                                    <div>
                                        <div id="highlighter_838748" class="syntaxhighlighter sh-eclipse nogutter  applescript">
                                            <div class="toolbar"><span><a href="/display/docs/Client+Library+APIs#" class="toolbar_item command_help help">?</a></span></div>
                                            <table border="0" cellpadding="0" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="code">
                                                        <div class="container" title="Hint: double-click to select code">
                                                            <div class="line number1 index0 alt2"><code class="applescript plain">recoPick</code><code class="applescript color1">(</code><code class="applescript plain">'sendLog'</code><code class="applescript color1">,</code> <code class="applescript plain">'order'</code><code class="applescript color1">,</code> <code class="applescript plain">'</code><code class="applescript color1">123</code><code class="applescript plain">'</code><code class="applescript color1">)</code><code class="applescript plain">;</code></div>
                                                            <div class="line number2 index1 alt1"><code class="applescript plain">recoPick</code><code class="applescript color1">(</code><code class="applescript plain">'sendLog'</code><code class="applescript color1">,</code> <code class="applescript plain">'order'</code><code class="applescript color1">,</code> <code class="applescript color1">{</code><code class="applescript color3">id</code><code class="applescript color1">:</code><code class="applescript plain">'</code><code class="applescript color1">123</code><code class="applescript plain">'</code><code class="applescript color1">}</code><code class="applescript color1">)</code><code class="applescript plain">;</code></div>
                                                            <div class="line number3 index2 alt2"><code class="applescript plain">recoPick</code><code class="applescript color1">(</code><code class="applescript plain">'sendLog'</code><code class="applescript color1">,</code> <code class="applescript plain">'order'</code><code class="applescript color1">,</code> <code class="applescript color1">{</code><code class="applescript color3">id</code><code class="applescript color1">:</code> <code class="applescript plain">'</code><code class="applescript color1">133</code><code class="applescript plain">'</code><code class="applescript color1">,</code> <code class="applescript plain">count</code><code class="applescript color1">:</code> <code class="applescript color1">1</code><code class="applescript color1">}</code><code class="applescript color1">)</code><code class="applescript plain">;</code></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p></p>
                        </li>
                        <li>
                            <p>사이트 방문객이 "미니스커트"를 검색했을 때</p>
                            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                                <div class="codeContent panelContent pdl">
                                    <div>
                                        <div id="highlighter_910520" class="syntaxhighlighter sh-eclipse nogutter  applescript">
                                            <div class="toolbar"><span><a href="/display/docs/Client+Library+APIs#" class="toolbar_item command_help help">?</a></span></div>
                                            <table border="0" cellpadding="0" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="code">
                                                        <div class="container" title="Hint: double-click to select code">
                                                            <div class="line number1 index0 alt2"><code class="applescript plain">recoPick</code><code class="applescript color1">(</code><code class="applescript plain">'sendLog'</code><code class="applescript color1">,</code> <code class="applescript plain">'search'</code><code class="applescript color1">,</code> <code class="applescript plain">'미니스커트'</code><code class="applescript color1">)</code><code class="applescript plain">;</code></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p></p>
                        </li>
                        <li>
                          <p>사이트 방문객이 133번 상품을 즐겨찾기를 한 경우 :</p>
                          <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                            <div class="codeContent panelContent pdl">
                              <div>
                                <div id="highlighter_910520" class="syntaxhighlighter sh-eclipse nogutter  applescript">
                                  <div class="toolbar"><span><a href="/display/docs/Client+Library+APIs#" class="toolbar_item command_help help">?</a></span></div>
                                  <table border="0" cellpadding="0" cellspacing="0">
                                    <tbody>
                                    <tr>
                                      <td class="code">
                                        <div class="container" title="Hint: double-click to select code">
                                          <div class="line number2 index1 alt1"><code class="applescript plain">recoPick</code><code class="applescript color1">(</code><code class="applescript plain">'sendLog'</code><code class="applescript color1">,</code> <code class="applescript plain">'like'</code><code class="applescript color1">,</code> <code class="applescript color1">{</code><code class="applescript color3">id</code><code class="applescript color1">:</code><code class="applescript plain">'</code><code class="applescript color1">123</code><code class="applescript plain">'</code><code class="applescript color1">}</code><code class="applescript color1">)</code><code class="applescript plain">;</code></div>
                                        </div>
                                      </td>
                                    </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                          <p></p>
                        </li>
                    </ul>
                </div>
                <h2 id="ClientLibraryAPIs-WidgetAPI"><strong>Widget API</strong></h2>
            </div>
            <div>
                <div>
                    <p>Widget API를 이용하시면 고객사의 상품 상세정보 페이지에 추천 결과를 원하는 영역에 쉽게 노출시킬 수 있습니다.&nbsp;아래의 위젯 삽입 코드를 위젯을 노출하고자 하는 영역에 붙여 넣으시면 됩니다.</p>
                    <p><br></p>
                    <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                        <div class="codeContent panelContent pdl">
                            <div>
                                <div id="highlighter_970047" class="syntaxhighlighter sh-eclipse nogutter  javafx">
                                    <div class="toolbar"><span><a href="/display/docs/Client+Library+APIs#" class="toolbar_item command_help help">?</a></span></div>
                                    <table border="0" cellpadding="0" cellspacing="0">
                                        <tbody>
                                        <tr>
                                            <td class="code">
                                                <div class="container" title="Hint: double-click to select code">
                                                    <div class="line number1 index0 alt2"><code class="javafx plain">&lt;div id=</code><code class="javafx string">"recopick_widget"</code><code class="javafx plain">&gt;</code></div>
                                                    <div class="line number2 index1 alt1"><code class="javafx spaces">&nbsp;&nbsp;</code><code class="javafx plain">&lt;script&gt;</code></div>
                                                    <div class="line number3 index2 alt2"><code class="javafx spaces">&nbsp;&nbsp;</code><code class="javafx plain">(</code><code class="javafx keyword">function</code><code class="javafx plain">(w,n){w[n]=w[n]||</code><code class="javafx keyword">function</code><code class="javafx plain">(){(w[n].q=w[n].q||[]).push(arguments)};}(window, </code><code class="javafx string">'recoPick'</code><code class="javafx plain">));</code></div>
                                                    <div class="line number4 index3 alt1"><code class="javafx spaces">&nbsp;&nbsp;</code><code class="javafx plain">recoPick(</code><code class="javafx string">'widget'</code><code class="javafx plain">, DOM_ID[, ITEM_ID[, OPTIONS], CALLBACK]);</code></div>
                                                    <div class="line number5 index4 alt2"><code class="javafx spaces">&nbsp;&nbsp;</code><code class="javafx plain">&lt;/script&gt;</code></div>
                                                    <div class="line number6 index5 alt1"><code class="javafx plain">&lt;/div&gt;</code></div>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p><br></p>
                    <ul>
                        <li><strong>DOM_ID</strong>: 위젯을 렌더링할 HTML 요소의 id 값. 예를 들어 위 코드와 같이 div 태그의 id를 'recopick_widget'로 지정했으면 DOM_ID도 그와 같이 'recopick_widget'으로 지정하시면 됩니다.</li>
                        <li><strong>ITEM_ID</strong>: 함께 본 상품, 함께 구매한 상품 등 특정 상품에 대한 추천 위젯을 제공하는 경우의 대상 상품ID 혹은 상품ID 배열. 지정되지 않거나 null로 지정되는 경우 로그 전송 시 지정한 상품ID를 통해 추천 위젯을 제공합니다. (예: "ITEM_ID1") (예2:&nbsp;["ITEM_ID1", "ITEM_ID2"])</li>
                        <li><strong>OPTIONS</strong>: 위젯 설정. JavaScript object 형식으로 지정하시면 되며, 지원되는 값은 <a href="/pages/viewpage.action?pageId=9568307#ClientLibraryAPIs-%EC%9C%84%EC%A0%AF%EC%84%A4%EC%A0%95">위젯 설정에 대한 설명</a>을 참고하세요.</li>
                        <li><strong>CALLBACK</strong>: 위젯 렌더링이 완료된 후 호출되는 함수. 첫번째 인자로 렌더링된 위젯에 콘텐츠가 있는지 여부를 가리키는 Boolean값이 전달됩니다.</li>
                    </ul>
                    <h3 id="ClientLibraryAPIs-Example.1">Example</h3>
                    <p><br></p>
                    <p>기본 사용 예</p>
                    <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                        <div class="codeContent panelContent pdl">
                            <div>
                                <div id="highlighter_238662" class="syntaxhighlighter sh-confluence  actionscript3">
                                    <div class="toolbar"><span><a href="/display/docs/Client+Library+APIs#" class="toolbar_item command_help help">?</a></span></div>
                                    <table border="0" cellpadding="0" cellspacing="0">
                                        <tbody>
                                        <tr>
                                            <td class="gutter">
                                                <div class="line number1 index0 alt2">1</div>
                                                <div class="line number2 index1 alt1">2</div>
                                                <div class="line number3 index2 alt2">3</div>
                                                <div class="line number4 index3 alt1">4</div>
                                                <div class="line number5 index4 alt2">5</div>
                                                <div class="line number6 index5 alt1">6</div>
                                            </td>
                                            <td class="code">
                                                <div class="container" title="Hint: double-click to select code">
                                                    <div class="line number1 index0 alt2"><code class="actionscript3 plain">&lt;div id=</code><code class="actionscript3 string">"recopick_widget"</code><code class="actionscript3 plain">&gt;</code></div>
                                                    <div class="line number2 index1 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;</code><code class="actionscript3 plain">&lt;script&gt;</code></div>
                                                    <div class="line number3 index2 alt2"><code class="actionscript3 spaces">&nbsp;&nbsp;</code><code class="actionscript3 plain">(</code><code class="actionscript3 color3">function</code><code class="actionscript3 plain">(w,n){w[n]=w[n]||</code><code class="actionscript3 color3">function</code><code class="actionscript3 plain">(){(w[n].q=w[n].q||[]).push(arguments)};}(window, </code><code class="actionscript3 string">'recoPick'</code><code class="actionscript3 plain">));</code></div>
                                                    <div class="line number4 index3 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;</code><code class="actionscript3 plain">recoPick(</code><code class="actionscript3 string">'widget'</code><code class="actionscript3 plain">, </code><code class="actionscript3 string">"recopick_widget"</code><code class="actionscript3 plain">);</code></div>
                                                    <div class="line number5 index4 alt2"><code class="actionscript3 spaces">&nbsp;&nbsp;</code><code class="actionscript3 plain">&lt;/script&gt;</code></div>
                                                    <div class="line number6 index5 alt1"><code class="actionscript3 plain">&lt;/div&gt;</code></div>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p><br></p>
                    <p>노출되는 위젯의 스타일을 직접 변경하시려면<a href="http://admin.recopick.com/" style="line-height: 1.4285;" class="external-link">&nbsp;RecoPick Admin Dashboard</a>에 로그인 하신 후, "<strong style="line-height: 1.4285;">추천위젯 스타일 설정</strong>" 메뉴로 이동하시면 됩니다.</p>
                    <p>"<strong>추천위젯 스타일 설정</strong>" 메뉴에서는 위젯을 가로형으로 제공할지 세로형으로 제공할지 및 테두리 표시 유무, 제목, 상품 제목, 가격 색 등 다양한 스타일 설정이 가능합니다.</p>
                    <p>추천 대상 상품ID(item_id), 위젯의 가로 길이 및 스타일 설정에서 가능한 스타일은 위젯 표시를 위한 div 태그에 속성으로 지정하여 변경할 수도 있습니다.&nbsp;</p>
                    <p>div 태그에 지정가능한 속성 목록은 <a href="/pages/viewpage.action?pageId=9568307">위젯 설정</a>을 참고해주세요.</p>
                    <p><br></p>CALLBACK 사용 예<div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                    <div class="codeContent panelContent pdl">
                        <div>
                            <div id="highlighter_96687" class="syntaxhighlighter sh-confluence  actionscript3">
                                <div class="toolbar"><span><a href="/display/docs/Client+Library+APIs#" class="toolbar_item command_help help">?</a></span></div>
                                <table border="0" cellpadding="0" cellspacing="0">
                                    <tbody>
                                    <tr>
                                        <td class="gutter">
                                            <div class="line number1 index0 alt2">1</div>
                                            <div class="line number2 index1 alt1">2</div>
                                            <div class="line number3 index2 alt2">3</div>
                                            <div class="line number4 index3 alt1">4</div>
                                            <div class="line number5 index4 alt2">5</div>
                                            <div class="line number6 index5 alt1">6</div>
                                            <div class="line number7 index6 alt2">7</div>
                                            <div class="line number8 index7 alt1">8</div>
                                            <div class="line number9 index8 alt2">9</div>
                                            <div class="line number10 index9 alt1">10</div>
                                        </td>
                                        <td class="code">
                                            <div class="container" title="Hint: double-click to select code">
                                                <div class="line number1 index0 alt2"><code class="actionscript3 plain">&lt;div id=</code><code class="actionscript3 string">"recopick_widget"</code><code class="actionscript3 plain">&gt;</code></div>
                                                <div class="line number2 index1 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;</code><code class="actionscript3 plain">&lt;script&gt;</code></div>
                                                <div class="line number3 index2 alt2"><code class="actionscript3 spaces">&nbsp;&nbsp;</code><code class="actionscript3 plain">(</code><code class="actionscript3 color3">function</code><code class="actionscript3 plain">(w,n){w[n]=w[n]||</code><code class="actionscript3 color3">function</code><code class="actionscript3 plain">(){(w[n].q=w[n].q||[]).push(arguments)};}(window, </code><code class="actionscript3 string">'recoPick'</code><code class="actionscript3 plain">));</code></div>
                                                <div class="line number4 index3 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;</code><code class="actionscript3 plain">recoPick(</code><code class="actionscript3 string">'widget'</code><code class="actionscript3 plain">, </code><code class="actionscript3 string">"recopick_widget"</code><code class="actionscript3 plain">, </code><code class="actionscript3 keyword">null</code><code class="actionscript3 plain">, </code><code class="actionscript3 color3">function</code> <code class="actionscript3 plain">(done) {</code></div>
                                                <div class="line number5 index4 alt2"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 keyword">if</code> <code class="actionscript3 plain">(!done) {</code></div>
                                                <div class="line number6 index5 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">alert(</code><code class="actionscript3 string">"추천 결과가 없습니다!"</code><code class="actionscript3 plain">);</code></div>
                                                <div class="line number7 index6 alt2"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">}</code></div>
                                                <div class="line number8 index7 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;</code><code class="actionscript3 plain">});</code></div>
                                                <div class="line number9 index8 alt2"><code class="actionscript3 spaces">&nbsp;&nbsp;</code><code class="actionscript3 plain">&lt;/script&gt;</code></div>
                                                <div class="line number10 index9 alt1"><code class="actionscript3 plain">&lt;/div&gt;</code></div>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                    <div>OPTIONS 사용 예</div>
                    <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                        <div class="codeContent panelContent pdl">
                            <div>
                                <div id="highlighter_759344" class="syntaxhighlighter sh-confluence  actionscript3">
                                    <div class="toolbar"><span><a href="/display/docs/Client+Library+APIs#" class="toolbar_item command_help help">?</a></span></div>
                                    <table border="0" cellpadding="0" cellspacing="0">
                                        <tbody>
                                        <tr>
                                            <td class="gutter">
                                                <div class="line number1 index0 alt2">1</div>
                                                <div class="line number2 index1 alt1">2</div>
                                                <div class="line number3 index2 alt2">3</div>
                                                <div class="line number4 index3 alt1">4</div>
                                                <div class="line number5 index4 alt2">5</div>
                                                <div class="line number6 index5 alt1">6</div>
                                                <div class="line number7 index6 alt2">7</div>
                                                <div class="line number8 index7 alt1">8</div>
                                                <div class="line number9 index8 alt2">9</div>
                                                <div class="line number10 index9 alt1">10</div>
                                            </td>
                                            <td class="code">
                                                <div class="container" title="Hint: double-click to select code">
                                                    <div class="line number1 index0 alt2"><code class="actionscript3 plain">&lt;div id=</code><code class="actionscript3 string">"recopick_widget"</code><code class="actionscript3 plain">&gt;</code></div>
                                                    <div class="line number2 index1 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;</code><code class="actionscript3 plain">&lt;script&gt;</code></div>
                                                    <div class="line number3 index2 alt2"><code class="actionscript3 spaces">&nbsp;&nbsp;</code><code class="actionscript3 plain">(</code><code class="actionscript3 color3">function</code><code class="actionscript3 plain">(w,n){w[n]=w[n]||</code><code class="actionscript3 color3">function</code><code class="actionscript3 plain">(){(w[n].q=w[n].q||[]).push(arguments)};}(window, </code><code class="actionscript3 string">'recoPick'</code><code class="actionscript3 plain">));</code></div>
                                                    <div class="line number4 index3 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;</code><code class="actionscript3 plain">recoPick(</code><code class="actionscript3 string">'widget'</code><code class="actionscript3 plain">, </code><code class="actionscript3 string">"recopick_widget"</code><code class="actionscript3 plain">, </code><code class="actionscript3 keyword">null</code><code class="actionscript3 plain">, {widget_type: </code><code class="actionscript3 string">'view'</code><code class="actionscript3 plain">, channel: </code><code class="actionscript3 string">'main'</code><code class="actionscript3 plain">}, </code><code class="actionscript3 color3">function</code> <code class="actionscript3 plain">(done) {</code></div>
                                                    <div class="line number5 index4 alt2"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 keyword">if</code> <code class="actionscript3 plain">(!done) {</code></div>
                                                    <div class="line number6 index5 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">alert(</code><code class="actionscript3 string">"추천 결과가 없습니다!"</code><code class="actionscript3 plain">);</code></div>
                                                    <div class="line number7 index6 alt2"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">}</code></div>
                                                    <div class="line number8 index7 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;</code><code class="actionscript3 plain">});</code></div>
                                                    <div class="line number9 index8 alt2"><code class="actionscript3 spaces">&nbsp;&nbsp;</code><code class="actionscript3 plain">&lt;/script&gt;</code></div>
                                                    <div class="line number10 index9 alt1"><code class="actionscript3 plain">&lt;/div&gt;</code></div>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p><br></p>
                    <h3 id="ClientLibraryAPIs-위젯설정">위젯 설정</h3>
                    <div class="table-wrap">
                        <table class="wrapped confluenceTable">
                            <colgroup>
                                <col>
                                <col>
                            </colgroup>
                            <tbody>
                            <tr>
                                <th style="text-align: center;" colspan="1" class="confluenceTh"><strong style="text-align: center;">widget_type</strong></th>
                                <td colspan="1" class="confluenceTd">
                                    <p><span>추천 알고리즘의 종류. 기본값은 함께 본 상품(view_together)입니다.</span></p>
                                    <ul>
                                        <li>함께 본 상품: view_together</li>
                                        <li>함께 구매한 상품: buy_together</li>
                                        <li>가장 많이 본 상품: view</li>
                                        <li>추천클릭률Top: reco_click_rate</li>
                                        <li>구매전환율Top: purchase_rate</li>
                                        <li>추천유입률Top: reco_inflow_rate</li>
                                        <li>개인화 추천: user (개인화 추천의 이용을 위해서는 <a href="http://admin.recopick.com/" class="external-link">대시보드(admin.recopick.com)</a>의 문의 기능을 통해 저희에게 연락을 부탁드립니다.)</li>
                                    </ul>
                                    <p><strong>Example:</strong></p>
                                    <pre>예1: &lt;div id="recopick_widget" data-widget_type="reco_click_rate"&gt;&lt;/div&gt;</pre>
                                    <pre>예2: recoPick('widget', 'recopick_widget', null, {widget_type: 'purchase_rate'});</pre>
                                    <pre>예3: recoPick('widget', 'recopick_widget', null, {widget_type: 'user,purchase_rate'}); // 다중 알고리즘 노출 설정 (개인화 추천을 우선으로 노출하되, 결과가 없으면 구매전환율Top 결과를 노출)</pre>
                                </td>
                            </tr>
                            <tr>
                                <th style="text-align: center;" class="confluenceTh"><strong>widget_id</strong></th>
                                <td class="confluenceTd">위젯의 스타일 ID. 지정하지 않을 경우 기본 스타일이 적용됩니다. 위젯의 스타일 ID는 어드민 대시보드의 "추천위젯 스타일 설정" 메뉴에서 확인하실 수 있습니다.<br><br><strong>Example:</strong>
                                    <pre>예1: &lt;div id="recopick_widget" data-widget_id="gqieKeLe"&gt;&lt;/div&gt;</pre>
                                    <pre>예2: recoPick('widget', 'recopick_widget', null, {widget_id: 'gqieKeLe'});</pre>
                                </td>
                            </tr>
                            <tr>
                                <th style="text-align: center;" class="confluenceTh"><strong>item_id</strong></th>
                                <td class="confluenceTd">
                                    <p>상품의 ID값. 카페24/고도몰/메이크샵 솔루션을 이용하시는 경우 자동으로 할당되므로 지정할 필요가 없습니다.</p>
                                    <p>recoPick('sendLog') 함수를 통해 특정 상품 ID를 지정하셨다면 data-item_id 속성을 지정하지 않았을 경우 recoPick('sendLog') 함수에서 지정한 상품 ID에 해당하는 추천 위젯이 제공됩니다.</p>
                                    <p>그 이외의 경우 사용자가 열람하는 상품의 ID값을 지정해주시면 됩니다.<br><br><strong>Example:</strong></p>
                                    <pre>예1: &lt;div id="recopick_widget" data-item_id="SFSELFAA0078218"&gt;&lt;/div&gt;</pre>
                                    <pre>예2: recoPick('widget', 'recopick_widget', 'SFFFEQ075923');</pre>
                                </td>
                            </tr>
                            <tr>
                                <th style="text-align: center;" class="confluenceTh"><strong>width</strong></th>
                                <td class="confluenceTd">위젯의 가로 길이. (기본값: "100%")&nbsp;<br>정수로 입력할 경우 위젯의 가로 길이가 해당 픽셀로 지정됩니다.&nbsp;<br><br><strong>Example:</strong>
                                    <pre>예1: &lt;div id="recopick_widget" data-width="900"&gt;&lt;/div&gt;</pre>
                                    <pre>예2: recoPick('widget', 'recopick_widget', null, {widget: 900});</pre>
                                </td>
                            </tr>
                            <tr>
                                <th style="text-align: center;" class="confluenceTh"><strong>has_outline</strong></th>
                                <td class="confluenceTd">테두리 표시 여부. 1로 지정 시 위젯에 테두리 선을 표시합니다. 0으로 지정 시 표시하지 않습니다.<br><br><strong>Example:</strong>
                                    <pre>예1: &lt;div id="recopick_widget" data-has_outline="1"&gt;&lt;/div&gt;</pre>
                                    <pre>예2: recoPick('widget', 'recopick_widget', null, {has_outline: 1});</pre>
                                </td>
                            </tr>
                            <tr>
                                <th style="text-align: center;" colspan="1" class="confluenceTh"><strong>title_type</strong></th>
                                <td colspan="1" class="confluenceTd">위젯 타이틀 종류. 이미지 혹은 텍스트로 지정할 수 있다.<br><br><strong>Example:</strong>
                                    <pre>예1: &lt;div id="recopick_widget"
data-title_type="text" data-title_text="아무개님을 위한 추천"&gt;&lt;/div&gt;</pre>
                                    <pre>예2: recoPick('widget', 'recopick_widget', null, {title_type: "text", title_text: '아무개님을 위한 추천'});</pre>
                                </td>
                            </tr>
                            <tr>
                                <th style="text-align: center;" colspan="1" class="confluenceTh">title_type1<br>title_type2<br>title_type3&nbsp;</th>
                                <td colspan="1" class="confluenceTd">widget_type 속성을 통해 다중 알고리즘 노출 설정을 하신 경우 각 알고리즘별 타이틀 종류를 지정할 수 있습니다.</td>
                            </tr>
                            <tr>
                                <th style="text-align: center;" class="confluenceTh"><strong>title_img</strong></th>
                                <td class="confluenceTd">위젯 타이틀 이미지. 이미지의 URL 경로로 지정 시 위젯에 해당 이미지가 타이틀로 사용됩니다.<br><br><strong>Example:</strong>
                                    <pre>예1: &lt;div id="recopick_widget"
data-title_img="http://api.recopick.com/widget_title/title_text1_03.png"&gt;&lt;/div&gt;</pre>
                                    <pre>예2: recoPick('widget', 'recopick_widget', null, {title_img: "http://api.recopick.com/widget_title/title_text1_03.png"});</pre>
                                </td>
                            </tr>
                            <tr>
                                <th style="text-align: center;" colspan="1" class="confluenceTh">title_img1<br>title_img2<br>title_img3&nbsp;</th>
                                <td colspan="1" class="confluenceTd">widget_type 속성을 통해 다중 알고리즘 노출 설정을 하신 경우 각 알고리즘별 타이틀 이미지를 지정할 수 있습니다.</td>
                            </tr>
                            <tr>
                                <th style="text-align: center;" colspan="1" class="confluenceTh">title_text</th>
                                <td colspan="1" class="confluenceTd"><span>위젯 타이틀 텍스트. 위젯 제목을 텍스트 지정한 경우 본 속성을 통해 위젯 제목을 변경할 수 있습니다.</span><br><br><strong>Example:</strong>
                                    <pre>예1: &lt;div id="recopick_widget" data-title_text="홍길동님을 위한 추천"&gt;&lt;/div&gt;</pre>
                                    <pre>예2: recoPick('widget', 'recopick_widget', null, {title_text: '아무개님을 위한 추천'});</pre>
                                </td>
                            </tr>
                            <tr>
                                <th style="text-align: center;" colspan="1" class="confluenceTh">title_text1<br>title_text2<br>title_text3&nbsp;</th>
                                <td colspan="1" class="confluenceTd">widget_type 속성을 통해 다중 알고리즘 노출 설정을 하신 경우 각 알고리즘별 타이틀 텍스트를 지정할 수 있습니다.</td>
                            </tr>
                            <tr>
                                <th style="text-align: center;" colspan="1" class="confluenceTh">title_size</th>
                                <td colspan="1" class="confluenceTd">위젯 타이틀 텍스트의 크기. 위젯 제목을 텍스트 지정한 경우 본 속성을 통해 위젯 제목의 텍스트 크기를 변경할 수 있습니다.<br><br><strong>Example:</strong>
                                    <pre>예1: &lt;div id="recopick_widget" data-title_text="홍길동님을 위한 추천"&gt;&lt;/div&gt;</pre>
                                    <pre>예2: recoPick('widget', 'recopick_widget', null, {title_text: '아무개님을 위한 추천', title_size: 15});</pre>
                                </td>
                            </tr>
                            <tr>
                                <th style="text-align: center;" colspan="1" class="confluenceTh">title_size1<br>title_size2<br>title_size3&nbsp;</th>
                                <td colspan="1" class="confluenceTd">widget_type 속성을 통해 다중 알고리즘 노출 설정을 하신 경우 각 알고리즘별 타이틀 텍스트 크기를 지정할 수 있습니다.</td>
                            </tr>
                            <tr>
                                <th style="text-align: center;" class="confluenceTh"><strong>has_thumbnail </strong></th>
                                <td class="confluenceTd">섬네일 표시 여부. 0으로 지정 시 섬네일 이미지를 표시하지 않습니다. 1로 지정 시 표시합니다.<br><br><strong>Example:</strong>
                                    <pre>예1: &lt;div id="recopick_widget" data-has_thumbnail="0"&gt;&lt;/div&gt;</pre>
                                    <pre>예2: recoPick('widget', 'recopick_widget', null, {has_thumbnail: 0});</pre>
                                </td>
                            </tr>
                            <tr>
                                <th style="text-align: center;" class="confluenceTh"><strong>page_count</strong></th>
                                <td class="confluenceTd">
                                    <p>추천 목록 페이지 수. 1로 지정 시 한 페이지가, 2 이상 지정 시 이전, 다음 버튼이 노출되며 해당 페이지 수만큼 추천 목록이 제공됩니다.</p>
                                    <p>추천 목록의 최대 갯수는 추천 환경 설정에서 지정한 최대 "추천 아이템 수"입니다.<br><br><strong>Example:</strong></p>
                                    <pre>예1: &lt;div id="recopick_widget" data-page_count="3"&gt;&lt;/div&gt;</pre>
                                    <pre>예2: recoPick('widget', 'recopick_widget', null, {page_count: 3});</pre>
                                </td>
                            </tr>
                            <tr>
                                <th style="text-align: center;" class="confluenceTh"><strong>item_count</strong></th>
                                <td class="confluenceTd">추천 상품 수. 한 페이지에 표시할 추천 상품 수 입니다. 지정된 숫자만큼 상품 수가 표시됩니다.<br><br><strong>Example:</strong>
                                    <pre>예1: &lt;div id="recopick_widget" data-item_count="4"&gt;&lt;/div&gt;</pre>
                                    <pre>예2: recoPick('widget', 'recopick_widget', null, {item_count: 4});</pre>
                                </td>
                            </tr>
                            <tr>
                                <th style="text-align: center;" class="confluenceTh"><strong>item_color</strong></th>
                                <td class="confluenceTd">추천 상품명 색상. 추천 상품명의 색상을 변경할 수 있습니다.<br><br><strong>Example:</strong>
                                    <pre>예1: &lt;div id="recopick_widget" data-page_color="#55d"&gt;&lt;/div&gt;</pre>
                                    <pre>예2: recoPick('widget', 'recopick_widget', null, {page_count: '#55d'});</pre>
                                </td>
                            </tr>
                            <tr>
                                <th style="text-align: center;" class="confluenceTh"><strong>has_price</strong></th>
                                <td class="confluenceTd">
                                    <p>추천 상품 가격 표시 여부. 1로 지정 시 추천 상품의 가격을 표시합니다. 0으로 지정 시 표시하지 않습니다.</p>
                                    <p>만약 가격이 0원으로 표시되는 경우에는 가격 메타정보를 삽입하여 가격 정보가 수집될 수 있도록 수정하시면 됩니다.<br><br><strong>Example:</strong></p>
                                    <pre>예1: &lt;div id="recopick_widget" data-has_price="1"&gt;&lt;/div&gt;</pre>
                                    <pre>예2: recoPick('widget', 'recopick_widget', null, {has_price: '1'});</pre>
                                </td>
                            </tr>
                            <tr>
                                <th style="text-align: center;" class="confluenceTh"><strong>price_color</strong></th>
                                <td class="confluenceTd">추천 상품 가격 색상. 추천 상품 가격의 색상을 변경할 수 있습니다.<br><br><strong>Example:</strong>
                                    <pre>예1: &lt;div id="recopick_widget" data-price_color="#595"&gt;&lt;/div&gt;</pre>
                                    <pre>예2: recoPick('widget', 'recopick_widget', null, {price_color: '#595'});</pre>
                                </td>
                            </tr>
                            <tr>
                                <th style="text-align: center;" colspan="1" class="confluenceTh"><strong>channel</strong></th>
                                <td colspan="1" class="confluenceTd">
                                    <p>통계 구분을 위한 채널. 사이트에 여러 개의 위젯을 제공하시는 경우 채널명을 다르게 부여하여 위젯별로 각각 클릭률, 추천경유매출 등을 비교, 확인하실 수 있습니다.</p>
                                    <p>채널명은 임의로 지정하시면 됩니다.</p>
                                    <p>Tip) RecoPick 위젯을 메인 페이지에 하나, 상품 상세 페이지에 하나 이렇게 두 군데에 제공하신다면 채널명을 위젯의 위치에 맞게 메인은 "main", 상품 상세 페이지는 "view"와 같은 식으로 지정하시면 각 채널별 클릭률 등의 통계를 어드민 대시보드를 통해 확인하실 수 있습니다.</p>
                                    <p><br><strong>Example:</strong></p>
                                    <pre>예1: &lt;div id="recopick_widget" data-channel="ch01"&gt;&lt;/div&gt;</pre>
                                    <pre>예2: recoPick('widget', 'recopick_widget', null, {channel: 'ch01'});</pre>
                                </td>
                            </tr>
                            <tr>
                                <th colspan="1" class="confluenceTh">random</th>
                                <td colspan="1" class="confluenceTd">
                                    <p>"함께 본 상품", "통계형 상품"의 경우, 추천대상이 고정으로 제공됩니다.</p>
                                    <p>shuffling된 데이터가 노출되기 원할경우 사용하시면 됩니다.</p>
                                    <p><strong>Example:</strong></p>
                                    <pre>예1: &lt;div id="recopick_widget" data-random="all"&gt;&lt;/div&gt;</pre>
                                    <pre>예2: recoPick('widget', 'recopick_widget', null, {random: 'all'});</pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div id="floating-scrollbar" style="position: fixed; bottom: 0px; height: 30px; overflow: auto hidden; display: none; left: 326px; width: 437px;">
                        <div style="border: 1px solid rgb(255, 255, 255); opacity: 0.01; width: 1532px;"></div>
                    </div>
                    <div>
                        <ul>
                            <li><span style="color: rgb(153,51,0);">HTML 속성과 JavaScript OPTIONS에 같은 설정이 지정되는 경우 JavaScript OPTIONS에 지정된 값이 적용됩니다.</span></li>
                        </ul>
                    </div>
                    <h3 id="ClientLibraryAPIs-한페이지에여러위젯노출하기">한 페이지에 여러 위젯 노출하기</h3>
                    <p>RecoPick의 효과를 극대화하기 위해서, 혹은 한 페이지에 여러 상품을 노출하기 위해서 한 페이지에 여러 개의 RecoPick 추천 위젯을 제공할 수 있습니다.</p>
                    <p>Widget API를 참고하여 스크립트를 작성하셨다면 아래와 같이 스크립트 한 줄을 추가하는 것만으로 추천 위젯을 추가로 노출할 수 있습니다.</p>
                    <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                        <div class="codeContent panelContent pdl">
                            <div>
                                <div id="highlighter_968198" class="syntaxhighlighter sh-confluence  actionscript3">
                                    <div class="toolbar"><span><a href="/display/docs/Client+Library+APIs#" class="toolbar_item command_help help">?</a></span></div>
                                    <table border="0" cellpadding="0" cellspacing="0">
                                        <tbody>
                                        <tr>
                                            <td class="gutter">
                                                <div class="line number1 index0 alt2">1</div>
                                                <div class="line number2 index1 alt1">2</div>
                                                <div class="line number3 index2 alt2">3</div>
                                                <div class="line number4 index3 alt1">4</div>
                                                <div class="line number5 index4 alt2">5</div>
                                                <div class="line number6 index5 alt1">6</div>
                                                <div class="line number7 index6 alt2">7</div>
                                                <div class="line number8 index7 alt1">8</div>
                                                <div class="line number9 index8 alt2">9</div>
                                                <div class="line number10 index9 alt1">10</div>
                                                <div class="line number11 index10 alt2">11</div>
                                            </td>
                                            <td class="code">
                                                <div class="container" title="Hint: double-click to select code">
                                                    <div class="line number1 index0 alt2"><code class="actionscript3 plain">&lt;script type=</code><code class="actionscript3 string">"text/javascript"</code><code class="actionscript3 plain">&gt;</code></div>
                                                    <div class="line number2 index1 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;</code><code class="actionscript3 plain">(</code><code class="actionscript3 color3">function</code><code class="actionscript3 plain">(w,d,n,s,e,o) {</code></div>
                                                    <div class="line number3 index2 alt2"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">w[n]=w[n]||</code><code class="actionscript3 color3">function</code><code class="actionscript3 plain">(){(w[n].q=w[n].q||[]).push(arguments)};</code></div>
                                                    <div class="line number4 index3 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">e=d.createElement(s);e.async=</code><code class="actionscript3 value">1</code><code class="actionscript3 plain">;e.charset=</code><code class="actionscript3 string">'utf-8'</code><code class="actionscript3 plain">;e.src=</code><code class="actionscript3 string">'//static.recopick.com/dist/production.min.js'</code><code class="actionscript3 plain">;</code></div>
                                                    <div class="line number5 index4 alt2"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">o=d.getElementsByTagName(s)[</code><code class="actionscript3 value">0</code><code class="actionscript3 plain">];o.parentNode.insertBefore(e,o);</code></div>
                                                    <div class="line number6 index5 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;</code><code class="actionscript3 plain">})(window, document, </code><code class="actionscript3 string">'recoPick'</code><code class="actionscript3 plain">, </code><code class="actionscript3 string">'script'</code><code class="actionscript3 plain">);</code></div>
                                                    <div class="line number7 index6 alt2"><code class="actionscript3 spaces">&nbsp;&nbsp;</code><code class="actionscript3 plain">recoPick(</code><code class="actionscript3 string">'service'</code><code class="actionscript3 plain">, </code><code class="actionscript3 string">'SERVICE_NAME'</code><code class="actionscript3 plain">);</code></div>
                                                    <div class="line number8 index7 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;</code><code class="actionscript3 plain">recoPick(</code><code class="actionscript3 string">'sendLog'</code><code class="actionscript3 plain">, </code><code class="actionscript3 string">'view'</code><code class="actionscript3 plain">, </code><code class="actionscript3 string">'123'</code><code class="actionscript3 plain">);</code></div>
                                                    <div class="line number9 index8 alt2"><code class="actionscript3 spaces">&nbsp;&nbsp;</code><code class="actionscript3 plain">recoPick(</code><code class="actionscript3 string">'widget'</code><code class="actionscript3 plain">, </code><code class="actionscript3 string">'recopick_widget'</code><code class="actionscript3 plain">);</code></div>
                                                    <div class="line number10 index9 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;</code><code class="actionscript3 plain">recoPick(</code><code class="actionscript3 string">'widget'</code><code class="actionscript3 plain">, </code><code class="actionscript3 string">'recopick_widget2'</code><code class="actionscript3 plain">); </code><code class="actionscript3 comments">// 이 줄을 추가하면 id="recopick_widget", id="recopick_widget2" 이렇게 두 군데의 영역에 추천 위젯이 각각 제공됩니다.</code></div>
                                                    <div class="line number11 index10 alt2"><code class="actionscript3 plain">&lt;/script&gt;</code></div>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p><br></p>
                    <p>만약 HTML에 아래와 같이 추천 위젯을 위한 태그를 제공했다면...</p>
                    <div class="panel conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="panel">
                        <div class="panelContent">
                            <p><span style="line-height: 1.4285;">&lt;!-- 상품 상세 정보 이전 --&gt;<br>&lt;div id="</span><span style="color: rgb(255,0,0);">recopick_widget</span><span style="line-height: 1.4285;">"&gt;&lt;/div&gt;<br>&lt;!-- ... 상품 상세 정보&nbsp;영역 ... --&gt;<br><span>&lt;!-- 상품 상세 정보 이후 --&gt;<br></span></span><span style="line-height: 1.4285;">&lt;div id="</span><span style="color: rgb(255,0,0);">recopick_widget2</span><span style="line-height: 1.4285;">"&gt;&lt;/div&gt;</span>&nbsp;&nbsp;</p>
                        </div>
                    </div>
                    <p>상품 상세 정보의 이전과 이후에 추천 위젯이 노출됩니다.</p>
                    <p><br></p>
                    <p>한 페이지에 여러 개의 상품의 위젯을 제공하는 경우라면</p>
                    <div class="panel conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="panel">
                        <div class="panelContent">
                    <pre>&lt;script type="text/javascript"&gt;
  (function(w,d,n,s,e,o) {
    w[n]=w[n]||function(){(w[n].q=w[n].q||[]).push(arguments)};
    e=d.createElement(s);e.async=1;e.charset='utf-8';e.src='//static.recopick.com/dist/production.min.js';
    o=d.getElementsByTagName(s)[0];o.parentNode.insertBefore(e,o);
  })(window, document, 'recoPick', 'script');
  recoPick('service', 'SERVICE_NAME');
  recoPick('sendLog', 'view', '123');
  recoPick('widget', 'recopick_widget');
  recoPick('widget', 'recopick_widget2');
&lt;/script&gt;</pre>
                            <p><span>&lt;div id="</span><span style="color: rgb(255,0,0);">recopick_widget</span><span>" <u>data-item_id="123"</u>&gt;&lt;/div&gt;<br></span><span>&lt;div id="</span><span style="color: rgb(255,0,0);">recopick_widget2</span><span>"<u> data-item_id="124"</u>&gt;&lt;/div&gt;</span>&nbsp;&nbsp;</p>
                        </div>
                    </div>
                    <p>위와 같이 HTML 태그를 제공한다면 "123"번 상품과 "124"번 상품의 추천 위젯이 노출됩니다.</p>
                    <p>위의 코드는 다음과 같이 구현할 수도 있습니다.</p>
                    <div class="panel conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="panel">
                        <div class="panelContent">
                    <pre>&lt;script type="text/javascript"&gt;
  (function(w,d,n,s,e,o) {
    w[n]=w[n]||function(){(w[n].q=w[n].q||[]).push(arguments)};
    e=d.createElement(s);e.async=1;e.charset='utf-8';e.src='//static.recopick.com/dist/production.min.js';
    o=d.getElementsByTagName(s)[0];o.parentNode.insertBefore(e,o);
  })(window, document, 'recoPick', 'script');
  recoPick('service', 'SERVICE_NAME');
  recoPick('sendLog', 'view', '123');
  recoPick('widget', 'recopick_widget', '123');   // recopick_widget이라는 ID를 가진 요소에 ITEM_ID 123인 상품의 추천 위젯 노출
  recoPick('widget', 'recopick_widget2', '124');  // recopick_widget2라는 ID를 가진 요소에 ITEM_ID 124인 상품의 추천 위젯 노출</pre>
                            <pre>&lt;/script&gt;</pre>
                            <pre><br></pre>
                            <pre>&lt;div id="recopick_widget"&gt;&lt;/div&gt;<br>&lt;div id="recopick_widget2"&gt;&lt;/div&gt;&nbsp;&nbsp;</pre>
                        </div>
                    </div>
                    <h3 id="ClientLibraryAPIs-개인화추천,통계형추천등추천알고리즘별위젯노출">개인화 추천, 통계형 추천 등 추천 알고리즘별 위젯 노출</h3>
                    <p>RecoPick에서는 함께 본 상품을 통한 추천 제공 이외에 개인의 성향을 분석한 개인화 추천, 상품별 조회수/클릭률/구매전환율 등을 기준으로 인기 상품을 추천하는 통계형 추천 등 다양한 알고리즘별 추천을 제공합니다. 각 알고리즘별 추천은 다음과 같은 방식으로 추천 위젯을 제공할 HTML 태그에 <strong><span style="color: rgb(0,51,102);">data-widget_type</span></strong> 속성을 지정하여 노출하실 수 있습니다.</p>
                    <h4 id="ClientLibraryAPIs-함께본상품(기본값,생략가능)">함께 본 상품 (기본값, 생략가능)</h4>
                    <p><span style="color: rgb(153,51,0);">대시보드에서 임의의 상품에 대한 추천 목록 확인하기:&nbsp;<a href="https://admin.recopick.com/-/rand" class="external-link">https://admin.recopick.com/-/rand</a>&nbsp;</span></p>
                    <div class="panel conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="panel">
                        <div class="panelContent">
                            <pre>&lt;!-- 아래 코드는 SERVICE_NAME을 운영하시는 서비스의 URL에 맞게 recopick.com과 같이 변경하신 후 &lt;/head&gt; 직전에 삽입해주세요 --&gt;<br>&lt;script type="text/javascript"&gt;</pre>
                            <pre>  (function(w,d,n,s,e,o) {
    w[n]=w[n]||function(){(w[n].q=w[n].q||[]).push(arguments)};
    e=d.createElement(s);e.async=1;e.charset='utf-8';e.src='//static.recopick.com/dist/production.min.js';
    o=d.getElementsByTagName(s)[0];o.parentNode.insertBefore(e,o);
  })(window, document, 'recoPick', 'script');
  recoPick('service', 'SERVICE_NAME');
  recoPick('widget', 'recopick_widget_view'); // 로그 수집, 다른 추천 제공 등을 위해 위 코드를 이미 삽입하신 경우 이 줄만 넣어주세요!</pre>
                            <pre>&lt;/script&gt;</pre>
                            <p><br></p>
                            <pre>&lt;!-- 아래 코드는 위젯을 노출할 영역에 삽입해주세요. --&gt;<br>&lt;div id="<span style="color: rgb(255,0,0);">recopick_widget_view</span>" <u style="line-height: 1.4285;font-family: Arial , sans-serif;">data-widget_type="view_together"</u>&gt;&lt;/div&gt;</pre>
                        </div>
                    </div>
                    <p><span style="line-height: 1.5;">&nbsp;</span></p>
                    <h4 id="ClientLibraryAPIs-함께구매한상품">함께 구매한 상품</h4>
                    <div class="panel conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="panel">
                        <div class="panelContent">
                            <pre>&lt;!-- 아래 코드는 SERVICE_NAME을 운영하시는 서비스의 URL에 맞게 recopick.com과 같이 변경하신 후 &lt;/head&gt; 직전에 삽입해주세요 --&gt;<br>&lt;script type="text/javascript"&gt;</pre>
                            <pre>  (function(w,d,n,s,e,o) {
    w[n]=w[n]||function(){(w[n].q=w[n].q||[]).push(arguments)};
    e=d.createElement(s);e.async=1;e.charset='utf-8';e.src='//static.recopick.com/dist/production.min.js';
    o=d.getElementsByTagName(s)[0];o.parentNode.insertBefore(e,o);
  })(window, document, 'recoPick', 'script');
  recoPick('service', 'SERVICE_NAME');
  recoPick('widget', 'recopick_widget_buy'); // 로그 수집, 다른 추천 제공 등을 위해 위 코드를 이미 삽입하신 경우 이 줄만 넣어주세요!</pre>
                            <pre>&lt;/script&gt;</pre>
                            <p><br></p>
                            <pre>&lt;!-- 아래 코드는 위젯을 노출할 영역에 삽입해주세요. --&gt;<br>&lt;div id="<span style="color: rgb(255,0,0);">recopick_widget_buy</span>" <u style="line-height: 1.4285;font-family: Arial , sans-serif;">data-widget_type="buy_together"</u>&gt;&lt;/div&gt;</pre>
                        </div>
                    </div>
                    <h4 id="ClientLibraryAPIs-개인화추천"><span style="line-height: 1.5;">개인화 추천</span></h4>
                    <div class="panel conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="panel">
                        <div class="panelContent">
                            <pre>&lt;!-- 아래 코드는 SERVICE_NAME을 운영하시는 서비스의 URL에 맞게 recopick.com과 같이 변경하신 후 &lt;/head&gt; 직전에 삽입해주세요 --&gt;<br>&lt;script type="text/javascript"&gt;</pre>
                            <pre>  (function(w,d,n,s,e,o) {
    w[n]=w[n]||function(){(w[n].q=w[n].q||[]).push(arguments)};
    e=d.createElement(s);e.async=1;e.charset='utf-8';e.src='//static.recopick.com/dist/production.min.js';
    o=d.getElementsByTagName(s)[0];o.parentNode.insertBefore(e,o);
  })(window, document, 'recoPick', 'script');
  recoPick('service', 'SERVICE_NAME');
  recoPick('widget', 'recopick_widget_user'); // 로그 수집, 다른 추천 제공 등을 위해 위 코드를 이미 삽입하신 경우 이 줄만 넣어주세요!</pre>
                            <pre>&lt;/script&gt;</pre>
                            <p><br></p>
                            <pre>&lt;!-- 아래 코드는 위젯을 노출할 영역에 삽입해주세요. --&gt;<br>&lt;div id="<span style="color: rgb(255,0,0);">recopick_widget_user</span>" <u style="line-height: 1.4285;font-family: Arial , sans-serif;">data-widget_type="user"</u>&gt;&lt;/div&gt;</pre>
                        </div>
                    </div>
                    <h4 id="ClientLibraryAPIs-통계형추천-클릭률"><span style="line-height: 1.5;">통계형 추천 - 클릭률</span></h4>
                    <p><span style="color: rgb(153,51,0);">통계형 추천 데이터 중 클릭률/구매전환율/추천유입률은 함께 본 상품을 통한 위젯이 노출된 후 하루 이틀 후 클릭률/구매전환율 등이 계산된 후 제공됩니다.</span></p>
                    <p><span style="color: rgb(153,51,0);">대시보드에서 클릭률 Top 100 확인하기:&nbsp;<a href="https://admin.recopick.com/-/item/top100/ctr" class="external-link">https://admin.recopick.com/-/item/top100/ctr</a></span></p>
                    <div class="panel conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="panel">
                        <div class="panelContent">
                            <pre>&lt;!-- 아래 코드는 SERVICE_NAME을 운영하시는 서비스의 URL에 맞게 recopick.com과 같이 변경하신 후 &lt;/head&gt; 직전에 삽입해주세요 --&gt;<br>&lt;script type="text/javascript"&gt;</pre>
                            <pre>  (function(w,d,n,s,e,o) {
    w[n]=w[n]||function(){(w[n].q=w[n].q||[]).push(arguments)};
    e=d.createElement(s);e.async=1;e.charset='utf-8';e.src='//static.recopick.com/dist/production.min.js';
    o=d.getElementsByTagName(s)[0];o.parentNode.insertBefore(e,o);
  })(window, document, 'recoPick', 'script');
  recoPick('service', 'SERVICE_NAME');
  recoPick('widget', 'recopick_widget_click'); // 로그 수집, 다른 추천 제공 등을 위해 위 코드를 이미 삽입하신 경우 이 줄만 넣어주세요!</pre>
                            <pre>&lt;/script&gt;</pre>
                            <p><br></p>
                            <pre>&lt;!-- 아래 코드는 위젯을 노출할 영역에 삽입해주세요. --&gt;<br>&lt;div id="<span style="color: rgb(255,0,0);">recopick_widget_click</span>" <u style="line-height: 1.4285;font-family: Arial , sans-serif;">data-widget_type="reco_click_rate"</u>&gt;&lt;/div&gt;</pre>
                        </div>
                    </div>
                    <h4 id="ClientLibraryAPIs-통계형추천-구매전환율"><span style="line-height: 1.5;">통계형 추천 - 구매전환율</span></h4>
                    <p><span style="color: rgb(153,51,0);">통계형 추천 데이터 중 클릭률/구매전환율/추천유입률은 함께 본 상품을 통한 위젯이 노출된 후 하루 이틀 후 클릭률/구매전환율 등이 계산된 후 제공됩니다.</span></p>
                    <p><span style="color: rgb(153,51,0);">대시보드에서 클릭률 Top 100 확인하기:&nbsp;<a href="https://admin.recopick.com/-/item/top100/purchase_rate" class="external-link">https://admin.recopick.com/-/item/top100/purchase_rate</a></span></p>
                    <div class="panel conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="panel">
                        <div class="panelContent">
                            <pre>&lt;!-- 아래 코드는 SERVICE_NAME을 운영하시는 서비스의 URL에 맞게 recopick.com과 같이 변경하신 후 &lt;/head&gt; 직전에 삽입해주세요 --&gt;<br>&lt;script type="text/javascript"&gt;</pre>
                            <pre>  (function(w,d,n,s,e,o) {
    w[n]=w[n]||function(){(w[n].q=w[n].q||[]).push(arguments)};
    e=d.createElement(s);e.async=1;e.charset='utf-8';e.src='//static.recopick.com/dist/production.min.js';
    o=d.getElementsByTagName(s)[0];o.parentNode.insertBefore(e,o);
  })(window, document, 'recoPick', 'script');
  recoPick('service', 'SERVICE_NAME');
  recoPick('widget', 'recopick_widget_purchase'); // 로그 수집, 다른 추천 제공 등을 위해 위 코드를 이미 삽입하신 경우 이 줄만 넣어주세요!</pre>
                            <pre>&lt;/script&gt;</pre>
                            <p><br></p>
                            <pre>&lt;!-- 아래 코드는 위젯을 노출할 영역에 삽입해주세요. --&gt;<br>&lt;div id="<span style="color: rgb(255,0,0);">recopick_widget_purchase</span>" <u style="line-height: 1.4285;font-family: Arial , sans-serif;">data-widget_type="purchase_rate"</u>&gt;&lt;/div&gt;</pre>
                        </div>
                    </div>
                    <h4 id="ClientLibraryAPIs-통계형추천-추천유입률"><span style="line-height: 1.5;">통계형 추천 - 추천유입률</span></h4>
                    <p><span style="color: rgb(153,51,0);"><span style="color: rgb(153,51,0);">통계형 추천 데이터 중 클릭률/구매전환율/추천유입률은 함께 본 상품을 통한 위젯이 노출된 후 하루 이틀 후 클릭률/구매전환율 등이 계산된 후 제공됩니다.</span></span></p>
                    <p><span style="color: rgb(153,51,0);">대시보드에서 클릭률 Top 100 확인하기:&nbsp;<a href="https://admin.recopick.com/-/item/top100/item_inflow" class="external-link">https://admin.recopick.com/-/item/top100/item_inflow</a>&nbsp;</span></p>
                    <div class="panel conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="panel">
                        <div class="panelContent">
                            <pre>&lt;!-- 아래 코드는 SERVICE_NAME을 운영하시는 서비스의 URL에 맞게 recopick.com과 같이 변경하신 후 &lt;/head&gt; 직전에 삽입해주세요 --&gt;<br>&lt;script type="text/javascript"&gt;</pre>
                            <pre>  (function(w,d,n,s,e,o) {
    w[n]=w[n]||function(){(w[n].q=w[n].q||[]).push(arguments)};
    e=d.createElement(s);e.async=1;e.charset='utf-8';e.src='//static.recopick.com/dist/production.min.js';
    o=d.getElementsByTagName(s)[0];o.parentNode.insertBefore(e,o);
  })(window, document, 'recoPick', 'script');
  recoPick('service', 'SERVICE_NAME');
  recoPick('widget', 'recopick_widget_inflow'); // 로그 수집, 다른 추천 제공 등을 위해 위 코드를 이미 삽입하신 경우 이 줄만 넣어주세요!</pre>
                            <pre>&lt;/script&gt;</pre>
                            <p><br></p>
                            <pre>&lt;!-- 아래 코드는 위젯을 노출할 영역에 삽입해주세요. --&gt;<br>&lt;div id="<span style="color: rgb(255,0,0);">recopick_widget_inflow</span>" <u style="line-height: 1.4285;font-family: Arial , sans-serif;">data-widget_type="reco_inflow_rate"</u>&gt;&lt;/div&gt;</pre>
                        </div>
                    </div>
                    <h4 id="ClientLibraryAPIs-통계형추천-가장많이본상품"><span style="line-height: 1.5;">통계형 추천 - 가장 많이 본 상품</span></h4>
                    <p>대시보드에서 가장 많이 본 상품 확인하기:&nbsp;<a href="https://admin.recopick.com/-/items" class="external-link">https://admin.recopick.com/-/items</a>&nbsp;</p>
                    <div class="panel conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="panel">
                        <div class="panelContent">
                            <pre>&lt;!-- 아래 코드는 SERVICE_NAME을 운영하시는 서비스의 URL에 맞게 recopick.com과 같이 변경하신 후 &lt;/head&gt; 직전에 삽입해주세요 --&gt;<br>&lt;script type="text/javascript"&gt;</pre>
                            <pre>  (function(w,d,n,s,e,o) {
    w[n]=w[n]||function(){(w[n].q=w[n].q||[]).push(arguments)};
    e=d.createElement(s);e.async=1;e.charset='utf-8';e.src='//static.recopick.com/dist/production.min.js';
    o=d.getElementsByTagName(s)[0];o.parentNode.insertBefore(e,o);
  })(window, document, 'recoPick', 'script');
  recoPick('service', 'SERVICE_NAME');
  recoPick('widget', 'recopick_widget_view'); // 로그 수집, 다른 추천 제공 등을 위해 위 코드를 이미 삽입하신 경우 이 줄만 넣어주세요!</pre>
                            <pre>&lt;/script&gt;</pre>
                            <p><br></p>
                            <pre>&lt;!-- 아래 코드는 위젯을 노출할 영역에 삽입해주세요. --&gt;<br>&lt;div id="<span style="color: rgb(255,0,0);">recopick_widget_view</span>" <u style="line-height: 1.4285;font-family: Arial , sans-serif;">data-widget_type="view"</u>&gt;&lt;/div&gt;</pre>
                        </div>
                    </div>
                    <h3 id="ClientLibraryAPIs-추천결과가없을때다른추천알고리즘을사용하기">추천 결과가 없을 때 다른 추천 알고리즘을 사용하기</h3>
                    <p>특정 추천 알고리즘(widget_type)으로 위젯을 노출하였으나 해당 알고리즘이 빈 결과를 반환하는 경우 위젯도 노출되지 않습니다. 이렇게 빈 결과를 반환하는 경우 다중 알고리즘 설정을 통해 다른 알고리즘의 추천 결과로 대체할 수 있습니다.</p>
                    <div class="panel conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="panel">
                        <div class="panelContent">
                            <pre>&lt;!-- 아래 코드는 SERVICE_NAME을 운영하시는 서비스의 URL에 맞게 recopick.com과 같이 변경하신 후 &lt;/head&gt; 직전에 삽입해주세요 --&gt;<br>&lt;script type="text/javascript"&gt;</pre>
                            <pre>  (function(w,d,n,s,e,o) {
    w[n]=w[n]||function(){(w[n].q=w[n].q||[]).push(arguments)};
    e=d.createElement(s);e.async=1;e.charset='utf-8';e.src='//static.recopick.com/dist/production.min.js';
    o=d.getElementsByTagName(s)[0];o.parentNode.insertBefore(e,o);
  })(window, document, 'recoPick', 'script');
  recoPick('service', 'SERVICE_NAME');
  recoPick('widget', 'recopick_widget_multi'); // 로그 수집, 다른 추천 제공 등을 위해 위 코드를 이미 삽입하신 경우 이 줄만 넣어주세요!</pre>
                            <pre>&lt;/script&gt;</pre>
                            <p><br></p>
                            <pre>&lt;!-- 아래 코드는 위젯을 노출할 영역에 삽입해주세요. --&gt;<br>&lt;div id="<span style="color: rgb(255,0,0);">recopick_widget_multi</span>" <u>data-widget_type="user,view"</u>&gt;&lt;/div&gt; &lt;!-- 개인화 추천(user)을 먼저 요청하고 빈 결과가 반환되면 가장 많이 본 상품(view) 위젯을 노출 --&gt;</pre>
                        </div>
                    </div>
                    <p><br></p>위의 코드는 아래와 같이 recoPick('widget') 함수의 인자로도 동일하게 구현할 수 있습니다.<div class="panel conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="panel">
                    <div class="panelContent">
                        <pre>&lt;!-- 아래 코드는 SERVICE_NAME을 운영하시는 서비스의 URL에 맞게 recopick.com과 같이 변경하신 후 &lt;/head&gt; 직전에 삽입해주세요 --&gt;<br>&lt;script type="text/javascript"&gt;</pre>
                        <pre>  (function(w,d,n,s,e,o) {
    w[n]=w[n]||function(){(w[n].q=w[n].q||[]).push(arguments)};
    e=d.createElement(s);e.async=1;e.charset='utf-8';e.src='//static.recopick.com/dist/production.min.js';
    o=d.getElementsByTagName(s)[0];o.parentNode.insertBefore(e,o);
  })(window, document, 'recoPick', 'script');
  recoPick('service', 'SERVICE_NAME');
  recoPick('widget', 'recopick_widget_multi', null, {widget_type: ['user', 'view']}); // 로그 수집, 다른 추천 제공 등을 위해 위 코드를 이미 삽입하신 경우 이 줄만 넣어주세요!</pre>
                        <pre>&lt;/script&gt;</pre>
                        <p><br></p>
                        <pre>&lt;!-- 아래 코드는 위젯을 노출할 영역에 삽입해주세요. --&gt;<br>&lt;div id="<span style="color: rgb(255,0,0);">recopick_widget_multi</span>"&gt;&lt;/div&gt;</pre>
                    </div>
                </div>
                    <h2 id="ClientLibraryAPIs-ABtestAPI"><strong style="line-height: 1.5;font-size: 20.0px;">AB test API</strong></h2>
                    <p><strong style="line-height: 1.5;font-size: 20.0px;"></strong></p>
                    <div class="conf-macro output-block" data-hasbody="false" data-macro-name="include"><strong style="line-height: 1.5;font-size: 20.0px;">AB test API를 이용하시면 운영하시는 상품 상세정보 페이지에 여러 조건의 추천 위젯을 통해 A/B 테스트를 간단히 수행할 수 있습니다.<h2 id="ClientLibraryAPIs-A/B테스트적용">A/B 테스트 적용</h2>
                        <p>A/B 테스트를 위해 먼저 필요한 것은 어떤 조건으로 테스트를 진행할지를 지정하는 것입니다.</p>
                        <ul>
                            <li><span style="line-height: 1.4285715;">먼저 대시보드의 AB 테스트 설정 페이지(&nbsp;</span><a href="https://admin.recopick.com/-/abtest" style="line-height: 1.4285715;" class="external-link">https://admin.recopick.com/-/abtest</a><span style="line-height: 1.4285715;">)에 접속하여 진행할 테스트 조건을 입력합니다.</span></li>
                        </ul>
                        <p><span style="line-height: 1.4285715;">&nbsp;</span><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/api_img03.png" data-image-src="/download/attachments/3735648/api_img03.png?version=1&amp;modificationDate=1416465721000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="3932411" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="api_img03.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="3735648" data-linked-resource-container-version="5"></span></p>
                        <ol>
                            <li><span style="line-height: 1.4285715;">테스트명: 이 테스트의 이름입니다. 적절히 작성하시면 테스트 중 혹은 테스트 후 결과를 확인하실 때 입력하신 테스트명으로 표시되어 편리합니다.</span></li>
                            <li><span style="line-height: 1.4285715;">테스트 시작일/종료일: 테스트를 특정 기간동안에만 수행하려고 하신다면 시작일과 종료일을 입력해주세요. 지정 안함을 선택하시면 기간과 상관없이 테스트가 진행됩니다.</span></li>
                            <li><span style="line-height: 1.4285715;">위젯: 해당 그룹에게 노출될 위젯입니다. 위젯은 추천 위젯 설정 페이지( </span><a href="https://admin.recopick.com/-/widget" style="line-height: 1.4285715;" class="external-link">https://admin.recopick.com/-/widget</a><span style="line-height: 1.4285715;">&nbsp;)에서 추가/수정하실 수 있습니다.</span></li>
                            <li><span style="line-height: 1.4285715;">채널명: 해당 그룹의 테스트 결과를 통계로 제공해드리는 식별자입니다. 채널명을 "test"라고 입력하시면 클릭률, 경유매출 등의 통계를 "abtest_test"라는 채널명으로 제공해드립니다.</span></li>
                            <li><span style="line-height: 1.4285715;">노출설정: 전체 사용자를 10개의 그룹으로 나누어 각 그룹별로 노출할 위젯을 선택합니다. 그룹1과 그룹2를 5:5로 테스트 하시려면 그룹0~4는 그룹1, 5~9는 그룹2에 체크해주시면 됩니다.</span></li>
                            <li><span style="line-height: 1.4285715;">그룹 추가 버튼을 통해 3개 이상의 그룹(최대 5개)으로 테스트 설정이 가능합니다.</span><br><span style="line-height: 1.4285715;"><br></span></li>
                        </ol>
                        <ul>
                            <li>테스트 조건 입력이 끝난 후 저장하시면 "코드 가져오기" 메뉴가 생성됩니다. "코드 가져오기" 메뉴를 클릭하면&nbsp;아래와 같이 위젯을 표시할 영역에 붙여넣을 수 있는 HTML 코드가 표시됩니다. 코드를 복사 후 위젯을 표시할 영역에 붙여넣으시면 됩니다.</li>
                        </ul>
                        <p>&nbsp;&nbsp;<span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/api_img04.png" data-image-src="/download/attachments/3735648/api_img04.png?version=1&amp;modificationDate=1416465884000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="3932412" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="api_img04.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="3735648" data-linked-resource-container-version="5"></span></p>
                        <h2 id="ClientLibraryAPIs-A/B테스트결과확인">A/B 테스트 결과 확인</h2>
                        <p>A/B 테스트 적용 다음날부터 대시보드를 통해 A/B 테스트의 결과를 확인하실 수 있습니다.</p>
                        <ul>
                            <li><span style="line-height: 1.4285715;">A/B 테스트 통계 페이지(&nbsp;</span><a href="https://admin.recopick.com/237/recommend/user_group_stat" style="line-height: 1.4285715;" class="external-link">https://admin.recopick.com/237/recommend/user_group_stat</a><span style="line-height: 1.4285715;">&nbsp;)에 접속합니다. A/B 테스트가 하나 이상 있다면 아래와 같이 데이터가 표시됩니다.</span></li>
                        </ul>
                        <p><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/api_img05.png" data-image-src="/download/attachments/3735648/api_img05.png?version=1&amp;modificationDate=1416466029000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="3932413" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="api_img05.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="3735648" data-linked-resource-container-version="5"></span></p>
                        <ol>
                            <li>통계의 사용자 기준입니다. 전체사용자의 데이터를 볼지, 구매자의 데이터를 볼지, 추천 클릭 사용자의 데이터를 볼지 선택합니다.</li>
                            <li>각 사용자 기준의 상세 데이터입니다. (1)에서 추천 클릭 사용자, (2)에서 그룹별 평균 매출을 선택했다면 각 그룹의 추천 클릭 사용자에 대한 평균 매출 데이터가 표시됩니다.</li>
                            <li>기준 A/B 테스트입니다. "A/B 테스트 적용" 단계에서 테스트 조건을 입력하셨을 때의 각 그룹별로 데이터가 제공됩니다.<br>가령, 선택한 기준 A/B 테스트에 테스트그룹0은 사용자그룹0~8까지, 테스트그룹1은 사용자그룹9가 지정되어 있었다면 테스트그룹0의 데이터는 그룹0~8의 평균, 테스트그룹1의 데이터는 그룹9의 데이터가 됩니다.</li>
                            <li>&nbsp;"사용자 그룹별" 탭을 선택하면 사용자그룹0~9까지 각 그룹의 데이터를 모두 확인하실 수 있습니다.</li>
                        </ol>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                    </strong></div><strong style="line-height: 1.5;font-size: 20.0px;"><br></strong>
                    <p></p>
                    <h2 id="ClientLibraryAPIs-UserIdentificationAPI"><strong style="line-height: 1.5;font-size: 20.0px;">User Identification API</strong></h2>
                </div>
                <div>
                    <div><span style="color: rgb(0,0,0);"><br></span></div>
                    <div><span style="color: rgb(0,0,0);">RecoPick 클라이언트 라이브러리는 쿠키에 기반한 자체적인 사용자 키(이하 UID)를 통한 분류 기능을 제공하고 있습니다. 만약 자체적으로 관리하시는 사용자 키가 있고 RecoPick에서도 그 키를 사용하고 싶다면 본 단락을 참고하여 UID를 지정해주시기 바랍니다.</span></div>
                    <p><span style="color: rgb(255,0,0);"><span style="color: rgb(0,51,102);">(</span>&nbsp;&nbsp;<img class="emoticon emoticon-warning" src="@/assets/images/warning.svg" data-emoticon-name="warning" alt="(warning)">&nbsp;잠깐! 이 API는 UID를 꼭 수정하고 싶으신 분들을 위한 API로써 가급적이면 사용하지 않는 것을 권장합니다</span>. )</p>
                    <p><span style="color: rgb(0,0,0);"><br></span></p>
                    <p><span style="color: rgb(0,0,0);">RecoPick의 UID를 통한 사용자별 구분은 한 사용자가 여러 페이지를 방문했을 때, 이 사용자가 방문한 페이지들을 추적할 수 있으며 이를 기반으로 고품질의 추천을 계산할 수 있게 합니다.</span></p>
                    <p><span style="color: rgb(255,0,0);"><strong><em><u><br></u></em></strong></span></p>
                    <p><span style="color: rgb(0,0,0);"><br class="_mce_tagged_br"></span></p>
                    <h3 id="ClientLibraryAPIs-setUID">setUID</h3>
                    <p>RecoPick에서 만드는 쿠키 기반 사용자 ID를 사용하지 않고 자체적인 &nbsp;ID를 사용하고자 한다면 아래와 같이 setUID 함수를 사용할 수 있습니다.</p>
                    <p>(예: 브라우저 쿠키 기반 사용자 식별값, 쿠키를 사용할 수 없는 모바일 네이티브 앱의 사용자 식별값)</p>
                    <p><span style="color: rgb(255,0,0);"><strong><em><u><strong><em><u>*Native(Hybrid)App에서 setUID 시에&nbsp; GAID값 혹은&nbsp; IDFA값을 반드시 넣으셔야 합니다.</u></em></strong></u></em></strong></span></p>
                    <p><span style="color: rgb(255,0,0);"><strong><em><u>*Native(Hybrid)App에서 sendLog 시에 setGAID 혹은 set IDFA값을 반드시 삽입하셔야 합니다.</u></em></strong></span></p>
                    <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                        <div class="codeContent panelContent pdl">
                            <div>
                                <div id="highlighter_848510" class="syntaxhighlighter sh-confluence  actionscript3">
                                    <div class="toolbar"><span><a href="/display/docs/Client+Library+APIs#" class="toolbar_item command_help help">?</a></span></div>
                                    <table border="0" cellpadding="0" cellspacing="0">
                                        <tbody>
                                        <tr>
                                            <td class="gutter">
                                                <div class="line number1 index0 alt2">1</div>
                                                <div class="line number2 index1 alt1">2</div>
                                                <div class="line number3 index2 alt2">3</div>
                                                <div class="line number4 index3 alt1">4</div>
                                                <div class="line number5 index4 alt2">5</div>
                                                <div class="line number6 index5 alt1">6</div>
                                                <div class="line number7 index6 alt2">7</div>
                                                <div class="line number8 index7 alt1">8</div>
                                                <div class="line number9 index8 alt2">9</div>
                                                <div class="line number10 index9 alt1">10</div>
                                            </td>
                                            <td class="code">
                                                <div class="container" title="Hint: double-click to select code">
                                                    <div class="line number1 index0 alt2"><code class="actionscript3 plain">&lt;script type=</code><code class="actionscript3 string">"text/javascript"</code><code class="actionscript3 plain">&gt;</code></div>
                                                    <div class="line number2 index1 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;</code><code class="actionscript3 plain">(</code><code class="actionscript3 color3">function</code><code class="actionscript3 plain">(w,d,n,s,e,o) {</code></div>
                                                    <div class="line number3 index2 alt2"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">w[n]=w[n]||</code><code class="actionscript3 color3">function</code><code class="actionscript3 plain">(){(w[n].q=w[n].q||[]).push(arguments)};</code></div>
                                                    <div class="line number4 index3 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">e=d.createElement(s);e.async=</code><code class="actionscript3 value">1</code><code class="actionscript3 plain">;e.charset=</code><code class="actionscript3 string">'utf-8'</code><code class="actionscript3 plain">;e.src=</code><code class="actionscript3 string">'//static.recopick.com/dist/production.min.js'</code><code class="actionscript3 plain">;</code></div>
                                                    <div class="line number5 index4 alt2"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">o=d.getElementsByTagName(s)[</code><code class="actionscript3 value">0</code><code class="actionscript3 plain">];o.parentNode.insertBefore(e,o);</code></div>
                                                    <div class="line number6 index5 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">})(window, document, </code><code class="actionscript3 string">'recoPick'</code><code class="actionscript3 plain">, </code><code class="actionscript3 string">'script'</code><code class="actionscript3 plain">);</code></div>
                                                    <div class="line number7 index6 alt2"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">recoPick(</code><code class="actionscript3 string">'service'</code><code class="actionscript3 plain">, </code><code class="actionscript3 string">'SERVICE_NAME'</code><code class="actionscript3 plain">);&nbsp;&nbsp; </code><code class="actionscript3 comments">// 반드시 필요한 부분이며,'SERVICE_NAME'은 수정해주셔야 합니다.</code></div>
                                                    <div class="line number8 index7 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">recoPick(</code><code class="actionscript3 string">'setUID'</code><code class="actionscript3 plain">, USER_ID);&nbsp;&nbsp;&nbsp;&nbsp; </code><code class="actionscript3 comments">// USER_ID는 자체적으로 사용하시는 사용자 id를 사용하면 됩니다. 이 값은 사용자별로 유일해야 합니다.</code></div>
                                                    <div class="line number9 index8 alt2"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 comments">// Native(Hybrid)App사용시 USER_ID값을 GAID 혹은 IDFA값을 반드시 넣으셔야 합니다.</code></div>
                                                    <div class="line number10 index9 alt1"><code class="actionscript3 plain">&lt;/script&gt;</code></div>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                  <p>필독! 고객사 사이트내 페이지 이동시 recopick에서 발급하는 recopick_uid(cookies)값이 변경되는 경우가 있습니다.(예, 제품 상세페이지  https://store-kr.xxx.com/ -->  구매완료페이지  https://secure.xxx.com/ ) </p>
                  <p>페이지 도메인 변경되는 고객사의 경우 UID값이 변경되어 추천기여매출등 성과 지표에 누락이 되는 경우가 발생하여 정확한 성과측정이 불가능 하게 됩니다. 이를 방지 하기 위해 도메인이 변경되더라도 동일한 UID값이 유지가 되도록 아래와 같은 방법을 적용해주셔야합니다.</p>
                  <ul>
                    <li>고객사 자체적으로 ID생성 방법
                      <ol>
                        <li><span style="line-height: 1.4285715;">생성 rule : 현재시간 + 랜덤수(10자리)로 만들고 만료기간은 60일 설정</span></li>
                        <li><span style="line-height: 1.4285715;">동일인이 방문시 그리고 제품상세페이지 --> 구매완료 페이지 이동시 60일간 같은 ID로 되도록 유지 해야함.</span></li>
                        <li><span style="line-height: 1.4285715;">상기와 같은 방법으로 생성한 ID를 위에 설명된 setUID 함수를 호출</span></li>
                        <li><span style="line-height: 1.4285715;">기존 recopick_uid --> 고객사 자체 생성 ID 변경이 완료</span></li>
                      </ol>
                    </li>
                  </ul>
                  <h3 id="ClientLibraryAPIs-fetchUID">fetchUID</h3>
                    <p>UID를 얻어올 수 있는 함수입니다. setUID를 통해 UID를 지정했다면 그 값이, 그렇지 않은 경우 RecoPick에서 생성하는 사용자 식별값이 반환됩니다.</p>
                    <p>비동기로 동작하는 함수이며, 구현 방법은 아래 예제를 참고해주시기 바랍니다.</p>
                    <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                        <div class="codeContent panelContent pdl">
                            <div>
                                <div id="highlighter_208145" class="syntaxhighlighter sh-confluence  actionscript3">
                                    <div class="toolbar"><span><a href="/display/docs/Client+Library+APIs#" class="toolbar_item command_help help">?</a></span></div>
                                    <table border="0" cellpadding="0" cellspacing="0">
                                        <tbody>
                                        <tr>
                                            <td class="gutter">
                                                <div class="line number1 index0 alt2">1</div>
                                                <div class="line number2 index1 alt1">2</div>
                                                <div class="line number3 index2 alt2">3</div>
                                                <div class="line number4 index3 alt1">4</div>
                                                <div class="line number5 index4 alt2">5</div>
                                                <div class="line number6 index5 alt1">6</div>
                                                <div class="line number7 index6 alt2">7</div>
                                                <div class="line number8 index7 alt1">8</div>
                                                <div class="line number9 index8 alt2">9</div>
                                                <div class="line number10 index9 alt1">10</div>
                                                <div class="line number11 index10 alt2">11</div>
                                            </td>
                                            <td class="code">
                                                <div class="container" title="Hint: double-click to select code">
                                                    <div class="line number1 index0 alt2"><code class="actionscript3 plain">&lt;script type=</code><code class="actionscript3 string">"text/javascript"</code><code class="actionscript3 plain">&gt;</code></div>
                                                    <div class="line number2 index1 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;</code><code class="actionscript3 plain">(</code><code class="actionscript3 color3">function</code><code class="actionscript3 plain">(w,d,n,s,e,o) {</code></div>
                                                    <div class="line number3 index2 alt2"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">w[n]=w[n]||</code><code class="actionscript3 color3">function</code><code class="actionscript3 plain">(){(w[n].q=w[n].q||[]).push(arguments)};</code></div>
                                                    <div class="line number4 index3 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">e=d.createElement(s);e.async=</code><code class="actionscript3 value">1</code><code class="actionscript3 plain">;e.charset=</code><code class="actionscript3 string">'utf-8'</code><code class="actionscript3 plain">;e.src=</code><code class="actionscript3 string">'//static.recopick.com/dist/production.min.js'</code><code class="actionscript3 plain">;</code></div>
                                                    <div class="line number5 index4 alt2"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">o=d.getElementsByTagName(s)[</code><code class="actionscript3 value">0</code><code class="actionscript3 plain">];o.parentNode.insertBefore(e,o);</code></div>
                                                    <div class="line number6 index5 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">})(window, document, </code><code class="actionscript3 string">'recoPick'</code><code class="actionscript3 plain">, </code><code class="actionscript3 string">'script'</code><code class="actionscript3 plain">);</code></div>
                                                    <div class="line number7 index6 alt2"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">recoPick(</code><code class="actionscript3 string">'service'</code><code class="actionscript3 plain">, </code><code class="actionscript3 string">'SERVICE_NAME'</code><code class="actionscript3 plain">);&nbsp;&nbsp; </code><code class="actionscript3 comments">// 반드시 필요한 부분이며,'SERVICE_NAME'은 수정해주셔야 합니다.</code></div>
                                                    <div class="line number8 index7 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">recoPick(</code><code class="actionscript3 string">'fetchUID'</code><code class="actionscript3 plain">, </code><code class="actionscript3 color3">function</code> <code class="actionscript3 plain">(uid) {</code></div>
                                                    <div class="line number9 index8 alt2"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 comments">// 변수 uid에 UID 값이 들어갑니다.</code></div>
                                                    <div class="line number10 index9 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">});</code></div>
                                                    <div class="line number11 index10 alt2"><code class="actionscript3 plain">&lt;/script&gt;</code></div>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>RecoPick에서 생성하는 사용자 식별값은 개발의 편의성을 위해 운영하시는 사이트에 recopick_uid라는 이름의 쿠키로도 남기고 있습니다.</div>
                    <div>만약 서버사이드에서 로그 전송 혹은 추천 API 호출을 하시는 경우 recopick_uid라는 쿠키가 있다면 그 쿠키를 이용하여 uid 값을 지정해주시면 됩니다.</div>
                    <div>
                        <p><br></p>
                        <p>php 예제</p>
                        <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                            <div class="codeContent panelContent pdl">
                                <div>
                                    <div id="highlighter_518102" class="syntaxhighlighter sh-eclipse nogutter  javafx">
                                        <div class="toolbar"><span><a href="/display/docs/Client+Library+APIs#" class="toolbar_item command_help help">?</a></span></div>
                                        <table border="0" cellpadding="0" cellspacing="0">
                                            <tbody>
                                            <tr>
                                                <td class="code">
                                                    <div class="container" title="Hint: double-click to select code">
                                                        <div class="line number1 index0 alt2"><code class="javafx plain">&lt;?php</code></div>
                                                        <div class="line number2 index1 alt1"><code class="javafx plain">$recopick_uid = $_COOKIE[</code><code class="javafx string">'recopick_uid'</code><code class="javafx plain">];</code></div>
                                                        <div class="line number3 index2 alt2"><code class="javafx plain">echo $recopick_uid;</code></div>
                                                        <div class="line number4 index3 alt1"><code class="javafx plain">?&gt;</code></div>
                                                    </div>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h3 id="ClientLibraryAPIs-setMID">setMID</h3>
                    <div>자체적으로 관리하는 회원과 RecoPick의 UID를 매핑하려면 setMID를 통해 사용자의 아이디 값을 지정할 수 있습니다.</div>
                    <div>예를 들어 같은 회원이 PC와 모바일을 통해 접속한다면 UID는 각각 다른 값이 되어 다른 사용자로 인식되지만 MID를 통해 매핑하면 RecoPick에서도 두 사용자를 같은 회원으로 인식하여 개인화 추천 등의 품질 향상에 도움이 됩니다.</div>
                    <div>MID를 지정한 후 <a href="/pages/viewpage.action?pageId=9568307#ClientLibraryAPIs-LoggingA">Logging API</a>를 통해 로그를 전송하면 개인화 추천 API를 통해 특정 회원의 추천 상품을 요청할 수 있게 되어 이메일 마케팅 등에 활용할 수 있습니다.</div>
                    <div><span style="color: rgb(153,51,0);"><br></span></div>
                    <div><span style="color: rgb(153,51,0);"><img class="emoticon emoticon-warning" src="@/assets/images/warning.svg" data-emoticon-name="warning" alt="(warning)">&nbsp;개인정보보호를 위해 회원의 아이디를 암호화하여 보내주시기 바랍니다. (암호화시 특수문자 제거 필요)</span></div>
                    <div>
                        <p><br></p>
                        <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                            <div class="codeContent panelContent pdl">
                                <div>
                                    <div id="highlighter_773792" class="syntaxhighlighter sh-confluence  actionscript3">
                                        <div class="toolbar"><span><a href="/display/docs/Client+Library+APIs#" class="toolbar_item command_help help">?</a></span></div>
                                        <table border="0" cellpadding="0" cellspacing="0">
                                            <tbody>
                                            <tr>
                                                <td class="gutter">
                                                    <div class="line number1 index0 alt2">1</div>
                                                    <div class="line number2 index1 alt1">2</div>
                                                    <div class="line number3 index2 alt2">3</div>
                                                    <div class="line number4 index3 alt1">4</div>
                                                    <div class="line number5 index4 alt2">5</div>
                                                    <div class="line number6 index5 alt1">6</div>
                                                    <div class="line number7 index6 alt2">7</div>
                                                    <div class="line number8 index7 alt1">8</div>
                                                    <div class="line number9 index8 alt2">9</div>
                                                    <div class="line number10 index9 alt1">10</div>
                                                </td>
                                                <td class="code">
                                                    <div class="container" title="Hint: double-click to select code">
                                                        <div class="line number1 index0 alt2"><code class="actionscript3 plain">&lt;script type=</code><code class="actionscript3 string">"text/javascript"</code><code class="actionscript3 plain">&gt;</code></div>
                                                        <div class="line number2 index1 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;</code><code class="actionscript3 plain">(</code><code class="actionscript3 color3">function</code><code class="actionscript3 plain">(w,d,n,s,e,o) {</code></div>
                                                        <div class="line number3 index2 alt2"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">w[n]=w[n]||</code><code class="actionscript3 color3">function</code><code class="actionscript3 plain">(){(w[n].q=w[n].q||[]).push(arguments)};</code></div>
                                                        <div class="line number4 index3 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">e=d.createElement(s);e.async=</code><code class="actionscript3 value">1</code><code class="actionscript3 plain">;e.charset=</code><code class="actionscript3 string">'utf-8'</code><code class="actionscript3 plain">;e.src=</code><code class="actionscript3 string">'//static.recopick.com/dist/production.min.js'</code><code class="actionscript3 plain">;</code></div>
                                                        <div class="line number5 index4 alt2"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">o=d.getElementsByTagName(s)[</code><code class="actionscript3 value">0</code><code class="actionscript3 plain">];o.parentNode.insertBefore(e,o);</code></div>
                                                        <div class="line number6 index5 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">})(window, document, </code><code class="actionscript3 string">'recoPick'</code><code class="actionscript3 plain">, </code><code class="actionscript3 string">'script'</code><code class="actionscript3 plain">);</code></div>
                                                        <div class="line number7 index6 alt2"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">recoPick(</code><code class="actionscript3 string">'service'</code><code class="actionscript3 plain">, </code><code class="actionscript3 string">'SERVICE_NAME'</code><code class="actionscript3 plain">);&nbsp;&nbsp; </code><code class="actionscript3 comments">// 반드시 필요한 부분이며,'SERVICE_NAME'은 수정해주셔야 합니다.</code></div>
                                                        <div class="line number8 index7 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">recoPick(</code><code class="actionscript3 string">'setMID'</code><code class="actionscript3 plain">, MEMBER_ID);&nbsp;&nbsp;&nbsp;&nbsp; </code><code class="actionscript3 comments">// MEMEBR_ID는 자체적으로 사용하시는 회원 id를 사용하면 됩니다. 이 값은 사용자별로 유일해야 합니다.</code></div>
                                                        <div class="line number9 index8 alt2"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">recoPick(</code><code class="actionscript3 string">'sendLog'</code><code class="actionscript3 plain">, </code><code class="actionscript3 string">'view'</code><code class="actionscript3 plain">, </code><code class="actionscript3 string">'123'</code><code class="actionscript3 plain">);&nbsp;&nbsp; </code><code class="actionscript3 comments">// setMID는 설정의 개념이고, 'sendLog' 함수 호출 시 로그가 전송되므로, 반드시 'sendLog' 함수 이전에 setMID를 호출해주셔야 정상적으로 반영이 됩니다.</code></div>
                                                        <div class="line number10 index9 alt1"><code class="actionscript3 plain">&lt;/script&gt;</code></div>
                                                    </div>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p><br></p><img class="emoticon emoticon-warning" src="@/assets/images/warning.svg" data-emoticon-name="warning" alt="(warning)"><span style="color: rgb(153,51,0);">&nbsp;setMID 호출은 sendLog 이전에 호출 하셔야 적용이 됩니다.</span>
                        <h3 id="ClientLibraryAPIs-setUserInfo">setUserInfo</h3>
                        <p>사용자의 성별, 연령 등의 데이터를 전송해주시면 각 사용자에게 더욱 특화된 추천을 제공해 드립니다.</p>
                        <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                            <div class="codeContent panelContent pdl">
                                <div>
                                    <div id="highlighter_290478" class="syntaxhighlighter sh-confluence  actionscript3">
                                        <div class="toolbar"><span><a href="/display/docs/Client+Library+APIs#" class="toolbar_item command_help help">?</a></span></div>
                                        <table border="0" cellpadding="0" cellspacing="0">
                                            <tbody>
                                            <tr>
                                                <td class="gutter">
                                                    <div class="line number1 index0 alt2">1</div>
                                                    <div class="line number2 index1 alt1">2</div>
                                                    <div class="line number3 index2 alt2">3</div>
                                                    <div class="line number4 index3 alt1">4</div>
                                                    <div class="line number5 index4 alt2">5</div>
                                                    <div class="line number6 index5 alt1">6</div>
                                                    <div class="line number7 index6 alt2">7</div>
                                                    <div class="line number8 index7 alt1">8</div>
                                                    <div class="line number9 index8 alt2">9</div>
                                                    <div class="line number10 index9 alt1">10</div>
                                                    <div class="line number11 index10 alt2">11</div>
                                                    <div class="line number12 index11 alt1">12</div>
                                                    <div class="line number13 index12 alt2">13</div>
                                                    <div class="line number14 index13 alt1">14</div>
                                                    <div class="line number15 index14 alt2">15</div>
                                                    <div class="line number16 index15 alt1">16</div>
                                                </td>
                                                <td class="code">
                                                    <div class="container" title="Hint: double-click to select code">
                                                        <div class="line number1 index0 alt2"><code class="actionscript3 plain">&lt;script type=</code><code class="actionscript3 string">"text/javascript"</code><code class="actionscript3 plain">&gt;</code></div>
                                                        <div class="line number2 index1 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;</code><code class="actionscript3 plain">(</code><code class="actionscript3 color3">function</code><code class="actionscript3 plain">(w,d,n,s,e,o) {</code></div>
                                                        <div class="line number3 index2 alt2"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">w[n]=w[n]||</code><code class="actionscript3 color3">function</code><code class="actionscript3 plain">(){(w[n].q=w[n].q||[]).push(arguments)};</code></div>
                                                        <div class="line number4 index3 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">e=d.createElement(s);e.async=</code><code class="actionscript3 value">1</code><code class="actionscript3 plain">;e.charset=</code><code class="actionscript3 string">'utf-8'</code><code class="actionscript3 plain">;e.src=</code><code class="actionscript3 string">'//static.recopick.com/dist/production.min.js'</code><code class="actionscript3 plain">;</code></div>
                                                        <div class="line number5 index4 alt2"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">o=d.getElementsByTagName(s)[</code><code class="actionscript3 value">0</code><code class="actionscript3 plain">];o.parentNode.insertBefore(e,o);</code></div>
                                                        <div class="line number6 index5 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">})(window, document, </code><code class="actionscript3 string">'recoPick'</code><code class="actionscript3 plain">, </code><code class="actionscript3 string">'script'</code><code class="actionscript3 plain">);</code></div>
                                                        <div class="line number7 index6 alt2"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">recoPick(</code><code class="actionscript3 string">'service'</code><code class="actionscript3 plain">, </code><code class="actionscript3 string">'SERVICE_NAME'</code><code class="actionscript3 plain">);&nbsp;&nbsp; </code><code class="actionscript3 comments">// 반드시 필요한 부분이며,'SERVICE_NAME'은 수정해주셔야 합니다.</code></div>
                                                        <div class="line number8 index7 alt1">&nbsp;</div>
                                                        <div class="line number9 index8 alt2"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">recoPick(</code><code class="actionscript3 string">'setUserInfo'</code><code class="actionscript3 plain">, {</code></div>
                                                        <div class="line number10 index9 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">birthyear: </code><code class="actionscript3 value">1985</code><code class="actionscript3 plain">,&nbsp;&nbsp;&nbsp; </code><code class="actionscript3 comments">// 출생연도</code></div>
                                                        <div class="line number11 index10 alt2"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">gender: </code><code class="actionscript3 string">'F'</code>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <code class="actionscript3 comments">// M: 남자, F: 여자, O: 기타</code></div>
                                                        <div class="line number12 index11 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">});</code></div>
                                                        <div class="line number13 index12 alt2"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 comments">// birthyear, gender 중 한가지만 선언하시는 경우 아래와 같은 방법으로 코드를 작성해주셔도 됩니다.</code></div>
                                                        <div class="line number14 index13 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 comments">// recoPick('setUserInfo', 'birthyear', 1994);</code></div>
                                                        <div class="line number15 index14 alt2"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">recoPick(</code><code class="actionscript3 string">'sendLog'</code><code class="actionscript3 plain">, </code><code class="actionscript3 string">'view'</code><code class="actionscript3 plain">, </code><code class="actionscript3 string">'123'</code><code class="actionscript3 plain">);&nbsp;&nbsp;&nbsp; </code><code class="actionscript3 comments">// setUserInfo는 설정의 개념이고, 'sendLog' 함수 호출 시 로그가 전송되므로, 반드시 'sendLog' 함수 이전에 setUserInfo를 호출해주셔야 정상적으로 반영이 됩니다.</code></div>
                                                        <div class="line number16 index15 alt1"><code class="actionscript3 plain">&lt;/script&gt;</code></div>
                                                    </div>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>아래는 setUserInfo 함수에 지정할 수 있는 속성입니다. 한 가지 이상 지정해주시면 됩니다.</div>
                        <div class="table-wrap">
                            <table class="wrapped confluenceTable">
                                <colgroup>
                                    <col>
                                    <col>
                                </colgroup>
                                <tbody>
                                <tr>
                                    <th style="text-align: center;" colspan="1" class="confluenceTh"><strong>birthyear</strong></th>
                                    <td colspan="1" class="confluenceTd">
                                        <p>출생연도 (반드시 4자리로 세팅)</p>
                                        <p>예: 1987</p>
                                    </td>
                                </tr>
                                <tr>
                                    <th colspan="1" class="confluenceTh">gender</th>
                                    <td colspan="1" class="confluenceTd">
                                        <p>성별 (M: 남자, F: 여자, O: 기타)</p>
                                        <p>예: F</p>
                                    </td>
                                </tr>
                                <tr>
                                    <th colspan="1" class="confluenceTh">mid</th>
                                    <td colspan="1" class="confluenceTd">
                                        <p>자체적으로 사용하시는 회원ID</p>
                                        <p><span>(setMID 함수와 같은 역할을 합니다.)</span></p>
                                    </td>
                                </tr>
                                <tr>
                                    <th colspan="1" class="confluenceTh">uid</th>
                                    <td colspan="1" class="confluenceTd">
                                        <p>RecoPick에서 만드는 쿠키 기반 사용자 ID를 사용하지 않고 자체적인&nbsp;ID를 사용하고자 하시는 경우</p>
                                        <p>(setUID 함수와 같은 역할을 합니다.)</p>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <p><img class="emoticon emoticon-warning" src="@/assets/images/warning.svg" data-emoticon-name="warning" alt="(warning)"><span style="color: rgb(153,51,0);">&nbsp;setUserInfo 호출은 sendLog 이전에 호출 하셔야 적용이 됩니다.</span></p>
                        <h2 id="ClientLibraryAPIs-DeviceIdentificationAPI"><strong>Device&nbsp;Identification API</strong></h2>
                        <p>플랫폼에서 제공하는 Device의 고유 식별 값을 지정하기 위한 API입니다. Android에서는 <span>GAID(Google Advertising Identifier) 또는 AAID(Android Advertising Identifier)라는 이름으로 제공되고, IOS는 IDFA(Identifier For Advertising) 또는 IFA 라는 이름으로 제공 합니다.</span></p>
                        <p><u><span style="color: rgb(255,0,0);">*Native(Hybrid) App에서 sendLog 전에&nbsp; 반드시 setGAID&nbsp; 혹은 setIDFA를 설정하셔야합니다.</span></u><span><br></span></p>
                        <h3 id="ClientLibraryAPIs-setGAID">setGAID</h3>
                        <p>Android 에서 제공하는 GAID(Google Advertising ID)&nbsp;또는 AAID(Android Advertising Identifier) 값을 지정하기 위한 API 입니다.&nbsp;</p>
                        <p><a href="https://developers.google.com/android/reference/com/google/android/gms/ads/identifier/AdvertisingIdClient" class="external-link"><img class="emoticon emoticon-information" src="@/assets/images/information.svg" data-emoticon-name="information" alt="(정보)">&nbsp;Reference</a></p>
                        <div>
                            <p><br></p>
                            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                                <div class="codeContent panelContent pdl">
                                    <div>
                                        <div id="highlighter_364135" class="syntaxhighlighter sh-confluence  actionscript3">
                                            <div class="toolbar"><span><a href="/display/docs/Client+Library+APIs#" class="toolbar_item command_help help">?</a></span></div>
                                            <table border="0" cellpadding="0" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="gutter">
                                                        <div class="line number1 index0 alt2">1</div>
                                                        <div class="line number2 index1 alt1">2</div>
                                                        <div class="line number3 index2 alt2">3</div>
                                                        <div class="line number4 index3 alt1">4</div>
                                                        <div class="line number5 index4 alt2">5</div>
                                                        <div class="line number6 index5 alt1">6</div>
                                                        <div class="line number7 index6 alt2">7</div>
                                                        <div class="line number8 index7 alt1">8</div>
                                                        <div class="line number9 index8 alt2">9</div>
                                                        <div class="line number10 index9 alt1">10</div>
                                                        <div class="line number11 index10 alt2">11</div>
                                                    </td>
                                                    <td class="code">
                                                        <div class="container" title="Hint: double-click to select code">
                                                            <div class="line number1 index0 alt2"><code class="actionscript3 plain">&lt;script type=</code><code class="actionscript3 string">"text/javascript"</code><code class="actionscript3 plain">&gt;</code></div>
                                                            <div class="line number2 index1 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;</code><code class="actionscript3 plain">(</code><code class="actionscript3 color3">function</code><code class="actionscript3 plain">(w,d,n,s,e,o) {</code></div>
                                                            <div class="line number3 index2 alt2"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">w[n]=w[n]||</code><code class="actionscript3 color3">function</code><code class="actionscript3 plain">(){(w[n].q=w[n].q||[]).push(arguments)};</code></div>
                                                            <div class="line number4 index3 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">e=d.createElement(s);e.async=</code><code class="actionscript3 value">1</code><code class="actionscript3 plain">;e.charset=</code><code class="actionscript3 string">'utf-8'</code><code class="actionscript3 plain">;e.src=</code><code class="actionscript3 string">'//static.recopick.com/dist/production.min.js'</code><code class="actionscript3 plain">;</code></div>
                                                            <div class="line number5 index4 alt2"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">o=d.getElementsByTagName(s)[</code><code class="actionscript3 value">0</code><code class="actionscript3 plain">];o.parentNode.insertBefore(e,o);</code></div>
                                                            <div class="line number6 index5 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">})(window, document, </code><code class="actionscript3 string">'recoPick'</code><code class="actionscript3 plain">, </code><code class="actionscript3 string">'script'</code><code class="actionscript3 plain">);</code></div>
                                                            <div class="line number7 index6 alt2"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">recoPick(</code><code class="actionscript3 string">'service'</code><code class="actionscript3 plain">, </code><code class="actionscript3 string">'SERVICE_NAME'</code><code class="actionscript3 plain">);&nbsp;&nbsp; </code><code class="actionscript3 comments">// 반드시 필요한 부분이며,'SERVICE_NAME'은 수정해주셔야 합니다.</code></div>
                                                            <div class="line number8 index7 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">recoPick(</code><code class="actionscript3 string">'setUID'</code><code class="actionscript3 plain">, GAID);&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </code><code class="actionscript3 comments">// GAID를 UID로 지정하시면 됩니다.</code></div>
                                                            <div class="line number9 index8 alt2"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">recoPick(</code><code class="actionscript3 string">'setGAID'</code><code class="actionscript3 plain">, GAID);&nbsp;&nbsp;&nbsp;&nbsp; </code><code class="actionscript3 comments">// GAID를 지정하시면 됩니다.</code></div>
                                                            <div class="line number10 index9 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">recoPick(</code><code class="actionscript3 string">'sendLog'</code><code class="actionscript3 plain">, </code><code class="actionscript3 string">'view'</code><code class="actionscript3 plain">, </code><code class="actionscript3 string">'123'</code><code class="actionscript3 plain">);&nbsp;&nbsp; </code><code class="actionscript3 comments">// setGAID는 설정의 개념이고, 'sendLog' 함수 호출 시 로그가 전송되므로, 반드시 'sendLog' 함수 이전에 setGAID를 호출해주셔야 정상적으로 반영이 됩니다.</code></div>
                                                            <div class="line number11 index10 alt2"><code class="actionscript3 plain">&lt;/script&gt;</code></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p><img class="emoticon emoticon-warning" src="@/assets/images/warning.svg" data-emoticon-name="warning" alt="(warning)"><span style="color: rgb(153,51,0);">&nbsp;setGAID 호출은 sendLog 이전에 호출 하셔야 적용이 됩니다.</span></p>
                            <h3 id="ClientLibraryAPIs-setIDFA">setIDFA</h3>
                            <p>IOS에서 제공하는 IDFA(Identifier For Advertising) 또는 IFA 값을 지정하기 위한 API 입니다.&nbsp;</p>
                            <p><a href="https://developer.apple.com/library/ios/documentation/AdSupport/Reference/ASIdentifierManager_Ref/" class="external-link"><img class="emoticon emoticon-information" src="@/assets/images/information.svg" data-emoticon-name="information" alt="(정보)">&nbsp;Reference</a></p>
                            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                                <div class="codeContent panelContent pdl">
                                    <div>
                                        <div id="highlighter_192407" class="syntaxhighlighter sh-confluence  actionscript3">
                                            <div class="toolbar"><span><a href="/display/docs/Client+Library+APIs#" class="toolbar_item command_help help">?</a></span></div>
                                            <table border="0" cellpadding="0" cellspacing="0">
                                                <tbody>
                                                <tr>
                                                    <td class="gutter">
                                                        <div class="line number1 index0 alt2">1</div>
                                                        <div class="line number2 index1 alt1">2</div>
                                                        <div class="line number3 index2 alt2">3</div>
                                                        <div class="line number4 index3 alt1">4</div>
                                                        <div class="line number5 index4 alt2">5</div>
                                                        <div class="line number6 index5 alt1">6</div>
                                                        <div class="line number7 index6 alt2">7</div>
                                                        <div class="line number8 index7 alt1">8</div>
                                                        <div class="line number9 index8 alt2">9</div>
                                                        <div class="line number10 index9 alt1">10</div>
                                                        <div class="line number11 index10 alt2">11</div>
                                                    </td>
                                                    <td class="code">
                                                        <div class="container" title="Hint: double-click to select code">
                                                            <div class="line number1 index0 alt2"><code class="actionscript3 plain">&lt;script type=</code><code class="actionscript3 string">"text/javascript"</code><code class="actionscript3 plain">&gt;</code></div>
                                                            <div class="line number2 index1 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;</code><code class="actionscript3 plain">(</code><code class="actionscript3 color3">function</code><code class="actionscript3 plain">(w,d,n,s,e,o) {</code></div>
                                                            <div class="line number3 index2 alt2"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">w[n]=w[n]||</code><code class="actionscript3 color3">function</code><code class="actionscript3 plain">(){(w[n].q=w[n].q||[]).push(arguments)};</code></div>
                                                            <div class="line number4 index3 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">e=d.createElement(s);e.async=</code><code class="actionscript3 value">1</code><code class="actionscript3 plain">;e.charset=</code><code class="actionscript3 string">'utf-8'</code><code class="actionscript3 plain">;e.src=</code><code class="actionscript3 string">'//static.recopick.com/dist/production.min.js'</code><code class="actionscript3 plain">;</code></div>
                                                            <div class="line number5 index4 alt2"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">o=d.getElementsByTagName(s)[</code><code class="actionscript3 value">0</code><code class="actionscript3 plain">];o.parentNode.insertBefore(e,o);</code></div>
                                                            <div class="line number6 index5 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">})(window, document, </code><code class="actionscript3 string">'recoPick'</code><code class="actionscript3 plain">, </code><code class="actionscript3 string">'script'</code><code class="actionscript3 plain">);</code></div>
                                                            <div class="line number7 index6 alt2"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">recoPick(</code><code class="actionscript3 string">'service'</code><code class="actionscript3 plain">, </code><code class="actionscript3 string">'SERVICE_NAME'</code><code class="actionscript3 plain">);&nbsp;&nbsp; </code><code class="actionscript3 comments">// 반드시 필요한 부분이며,'SERVICE_NAME'은 수정해주셔야 합니다.</code></div>
                                                            <div class="line number8 index7 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">recoPick(</code><code class="actionscript3 string">'setUID'</code><code class="actionscript3 plain">, IDFA);&nbsp;&nbsp;&nbsp;&nbsp; </code><code class="actionscript3 comments">// IDFA를 UID로 지정하시면 됩니다.</code></div>
                                                            <div class="line number9 index8 alt2"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">recoPick(</code><code class="actionscript3 string">'setIDFA'</code><code class="actionscript3 plain">, IDFA);&nbsp;&nbsp;&nbsp;&nbsp; </code><code class="actionscript3 comments">// IDFA를 지정하시면 됩니다.</code></div>
                                                            <div class="line number10 index9 alt1"><code class="actionscript3 spaces">&nbsp;&nbsp;&nbsp;</code><code class="actionscript3 plain">recoPick(</code><code class="actionscript3 string">'sendLog'</code><code class="actionscript3 plain">, </code><code class="actionscript3 string">'view'</code><code class="actionscript3 plain">, </code><code class="actionscript3 string">'123'</code><code class="actionscript3 plain">);&nbsp;&nbsp; </code><code class="actionscript3 comments">// setIDFA는 설정의 개념이고, 'sendLog' 함수 호출 시 로그가 전송되므로, 반드시 'sendLog' 함수 이전에 setIDFA를 호출해주셔야 정상적으로 반영상적으로 반영이 됩니다.</code></div>
                                                            <div class="line number11 index10 alt2"><code class="actionscript3 plain">&lt;/script&gt;</code></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p><img class="emoticon emoticon-warning" src="@/assets/images/warning.svg" data-emoticon-name="warning" alt="(warning)"><span style="color: rgb(153,51,0);">&nbsp;setIDFA 호출은 sendLog 이전에 호출 하셔야 적용이 됩니다.</span></p>
                        </div>
                        <h2 id="ClientLibraryAPIs-AdvancedUsageExample"><strong style="font-size: 20.0px;">Advanced Usage Example</strong></h2>
                    </div>
                    <div>
                        <p>onclick 등의 이벤트에 recoPick 함수를 붙여서 클릭 로그를 추가할 수 있습니다. 페이지 전환 없이 ajax 호출로 컨텐츠를 불러오는 경우 유용하게 활용할 수 있습니다.</p>
                        <pre>&lt;a href="#some_item_detail" onclick="recoPick('view', '...')"&gt;Some link&lt;/a&gt;
</pre><span class="mw-headline"><span style="font-weight: bold;"><br></span></span>
                    </div>
                </div>
            </div>
            <div><span style="font-family: monospace;white-space: pre;"><br></span></div>
            <div><span style="color: rgb(153,51,0);"><br></span></div>
            <p><span style="color: rgb(153,153,153);">&nbsp;</span></p>




        </div>
    </div>
</template>