<template>
  <div class="page view">
    <h1>03) PV, UV 통계 (월간)</h1>
    <div id="main-content" class="wiki-content">
      <br>
      <p>PV, UV(방문자수) 데이터를 월 단위로 제공합니다. 지난달까지의 데이터까지 제공됩니다.</p>
      <p><span style="color: rgb(0,0,0);">GET 방식으로 요청하실 수 있고 정상적으로 요청이 완료되는 경우는 JSON 형태의 추천리스트가 반환됩니다. callback 파라미터를 명시하는 경우 JSONP 형태로 응답합니다.</span></p>
      <p><span style="color: rgb(0,0,0);">요청에 실패할 경우 상황에 따라 4XX 혹은 5XX형태의 에러코드가 반환됩니다.</span></p>
      <h3 id="id-02)PV,UV통계(주간)-ResourceURL"><span style="color: rgb(0,0,0);">Resource URL</span></h3>
      <p>
        <span style="color: rgb(0,0,0);">https://api.recopick.com/1/admin/</span><span style="color: rgb(255,0,0);"><strong>:service_id</strong></span><span style="color: rgb(0,0,0);">/feed/monthlypvuv</span>
      </p>
      <p> </p>
      <h3 id="id-02)PV,UV통계(주간)-Parameters"><span class="mw-headline">Parameters</span></h3>
      <div class="table-wrap">
        <table class="confluenceTable">
          <tbody>
          <tr>
            <th class="confluenceTh">
              <p>service_id</p>
              <p><sup><span style="color: rgb(153,153,153);">(required)</span></sup></p>
            </th>
            <td class="confluenceTd">
              <p><span style="color: rgb(34,34,34);">RecoPick에서 발급된 service id를 의미합니다. service id <span style="color: rgb(34,34,34);">값은 </span><a href="http://admin.recopick.com/-/api_info" class="external-link">어드민 대시보드</a><span style="color: rgb(34,34,34);"> </span><span style="color: rgb(34,34,34);">에서 확인하실 수 있습니다.</span></span></p>
              <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> 17</span></code></p>
            </td>
          </tr>
          <tr>
            <th class="confluenceTh">
              <p>access_key</p>
              <p><sup><span style="color: rgb(153,153,153);">(required)</span></sup></p>
            </th>
            <td class="confluenceTd">
              <p><span style="color: rgb(34,34,34);">보안이 적용된 API를 호출하기 위해 사용되는 비밀키를 의미합니다. 비밀키 값은 <a href="http://admin.recopick.com/-/api_info" class="external-link">어드민 대시보드</a>에서 확인하실 수 있습니다.</span></p>
              <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> 1f385dca73fb935cb11f8a246ea85f94</span></code></p>
            </td>
          </tr>
          <tr>
            <th colspan="1" class="confluenceTh">
              <p>ago</p>
              <p><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></p>
            </th>
            <td colspan="1" class="confluenceTd">
              <p><span style="color: rgb(34,34,34);">읽어올 데이터의 항목수를 의미합니다. 기본값은 12입니다.</span></p>
              <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> 12</span></code></p>
            </td>
          </tr>
          <tr>
            <th colspan="1" class="confluenceTh">
              <p>skip</p>
              <p><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></p>
            </th>
            <td colspan="1" class="confluenceTd">
              <p><span style="color: rgb(34,34,34);">지정된 값만큼 이전 데이터를 읽어옵니다. 0으로 지정할 경우 지난달의 데이터까지, 1로 지정할 경우 지지난달의 데이터까지 읽어옵니다. 기본값은 0입니다.</span></p>
              <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> 12</span></code></p>
            </td>
          </tr>
          <tr>
            <th class="confluenceTh">
              <p>callback</p>
              <p><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></p>
            </th>
            <td class="confluenceTd">
              <p><span style="color: rgb(34,34,34);"><span>callback을 입력하시면 JSONP 형태의 응답을 제공합니다.</span></span></p>
              <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> callback=parseResponse<br /></span></code></p>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <p><span><br /></span></p>
      <h3 id="id-02)PV,UV통계(주간)-ExampleRequest"><span>Example Request</span></h3>
      <hr />
      <p><span><strong>GET   </strong> </span><span class="nolink">https://api.recopick.com/1/admin/2/feed/monthlypvuv?access_key=REPLACE_THIS&amp;ago=12&amp;skip=0</span><br /><span> </span></p>
      <h3 id="id-02)PV,UV통계(주간)-Output"><span style="color: rgb(0,0,0);"><a name="Output_5"></a>Output</span></h3>
      <h3 id="id-02)PV,UV통계(주간)-반환되는JSON필드설명은아래를참고해주세요."><span style="color: rgb(0,0,0);"> </span><span>반환되는 JSON 필드 설명은 아래를 참고해주세요.</span></h3>
      <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
        <div class="codeContent panelContent pdl">
            <pre class="syntaxhighlighter-pre" data-syntaxhighlighter-params="brush: js; gutter: false; theme: Eclipse" data-theme="Eclipse">{
  {
    "202104": {
      "pv": 1996298,
      "uv": 377588
    },
    "202105": {
      "pv": 1761693,
      "uv": 332745
    },
    "202106": {
      "pv": 2688009,
      "uv": 468889
    },
    "202107": {
      "pv": 2182442,
      "uv": 468618
    },
    "202108": {
      "pv": 1509055,
      "uv": 297386
    },
    "202109": {
      "pv": 1149558,
      "uv": 232269
    },
    "202110": {
      "pv": 1098762,
      "uv": 209370
    },
    "202111": {
      "pv": 1805654,
      "uv": 360106
    },
    "202112": {
      "pv": 988672,
      "uv": 199698
    },
    "202201": {
      "pv": 959955,
      "uv": 187624
    },
    "202202": {
      "pv": 890952,
      "uv": 168484
    },
    "202203": {
      "pv": 1270512,
      "uv": 205229
    }
  }
}</pre>
        </div>
      </div>
      <p><span><br /></span></p>
      <p> </p>
      <div class="table-wrap">
        <table class="confluenceTable">
          <tbody>
          <tr>
            <th colspan="2" class="confluenceTh">field</th>
            <th style="text-align: center;" class="confluenceTh"><strong>의 미</strong></th>
            <th style="text-align: center;" class="confluenceTh"><strong>비 고</strong></th>
          </tr>
          <tr>
            <th class="confluenceTh">YYYYMM</th>
            <th colspan="1" class="confluenceTh"> </th>
            <td class="confluenceTd">연도달</td>
            <td class="confluenceTd">연도와 달</td>
          </tr>
          <tr>
            <th colspan="1" class="confluenceTh"> </th>
            <th colspan="1" class="confluenceTh">pv</th>
            <td colspan="1" class="confluenceTd">페이지뷰</td>
            <td colspan="1" class="confluenceTd">해당 달의 페이지뷰</td>
          </tr>
          <tr>
            <th colspan="1" class="confluenceTh"> </th>
            <th colspan="1" class="confluenceTh">uv</th>
            <td colspan="1" class="confluenceTd">방문자수</td>
            <td colspan="1" class="confluenceTd">해당 달의 방문자수</td>
          </tr>
          </tbody>
        </table>
      </div>
      <p><span><br /></span></p>
    </div>
  </div>
</template>