<template>
    <div class="page view">
        <h1>APIs</h1>
        <div id="main-content" class="wiki-content">
            <br>
            <p>웹 서비스를 운영하시는 고객들이 API를 이용하여 Recopick서비스를 사용할 수 있도록 구현되어 있습니다.</p>
            <p>&nbsp;</p>
            <div class="confluence-information-macro confluence-information-macro-information conf-macro output-block" data-hasbody="true" data-macro-name="info">
                <div class="confluence-information-macro-body">
                    <p><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> &nbsp;&nbsp;<strong>Recopick API를 이용하기 위해서는 ?</strong></p>
                    <p><a href="http://www.recopick.com/" class="external-link">Recopick 홈페이지</a>에서 서비스 가입을 위한 신청 문의를 주셔야 합니다.&nbsp;서비스 신청을 하시면, 레코픽 운영담당자가 확인 후 서비스 가입 URL을 메일로 보내드립니다. Recopick에서 등록을 완료하게 되면 API를 이용하실 수 있는 AccessKey와 서비스별로 고유한 ID가 발급이 되며 동시에&nbsp;<a href="http://admin.recopick.com/" class="external-link">Admin Dashboard</a>를 이용하실 수 있는 아이디와 패스워드가 발급됩니다. <span style="color: rgb(0,0,0);">AccessKey</span>가 있어야 Admin관련 API를 사용하실 수 있습니다.</p>
                    <p>(일부 API는 Access Key 가 없으면 사용 불가합니다. )&nbsp;</p>
                </div>
            </div>
            <p>&nbsp;</p>
            <p>Recopick API에는<strong>&nbsp;<a href="/display/docs/2.1REST+APIs">&nbsp;REST APIs</a></strong>와<strong>&nbsp;<a href="/display/docs/Client+Library+APIs">Client Library APIs</a>&nbsp;이렇게</strong>&nbsp;두 종류의 API가 있습니다.</p>
            <ol>
                <li><strong>REST API</strong><br>
                    <p>REST API는 HTTP방식으로 GET 방식 혹은 POST 방식을 통하여 서버로그를 직접 전송할 수 있습니다. API로 사용자행동로그 수집(Log Collect), 상품메타정보 수집(Meta), 추천결과 가져가기(Recommend), 품절상품 관리(Mass Hide) 등의 &nbsp;Recopick 서비스를 이용하실 수 있도록 구현되어 있습니다. 자세한 설명은&nbsp;<a href="/display/docs/2.1REST+APIs">REST APIs</a>를 참고해주세요.</p>
                    <p><br><br></p>
                </li>
                <li><strong>Client Library API</strong><br>
                    <p>Client Library API는 고객사에서 쉽고 빠르게 Recopick 추천 서비스를 사용할 수 있도록 돕는 Javascript 라이브러리입니다. Client Library API를 사용하면 Recopick 서비스로 로그 수집이 가능하기 때문에 고객사에서 서버 로그를 전송하는 등의 추가적인 부담이 전혀 없습니다.&nbsp;뿐만 아니라 Client Library API&nbsp;중 Widget API를 사용하시면 쉽게 추천을 적용할 수도 있습니다.&nbsp;Client Library API는 기존 고객 사이트 이용자들이 불편해하는 일이 없도록 사이트 로딩속도에 가능한 한 영향을 주지 않게(비동기 방식으로 처리함) 세심하게 설계되었습니다. 자세한 설명은&nbsp;<a href="/display/docs/Client+Library+APIs">&nbsp;Client Library APIs</a>를 참고해주세요.</p>
                </li>
            </ol>
            <p>&nbsp;</p>
            <p>&nbsp;</p>




        </div>
    </div>
</template>