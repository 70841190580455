<template>
    <div class="page view">
        <h1>2) 상품 품절 처리 (추천 제외)</h1>
        <div id="main-content" class="wiki-content">
            <br>
            <p>고객사 사이트에서 품절 등의 이슈로 미노출되는 상품을 레코픽에서 품절 처리되도록 하는 API입니다. 1개 이상의 상품을 레코픽 시스템에서 품절 처리함으로써 추천 상품이 노출되지 않도록 합니다.</p>
            <p>단, 품절 처리된 이후에 해당 상품의 상품상세페이지가 정상적으로 조회 되면 상품 품절 상태는 풀리게 됩니다.</p>
            <p>이렇게 되면, 해당 상품이 상품 추천 가능 목록에 다시 포함되어 추천 상품으로 노출될 수 있습니다.</p>
            <p>API 동작 안정성을 위해 최대 200개 단위로 품절 처리 가능합니다. 200개 이상 품절처리를 해야 하는 경우에는 200개 단위로 끊어서 API를 호출해 주십시오.</p>
            <p><strong style="color: rgb(0,0,0);font-size: 16.0px;">Resource URL</strong><span style="color: rgb(0,0,0);"> </span></p>
            <p><span class="nolink">https://admin-api.recopick.com/1/admin/:service_id/masshide/add</span></p>
            <div>
                <h3 id="id-2)상품품절처리(추천제외)-(POST1/admin/:service_id/masshide/add)"><span class="mw-headline">(POST 1/admin/:service_id/masshide/add)</span></h3>
                <h3 id="id-2)상품품절처리(추천제외)-"><span class="mw-headline"><br /></span></h3>
                <h3 id="id-2)상품품절처리(추천제외)-Input"><span class="mw-headline">Input</span></h3>
                <p><span class="mw-headline"> </span>POST의 body에 한 줄에 하나의 상품의 ID를 입력하시면 됩니다.</p>
                <div class="panel conf-macro output-block" style="border-style: dashed;border-width: 1px;" data-hasbody="true" data-macro-name="panel">
                    <div class="panelContent">
                        <pre>c123</pre>
                        <pre>c456</pre>
                        <pre>c789</pre>
                    </div>
                </div>
                <h3 id="id-2)상품품절처리(추천제외)-.1"><span class="mw-headline"><br /></span></h3>
                <h3 id="id-2)상품품절처리(추천제외)-ExampleRequest"><span class="mw-headline">Example Request</span></h3>
                <div>
                    <hr />
                    <p><strong>POST  </strong>  <span class="nolink">https://admin-api.recopick.com/1/admin/17/masshide/add</span></p>
                </div>
                <div>
                    <p> </p>
                    <p> </p>
                    <p>예를 들어, service_id가 6이고, A123, A456, A789 상품을 품절 상품으로 추가하여 추천 리스트에서 제거하는 경우 (상품 구분자 : \n)</p>
                    <div class="panel conf-macro output-block" style="border-style: dashed;border-width: 1px;" data-hasbody="true" data-macro-name="panel">
                        <div class="panelContent">
                            <pre>$ curl -v -H &quot;Content-type: text/plain&quot; --data $'A123\nA456\nA789' &quot;https://admin-api.recopick.com/1/admin/6/masshide/add?access_key=eaad6745c8abdc960794f2f6f5759dc6&quot;</pre>
                        </div>
                    </div>
                    <p>주의사항 &gt; Content-type: text/plain으로 해주셔야 합니다.</p>
                    <p><span>                   ASP 코드에서는 복수 개의 상품 품절 시 상품 구분을 위해 '\n'이 아닌 +VBCRLF로 구분해주셔야 합니다.</span></p>
                    <p>팁&gt; 본 API 테스트 시, 구글 크롬 브라우저의 확장프로그램인 'Postman'을 사용하시면 좋습니다.</p>
                    <p>       구글 크롬 웹 스토어에서의 Postman : <a class="external-link" href="https://chrome.google.com/webstore/detail/postman/fhbjgbiflinjbdggehcddcbncdddomop" rel="nofollow">https://chrome.google.com/webstore/detail/postman/fhbjgbiflinjbdggehcddcbncdddomop</a></p>
                    <p><span><br /></span></p>
                    <h3 id="id-2)상품품절처리(추천제외)-Output"><span class="mw-headline">Output</span></h3>
                    <p style="margin-left: 0.5em;">잘못된 입력이 들어온 경우 400 Bad Request가, 성공적으로 처리된 경우 204 No Contents가 반환됩니다. 예외적으로 서버가 처리하지 못했을 경우 5xx가 반환될 수도 있습니다.</p>
                    <p><span class="mw-headline"><a></a><br /></span></p>
                </div>
            </div>
        </div>
    </div>
</template>