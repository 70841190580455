<template>
    <div class="page view">
        <h1>04) 사용자 행동 로그 통계 (일간)</h1>
        <div id="main-content" class="wiki-content">
            <br>
            <p>사용자 행동 기록 데이터를 일 단위로 제공합니다. 어제 날짜의 데이터까지 제공됩니다.</p>
            <p><span style="color: rgb(0,0,0);">GET 방식으로 요청하실 수 있고 정상적으로 요청이 완료되는 경우는 JSON 형태의 추천리스트가 반환됩니다. callback 파라미터를 명시하는 경우 JSONP 형태로 응답합니다.</span></p>
            <p><span style="color: rgb(0,0,0);">요청에 실패할 경우 상황에 따라 4XX 혹은 5XX형태의 에러코드가 반환됩니다.</span></p>
            <h3 id="id-04)사용자행동로그통계(일간)-ResourceURL"><span style="color: rgb(0,0,0);">Resource URL</span></h3>
            <p><span style="color: rgb(0,0,0);"><span> <span class="nolink">https://api.recopick.com/1/admin/:service_id/feed/dailyaction<a href="https://api.recopick.com/2/recommend/:service_id/:item_id" class="external-link"></a></span></span></span></p>
            <p> </p>
            <h3 id="id-04)사용자행동로그통계(일간)-Parameters"><span class="mw-headline">Parameters</span></h3>
            <div class="table-wrap">
                <table class="confluenceTable">
                    <tbody>
                    <tr>
                        <th class="confluenceTh">
                            <p>service_id</p>
                            <p><sup><span style="color: rgb(153,153,153);">(required)</span></sup></p>
                        </th>
                        <td class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">RecoPick에서 발급된 service id를 의미합니다. service id <span style="color: rgb(34,34,34);">값은 </span><a href="http://admin.recopick.com/-/api_info" class="external-link">어드민 대시보드</a><span style="color: rgb(34,34,34);"> </span><span style="color: rgb(34,34,34);">에서 확인하실 수 있습니다.</span></span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> 17</span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th class="confluenceTh">
                            <p>access_key</p>
                            <p><sup><span style="color: rgb(153,153,153);">(required)</span></sup></p>
                        </th>
                        <td class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">보안이 적용된 API를 호출하기 위해 사용되는 비밀키를 의미합니다. 비밀키 값은 <a href="http://admin.recopick.com/-/api_info" class="external-link">어드민 대시보드</a>에서 확인하실 수 있습니다.</span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> 1f385dca73fb935cb11f8a246ea85f94</span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh">
                            <p>ago</p>
                            <p><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></p>
                        </th>
                        <td colspan="1" class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">읽어올 데이터의 항목수를 의미합니다. 기본값은 12입니다.</span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> 12</span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh">
                            <p>skip</p>
                            <p><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></p>
                        </th>
                        <td colspan="1" class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">지정된 값만큼 이전 데이터를 읽어옵니다. 0으로 지정할 경우 어제 데이터까지, 1로 지정할 경우 그저께의 데이터까지 읽어옵니다. 기본값은 0입니다.</span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> 12</span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th class="confluenceTh">
                            <p>callback</p>
                            <p><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></p>
                        </th>
                        <td class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);"><span>callback을 입력하시면 JSONP 형태의 응답을 제공합니다.</span></span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> callback=parseResponse<br /></span></code></p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <p><span><br /></span></p>
            <h3 id="id-04)사용자행동로그통계(일간)-ExampleRequest"><span>Example Request</span></h3>
            <hr />
            <p><span><strong>GET   </strong> </span><span class="nolink">https://api.recopick.com/1/admin/2/feed/dailyaction?access_key=REPLACE_THIS&amp;ago=12&amp;skip=0</span><br /><span> </span></p>
            <h3 id="id-04)사용자행동로그통계(일간)-Output"><span style="color: rgb(0,0,0);"><a name="Output_5"></a>Output</span></h3>
            <h3 id="id-04)사용자행동로그통계(일간)-반환되는JSON필드설명은아래를참고해주세요."><span style="color: rgb(0,0,0);"> </span><span>반환되는 JSON 필드 설명은 아래를 참고해주세요.</span></h3>
            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                <div class="codeContent panelContent pdl">
            <pre class="syntaxhighlighter-pre" data-syntaxhighlighter-params="brush: js; gutter: false; theme: Eclipse" data-theme="Eclipse">{
  &quot;20130916&quot;: {
    &quot;view&quot;: 11262,
    &quot;click&quot;: 5020,
    &quot;basket&quot;: 1423,
    &quot;order&quot;: 863,
    &quot;visit&quot;: 0,
    &quot;search&quot;: 0,
    &quot;like&quot;: 0
  },
  &quot;20130917&quot;: {
    &quot;view&quot;: 11750,
    &quot;click&quot;: 5142,
    &quot;basket&quot;: 1423,
    &quot;order&quot;: 863,
    &quot;visit&quot;: 0,
    &quot;search&quot;: 0,
    &quot;like&quot;: 0
  },
  &quot;20130918&quot;: {
    &quot;view&quot;: 9301,
    &quot;click&quot;: 4443,
    &quot;basket&quot;: 1423,
    &quot;order&quot;: 863,
    &quot;visit&quot;: 0,
    &quot;search&quot;: 0,
    &quot;like&quot;: 0
  },
  &quot;20130919&quot;: {
    &quot;view&quot;: 8250,
    &quot;click&quot;: 3957,
    &quot;basket&quot;: 1423,
    &quot;order&quot;: 863,
    &quot;visit&quot;: 0,
    &quot;search&quot;: 0,
    &quot;like&quot;: 0
  },
  &quot;20130920&quot;: {
    &quot;view&quot;: 10178,
    &quot;click&quot;: 4681,
    &quot;basket&quot;: 1423,
    &quot;order&quot;: 863,
    &quot;visit&quot;: 0,
    &quot;search&quot;: 0,
    &quot;like&quot;: 0
  },
  &quot;20130921&quot;: {
    &quot;view&quot;: 11398,
    &quot;click&quot;: 4995,
    &quot;basket&quot;: 1423,
    &quot;order&quot;: 863,
    &quot;visit&quot;: 0,
    &quot;search&quot;: 0,
    &quot;like&quot;: 0
  },
  &quot;20130922&quot;: {
    &quot;view&quot;: 13064,
    &quot;click&quot;: 5629,
    &quot;basket&quot;: 1423,
    &quot;order&quot;: 863,
    &quot;visit&quot;: 0,
    &quot;search&quot;: 0,
    &quot;like&quot;: 0
  },
  &quot;20130923&quot;: {
    &quot;view&quot;: 11138,
    &quot;click&quot;: 5053,
    &quot;basket&quot;: 1423,
    &quot;order&quot;: 863,
    &quot;visit&quot;: 0,
    &quot;search&quot;: 0,
    &quot;like&quot;: 0
  },
  &quot;20130924&quot;: {
    &quot;view&quot;: 12195,
    &quot;click&quot;: 5212,
    &quot;basket&quot;: 1423,
    &quot;order&quot;: 863,
    &quot;visit&quot;: 0,
    &quot;search&quot;: 0,
    &quot;like&quot;: 0
  },
  &quot;20130925&quot;: {
    &quot;view&quot;: 10958,
    &quot;click&quot;: 5004,
    &quot;basket&quot;: 1423,
    &quot;order&quot;: 863,
    &quot;visit&quot;: 0,
    &quot;search&quot;: 0,
    &quot;like&quot;: 0
  },
  &quot;20130926&quot;: {
    &quot;view&quot;: 11045,
    &quot;click&quot;: 4941,
    &quot;basket&quot;: 1423,
    &quot;order&quot;: 863,
    &quot;visit&quot;: 0,
    &quot;search&quot;: 0,
    &quot;like&quot;: 0
  },
  &quot;20130927&quot;: {
    &quot;view&quot;: 12845,
    &quot;click&quot;: 5589,
    &quot;basket&quot;: 1423,
    &quot;order&quot;: 863,
    &quot;visit&quot;: 0,
    &quot;search&quot;: 0,
    &quot;like&quot;: 0
  },
  &quot;20130928&quot;: {
    &quot;view&quot;: 14894,
    &quot;click&quot;: 5955,
    &quot;basket&quot;: 1423,
    &quot;order&quot;: 863,
    &quot;visit&quot;: 0,
    &quot;search&quot;: 0,
    &quot;like&quot;: 0
  },
  &quot;20130929&quot;: {
    &quot;view&quot;: 14914,
    &quot;click&quot;: 6048,
    &quot;basket&quot;: 1423,
    &quot;order&quot;: 863,
    &quot;visit&quot;: 0,
    &quot;search&quot;: 0,
    &quot;like&quot;: 0
  }
}</pre>
                </div>
            </div>
            <p><span><br /></span></p>
            <p> </p>
            <div class="table-wrap">
                <table class="confluenceTable">
                    <tbody>
                    <tr>
                        <th colspan="2" class="confluenceTh">field</th>
                        <th style="text-align: center;" class="confluenceTh"><strong>의 미</strong></th>
                        <th style="text-align: center;" class="confluenceTh"><strong>비 고</strong></th>
                    </tr>
                    <tr>
                        <th class="confluenceTh">YYYYMMDD</th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <td class="confluenceTd">년월일</td>
                        <td class="confluenceTd">해당 일자의 데이터를 나타냅니다.</td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh">view</th>
                        <td colspan="1" class="confluenceTd">상품 상세 페이지뷰</td>
                        <td colspan="1" class="confluenceTd">해당 일자의 상품 상세 페이지뷰</td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh">click</th>
                        <td colspan="1" class="confluenceTd">추천 클릭수</td>
                        <td colspan="1" class="confluenceTd">해당 일자의 추천 클릭수</td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh">basket</th>
                        <td colspan="1" class="confluenceTd">장바구니 페이지뷰</td>
                        <td colspan="1" class="confluenceTd"><span>해당 일자의 장바구니 페이지뷰</span></td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh">order</th>
                        <td colspan="1" class="confluenceTd">구매 페이지뷰</td>
                        <td colspan="1" class="confluenceTd">해당 일자의 구매 페이지뷰</td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"> </th>
                        <td class="highlight-grey confluenceTd" colspan="1" data-highlight-colour="grey"><strong>visit</strong></td>
                        <td colspan="1" class="confluenceTd">메인페이지 뷰</td>
                        <td colspan="1" class="confluenceTd"> </td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"> </th>
                        <td class="highlight-grey confluenceTd" colspan="1" data-highlight-colour="grey"><strong>search</strong></td>
                        <td colspan="1" class="confluenceTd">검색결과페이지 뷰</td>
                        <td colspan="1" class="confluenceTd"> </td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"> </th>
                        <td class="highlight-grey confluenceTd" colspan="1" data-highlight-colour="grey"><strong>like</strong></td>
                        <td colspan="1" class="confluenceTd">좋아요 클릭수</td>
                        <td colspan="1" class="confluenceTd"> </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <p><span><br /></span></p>
            <p><span>.</span></p>
        </div>
    </div>
</template>