<template>
    <div class="page view">
        <h1>자주하는 질문</h1>
        <div id="main-content" class="wiki-content">
            <br>
            <div class="toc-macro client-side-toc-macro  conf-macro output-block" data-headerelements="H1,H2,H3,H4,H5,H6,H7" data-hasbody="false" data-macro-name="toc">
              <ul style="">
                <li><span class="toc-item-body" data-outline="1">
                  <a href="#id-자주하는질문-Q.RecoPick을이용하고싶은데이용방법이나가격정책이궁금합니다." class="toc-link">Q. RecoPick을 이용하고 싶은데 이용 방법이나 가격 정책이 궁금합니다.</a>
                </span></li>
                <li><span class="toc-item-body" data-outline="2">
                  <a href="#id-자주하는질문-Q.추천은어떻게적용해야하나요?" class="toc-link">Q. 추천은 어떻게 적용해야 하나요?</a>
                </span></li>
                <li><span class="toc-item-body" data-outline="3">
                  <a href="#id-자주하는질문-Q.추천위젯은바로적용되나요?" class="toc-link">Q. 추천 위젯은 바로 적용되나요?</a>
                </span></li>
                <li><span class="toc-item-body" data-outline="4">
                  <a href="#id-자주하는질문-Q.수집이필요한로그종류는어떤것들이있나요?" class="toc-link">Q. 수집이 필요한 로그 종류는 어떤것들이 있나요?</a>
                </span></li>
                <li><span class="toc-item-body" data-outline="5">
                  <a href="#id-자주하는질문-Q.추천성과는어떻게확인할수있나요?" class="toc-link">Q. 추천 성과는 어떻게 확인할 수 있나요?</a>
                </span></li>
                <li><span class="toc-item-body" data-outline="6">
                  <a href="#id-자주하는질문-Q.추천에나가는상품정보가변경되는경우어떻게하나요?" class="toc-link">Q. 추천에 나가는 상품 정보가 변경되는 경우 어떻게 하나요?</a>
                </span></li>
                <li><span class="toc-item-body" data-outline="7">
                  <a href="#id-자주하는질문-Q.추천서버에문제가생겨서,추천이나가지않으면쇼핑몰에문제가되나요?" class="toc-link">Q. 추천 서버에 문제가 생겨서, 추천이 나가지 않으면 쇼핑몰에 문제가 되나요?</a>
                </span></li>
                <li><span class="toc-item-body" data-outline="8">
                  <a href="#id-자주하는질문-Q.패션몰과같은빠르고,트렌디한쇼핑몰에도잘적용이되나요?" class="toc-link">Q. 패션몰과 같은 빠르고, 트렌디한 쇼핑몰에도 잘 적용이 되나요?</a>
                </span></li>
                <li><span class="toc-item-body" data-outline="9">
                  <a href="#id-자주하는질문-Q.상품형추천과사용자기반추천은뭐가다른가요?" class="toc-link">Q. 상품형 추천과 사용자 기반 추천은 뭐가 다른가요?</a>
                </span></li>
                <li><span class="toc-item-body" data-outline="10">
                  <a href="#id-자주하는질문-Q.추천클릭률,추천기여매출등에서확인할수있는채널이란무엇인가요?" class="toc-link">Q. 추천 클릭률, 추천 기여 매출 등에서 확인할 수 있는 채널이란 무엇인가요?</a>
                </span></li>
              </ul>
            </div>
            <h2 id="id-자주하는질문-Q.RecoPick을이용하고싶은데이용방법이나가격정책이궁금합니다.">Q. RecoPick을 이용하고 싶은데 이용 방법이나 가격 정책이 궁금합니다.</h2>
            <p>A.  <a href="https://recopick.com/recopick_guide" class="external-link">레코픽 &gt; 이용안내</a> 에 진행 프로세스에 대해 소개되어 있으며 가격정책은  <a href="https://blog.recopick.com/199?category=552784" class="external-link">레코픽 이용요금 안내</a> 에서 확인하실 수 있습니다.</p>
            <p>회원가입 후 레코픽 추천 설치는 본  Docs &gt; <a href="/pages/viewpage.action?pageId=4718816">RecoPick 설치 가이드</a>를 참고하시면 됩니다. 자세한 내용, 가격 정책 등이 궁금하시면 recopick-help@skplanet.com 로 문의해 주세요.</p>
            <h2 id="id-자주하는질문-Q.추천은어떻게적용해야하나요?">Q. 추천은 어떻게 적용해야 하나요?</h2>
            <p>A. 저희 RecoPick 추천을 적용하기 위해서는, 상품/사용자 간의 연결관계를 분석하기 위한  1.<strong style="line-height: 1.42857;">로그수집 스크립트 삽입</strong>, 상품의 정보를 위젯에 표시하기 위한 2.<strong style="line-height: 1.42857;">상품정보 메타 태그 삽입</strong> , 원하는 곳에 위젯을 노출하기 위한 3.<strong style="line-height: 1.42857;">추천 위젯 삽입 </strong>등의 과정이 필요합니다. Admin Dashboard에 로그인한 후  <strong style="line-height: 1.42857;">추천 설치 안내 </strong>페이지로 들어가셔서 안내에 따라 진행하세요. 좀더 상세한 내용은 본  Docs &gt; <a href="/pages/viewpage.action?pageId=4718816">RecoPick 설치 가이드</a>를 참고해 주세요. 적용하시는데 어려움이 있으시다면 언제든 연락주시기 바랍니다.</p>
            <h2 id="id-자주하는질문-Q.추천위젯은바로적용되나요?">Q. 추천 위젯은 바로 적용되나요?</h2>
            <p>A. 저희 RecoPick 추천은 사이트 방문자의 행태를 분석하여, 그에 최적화된 상품을 제공해 드리기 위해 2~3일 정도후 추천결과를 반영할 수 있습니다. 추천위젯을 대형사이트의 경우 더 짧은 기간에도 충분한 연결관계를 찾을 수 있지만, 데이터가 축적될수록 더 정교한 추천이 계산될 수 있기 때문에 로그수집 기간이 필요합니다. 추천위젯에서 결과가 반영되는 시점은 메일을 통해 알려드립니다. 메일을 받으신 후 안내에 따라<strong style="line-height: 1.42857;"> <strong> <a class="external-link" rel="nofollow" style="text-decoration: underline;" href="http://admin.recopick.com/">Admin Dashboard</a></strong> </strong> &gt; <strong style="line-height: 1.42857;">추천 위젯 설정</strong>에 들어가셔서 사용안함으로 설정된 추천타입을 아래 가이드에 따라 설정한 후 저장하시면 사이트에서 추천결과를 확인 하실 수 있습니다.<strong style="line-height: 1.42857;"> </strong></p>
            <h2 id="id-자주하는질문-Q.수집이필요한로그종류는어떤것들이있나요?">Q. 수집이 필요한 로그 종류는 어떤것들이 있나요?</h2>
            <p>A. 저희 RecoPick의 추천 방식은 구매 &gt; 장바구니 &gt; 상품view의 순으로 가중치를 두어 계산을 하기 때문에, 구매와 장바구니, 상품view 로그는 필수로 수집되어야 합니다.</p>
            <h2 id="id-자주하는질문-Q.추천성과는어떻게확인할수있나요?">Q. 추천 성과는 어떻게 확인할 수 있나요?</h2>
            <p>A. 저희 RecoPick의 추천을 통해 발생한 성과는  <a href="http://admin.recopick.com/" class="external-link">Admin Dashboard</a> 추천 성과분석에서 확인하실 수 있습니다. 대시보드에서 추천을 클릭하여, 이동하는 사용자와 전체 사용자의 PV / 구매상품수 / 구매전환율 등을 확인하실 수 있으며, 상품 구매금액 정보가 수집되는 경우는 저희 추천을 통해 24시간 이내(변경 가능) 구입한 사용자의 매출 또한 확인하실 수 있습니다.</p>
            <h2 id="id-자주하는질문-Q.추천에나가는상품정보가변경되는경우어떻게하나요?">Q. 추천에 나가는 상품 정보가 변경되는 경우 어떻게 하나요?</h2>
            <p>A. 저희 RecoPick 추천은 야간 시간대를 이용하여, 24시간 간격으로 매일 상품정보를 재수집합니다. 하지만 매일 정해진 시간대(오전 등)에 상품 정보를 수정하거나 이벤트를 하시는 경우, 상품정보 재수집 시간을 조정하실수 있습니다. 또한 1~2가지 상품 정보가 급하게 변경되었을 경우 <a href="http://admin.recopick.com" class="external-link">Admin Dashboard</a>에서 상품 정보를 눌러서 개별로 상품정보를 재수집하실 수 있습니다.</p>
            <h2 id="id-자주하는질문-Q.추천서버에문제가생겨서,추천이나가지않으면쇼핑몰에문제가되나요?">Q. 추천 서버에 문제가 생겨서, 추천이 나가지 않으면 쇼핑몰에 문제가 되나요?</h2>
            <p>A. 저희 RecoPick은 AWS(아마존웹서비스) 서버를 사용하며, 11번가 등 대형사에 추천을 제공하면서도 현재까지 서버 장애가 발생한적이 없습니다. 하지만 만약에 서버에 장애가 발생하더라도, 저희는 외부 호출방식이라, 추천 위젯 자리가 사라질 뿐 쇼핑몰 디자인 및 운영에 전혀 영향을 끼치지 않습니다.</p>
            <h2 id="id-자주하는질문-Q.패션몰과같은빠르고,트렌디한쇼핑몰에도잘적용이되나요?">Q. 패션몰과 같은 빠르고, 트렌디한 쇼핑몰에도 잘 적용이 되나요?</h2>
            <p>A. 물론입니다. 저희 고객사 중 가장 비중이 높은 부분이 패션/잡화 쇼핑몰들이며, 해당 쇼핑몰들에서도 좋은 성과를 보이고 있습니다. 특히, 실시간 개인화 추천의 경우 국내에서 유일하게 RecoPick만이 제공하는, 방문자의 최근 행태를 실시간으로 분석해서 제공하는 방식으로 고객의 관심사가 빠르게 변하고 트렌디한 패션/잡화 쇼핑몰에 가장 잘 적합하다고 할 수 있습니다.</p>
            <h2 id="id-자주하는질문-Q.상품형추천과사용자기반추천은뭐가다른가요?">Q. 상품형 추천과 사용자 기반 추천은 뭐가 다른가요?</h2>
            <p>A. 상품형 추천은 상품 별 로그를 기반으로, 해당 상품을 본 사람이 본 다른 상품(ViewTogether) 또는 해당 상품을 구매한 사람이 구매한 다른 상품(BuyTogether)와 같은 서비스입니다. 반면 사용자 기준 추천은 상품 기준이 아니라, 방문한 사용자의 기준에서 최근에 봤던 상품들의 정보를 기반으로 사용자 관심사와 구매행태를 분석하여 추천을 제공하는 서비스입니다.</p>
            <h2 id="id-자주하는질문-Q.추천클릭률,추천기여매출등에서확인할수있는채널이란무엇인가요?">Q. 추천 클릭률, 추천 기여 매출 등에서 확인할 수 있는 채널이란 무엇인가요?</h2>
            <p>A. 채널은 사이트에 여러개의 추천 위젯을 각각 다른 위치에 제공하시는 경우, 각 위치에 제공된 위젯별로 추천 클릭률, 추천 경유 매출을 확인하실 수 있도록 하기 위한 각 위젯의 식별자입니다.</p>
            <p><a href="/pages/viewpage.action?pageId=3244098">추천 API</a> 혹은 위젯을 사용하실 때 채널을 지정하시면 각 채널별 통계를 <a href="http://admin.recopick.com/" class="external-link">Admin Dashboard</a>에서 확인하실 수 있습니다.</p>
            <p>채널 지정 방법은 추천 위젯의 경우 <a href="/display/docs/Client+Library+APIs">Client Library APIs</a>, <a href="/pages/viewpage.action?pageId=3244098">추천 API</a>의 경우 <a href="/pages/viewpage.action?pageId=3244404">1) 함께본상품 / 함께구매한상품 추천</a> 등의 API 문서를 참고해주세요.</p>
            <p><br /></p>




        </div>
    </div>
</template>