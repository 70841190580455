<template>
    <div class="page view">
        <h1>3) 상품 품절 해제 (추천 재등록)</h1>
        <div id="main-content" class="wiki-content">
            <br>
            <p>레코픽 시스템에서 품절 처리되어 상품 추천 가능 목록에서 제외되어 있는 상품을 다시 품절 추천 가능 목록에 추가시키는(품절 상태를 해제시키는) API입니다.</p>
            <p>품절 리스트에서 제거되어(품절 상태가 해제되어) 품절 추천 가능 목록에 추가되면, 해당 상품은 추천 상품으로 노출될 수 있습니다.</p>
            <p>API 동작 안정성을 위해 최대 200개 단위로 품절 처리 가능합니다. 200개 이상 품절처리를 해야 하는 경우에는 200개 단위로 끊어서 API를 호출해 주십시오.</p>
            <p> </p>
            <h3 id="id-3)상품품절해제(추천재등록)-ResourceURL"><strong>Resource URL</strong></h3>
            <p><span class="nolink"><a href="https://admin-api.recopick.com/1/admin/:service_id/masshide/remove" class="external-link">https://admin-api.recopick.com/1/admin/:service_id/masshide/remove</a></span></p>
            <h3 id="id-3)상품품절해제(추천재등록)-(POST1/admin/:service_id/masshide/remove)"><span class="nolink">(POST 1/admin/:service_id/masshide/remove)<br /></span></h3>
            <p><span class="nolink"><br /></span></p>
            <p><a name="Input_2"></a></p>
            <div>
                <h3 id="id-3)상품품절해제(추천재등록)-Input"><span class="mw-headline">Input</span></h3>
                <p><span class="mw-headline"> </span><span style="font-size: 14.0px;line-height: 1.4285715;">POST의 body에 한 줄에 하나의 상품의 ID를 입력하시면 됩니다.</span></p>
                <div class="panel conf-macro output-block" style="border-style: dashed;border-width: 1px;" data-hasbody="true" data-macro-name="panel">
                    <div class="panelContent">
                        <pre>c123</pre>
                        <pre>c456</pre>
                        <pre>c789</pre>
                    </div>
                </div>
                <p><span style="font-size: 14.0px;line-height: 1.4285715;"> </span></p>
                <p> </p>
                <h3 id="id-3)상품품절해제(추천재등록)-ExampleRequest"><span class="mw-headline">Example Request</span></h3>
                <div>
                    <hr />
                    <p><strong>POST  </strong>  <span class="nolink">https://admin-api.recopick.com/1/admin/17/masshide/remove</span></p>
                </div>
                <div><span class="nolink"><br /></span></div>
                <div>
                    <p>  </p>
                </div>
                <p>예를 들어, service_id가 6이고 A456 상품을 품절 리스트에서 제거하는 경우 (복수 개인 경우 '\n'을 상품 구분자로 사용)</p>
                <div class="panel conf-macro output-block" style="border-style: dashed;border-width: 1px;" data-hasbody="true" data-macro-name="panel">
                    <div class="panelContent">
                       <pre>$ curl -v -H &quot;Content-type: text/plain&quot; --data $'A456' &quot;https://admin-api.recopick.com/1/admin/6/masshide/remove?access_key=eaad6745c8abdc960794f2f6f5759dc6&quot;
</pre>
                        <pre><a name="Output_4"> </a></pre>
                    </div>
                </div>
                <p>주의사항 &gt; Content-type: text/plain으로 해주셔야 합니다.</p>
                <p>                   ASP 코드에서는 복수 개의 상품 품절 시 상품 구분을 위해 '\n'이 아닌 +VBCRLF로 구분해주셔야 합니다.</p>
                <p>팁&gt; 본 API 테스트 시, 구글 크롬 브라우저의 확장프로그램인 'Postman'을 사용하시면 좋습니다.</p>
                <p>       구글 크롬 웹 스토어에서의 Postman : <a href="https://chrome.google.com/webstore/detail/postman/fhbjgbiflinjbdggehcddcbncdddomop" class="external-link">https://chrome.google.com/webstore/detail/postman/fhbjgbiflinjbdggehcddcbncdddomop</a></p>
                <h3 id="id-3)상품품절해제(추천재등록)-Output"><span class="mw-headline">Output</span></h3>
                <p>잘못된 입력이 들어온 경우 400 Bad Request가, 성공적으로 처리된 경우 204 No Contents가 반환됩니다. 예외적으로 서버가 처리하지 못했을 경우 5xx가 반환될 수도 있습니다.</p>
                <p><span style="font-size: 14.0px;line-height: 1.4285715;"> </span></p>
                <p><span style="font-size: 14.0px;line-height: 1.4285715;"><br /></span></p>
            </div>
        </div>
    </div>
</template>