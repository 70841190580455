<template>
    <div class="page view">
        <h1>Hybrid app에서 ADID(gaid,idfa)획득방법</h1>
        <div id="main-content" class="wiki-content">
        <br>
            <h1 id="Hybridapp에서ADID(gaid,idfa)획득방법-1.안드로이드에서GAID를구하는방법">1.안드로이드에서 GAID를 구하는 방법</h1>
            <p><br></p>
            <p>GAID는 AdvertisingIdClient.Info 클래스를 통해서 구할 수 있습니다.</p>
            <p>다만, <a rel="nofollow" href="https://play.google.com/intl/ko_ALL/about/monetization-ads/ads/ad-id/" class="external-link">안드로이드 정책</a>에 따라, <span style="color: rgb(0,51,102);"><strong>사용자가 '관심기반 광고 선택 해제' 또는 '광고 맞춤설정 선택 해제'로 설정해 둔 경우에는 GAID를 수집해서 광고 목적으로 사용해서는 안됩니다</strong></span>. &nbsp;</p>
            <p>AdvertisingIdClient.Info 클래스를 사용하려면 <strong><a href="https://developers.google.com/android/guides/setup" rel="nofollow" class="external-link">&lt;&lt;여기&gt;&gt;</a></strong>를 참고해서 프로젝트에 Google Play Services를 추가해야 합니다.</p>
            <p>안드로이드 스튜디오는 다음과 같이 Google Play Services를 추가할 수 있습니다.</p>
            <ol>
                <li>애플리케이션 모듈의 build.gradle 파일 열기</li>
                <li>
                    <p>dependencies 안에 Google Play Services 추가</p>
                    <div>
                        <div class="syntaxhighlighter nogutter groovy">
                            <p><br></p>
                            <div class="table-wrap">
                                <table class="wrapped confluenceTable">
                                    <tbody>
                                    <tr>
                                        <td class="confluenceTd">
                                            <div class="container" title="Hint: double-click to select code">
                                                <div class="line number1 index0 alt2"><code class="groovy plain">apply plugin: </code><code class="groovy string">'com.android.application'</code></div>
                                                <div class="line number2 index1 alt1"><code class="groovy spaces">&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="groovy plain">...</code></div>
                                                <div class="line number3 index2 alt2"><br></div>
                                                <div class="line number4 index3 alt1"><code class="groovy spaces">&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="groovy plain">dependencies {</code></div>
                                                <div class="line number5 index4 alt2"><code class="groovy spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="groovy plain">...</code></div>
                                                <div class="line number6 index5 alt1"><code class="groovy spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="groovy plain">compile </code><code class="groovy string">'com.google.android.gms:play-services:11.0.2'</code></div>
                                                <div class="line number7 index6 alt2"><code class="groovy spaces">&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="groovy plain">}</code></div>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </li>
                <li>Toolbar에서 Sync Project with Gradle Files 클릭</li>
            </ol>
            <p>Google Play Services가 추가되면 다음과 같은 코드로 디바이스의 GAID를 읽어올 수 있습니다.</p>
            <div>
                <div class="syntaxhighlighter nogutter java">
                    <p><br></p>
                    <div class="table-wrap">
                        <table class="wrapped confluenceTable">
                            <tbody>
                            <tr>
                                <td class="confluenceTd">
                                    <div class="container" title="Hint: double-click to select code">
                                        <div class="line number1 index0 alt2"><code class="java comments">// Adid 값 저장용 객체</code></div>
                                        <div class="line number2 index1 alt1"><code class="java keyword">class</code> <code class="java plain">Adid {</code></div>
                                        <div class="line number3 index2 alt2"><code class="java spaces">&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="java plain">String adid;</code></div>
                                        <div class="line number4 index3 alt1"><code class="java spaces">&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="java keyword">public</code> <code class="java keyword">void</code> <code class="java plain">setAdid(String adid) { </code><code class="java keyword">this</code><code class="java plain">.adid = adid; }</code></div>
                                        <div class="line number5 index4 alt2"><code class="java spaces">&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="java keyword">public</code> <code class="java plain">String getAdid() { </code><code class="java keyword">return</code> <code class="java keyword">this</code><code class="java plain">.adid; }</code></div>
                                        <div class="line number6 index5 alt1"><code class="java plain">}</code></div>
                                        <div class="line number7 index6 alt2"><code class="java plain">&nbsp;</code></div>
                                        <div class="line number8 index7 alt1"><code class="java plain">...</code></div>
                                        <div class="line number9 index8 alt2"><code class="java plain">&nbsp;</code></div>
                                        <div class="line number10 index9 alt1"><code class="java spaces">&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="java keyword">final</code> <code class="java plain">Adid adid = </code><code class="java keyword">new</code> <code class="java plain">Adid();</code></div>
                                        <div class="line number11 index10 alt2"><code class="java plain">&nbsp;</code></div>
                                        <div class="line number12 index11 alt1"><code class="java spaces">&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="java comments">// 별도의 스레드에서 실행하지 않고 메인 스레드에서 실행하면 IllegalStateException 발생</code></div>
                                        <div class="line number13 index12 alt2"><code class="java spaces">&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="java plain">AsyncTask.execute(</code><code class="java keyword">new</code> <code class="java plain">Runnable() {</code></div>
                                        <div class="line number14 index13 alt1"><code class="java spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="java color1">@Override</code></div>
                                        <div class="line number15 index14 alt2"><code class="java spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="java keyword">public</code> <code class="java keyword">void</code> <code class="java plain">run() {</code></div>
                                        <div class="line number16 index15 alt1"><code class="java spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="java keyword">try</code> <code class="java plain">{</code></div>
                                        <div class="line number17 index16 alt2"><code class="java spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="java plain">AdvertisingIdClient.Info advertisingIdInfo = AdvertisingIdClient.getAdvertisingIdInfo(getApplicationContext());</code></div>
                                        <div class="line number18 index17 alt1"><code class="java spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="java keyword">if</code> <code class="java plain">(!advertisingIdInfo.isLimitAdTrackingEnabled())</code></div>
                                        <div class="line number19 index18 alt2"><code class="java spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="java plain">adid.setAdid(advertisingIdInfo.getId());</code></div>
                                        <div class="line number20 index19 alt1"><code class="java spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="java plain">} </code><code class="java keyword">catch</code> <code class="java plain">(IOException | GooglePlayServicesNotAvailableException | GooglePlayServicesRepairableException e) {</code></div>
                                        <div class="line number21 index20 alt2"><code class="java spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="java plain">e.printStackTrace();</code></div>
                                        <div class="line number22 index21 alt1"><code class="java spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="java plain">}</code></div>
                                        <div class="line number23 index22 alt2"><code class="java spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="java plain">}</code></div>
                                        <div class="line number24 index23 alt1"><code class="java spaces">&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="java plain">});</code></div>
                                        <div class="line number25 index24 alt2"><code class="java spaces">&nbsp;&nbsp;&nbsp;&nbsp;</code>&nbsp;</div>
                                        <div class="line number26 index25 alt1"><code class="java spaces">&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="java plain">...</code></div>
                                        <div class="line number27 index26 alt2"><code class="java spaces">&nbsp;&nbsp;&nbsp;&nbsp;</code>&nbsp;</div>
                                        <div class="line number28 index27 alt1"><code class="java spaces">&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="java comments">// 이후 adid.getAdid()로 읽어서 사용 가능</code></div>
                                        <div class="line number29 index28 alt2"><code class="java spaces">&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="java color1">@JavascriptInterface</code></div>
                                        <div class="line number30 index29 alt1"><code class="java spaces">&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="java keyword">public</code> <code class="java plain">String getGaid() {</code></div>
                                        <div class="line number31 index30 alt2"><code class="java spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="java keyword">return</code> <code class="java plain">adid.getAdid();</code></div>
                                        <div class="line number32 index31 alt1"><code class="java spaces">&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="java plain">}</code></div>
                                        <div class="line number33 index32 alt2"><code class="java plain">&nbsp;</code></div>
                                        <div class="line number34 index33 alt1"><code class="java plain">...</code></div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <p><br></p>
            <h2 id="Hybridapp에서ADID(gaid,idfa)획득방법-2.iOS에서IDFA를구하는방법">2. iOS에서 IDFA를 구하는 방법</h2>
            <p>IDFA는 ASIdentifierManager&nbsp;클래스를 통해서 구할 수 있습니다.</p>
            <p>프로젝트 설정 -&gt; Linked Frameworks and Libraries에서 '+' 버튼을 눌러 -&gt; 'AdSupport' 프레임워크 추가</p>
            <p>다만,&nbsp;<a rel="nofollow" class="external-link" href="https://developer.apple.com/documentation/adsupport/asidentifiermanager/1614148-isadvertisingtrackingenabled">애플의 정책</a>에 따라,&nbsp;<span style="color: rgb(0,51,102);"><strong>사용자가 '관심기반 광고 선택 해제' 또는 '광고 맞춤설정 선택 해제'로 설정해 둔 경우에는 IDFA를 수집해서 광고 목적으로 사용해서는 안됩니다</strong></span>.</p>
            <p><br></p>
            <h3 id="Hybridapp에서ADID(gaid,idfa)획득방법-2-1.Swift">&nbsp;2-1. Swift</h3>
            <div>
                <div class="syntaxhighlighter nogutter applescript">
                    <p><br></p>
                    <div class="table-wrap">
                        <table class="wrapped confluenceTable">
                            <tbody>
                            <tr>
                                <td class="confluenceTd">
                                    <div class="container" title="Hint: double-click to select code">
                                        <div class="line number1 index0 alt2"><code class="applescript plain">import AdSupport</code></div>
                                        <div class="line number2 index1 alt1"><code class="applescript plain">&nbsp;</code></div>
                                        <div class="line number3 index2 alt2"><code class="applescript plain">...</code></div>
                                        <div class="line number4 index3 alt1"><code class="applescript plain">&nbsp;</code></div>
                                        <div class="line number5 index4 alt2"><code class="applescript spaces">&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="applescript plain">var adid</code><code class="applescript color1">:</code><code class="applescript plain">String </code><code class="applescript color2">=</code> <code class="applescript string">""</code></div>
                                        <div class="line number6 index5 alt1"><code class="applescript spaces">&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="applescript keyword">if</code> <code class="applescript color1">(</code><code class="applescript plain">ASIdentifierManager.shared</code><code class="applescript color1">(</code><code class="applescript color1">)</code><code class="applescript plain">.isAdvertisingTrackingEnabled</code><code class="applescript color1">)</code> <code class="applescript color1">{</code></div>
                                        <div class="line number7 index6 alt2"><code class="applescript spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="applescript plain">adid </code><code class="applescript color2">=</code> <code class="applescript plain">ASIdentifierManager.shared</code><code class="applescript color1">(</code><code class="applescript color1">)</code><code class="applescript plain">.advertisingIdentifier.uuidString</code></div>
                                        <div class="line number8 index7 alt1"><code class="applescript spaces">&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="applescript color1">}</code></div>
                                        <div class="line number9 index8 alt2"><code class="applescript plain">&nbsp;</code></div>
                                        <div class="line number10 index9 alt1"><code class="applescript spaces">&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="applescript color2">/</code><code class="applescript color2">/</code> <code class="applescript plain">이후 adid 사용 가능</code></div>
                                        <div class="line number11 index10 alt2"><code class="applescript plain">...</code></div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <p><br></p>
            <h3 id="Hybridapp에서ADID(gaid,idfa)획득방법-2-2.Objective-C">&nbsp;2-2. Objective-C</h3>
            <div>
                <div class="syntaxhighlighter nogutter applescript">
                    <p><br></p>
                    <div class="table-wrap">
                        <table class="wrapped confluenceTable">
                            <colgroup>
                                <col>
                            </colgroup>
                            <tbody>
                            <tr>
                                <td class="confluenceTd">
                                    <div class="container" title="Hint: double-click to select code">
                                        <div class="line number1 index0 alt2"><code class="applescript comments">#import &lt;AdSupport/ASIdentifierManager.h&gt;</code></div>
                                        <div class="line number2 index1 alt1"><code class="applescript plain">&nbsp;</code></div>
                                        <div class="line number3 index2 alt2"><code class="applescript plain">...</code></div>
                                        <div class="line number4 index3 alt1"><code class="applescript plain">&nbsp;</code></div>
                                        <div class="line number5 index4 alt2"><code class="applescript color2">-</code> <code class="applescript color1">(</code><code class="applescript plain">NSString </code><code class="applescript color2">*</code><code class="applescript color1">)</code><code class="applescript plain">identifierForAdvertising </code><code class="applescript color1">{</code></div>
                                        <div class="line number6 index5 alt1"><code class="applescript spaces">&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="applescript keyword">if</code><code class="applescript color1">(</code><code class="applescript plain">[[ASIdentifierManager sharedManager] isAdvertisingTrackingEnabled]</code><code class="applescript color1">)</code> <code class="applescript color1">{</code></div>
                                        <div class="line number7 index6 alt2"><code class="applescript spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="applescript plain">NSUUID </code><code class="applescript color2">*</code><code class="applescript plain">IDFA </code><code class="applescript color2">=</code> <code class="applescript plain">[[ASIdentifierManager sharedManager] advertisingIdentifier];</code></div>
                                        <div class="line number8 index7 alt1"><code class="applescript spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="applescript keyword">return</code> <code class="applescript plain">[IDFA UUIDString];</code></div>
                                        <div class="line number9 index8 alt2"><code class="applescript spaces">&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="applescript color1">}</code></div>
                                        <div class="line number10 index9 alt1"><code class="applescript spaces">&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="applescript keyword">return</code> <code class="applescript plain">nil;</code></div>
                                        <div class="line number11 index10 alt2"><code class="applescript color1">}</code></div>
                                        <div class="line number12 index11 alt1"><code class="applescript plain">&nbsp;</code></div>
                                        <div class="line number13 index12 alt2"><code class="applescript plain">...</code></div>
                                        <div class="line number14 index13 alt1"><code class="applescript plain">&nbsp;</code></div>
                                        <div class="line number15 index14 alt2"><code class="applescript color2">/</code><code class="applescript color2">/</code> <code class="applescript plain">이후 [self identifierForAdvertising]로 값을 읽어서 사용 가능</code></div>
                                        <div class="line number16 index15 alt1"><code class="applescript plain">&nbsp;</code></div>
                                        <div class="line number17 index16 alt2"><code class="applescript plain">...</code></div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <h3 id="Hybridapp에서ADID(gaid,idfa)획득방법-2.3애플의새로운IDFA가이드라인-IDFA관련iOS앱제출방법">2.3 애플의 새로운 IDFA 가이드라인 - IDFA 관련 iOS 앱 제출 방법&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h3>
            <div class="article-meta meta">애플은 2015년 4월 앱 개발자들이 IDFA를 어떻게 쓸 수 있는지에 대한 <a rel="nofollow" href="https://developer.apple.com/library/ios/documentation/LanguagesUtilities/Conceptual/iTunesConnect_Guide_ko/Chapters/SubmittingTheApp.html" class="external-link">아이튠즈 커넥트(iTunes Connect) 가이드라인</a>을 업데이트하여 발표하였습니다.&nbsp;</div>
            <div class="article-meta meta">앱의 목적에 맞게 해당사항을 check 후 앱을&nbsp;등록 해주시기 바랍니다.</div>
            <h2 id="Hybridapp에서ADID(gaid,idfa)획득방법-3.ADID를레코픽에전송하는방법">3. ADID를 레코픽에 전송하는 방법</h2>
            <p>레코픽에서 제공하는 별도의 JavaScript SDK를 통해 ADID를 레코픽에 전송할 수 있습니다.</p>
            <div class="aui-message hint shadowed information-macro has-no-icon">
                <p class="title">Notice</p>
                <div class="message-content">
                    <p><a href="https://play.google.com/intl/ko_ALL/about/monetization-ads/ads/ad-id/" class="external-link" rel="nofollow">안드로이드 정책</a>&nbsp;또는&nbsp;<a rel="nofollow" class="external-link" href="https://developer.apple.com/documentation/adsupport/asidentifiermanager/1614148-isadvertisingtrackingenabled">애플의 정책</a>에&nbsp;따라,&nbsp;<span style="color: rgb(0,51,102);"><strong>사용자가&nbsp;'관심기반 광고 선택 해제' 또는 '광고 맞춤설정 선택 해제'로 설정해 둔 경우에는 ADID를 수집해서 광고 목적으로 사용해서는 안됩니다</strong></span>.</p>
                    <p>따라서,&nbsp;<span style="color: rgb(255,0,0);"><strong>다음의 경우에는 ADID를 전송해서는 안됩니다.</strong></span></p>
                    <ol>
                        <li><span style="color: rgb(255,0,0);"><strong>안드로이드의 경우 'AdvertisingIdClient.Info.isLimitAdTrackingEnabled()'가 true 일 때</strong></span></li>
                        <li><span style="color: rgb(255,0,0);"><strong>iOS의 경우&nbsp;</strong></span>
                            <ol>
                                <li><span style="color: rgb(255,0,0);"><strong>Swift 사용 시 'ASIdentifierManager.shared().isAdvertisingTrackingEnabled'가 false 일 때</strong></span></li>
                                <li><span style="color: rgb(255,0,0);"><strong>Objective-C 사용 시, '[[ASIdentifierManager sharedManager] isAdvertisingTrackingEnabled]'가 false 일 때</strong></span></li>
                            </ol>
                        </li>
                    </ol>
                </div>
            </div>
            <p>&nbsp;ADID를 보낼 수 있는 JavaScript API는 <a href="http://docs.recopick.com/display/docs/Client+Library+APIs#ClientLibraryAPIs-DeviceIdentificationAPI" class="external-link">Client Library APIs</a>&nbsp;에 나와 있습니다.</p>
            <ul>
                <li>안드로이드의 경우 하이브리드 앱에서 GAID를 반환해주기 위해 @JavascriptInterface로 지정한 함수를 WebView의 웹페이지에서 호출해서 GAID 값을 구한 후,<ul>
                    <li>아래 표의 전송 방식 가이드에 따라, WebView로 보여지는 페이지의 &lt;script&gt; 안에서&nbsp;recoPick('setGAID', &nbsp;'GAID값'); 와 같이 호출하면 GAID 값이 레코픽에 전달 됩니다.</li>
                    <li>예) 하이브리드 앱의 WebView에 추가한 JavascriptInterface 이름을 Android라 하고, 그 안에 @JavascriptInterface로 지정된 GAID를 반환하는 함수를 getGaid() 라고 하면,</li>
                </ul>
                </li>
            </ul>
            <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;recoPick('setGAID', window.Android.getGaid()); &nbsp;와 같이 호출</p>
            <ul>
                <li>iOS의 경우<ul>
                    <li>하이브리드 앱에서 WebView 내의 JavaScript를 호출할 수 있으므로, 하이브리드 앱에서 직접 window.recoPick('setIDFA', 'IDFA값'); 를 호출하면 IDFA 값이 레코픽에 전달 됩니다.<ul>
                        <li><a href="/pages/viewpage.action?pageId=11010062">[참고] iOS에서 WebView 로 token 값 전달 하는 방법</a></li>
                    </ul>
                    </li>
                    <li>WebView 내에서 하이브리드 앱의 메서드를 호출할 수 있으므로, WebView로 보여지는 페이지의 &lt;script&gt; 안에서&nbsp;recoPick('setIDFA', &nbsp;IDFA를반환하는하이브리드앱의메서드호출); 와 같이 호출하면 IDFA 값이 레코픽에 전달 됩니다.</li>
                    <li>
                        <pre class="c-mrkdwn__pre">애플의 정책에 따라 idfa수집이 불가능할 경우가 있으니 idfa 획득에 실패할 경우를 확인하셔서 recopick uid를 셋팅하여 전송하셔야합니다.<br>참고) permission policy : <a href="https://developer.apple.com/app-store/user-privacy-and-data-use/" class="external-link" style="text-decoration: none;">https://developer.apple.com/app-store/user-privacy-and-data-use/</a></pre>
                    </li>
                </ul>
                </li>
            </ul>
            <p><br></p>




        </div>
    </div>
</template>