<template>
    <div class="page view">
        <h1>7) 추천상품 클릭 로그</h1>
        <div id="main-content" class="wiki-content">
            <p><br>클릭 로그를 남기고 입력하신 URL로 redirect를 합니다. 클릭 로그가 정상적으로 전송되어야 정확한 추천 클릭수와 추천 클릭률 등의 성과 측정이 가능합니다.</p>
            <p>또한, 클릭 로그 데이터는 추천 계산 시에 반영되므로 클릭 로그가 전송되어야 추천 품질이 더 좋아집니다.</p>
            <p><u>추천 API 의 응답결과 중 clicklog_redirect_link 의 값을 그대로 사용하시면 더 쉽게 사용하실 수 있습니다.</u></p>
            <p><strong style="line-height: 1.5625;font-size: 16.0px;">Resource URL</strong> </p>
            <p><span class="nolink"><a href="https://lc.recopick.com/1/banner/:service_id/pick?source=:item_id&amp;pick=:item_id&amp;method=:method&amp;url=:encodedURL&amp;uid=:uid&amp;channel=:channel" class="external-link">https://lc.recopick.com/1/banner/<span style="color: rgb(255,0,0);"><strong>:service_id</strong></span>/pick?source=<span style="color: rgb(255,0,0);"><strong>:item_id</strong></span>&amp;pick=<span style="color: rgb(255,0,0);"><strong>:item_id</strong></span>&amp;method=<span style="color: rgb(255,0,0);"><strong>:method</strong></span>&amp;url=</a><span style="color: rgb(255,0,0);"><a><strong>:encodedURL</strong><span style="color: rgb(0,0,0);">&amp;uid=</span></a></span><span style="color: rgb(255,0,0);"><a><span style="color: rgb(255,0,0);"><strong>:uid</strong></span></a></span><span style="color: rgb(255,0,0);"><a><span style="color: rgb(0,0,0);">&amp;channel=</span></a><strong><a>:channel</a></strong><a><span style="color: rgb(0,0,0);">&amp;product_type=</span></a><strong><a>:product_type</a></strong></span></span></p>
            <h3 id="id-7)추천상품클릭로그-(GET1/banner/:service_id/pick)"><span class="mw-headline">(GET 1/banner/:service_id/pick)</span></h3>
            <div><span class="mw-headline"> </span></div>
            <div>
                <h5 id="id-7)추천상품클릭로그-*Native(Hybrid)App에서는uid자리에반드시GAID혹은IDFA값을넣어서업로드해야합니다."><em><u><strong><span style="color: rgb(255,0,0);"><span class="nolink">*Native(Hybrid) App에서는 uid 자리에 반드시 GAID 혹은 IDFA값을 넣어서 업로드 해야 합니다.</span></span></strong></u></em></h5>
                <h3 id="id-7)추천상품클릭로그-Parameters"><span class="mw-headline">Parameters</span></h3>
                <div class="table-wrap">
                    <table class="confluenceTable">
                        <colgroup>
                            <col />
                            <col />
                        </colgroup>
                        <tbody>
                        <tr>
                            <th colspan="1" class="confluenceTh">
                                <p>service_id</p>
                                <p><span style="color: rgb(153,153,153);">(required)</span></p>
                            </th>
                            <td colspan="1" class="confluenceTd">
                                <p><span style="color: rgb(34,34,34);">RecoPick에서 발급된 service id를 의미합니다. service id를 모르실 경우 RecoPick으로 문의해주시기 바랍니다.</span></p>
                                <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> 17</span></code></p>
                            </td>
                        </tr>
                        <tr>
                            <th class="confluenceTh">
                                <p><strong>source</strong></p>
                                <p><sup><span style="color: rgb(153,153,153);">(required)</span></sup></p>
                            </th>
                            <td class="confluenceTd">추천리스트가 계산된 Item ID를 말합니다. 예를 들어 &quot;ITEM2&quot;라는 Item에 대해 추천리스트를 받으셨다면 source 값은 &quot;ITEM2&quot;입니다. <br /><br /><code><strong>Example Values :</strong> ITEM2</code></td>
                        </tr>
                        <tr>
                            <th class="confluenceTh">
                                <p>pick</p>
                                <p><sup><span style="color: rgb(153,153,153);">(required)</span></sup></p>
                            </th>
                            <td class="confluenceTd">사용자가 클릭한 Item ID를 말합니다. 예를 들어 &quot;ITEM2&quot;라는 Item에 대해 추천리스트를 요청하면 아래와 같은 형식의 json 을 받습니다.
                                <pre> {
   &quot;id&quot;: &quot;ITEM5&quot;,
   &quot;score&quot;: 0.142857,
   &quot;intersection&quot;:12,
   &quot;method&quot;: &quot;4&quot;
 },
 {
   &quot;id&quot;: &quot;ITEM6&quot;,
   &quot;score&quot;: 0.060976,
   &quot;intersection&quot;:6,
   &quot;method&quot;: &quot;3&quot;
 }
</pre>
                                <p>위 리스트를 이용하여 추천 리스트를 사용자에게 보여준 다음, 사용자가 ITEM5를 클릭했다면 pick값은 ITEM5 입니다. 따라서 URL을 만드실 때 사용자가 선택한 &quot;id&quot;값을 pick의 값으로 사용하시면 됩니다. <br /><br /><code><strong>Example Values :</strong> pick=ITEM5</code></p>
                            </td>
                        </tr>
                        <tr>
                            <th class="confluenceTh">
                                <p>method</p>
                                <p><sup><span style="color: rgb(153,153,153);">(required)</span></sup></p>
                            </th>
                            <td class="confluenceTd">RecoPick에서 추천 계산시 사용된 알고리즘의 ID 입니다. 이 값은 추천결과를 받으신 json에서 사용자가 선택한 아이템의 &quot;method&quot;값을 그대로 사용하시면 됩니다. 위 예제에서 사용자가 ITEM5를 선택했다면, method는 4가 됩니다.<br /><br /><code><strong>Example Values :</strong> method=4</code></td>
                        </tr>
                        <tr>
                            <th class="confluenceTh">
                                <p>url</p>
                                <p><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></p>
                            </th>
                            <td class="confluenceTd">
                                <p>사용자가 추천리스트를 클릭하고 난 후 redirect될 URL 입니다.</p>
                                <p>이 때, URL은 반드시 encoding 되어 있어야 합니다. 이 옵션은 필수 사항이 아니며, URL을 쓰지 않을 경우 자체적으로 저장하고있는 URL 패턴에 맞춰서 상품 상세페이지로 redirect 해드립니다.<br /><br /><code><strong>Example Values :</strong> url=http%3A%2F%2Fexample.com%2Fproduct%2Fdetail.html</code></p>
                            </td>
                        </tr>
                        <tr>
                            <th colspan="1" class="confluenceTh">
                                <p>channel</p>
                                <p><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></p>
                            </th>
                            <td colspan="1" class="confluenceTd">
                                <p>클릭률, 경유 매출을 추적할 channel을 입력합니다. 추천 노출 영역이 다르거나, A/B test 시에 서로 다른 channel을 부여하시면, channel 별로 성과를 계산하여 보여드립니다.</p>
                                <p><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> channel=ch1</span></p>
                            </td>
                        </tr>
                        <tr>
                            <th colspan="1" class="confluenceTh">
                                <p>product_type</p>
                                <p><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></p>
                            </th>
                            <td colspan="1" class="confluenceTd">
                                <p>레코픽에서 사용중인 상품 종류를 입력합니다. (R : 추천상품 / L : 랜딩상품) 이 옵션은 필수 사항이 아니며, product_type 을 쓰지 않을 경우 추천상품으로 간주합니다. </p>
                                <p><code><strong>Example Values :</strong> product_type=R</code></p>
                            </td>
                        </tr>
                        <tr>
                            <th colspan="1" class="confluenceTh">
                                <p>uid</p>
                                <p><sup><span style="color: rgb(153,153,153);">(required)</span></sup></p>
                            </th>
                            <td colspan="1" class="confluenceTd">
                                <p>클릭한 사용자를 구분할 수 있는 UID 입니다.</p>
                                <p>클릭한 사용자 UID를 API 요청시 남겨주시면, 추천 계산시 클릭여부를 반영하여 좀 더 고품질의 추천을 받아보실 수 있습니다.</p>
                                <p>uid 값을 생성하는 방법은 <a href="http://docs.recopick.com/display/docs/2.2+Client+Library+APIs#id-2.2ClientLibraryAPIs-2.2ClientLibraryAPIs-fetchUID" class="external-link">2.2 Client Library APIs</a> 를 참고해주세요.</p>
                                <p><code><strong>Example Values :</strong> uid=9293812938<br /></code></p>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <p><span class="mw-headline"><br /></span></p>
                <p><span class="mw-headline"><br /></span></p>
                <h3 id="id-7)추천상품클릭로그-ExampleRequest"><span class="mw-headline">Example Request</span></h3>
                <hr />
                <p><code><strong>GET    </strong><span class="nolink">https://lc.recopick.com/1/banner/<span style="color: rgb(255,0,0);">17</span>/pick?source=<span style="color: rgb(255,0,0);">ITEM2</span>&amp;pick=<span style="color: rgb(255,0,0);">ITEM5</span>&amp;method=<span style="color: rgb(255,0,0);">4</span>&amp;url=<span style="color: rgb(255,0,0);">http%3A%2F%2Fexample.com%2Fproduct%2Fdetail.html<span style="color: rgb(0,0,0);">&amp;uid=<span style="color: rgb(255,0,0);"><code>9293812938<span style="color: rgb(0,51,102);">&amp;product_type=</span>R</code></span></span></span></span></code></p>
                <p><br /></p>
                <h3 id="id-7)추천상품클릭로그-"><span class="mw-headline"><br /></span></h3>
                <h3 id="id-7)추천상품클릭로그-Output"><span class="mw-headline">Output</span></h3>
                <h3 id="id-7)추천상품클릭로그-정상적인경우Parameter에적어주신URL이나클릭한상품URL로redirect됩니다."><span class="mw-headline"> </span><span style="line-height: 1.4285;font-size: 14.0px;">정상적인 경우 Parameter에 적어주신 URL이나 클릭한 상품 URL로 redirect 됩니다.</span></h3>
                <p><span class="mw-headline"><br /></span></p>
            </div>
        </div>

    </div>
</template>