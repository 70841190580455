<template>
    <div class="page view">
        <h1>1) 상품 메타정보 조회</h1>
        <div id="main-content" class="wiki-content">
            <br>
            <p>메타정보에는 수집되는 정보에 따라 title, tag, description, 상품 이미지 url, 상품 링크등을 반환합니다.</p>
            <p>정상적으로 호출할 경우 메타정보 JSON을 반환하며 비정상적인 호출일 경우 { title : &quot;fail&quot;, description : &quot;fail to fetch product info&quot; } 라는 JSON을 반환합니다.</p>
            <p> </p>
            <h3 id="id-1)상품메타정보조회-ResourceURL"><strong>Resource URL</strong> </h3>
            <p><span class="nolink"><span class="nolink">https://api.recopick.com/2/product/meta/:service_id/:item_id</span></span></p>
            <h3 id="id-1)상품메타정보조회-(GET2/product/meta/:service_id/:item_id)"><span class="nolink">(GET 2/product/meta/:service_id/:item_id)</span></h3>
            <p><span class="nolink"><br /></span></p>
            <h3 id="id-1)상품메타정보조회-Parameters"><span class="mw-headline">Parameters</span></h3>
            <div class="table-wrap">
                <table class="confluenceTable">
                    <tbody>
                    <tr>
                        <th class="confluenceTh">
                            <p>service_id</p>
                            <p><sup><span style="color: rgb(153,153,153);">(required)</span></sup></p>
                        </th>
                        <td class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">RecoPick에서 발급된 service id를 의미합니다. service id를 모르실 경우 RecoPick으로 문의해주시기 바랍니다.</span></p>
                            <p><code><strong style="line-height: 1.4285715;">Example Values :</strong><span style="color: rgb(34,34,34);"> 17</span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th class="confluenceTh">
                            <p>item_id</p>
                            <p><sup><span style="color: rgb(153,153,153);">(required)</span></sup></p>
                        </th>
                        <td class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">추천리스트를 가져올 Item ID를 의미합니다. Item별로 추천이 계산되고 있기 때문에 해당 사이트에서 URL에 노출되는 Item ID를 입력해주시면 됩니다.</span></p>
                            <p><code><strong style="line-height: 1.4285715;">Example Values :</strong><span style="color: rgb(34,34,34);"> ITEM1234</span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th class="confluenceTh">
                            <p>callback</p>
                            <p><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></p>
                        </th>
                        <td class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);"><span>callback을 입력하시면 JSONP 형태의 응답을 제공합니다.</span></span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> callback=parseResponse</span></code></p>
                            <p><code><strong>Example Request :</strong><span style="color: rgb(34,34,34);"> <a href="https://api.recopick.com/2/product/meta/1/710?callback=parseResponse" class="external-link">https://api.recopick.com/2/product/meta/1/710?callback=parseResponse</a></span></code></p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <p><span class="nolink"><br /></span></p>
            <div>
                <h3 id="id-1)상품메타정보조회-ExampleRequest"><span class="mw-headline">Example Request</span></h3>
                <div>
                    <hr />
                    <p><code><strong>GET    <span class="nolink">https://api.recopick.com/2/product/meta/17/ITEM1234</span></strong></code></p>
                    <h3 id="id-1)상품메타정보조회-"><span class="mw-headline"><br /></span></h3>
                    <h3 id="id-1)상품메타정보조회-Output"><span class="mw-headline">Output</span></h3>
                    <p>아래의 JSON은 반환되는 예시를 나타낸 것입니다.</p>
                    <div class="panel conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="panel">
                        <div class="panelContent">
                    <pre>{
    title: &quot;상품명&quot;
    tag: &quot;&quot;
    description: &quot;[스페셜슬리브리스] &quot;
    image: {
         src: &quot;http://www.test.co.kr/shopimages/0009053.jpg&quot;
    }
    link: &quot;http://www.test.co.kr/shop/shopdetail.html?branduid=351458&amp;xcode=047&quot;
}
</pre>
                            <p><a name="Redirect"> </a></p>
                        </div>
                    </div>
                    <p><span style="font-size: 14.0px;line-height: 1.4285715;"><br /></span></p><span class="mw-headline"><a name="Output_6"></a><br /></span>
                </div>
            </div>
        </div>
    </div>
</template>