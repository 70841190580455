<template>
    <div class="page view">
        <h1>16) 상품상세페이지 인당 평균 PV, UV (일간)</h1>
        <div id="main-content" class="wiki-content">
            <br>
            <p><span style="color: rgb(0,0,0);">상품상세페이지의 인당 평균 PV와 UV를 일 단위로 제공합니다.</span></p>
            <p><span style="color: rgb(0,0,0);">전체사용자 PV, 전체사용자수(UV), 전체사용자 인당평균 PV, 추천클릭사용자 PV, 추천클릭사용자수, 추천클릭사용자 인당평균 PV를 알 수 있습니다.</span></p>
            <p><span style="color: rgb(0,0,0);">어제 날짜 데이터까지 제공합니다.</span></p>
            <p><span style="color: rgb(0,0,0);">GET 방식으로 요청하실 수 있고 정상적으로 요청이 완료되는 경우는 JSON 형태의 추천리스트가 반환됩니다.</span></p>
            <p><span style="color: rgb(0,0,0);">callback 파라미터를 명시하는 경우 JSONP 형태로 응답합니다.</span></p>
            <p><span style="color: rgb(0,0,0);">요청에 실패할 경우 상황에 따라 4XX 혹은 5XX형태의 에러코드가 반환됩니다.</span></p>
            <h3 id="id-16)상품상세페이지인당평균PV,UV(일간)-ResourceURL"><span style="color: rgb(0,0,0);">Resource URL</span></h3>
            <p><span style="color: rgb(0,0,0);"><span> <span class="nolink">https://api.recopick.com/1/admin/:service_id/feed/dailyviewpvuv</span></span></span></p>
            <p><a href="https://api.recopick.com/2/recommend/:service_id/:item_id" class="external-link"> </a></p>
            <h3 id="id-16)상품상세페이지인당평균PV,UV(일간)-Parameters"><a href="https://api.recopick.com/2/recommend/:service_id/:item_id" class="external-link"><span class="mw-headline">Parameters</span></a></h3>
            <div class="table-wrap">
                <table class="wrapped confluenceTable">
                    <tbody>
                    <tr>
                        <th class="confluenceTh">
                            <p>service_id</p>
                            <p><sup><span style="color: rgb(153,153,153);">(required)</span></sup></p>
                        </th>
                        <td class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">레코픽에서 발급한 service id를 의미합니다. service id <span style="color: rgb(34,34,34);">값은 <a href="http://admin.recopick.com/-/setting" class="external-link">레코픽 대시보드</a></span><span style="color: rgb(34,34,34);">에서 확인하실 수 있습니다.</span></span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> 17</span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th class="confluenceTh">
                            <p>access_key</p>
                            <p><sup><span style="color: rgb(153,153,153);">(required)</span></sup></p>
                        </th>
                        <td class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">보안이 적용된 API를 호출하기 위해 사용되는 보안키를 의미합니다. 보안키 값은 <a href="http://admin.recopick.com/-/setting" class="external-link">레코픽 대시보드</a>에서 확인하실 수 있습니다.</span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> 1f385dca73fb935cb11f8a246ea85f94</span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh">
                            <p>ago</p>
                            <p><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></p>
                        </th>
                        <td colspan="1" class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">읽어올 데이터의 항목수를 의미합니다. 기본값은 12입니다.</span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> 12</span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh">
                            <p>skip</p>
                            <p><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></p>
                        </th>
                        <td colspan="1" class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">지정된 값만큼 이전 데이터를 읽어옵니다. 0으로 지정할 경우 어제 데이터까지, 1로 지정할 경우 그저께의 데이터까지 읽어옵니다. 기본값은 0입니다.</span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> 12</span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh">
                            <p>offset</p>
                            <p><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></p>
                        </th>
                        <td colspan="1" class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">시간대(Timezone)의 UTC(협정 세계 표준시)와의 차이를 분 단위로 지정합니다. UTC 대비 9시간 빠른 서울의 경우 -540으로 지정합니다. 기본값은 0입니다.</span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> -540</span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th class="confluenceTh">
                            <p>callback</p>
                            <p><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></p>
                        </th>
                        <td class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);"><span>callback을 입력하시면 JSONP 형태의 응답을 제공합니다.</span></span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> callback=parseResponse<br /></span></code></p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <p><a href="https://api.recopick.com/2/recommend/:service_id/:item_id" class="external-link"><span><br /></span></a></p>
            <h3 id="id-16)상품상세페이지인당평균PV,UV(일간)-ExampleRequest"><a href="https://api.recopick.com/2/recommend/:service_id/:item_id" class="external-link"><span>Example Request</span></a></h3>
            <hr />
            <p><a href="https://api.recopick.com/2/recommend/:service_id/:item_id" class="external-link"><span><strong>GET   </strong> </span><span class="nolink">https://api.recopick.com/1/admin/2/feed/dailyviewpvuv?access_key=REPLACE_THIS&amp;ago=12&amp;skip=0&amp;offset=-540</span><br /><span> </span></a></p>
            <h3 id="id-16)상품상세페이지인당평균PV,UV(일간)-Output">Output</h3>
            <h3 id="id-16)상품상세페이지인당평균PV,UV(일간)-반환되는JSON필드설명은아래를참고해주세요."><span style="color: rgb(0,0,0);"> </span><span>반환되는 JSON 필드 설명은 아래를 참고해주세요.</span></h3>
            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                <div class="codeContent panelContent pdl">
             <pre class="syntaxhighlighter-pre" data-syntaxhighlighter-params="brush: js; gutter: false; theme: Eclipse" data-theme="Eclipse">{
  &quot;20130824&quot;: {
    &quot;uv&quot;: 7055,
    &quot;pv&quot;: 32688,
    &quot;avg_pv&quot;: 4.63,
    &quot;reco_uv&quot;: 298,
    &quot;reco_pv&quot;: 5314,
    &quot;reco_avg_pv&quot;: 17.83
  },
  &quot;20130825&quot;: {
    &quot;uv&quot;: 6470,
    &quot;pv&quot;: 33455,
    &quot;avg_pv&quot;: 5.17,
    &quot;reco_uv&quot;: 298,
    &quot;reco_pv&quot;: 5931,
    &quot;reco_avg_pv&quot;: 19.9
  },
  &quot;20130826&quot;: {
    &quot;uv&quot;: 7073,
    &quot;pv&quot;: 42227,
    &quot;avg_pv&quot;: 5.97,
    &quot;reco_uv&quot;: 360,
    &quot;reco_pv&quot;: 7917,
    &quot;reco_avg_pv&quot;: 21.99
  },
  &quot;20130827&quot;: {
    &quot;uv&quot;: 7167,
    &quot;pv&quot;: 39339,
    &quot;avg_pv&quot;: 5.48,
    &quot;reco_uv&quot;: 327,
    &quot;reco_pv&quot;: 7819,
    &quot;reco_avg_pv&quot;: 23.91
  },
  &quot;20130828&quot;: {
    &quot;uv&quot;: 6862,
    &quot;pv&quot;: 34800,
    &quot;avg_pv&quot;: 5.07,
    &quot;reco_uv&quot;: 321,
    &quot;reco_pv&quot;: 6381,
    &quot;reco_avg_pv&quot;: 19.87
  },
  &quot;20130829&quot;: {
    &quot;uv&quot;: 7353,
    &quot;pv&quot;: 42104,
    &quot;avg_pv&quot;: 5.72,
    &quot;reco_uv&quot;: 439,
    &quot;reco_pv&quot;: 8198,
    &quot;reco_avg_pv&quot;: 18.67
  },
  &quot;20130830&quot;: {
    &quot;uv&quot;: 6846,
    &quot;pv&quot;: 35925,
    &quot;avg_pv&quot;: 5.24,
    &quot;reco_uv&quot;: 391,
    &quot;reco_pv&quot;: 7097,
    &quot;reco_avg_pv&quot;: 18.15
  }
}</pre>
                </div>
            </div>
            <p><span><br /></span></p>
            <p> </p>
            <div class="table-wrap">
                <table class="wrapped confluenceTable">
                    <tbody>
                    <tr>
                        <th colspan="2" class="confluenceTh">field</th>
                        <th style="text-align: center;" class="confluenceTh"><strong>의 미</strong></th>
                        <th style="text-align: center;" class="confluenceTh"><strong>비 고</strong></th>
                    </tr>
                    <tr>
                        <th class="confluenceTh">YYYYMMDD</th>
                        <th colspan="1" class="confluenceTh"><br /></th>
                        <td class="confluenceTd">년월일</td>
                        <td class="confluenceTd">해당 일자의 데이터를 나타냅니다.</td>
                    </tr>
                    <tr>
                        <th class="confluenceTh"><br /></th>
                        <th class="confluenceTh">uv</th>
                        <td class="confluenceTd">전체사용자수</td>
                        <td class="confluenceTd">해당 일자에 상품상세페이지를 조회한 전체사용자수</td>
                    </tr>
                    <tr>
                        <th class="confluenceTh"><br /></th>
                        <th class="confluenceTh">pv</th>
                        <td class="confluenceTd">페이지뷰</td>
                        <td class="confluenceTd">해당 일자의 상품상세페이지 조회수</td>
                    </tr>
                    <tr>
                        <th class="confluenceTh"><br /></th>
                        <th class="confluenceTh">avg_pv</th>
                        <td class="confluenceTd">인당 페이지뷰</td>
                        <td class="confluenceTd">일간 상품상세페이지의 인당 평균 조회수(전체사용자 기준)</td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"><br /></th>
                        <th colspan="1" class="confluenceTh">reco_uv</th>
                        <td colspan="1" class="confluenceTd">추천사용자수</td>
                        <td colspan="1" class="confluenceTd"><span>해당 일자에 상품상세페이지를 조회한 추천사용자수</span></td>
                    </tr>
                    <tr>
                        <th class="confluenceTh"><br /></th>
                        <th class="confluenceTh"><span>reco_</span>pv</th>
                        <td class="confluenceTd"><span>추천 </span>페이지뷰</td>
                        <td class="confluenceTd"><span>해당 일자에 추천 클릭을 통한 상품상세페이지 조회수</span></td>
                    </tr>
                    <tr>
                        <th class="confluenceTh"><br /></th>
                        <th class="confluenceTh"><span>reco_</span>avg_pv</th>
                        <td class="confluenceTd"><span>추천사용</span>자 당 페이지뷰</td>
                        <td class="confluenceTd"><span>일간 상품상세페이지의 인당 평균 조회수(추천사용자 기준)</span></td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <p><span><br /></span></p>
        </div>
    </div>
</template>