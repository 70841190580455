<template>
    <div class="page view">
        <h1>07) 추천 서비스 전체 통계 (일간)</h1>
        <div id="main-content" class="wiki-content">
            <br>
            <p>추천 알고리즘별 클릭률 및 구매전환율, 구매자수, 상품수, Active상품수 데이터를 전체 및 추천에 따른 경우로 구분하여 제공합니다. 일 단위로 제공되며, 어제 날짜의 데이터까지 제공됩니다.</p>
            <p><span style="color: rgb(0,0,0);">GET 방식으로 요청하실 수 있고 정상적으로 요청이 완료되는 경우는 JSON 형태의 추천리스트가 반환됩니다. callback 파라미터를 명시하는 경우 JSONP 형태로 응답합니다.</span></p>
            <p><span style="color: rgb(0,0,0);">요청에 실패할 경우 상황에 따라 4XX 혹은 5XX형태의 에러코드가 반환됩니다.</span></p>
            <h3 id="id-07)추천서비스전체통계(일간)-ResourceURL"><span style="color: rgb(0,0,0);">Resource URL</span></h3>
            <p><span style="color: rgb(0,0,0);"><span> <span class="nolink">https://api.recopick.com/1/admin/:service_id/feed/dailyserviceinfo<a href="https://api.recopick.com/2/recommend/:service_id/:item_id" class="external-link"></a></span></span></span></p>
            <p> </p>
            <h3 id="id-07)추천서비스전체통계(일간)-Parameters"><span class="mw-headline">Parameters</span></h3>
            <div class="table-wrap">
                <table class="confluenceTable">
                    <tbody>
                    <tr>
                        <th class="confluenceTh">
                            <p>service_id</p>
                            <p><sup><span style="color: rgb(153,153,153);">(required)</span></sup></p>
                        </th>
                        <td class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">RecoPick에서 발급된 service id를 의미합니다. service id <span style="color: rgb(34,34,34);">값은 </span><a href="http://admin.recopick.com/-/api_info" class="external-link">어드민 대시보드</a><span style="color: rgb(34,34,34);"> </span><span style="color: rgb(34,34,34);">에서 확인하실 수 있습니다.</span></span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> 17</span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th class="confluenceTh">
                            <p>access_key</p>
                            <p><sup><span style="color: rgb(153,153,153);">(required)</span></sup></p>
                        </th>
                        <td class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">보안이 적용된 API를 호출하기 위해 사용되는 비밀키를 의미합니다. 비밀키 값은 <a href="http://admin.recopick.com/-/api_info" class="external-link">어드민 대시보드</a>에서 확인하실 수 있습니다.</span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> 1f385dca73fb935cb11f8a246ea85f94</span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh">
                            <p>ago</p>
                            <p><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></p>
                        </th>
                        <td colspan="1" class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">읽어올 데이터의 항목수를 의미합니다. 기본값은 7입니다.</span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> 7</span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh">
                            <p>skip</p>
                            <p><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></p>
                        </th>
                        <td colspan="1" class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">지정된 값만큼 이전 데이터를 읽어옵니다. 0으로 지정할 경우 어제 데이터까지, 1로 지정할 경우 그저께의 데이터까지 읽어옵니다. 기본값은 0입니다.</span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> 12</span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh">
                            <p>offset</p>
                            <p><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></p>
                        </th>
                        <td colspan="1" class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">시간대(Timezone)의 UTC(협정 세계 표준시)와의 차이를 분 단위로 지정합니다. UTC 대비 9시간 빠른 서울의 경우 -540으로 지정합니다. 기본값은 0입니다.</span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> -540</span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th class="confluenceTh">
                            <p>callback</p>
                            <p><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></p>
                        </th>
                        <td class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);"><span>callback을 입력하시면 JSONP 형태의 응답을 제공합니다.</span></span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> callback=parseResponse<br /></span></code></p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <p><span><br /></span></p>
            <h3 id="id-07)추천서비스전체통계(일간)-ExampleRequest"><span>Example Request</span></h3>
            <hr />
            <p><span><strong>GET   </strong> </span><span class="nolink">https://api.recopick.com/1/admin/2/feed/dailyserviceinfo?access_key=REPLACE_THIS&amp;ago=12&amp;skip=0&amp;offset=-540</span><br /><span> </span></p>
            <h3 id="id-07)추천서비스전체통계(일간)-Output"><span style="color: rgb(0,0,0);"><a name="Output_5"></a>Output</span></h3>
            <h3 id="id-07)추천서비스전체통계(일간)-반환되는JSON필드설명은아래를참고해주세요."><span style="color: rgb(0,0,0);"> </span><span>반환되는 JSON 필드 설명은 아래를 참고해주세요.</span></h3>
            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                <div class="codeContent panelContent pdl">
             <pre class="syntaxhighlighter-pre" data-syntaxhighlighter-params="brush: js; gutter: false; theme: Eclipse" data-theme="Eclipse">{
  &quot;20130810&quot;: {
    &quot;ctr&quot;: {
      &quot;3&quot;: {
        &quot;click_ratio&quot;: 0,
        &quot;click_count&quot;: 0,
        &quot;view_count&quot;: 23
      },
      &quot;4&quot;: {
        &quot;click_ratio&quot;: 0.059726156,
        &quot;click_count&quot;: 253,
        &quot;view_count&quot;: 4236
      }
    },
    &quot;item_count&quot;: 528,
    &quot;reco_item_count&quot;: 497,
    &quot;active_item_count&quot;: 0,
    &quot;active_reco_item_count&quot;: 0,
    &quot;view&quot;: 780,
    &quot;purchase&quot;: 242,
    &quot;recoclick&quot;: 95,
    &quot;purchase_and_recoclick&quot;: 35,
    &quot;click_ratio&quot;: 0,
    &quot;click_count&quot;: 0,
    &quot;view_count&quot;: 0,
    &quot;max_method&quot;: 4
  },
  &quot;20130811&quot;: {
    &quot;ctr&quot;: {
      &quot;3&quot;: {
        &quot;click_ratio&quot;: 0,
        &quot;click_count&quot;: 0,
        &quot;view_count&quot;: 8
      },
      &quot;4&quot;: {
        &quot;click_ratio&quot;: 0.072041166,
        &quot;click_count&quot;: 294,
        &quot;view_count&quot;: 4081
      }
    },
    &quot;item_count&quot;: 528,
    &quot;reco_item_count&quot;: 497,
    &quot;active_item_count&quot;: 0,
    &quot;active_reco_item_count&quot;: 0,
    &quot;view&quot;: 841,
    &quot;purchase&quot;: 280,
    &quot;recoclick&quot;: 107,
    &quot;purchase_and_recoclick&quot;: 35,
    &quot;click_ratio&quot;: 0,
    &quot;click_count&quot;: 0,
    &quot;view_count&quot;: 0,
    &quot;diff_item_count&quot;: 0,
    &quot;diff_reco_item_count&quot;: 0,
    &quot;max_method&quot;: 4,
    &quot;diff_click_ratio&quot;: 0.012315010000000001
  },
  &quot;20130812&quot;: {
    &quot;ctr&quot;: {
      &quot;3&quot;: {
        &quot;click_ratio&quot;: 0.020408163,
        &quot;click_count&quot;: 1,
        &quot;view_count&quot;: 49
      },
      &quot;4&quot;: {
        &quot;click_ratio&quot;: 0.055133614,
        &quot;click_count&quot;: 196,
        &quot;view_count&quot;: 3555
      }
    },
    &quot;item_count&quot;: 529,
    &quot;reco_item_count&quot;: 497,
    &quot;active_item_count&quot;: 0,
    &quot;active_reco_item_count&quot;: 0,
    &quot;view&quot;: 864,
    &quot;purchase&quot;: 213,
    &quot;recoclick&quot;: 97,
    &quot;purchase_and_recoclick&quot;: 31,
    &quot;click_ratio&quot;: 0,
    &quot;click_count&quot;: 0,
    &quot;view_count&quot;: 0,
    &quot;diff_item_count&quot;: 1,
    &quot;diff_reco_item_count&quot;: 0,
    &quot;max_method&quot;: 4,
    &quot;diff_click_ratio&quot;: -0.016907552000000006
  },
  &quot;20130813&quot;: {
    &quot;ctr&quot;: {
      &quot;3&quot;: {
        &quot;click_ratio&quot;: 0.076923076,
        &quot;click_count&quot;: 1,
        &quot;view_count&quot;: 13
      },
      &quot;4&quot;: {
        &quot;click_ratio&quot;: 0.052682611,
        &quot;click_count&quot;: 163,
        &quot;view_count&quot;: 3094
      }
    },
    &quot;item_count&quot;: 530,
    &quot;reco_item_count&quot;: 498,
    &quot;active_item_count&quot;: 0,
    &quot;active_reco_item_count&quot;: 0,
    &quot;view&quot;: 659,
    &quot;purchase&quot;: 160,
    &quot;recoclick&quot;: 61,
    &quot;purchase_and_recoclick&quot;: 17,
    &quot;click_ratio&quot;: 0,
    &quot;click_count&quot;: 0,
    &quot;view_count&quot;: 0,
    &quot;diff_item_count&quot;: 1,
    &quot;diff_reco_item_count&quot;: 1,
    &quot;max_method&quot;: 4,
    &quot;diff_click_ratio&quot;: -0.002451003
  },
  &quot;20130814&quot;: {
    &quot;ctr&quot;: {
      &quot;3&quot;: {
        &quot;click_ratio&quot;: 0,
        &quot;click_count&quot;: 0,
        &quot;view_count&quot;: 14
      },
      &quot;4&quot;: {
        &quot;click_ratio&quot;: 0.039664624,
        &quot;click_count&quot;: 123,
        &quot;view_count&quot;: 3101
      }
    },
    &quot;item_count&quot;: 530,
    &quot;reco_item_count&quot;: 498,
    &quot;active_item_count&quot;: 0,
    &quot;active_reco_item_count&quot;: 0,
    &quot;view&quot;: 758,
    &quot;purchase&quot;: 219,
    &quot;recoclick&quot;: 68,
    &quot;purchase_and_recoclick&quot;: 17,
    &quot;click_ratio&quot;: 0,
    &quot;click_count&quot;: 0,
    &quot;view_count&quot;: 0,
    &quot;diff_item_count&quot;: 0,
    &quot;diff_reco_item_count&quot;: 0,
    &quot;max_method&quot;: 4,
    &quot;diff_click_ratio&quot;: -0.013017986999999995
  },
  &quot;20130815&quot;: {
    &quot;ctr&quot;: {
      &quot;3&quot;: {
        &quot;click_ratio&quot;: 0,
        &quot;click_count&quot;: 0,
        &quot;view_count&quot;: 24
      },
      &quot;4&quot;: {
        &quot;click_ratio&quot;: 0.085981308,
        &quot;click_count&quot;: 276,
        &quot;view_count&quot;: 3210
      }
    },
    &quot;item_count&quot;: 530,
    &quot;reco_item_count&quot;: 498,
    &quot;active_item_count&quot;: 0,
    &quot;active_reco_item_count&quot;: 0,
    &quot;view&quot;: 745,
    &quot;purchase&quot;: 234,
    &quot;recoclick&quot;: 111,
    &quot;purchase_and_recoclick&quot;: 40,
    &quot;click_ratio&quot;: 0,
    &quot;click_count&quot;: 0,
    &quot;view_count&quot;: 0,
    &quot;diff_item_count&quot;: 0,
    &quot;diff_reco_item_count&quot;: 0,
    &quot;max_method&quot;: 4,
    &quot;diff_click_ratio&quot;: 0.046316684000000004
  },
  &quot;20130816&quot;: {
    &quot;ctr&quot;: {
      &quot;3&quot;: {
        &quot;click_ratio&quot;: 0,
        &quot;click_count&quot;: 0,
        &quot;view_count&quot;: 14
      },
      &quot;4&quot;: {
        &quot;click_ratio&quot;: 0.065521628,
        &quot;click_count&quot;: 206,
        &quot;view_count&quot;: 3144
      }
    },
    &quot;item_count&quot;: 533,
    &quot;reco_item_count&quot;: 498,
    &quot;active_item_count&quot;: 0,
    &quot;active_reco_item_count&quot;: 0,
    &quot;view&quot;: 743,
    &quot;purchase&quot;: 209,
    &quot;recoclick&quot;: 80,
    &quot;purchase_and_recoclick&quot;: 20,
    &quot;click_ratio&quot;: 0,
    &quot;click_count&quot;: 0,
    &quot;view_count&quot;: 0,
    &quot;diff_item_count&quot;: 3,
    &quot;diff_reco_item_count&quot;: 0,
    &quot;max_method&quot;: 4,
    &quot;diff_click_ratio&quot;: -0.020459680000000008
  }
}</pre>
                </div>
            </div>
            <p><span><br /></span></p>
            <p> </p>
            <div class="table-wrap">
                <table class="confluenceTable">
                    <tbody>
                    <tr>
                        <th colspan="4" class="confluenceTh">field</th>
                        <th style="text-align: center;" class="confluenceTh"><strong>의 미</strong></th>
                        <th style="text-align: center;" class="confluenceTh"><strong>비 고</strong></th>
                    </tr>
                    <tr>
                        <th class="confluenceTh">YYYYMMDD</th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <td class="confluenceTd">년월일</td>
                        <td class="confluenceTd">해당 일자의 데이터를 나타냅니다.</td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh">ctr</th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <td colspan="1" class="confluenceTd">추천 클릭률</td>
                        <td colspan="1" class="confluenceTd"> </td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh">METHOD</th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <td colspan="1" class="confluenceTd">추천 알고리즘</td>
                        <td colspan="1" class="confluenceTd"><a href="/pages/viewpage.action?pageId=3244041">1) 추천 알고리즘 목록</a> 참조</td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh">click_ratio</th>
                        <td colspan="1" class="confluenceTd">추천 클릭률</td>
                        <td colspan="1" class="confluenceTd"><span>해당 일자의 추천 클릭률</span></td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh">click_count</th>
                        <td colspan="1" class="confluenceTd">추천 클릭수</td>
                        <td colspan="1" class="confluenceTd">해당 일자의 추천 클릭수</td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh">view_count</th>
                        <td colspan="1" class="confluenceTd">추천 조회수</td>
                        <td colspan="1" class="confluenceTd">해당 일자의 추천이 제공된 상품 상세 페이지 조회수</td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh">item_count</th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <td colspan="1" class="confluenceTd">상품수</td>
                        <td colspan="1" class="confluenceTd">해당 일자에 수집된 전체 상품수</td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh">reco_item_count</th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <td colspan="1" class="confluenceTd">추천가능 상품수</td>
                        <td colspan="1" class="confluenceTd"><span>해당 일자에 수집된 추천가능 상품수</span></td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh">active_item_count</th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <td colspan="1" class="confluenceTd">Active 상품수</td>
                        <td colspan="1" class="confluenceTd">해당 일자 기준 30일 이내에 한번이라도 방문자가 있었던 상품수</td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh">active_reco_item_count</th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <td colspan="1" class="confluenceTd">Active 추천가능 상품수</td>
                        <td colspan="1" class="confluenceTd"><span>해당 일자 기준 30일 이내에 한번이라도 방문자가 있었던 추천가능 상품수</span></td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh">view</th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <td colspan="1" class="confluenceTd">조회수</td>
                        <td colspan="1" class="confluenceTd">해당 일자의 상품 상세 페이지 조회수</td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh">purchase</th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <td colspan="1" class="confluenceTd">구매수</td>
                        <td colspan="1" class="confluenceTd">해당 일자의 구매 페이지 조회수</td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh">recoclick</th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <td colspan="1" class="confluenceTd">추천 클릭수</td>
                        <td colspan="1" class="confluenceTd">해당 일자의 추천 클릭수</td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh">purchase_and_recoclick</th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <td colspan="1" class="confluenceTd">추천 클릭 후 구매수</td>
                        <td colspan="1" class="confluenceTd"><span>해당 일자의 구매 페이지 조회수 중 추천 클릭 한 사용자의 조회수</span></td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh">diff_item_count</th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <td colspan="1" class="confluenceTd">상품수 차이</td>
                        <td colspan="1" class="confluenceTd">이전 일자와의 item_count 차이</td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh">diff_reco_item_count</th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <td colspan="1" class="confluenceTd">추천가능 상품수 차이</td>
                        <td colspan="1" class="confluenceTd">이전 일자와의 reco_item_count 차이</td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh">max_method</th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <td colspan="1" class="confluenceTd">추천 클릭률이 가장 높은 추천 알고리즘</td>
                        <td colspan="1" class="confluenceTd"><a href="/pages/viewpage.action?pageId=3244041">1) 추천 알고리즘 목록</a><span> 참조</span></td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh">diff_click_ratio</th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <td colspan="1" class="confluenceTd">추천 클릭률 차이</td>
                        <td colspan="1" class="confluenceTd">이전 일자의 가장 높은 추천 클릭률과 해당 일자의 가장 높은 추천 클릭률의 차이</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <p><span><br /></span></p>
            <p><span>.</span></p>
        </div>
    </div>
</template>