<template>
    <div class="page view">
        <h1>4) Search 로그 (검색)</h1>
        <div id="main-content" class="wiki-content">
            <p><br />Search(검색) 로그를 수집하는 API 입니다. 정상적으로 수집되는 경우는 200 OK라는 코드가 반환됩니다. 요청에 실패할 경우 상황에 따라 4XX 혹은 5XX형태의 에러코드가 반환됩니다.</p>
            <h3 id="id-4)Search로그(검색)-ResourceURL">Resource URL</h3>
            <p><span class="nolink"><a href="https://lc.recopick.com/v1/logs/search/:service_id/:uid" class="external-link">https://lc.recopick.com/v1/logs/search/:service_id/:uid</a></span></p>
            <h3 id="id-4)Search로그(검색)-(GET/POSTv1/logs/search/:service_id/:uid)"><span style="color: rgb(34,34,34);">(GET/POST v1/logs/search/:service_id/:uid)</span></h3>
            <h3 id="id-4)Search로그(검색)-*Native(Hybrid)App에서행동로그를업로드할경우에는uid자리에반드시GAID혹은IDFA값을넣어서업로드해야합니다."><em><u><strong><span style="color: rgb(255,0,0);"><span class="nolink">*Native(Hybrid) App에서 행동로그를 업로드 할 경우에는 uid 자리에 반드시 GAID 혹은 IDFA값을 넣어서 업로드 해야 합니다.</span></span></strong></u></em></h3>
            <h3 id="id-4)Search로그(검색)-Parameters">Parameters</h3>
            <div class="table-wrap">
                <table class="confluenceTable">
                    <tbody>
                    <tr>
                        <th class="confluenceTh">
                            <p>service_id</p>
                            <p><sup><span style="color: rgb(153,153,153);">(required)</span></sup></p>
                        </th>
                        <td class="confluenceTd">
                            <p>업데이트할 서비스의 id를 의미합니다. 각 서비스 별 service_id는 <a href="https://admin.recopick.com/315/setting" class="external-link">https://admin.recopick.com/-/setting</a> 환경설정에서 확인하실 수 있습니다.</p>
                            <p><strong>Example Value :</strong><span style="color: rgb(34,34,34);"> 543</span></p>
                        </td>
                    </tr>
                    <tr>
                        <th class="confluenceTh">
                            <p>uid</p>
                            <p><sup><span style="color: rgb(153,153,153);">(required)</span></sup></p>
                        </th>
                        <td class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">사용자를 식별할 수 있는 ID를 문자열 형태로 입력합니다.</span></p>
                            <p><span style="color: rgb(34,34,34);">uid 값을 생성하는 방법은 <a href="/display/docs/Client+Library+APIs#ClientLibraryAPIs-2.2ClientLibraryAPIs-fetchUID">Client Library APIs</a> 를 참고해주세요.</span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> 123456</span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th class="confluenceTh">
                            <p>ref</p>
                            <p><sup><span style="color: rgb(153,153,153);">(required)</span></sup></p>
                        </th>
                        <td class="confluenceTd">
                            <p>사용자가 현재 페이지 이전에 보고 있던 Referrer page URL을 입력합니다. </p>
                            <p><code><strong>Example Values :</strong> <a href="http://example.com/" class="external-link">http://example.com/</a></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th class="confluenceTh">
                            <p>url</p>
                            <p><sup><span style="color: rgb(153,153,153);">(required)</span></sup></p>
                        </th>
                        <td class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">현재 보고 있는 페이지의 URL을 입력합니다. </span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> </span><span class="nolink"><a href="http://example.com/product/detail.html" class="external-link">http://example.com/product/detail.html</a></span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th class="confluenceTh">
                            <p>q</p>
                            <p><sup><span style="color: rgb(153,153,153);">(required)</span></sup></p>
                        </th>
                        <td class="confluenceTd"><span style="color: rgb(34,34,34);">검색어를 입력합니다.</span><br />
                            <p><strong>Example Values</strong><code> :</code><code> &quot;원피스&quot;</code></p>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh">
                            <p>user</p>
                            <p><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></p>
                        </th>
                        <td colspan="1" class="confluenceTd">
                            <p>사용자의 성별, 연령 등의 데이터를 전송해주시면 각 사용자에게 더욱 특화된 추천을 제공해 드립니다.</p>
                            <p>JSON Object 객체로 요청하시면 됩니다. 객체에는 다음과 같은 Key를 지정하실 수 있습니다.</p>
                            <ul>
                                <li>mid <em>(optional)</em> : 사이트 내에서 자체적으로 사용하는 회원ID<ul>
                                    <li><span style="color: rgb(0,51,102);">개인정보보호를 위해 회원ID를 암호화해야 하며, 암호화된 결과에 특수문자가 들어가지 않는 암호화 알고리즘으로 단방향 암호화 해주세요 (예&gt; SHA-256 등)</span></li>
                                </ul>
                                </li>
                                <li>gender <em>(optional)</em> : 성별 (M: 남자, F: 여자, O: 기타)</li>
                                <li>birthyear <em>(optional)</em> : 출생연도 (예: 1994)</li>
                            </ul>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> </span><span class="nolink"><a href="http://example.com/product/detail.html" class="external-link">u</a>ser: { mid: &quot;a891cd6a5b84d5a44d1edf17d080f39f&quot;, gender: &quot;F&quot;, birthyear: 1994 }</span></code></p>
                            <p><img class="emoticon emoticon-warning" src="@/assets/images/icons/emoticons/warning.svg" data-emoticon-name="warning" alt="(warning)" /><span style="color: rgb(255,0,0);"> 주의하세요! </span>레코픽 추천을 API 방식으로 사용하는 경우, GET방식으로 API를 호출하게 됩니다.<span style="color: rgb(255,0,0);"><span style="color: rgb(0,0,0);"><br /></span></span>  이 경우 일부 API의 파라미터에 MID가 사용되는데, MID 값에 일부 특수문자(예&gt; &amp;, =) 가 들어가게 되면 정상동작 하지 않을 수 있습니다.<span style="color: rgb(255,0,0);"><span style="color: rgb(0,0,0);"><br /></span></span>  암호화된 결과에 특수문자가 들어가지 않는 암호화 알고리즘으로 단방향 암호화 해주세요. (예&gt; SHA-256, MD5 등)</p>
                            <p>* MID 단방향 암호화 예 : 88020d58ceb8a4ad7f6756021fbc06b8a91f17daad2f04a5d369608d9c308bde (SHA-256으로 암호화), 4509bf9373ff3dca193ba02726c87988 (MD5로 암호화)</p>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh">
                            <p>gaid</p>
                            <p><sup><span style="color: rgb(153,153,153);"> </span></sup></p>
                        </th>
                        <td colspan="1" class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">Google Advertising ID 입니다.</span></p>
                            <p><span style="color: rgb(34,34,34);"><span style="color: rgb(34,34,34);"><u><span class="nolink"><u><span class="nolink">*Hybrid App에서 행동로그를 업로드 할 경우에는 반드시  GAID(IDFA)값을 넣어야 합니다. </span></u></span></u></span></span></p>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh">
                            <p>idfa</p>
                            <p><sup><span style="color: rgb(153,153,153);"> </span></sup></p>
                        </th>
                        <td colspan="1" class="confluenceTd">
                            <p>Apple Advertising Identifier 입니다.</p>
                            <p><span style="color: rgb(34,34,34);"><u><span class="nolink"><u><span class="nolink">*Hybrid App에서 행동로그를 업로드 할 경우에는 반드시  GAID(IDFA)값을 넣어야 합니다. </span></u></span></u></span></p>
                        </td>
                    </tr>
                    <tr>
                        <th class="confluenceTh">
                            <p>callback</p>
                            <p><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></p>
                        </th>
                        <td class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">callback을 입력하시면 JSONP 형태의 응답을 제공합니다.</span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> callback=parseResponse<br /></span></code></p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <h3 id="id-4)Search로그(검색)-ExampleRequest">Example Request</h3>
            <hr />
            <ul>
                <li><a href="http://jsfiddle.net" class="external-link">http://jsfiddle.net</a> 을 통해 테스트를 하시면 됩니다.</li>
                <li><span style="color: rgb(255,0,0);">주의사항 &gt;</span>
                    <ul>
                        <li>POST 방식인 경우,<strong> Content-type: application/json</strong>으로 해주셔야 합니다.</li>
                        <li>아래와 같이 jQuery를 사용하여 data 속성을 통해 파라미터값을 전송하는 경우, url과 ref 변수를 별도로 escape 하시면 안됩니다.</li>
                    </ul>
                </li>
            </ul>
            <ul>
                <li>
                    <p>&quot;여성&quot;인 사용자가 &quot;원피스&quot;를 검색한 경우</p>
                    <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                        <div class="codeContent panelContent pdl">
                     <pre class="syntaxhighlighter-pre" data-syntaxhighlighter-params="brush: js; gutter: true; theme: Confluence" data-theme="Confluence">(function ($) {
    $.ajax({
        url: 'https://lc.recopick.com/v1/logs/search/543/uid123',
        method: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({
            url: &quot;http://search.11st.co.kr/SearchPrdAction.tmall?method=getTotalSearchSeller&amp;kwd=%BF%F8%C7%C7%BD%BA&quot;,
            ref: &quot;http://www.11st.co.kr/browsing/DisplayCategory.tmall?method=getDisplayCategory2Depth&amp;dispCtgrNo=127684&quot;,
            q: &quot;원피스&quot;,
            user: {
                gender: &quot;F&quot;,
                birthyear: &quot;1980&quot;,
                mid: &quot;4d833442f8596025ef8e6f00684301d8&quot;
            }
        })
    });
})(jQuery);</pre>
                        </div>
                    </div>
                </li>
            </ul>
            <h3 id="id-4)Search로그(검색)-Output">Output</h3>
            <p>성공시에 200 OK가, 실패 시에는 그에 맞는 4xx나 5xx 에러 코드가 반환됩니다.</p>
        </div>
    </div>
</template>