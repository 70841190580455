<template>
    <div class="page view">
        <h1>1) View 로그 (상품조회)</h1>
        <div id="main-content" class="wiki-content">
            <p><br>POST 방식으로 View(상품 상세 페이지) 로그를 수집하는 API 입니다. 정상적으로 수집되는 경우는 200 OK라는 코드가 반환됩니다. 요청에 실패할 경우 상황에 따라 4XX 혹은 5XX형태의 에러코드가 반환됩니다.</p>
            <p>View 로그 전송을 통해 상품 정보를 업데이트합니다.</p>
            <h3 id="id-1)View로그(상품조회)-ResourceURL">Resource URL</h3>
            <p><span class="nolink"><a href="https://lc.recopick.com/v1/logs/view/:service_id/:uid" class="external-link">https://lc.recopick.com/v1/logs/view/:service_id/:uid</a></span></p>
            <h3 id="id-1)View로그(상품조회)-(GET/POSTv1/logs/view/:service_id/:uid)"><span class="nolink">(GET/POST v1/logs/view/:service_id/:uid)</span></h3>
            <p><em><u><strong><span style="color: rgb(255,0,0);">*Native(Hybrid) App에서 행동로그를 업로드 할 경우에는 uid 자리에 반드시 GAID 혹은 IDFA값을 넣어서 업로드 해야 합니다.</span></strong></u></em></p>
            <h3 id="id-1)View로그(상품조회)-Parameters">Parameters</h3>
            <div class="table-wrap">
                <table class="wrapped confluenceTable">
                    <colgroup>
                        <col />
                        <col />
                    </colgroup>
                    <tbody>
                    <tr>
                        <th class="confluenceTh">
                            <p>service_id</p>
                            <p><sup><span style="color: rgb(153,153,153);">(required)</span></sup></p>
                        </th>
                        <td class="confluenceTd">
                            <p>업데이트할 서비스의 id를 의미합니다. 각 서비스 별 service_id는 <a href="https://admin.recopick.com/315/setting" class="external-link">https://admin.recopick.com/-/setting</a> 환경설정에서 확인하실 수 있습니다.</p>
                            <p><strong>Example Value :</strong><span style="color: rgb(34,34,34);"> 543</span></p>
                        </td>
                    </tr>
                    <tr>
                        <th class="confluenceTh">
                            <p>uid</p>
                            <p><sup><span style="color: rgb(153,153,153);">(required)</span></sup></p>
                        </th>
                        <td class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">사용자를 식별할 수 있는 ID를 문자열 형태로 입력합니다.</span></p>
                            <p><span style="color: rgb(34,34,34);">uid 값을 생성하는 방법은 <a href="/display/docs/Client+Library+APIs#ClientLibraryAPIs-2.2ClientLibraryAPIs-fetchUID">Client Library APIs</a> 를 참고해주세요.</span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> 123456</span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th class="confluenceTh">
                            <p>ref</p>
                            <p><sup><span style="color: rgb(153,153,153);">(required)</span></sup></p>
                        </th>
                        <td class="confluenceTd">
                            <p>사용자가 현재 페이지 이전에 보고 있던 Referrer page URL을 입력합니다. </p>
                            <p><code><strong>Example Values :</strong> <a href="http://example.com/" class="external-link">http://example.com/</a></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th class="confluenceTh">
                            <p>url</p>
                            <p><sup><span style="color: rgb(153,153,153);">(required)</span></sup></p>
                        </th>
                        <td class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">현재 보고 있는 페이지의 URL을 입력합니다. </span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> </span><span class="nolink"><a href="http://example.com/product/detail.html" class="external-link">http://example.com/product/detail.html</a></span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th class="confluenceTh">
                            <p>items</p>
                            <p><sup><span style="color: rgb(153,153,153);">(required)</span></sup></p>
                        </th>
                        <td class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">JSON 형태의 Array 객체로 요청하시면 됩니다. 각각의 객체는 다음과 같은 Key 값을 포함해야 합니다.</span></p>
                            <div class="table-wrap">
                                <table class="wrapped confluenceTable">
                                    <tbody>
                                    <tr>
                                        <th class="confluenceTh">Json Key</th>
                                        <th class="confluenceTh">설명</th>
                                    </tr>
                                    <tr>
                                        <td class="confluenceTd">
                                            <p><strong>id</strong></p>
                                            <p><span style="color: rgb(153,153,153);">(required)</span></p>
                                        </td>
                                        <td class="confluenceTd">
                                            <p><span>Item의 unique한 ID 정보를 말합니다.</span></p>
                                            <p><span><strong>Example Value :</strong><span style="color: rgb(34,34,34);"> 1198708089</span></span></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="confluenceTd">
                                            <p><span style="color: rgb(0,0,0);"><strong>title</strong></span></p>
                                            <p><span style="color: rgb(153,153,153);">(required)</span></p>
                                        </td>
                                        <td class="confluenceTd">
                                            <p><span style="color: rgb(34,34,34);">item 제목을 의미합니다.</span></p>
                                            <p><span style="color: rgb(34,34,34);">limit length : 256</span></p>
                                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> <span style="color: rgb(0,0,0);">나이키 에어맥스</span></span></code></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="1" class="confluenceTd">
                                            <p><strong>image</strong></p>
                                            <p><span style="color: rgb(153,153,153);">(required)</span></p>
                                        </td>
                                        <td colspan="1" class="confluenceTd">
                                            <p>Item 이미지 URL을 의미합니다.</p>
                                            <p><span style="color: rgb(34,34,34);">limit length : 1024</span></p>
                                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> <span class="nolink"><a href="http://i.011st.com/t/100/aj/8/2/9/8/8/3/1197829883_B_V2.jpg" class="external-link">http://i.011st.com/t/100/aj/8/2/9/8/8/3/1197829883_B_V2.jpg</a></span></span></code></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="1" class="confluenceTd">
                                            <p><strong>price</strong></p>
                                            <p><span style="color: rgb(153,153,153);">(required)</span></p>
                                        </td>
                                        <td colspan="1" class="confluenceTd">
                                            <p>item 판매가를 의미합니다.</p>
                                            <p>0보다 큰 값으로 지정해주셔야합니다. (천단위 구분자는 제외해주셔야 합니다.)</p>
                                            <p><strong>Example Values</strong><span style="color: rgb(34,34,34);"> : </span>12000</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="1" class="confluenceTd">
                                            <p><strong>currency</strong></p>
                                            <p><span style="color: rgb(153,153,153);">(required)</span></p>
                                        </td>
                                        <td colspan="1" class="confluenceTd">
                                            <p>Item 판매가의 통화 단위를 의미합니다.</p>
                                            <p class="p1"><span class="s1">원화인 경우 “KRW,” 달러화인 경우 “USD”, 엔화인 경우 “JPY”, 바트화인 경우 &quot;<span style="color: rgb(0,0,0);">THB&quot;, 링깃화인 경우 &quot;<span>MYR&quot;</span></span></span></p>
                                            <p><strong>Example Values</strong><span style="color: rgb(34,34,34);"> : <span>KRW</span></span><span style="color: rgb(34,34,34);"> </span></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="confluenceTd">
                                            <p><strong>description</strong></p>
                                            <p><strong><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></strong></p>
                                        </td>
                                        <td class="confluenceTd">
                                            <p>item의 간단한 설명을 의미합니다.<span style="color: rgb(34,34,34);"> </span></p>
                                            <p><span style="color: rgb(34,34,34);">limit length : 1000</span></p>
                                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> 인기리에 판매 중인 나이키 운동화입니다.</span></code></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="confluenceTd">
                                            <p><strong>author </strong></p>
                                            <p><strong><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></strong></p>
                                        </td>
                                        <td class="confluenceTd">
                                            <p>item의 제작자, 작가, 메이커를 나타내는 용도로 활용할 수 있습니다. 향후 위젯에서 이 값을 표시할 수 있습니다.</p>
                                            <p><span style="color: rgb(34,34,34);">limit length : 256</span></p>
                                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> 나이키</span></code></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="1" class="confluenceTd">
                                            <p><strong>sale_price</strong></p>
                                            <p><strong><strong><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></strong></strong></p>
                                        </td>
                                        <td colspan="1" class="confluenceTd">
                                            <p>item의 할인 판매가를 의미합니다.</p>
                                            <p><span>0보다 큰 값으로 지정해주셔야합니다. (천단위 구분자는 제외해주셔야 합니다.)</span></p>
                                            <p><strong>Example Values</strong><span style="color: rgb(34,34,34);"> : </span>10000</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="1" class="confluenceTd">
                                            <p><strong>sale_currency</strong></p>
                                            <p><strong><strong><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></strong></strong></p>
                                        </td>
                                        <td colspan="1" class="confluenceTd"><span>Item의 할인 판매가 통화 단위를 의미합니다.</span><br />
                                            <p class="p1"><span class="s1">원화인 경우 “KRW,” 달러화인 경우 “USD”, 엔화인 경우 “JPY”<span>, 바트화인 경우 &quot;</span><span style="color: rgb(0,0,0);">THB&quot;, 링깃화인 경우 &quot;MYR&quot;</span></span></p>
                                            <p><strong>Example Values</strong><span style="color: rgb(34,34,34);"> : KRW</span><span style="color: rgb(34,34,34);"> </span></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="1" class="confluenceTd">
                                            <p><strong>is_hidden</strong></p>
                                            <p><strong><strong><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></strong></strong></p>
                                        </td>
                                        <td colspan="1" class="confluenceTd">item 의 상태여부를 의미하며, instock (정상) 혹은 oos (out of service : 사용 불가) 상태를 지정할 수 있습니다.<p>oos (사용 불가) 상태인 경우, 해당 상품은 다른 상품의 추천 리스트에서 실시간으로 제거됩니다.</p>
                                            <ul style="list-style-type: square;">
                                                <li><span>instock (정상)인 경우 : 0, <span>oos (out of service : 사용 불가)인 경우 1 </span></span>값을 지정하시면 됩니다.</li>
                                            </ul>
                                            <p><span style="color: rgb(34,34,34);">Default Value : 0</span></p>
                                            <p><code><strong>Example Values (품절인 경우) :</strong><span style="color: rgb(34,34,34);"> 1, <strong>Example Values (판매중인 경우) :</strong><span style="color: rgb(34,34,34);"> 0, </span></span></code></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="1" class="confluenceTd">
                                            <p><strong>c1</strong></p>
                                            <p><strong><strong><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></strong></strong></p>
                                        </td>
                                        <td colspan="1" class="confluenceTd">
                                            <p>item 대분류 정보를 의미합니다.<span style="color: rgb(34,34,34);"> (카테고리 정보는 향후 개인화 추천에서 사용자의 선호도를 계산할 때 사용 됩니다.)</span></p>
                                            <p><span style="color: rgb(34,34,34);">limit length : 256</span></p>
                                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> 스포츠</span></code></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="1" class="confluenceTd">
                                            <p><strong>c2</strong></p>
                                            <p><strong><strong><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></strong></strong></p>
                                        </td>
                                        <td colspan="1" class="confluenceTd">item 중분류 정보를 의미합니다. <p><span style="color: rgb(34,34,34);">limit length : 256</span></p>
                                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> 레저</span></code></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="1" class="confluenceTd">
                                            <p><strong>c3</strong></p>
                                            <p><strong><strong><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></strong></strong></p>
                                        </td>
                                        <td colspan="1" class="confluenceTd">
                                            <p>item 소분류 정보를 의미합니다.</p>
                                            <p><span style="color: rgb(34,34,34);">limit length : 256</span></p>
                                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> 운동화</span></code></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="1" class="confluenceTd">
                                            <p><strong>brand</strong></p>
                                            <p><strong><strong><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></strong></strong></p>
                                        </td>
                                        <td colspan="1" class="confluenceTd">
                                            <p>item 의 브랜드명을 의미합니다.</p>
                                            <p><span style="color: rgb(34,34,34);">limit length : 256</span></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="1" class="confluenceTd">
                                            <p><s><strong>bc1</strong></s><span style="color: rgb(255,0,0);"><strong> (삭제 예정)</strong></span></p>
                                            <p><s><strong><strong><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></strong></strong></s></p>
                                        </td>
                                        <td colspan="1" class="confluenceTd">
                                            <p><s>item 의 브랜드 분류 정보를 의미합니다.</s>  <span style="color: rgb(255,0,0);">(삭제 예정)</span></p>
                                            <p><s><span style="color: rgb(34,34,34);">limit length : 256</span></s></p>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p><span style="color: rgb(255,0,0);">※ </span><span style="color: rgb(255,0,0);"> item_id는 URL 패턴을 통해 상품 상세 페이지에 접근할 수 있는 값으로 지정해주셔야 합니다.</span></p>
                            <ul>
                                <li style="list-style-type: none;background-image: none;">
                                    <ul>
                                        <li>예1: 상품URL이 <a rel="nofollow" href="http://recopick.com/product/123," class="external-link">http://recopick.com/product/123</a>,<a rel="nofollow" class="external-link" href="http://recopick.com/product/124">http://recopick.com/product/124</a> 와 같이 구성되는 경우 item_id는 각각 &quot;123&quot;, &quot;124&quot;로 지정합니다.</li>
                                        <li>예2: 상품URL이 <a rel="nofollow" class="external-link" href="http://recopick.com/accessory/123,">http://recopick.com/accessory/123</a> ,  <a class="external-link" rel="nofollow" href="http://recopick.com/mobile/124">http://recopick.com/mobile/124</a> 와 같이 구성되는 경우 item_id는 &quot;accessory/123&quot;, &quot;mobile/124&quot;와 같이 지정합니다.</li>
                                        <li>예3: 상품URL이 <a href="http://recopick.com/product/view.php?product_id=123" rel="nofollow" class="external-link">http://recopick.com/product/view.php?product_id=123</a> , <a rel="nofollow" href="http://recopick.com/product/view.php?product_id=124" class="external-link">http://recopick.com/product/view.php?product_id=124</a> 와 같이 구성되는 경우 item_id는 각각 &quot;123&quot;, &quot;124&quot;로 지정합니다.</li>
                                        <li>예4: 상품URL이 <a href="http://recopick.com/product/view.php?category=accessory&amp;product_id=123" class="external-link" rel="nofollow">http://recopick.com/product/view.php?category=accessory&amp;product_id=123</a>, <a class="external-link" href="http://recopick.com/product/view.php?category=mobile&amp;product_id=124" rel="nofollow">http://recopick.com/product/view.php?category=mobile&amp;product_id=124</a> 와 같이 구성되는 경우 item_id는 각각 &quot;category=accessory&amp;product_id=123&quot;, &quot;category=mobile&amp;product_id=124&quot;로 지정합니다.<br />만약, category 파라미터가 없어도 상품 상세 페이지로 이동이 가능한 경우 예3과 같이 지정하시면 됩니다.</li>
                                    </ul>
                                </li>
                            </ul>
                            <p><span><strong><span><strong>Example Values</strong><code> :</code></span><code> </code></strong></span><code>items: [{ id: &quot;1198708089&quot;, title:&quot;나이키 에어맥스&quot;, image:&quot;</code><code><a href="http://i.011st.com/t/100/aj/8/2/9/8/8/3/1197829883_B_V2.jpg" class="external-link">http://i.011st.com/t/100/aj/8/2/9/8/8/3/1197829883_B_V2.jpg</a>&quot;, price:&quot;12000&quot;, currency:&quot;KRW&quot;} ]</code></p>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh">
                            <p>user</p>
                            <p><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></p>
                        </th>
                        <td colspan="1" class="confluenceTd">
                            <p>사용자의 성별, 연령 등의 데이터를 전송해주시면 각 사용자에게 더욱 특화된 추천을 제공해 드립니다.</p>
                            <p>JSON Object 객체로 요청하시면 됩니다. 객체에는 다음과 같은 Key를 지정하실 수 있습니다.</p>
                            <ul>
                                <li style="list-style-type: none;background-image: none;">
                                    <ul>
                                        <li>mid <em>(optional)</em> : 자체적으로 사용하시는 회원ID (<span style="color: rgb(153,51,0);">개인정보보호를 위해 회원의 아이디를 암호화하여 전송하셔야 합니다. 암호화시 특수문자 제거 필요</span>)</li>
                                        <li>gender <em>(optional)</em><span> : 성별 (M: 남자, F: 여자)</span></li>
                                        <li><span>birthyear <em>(optional)</em><span> : 출생연도 (예: 1994)</span></span></li>
                                    </ul>
                                </li>
                            </ul>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> </span><span class="nolink"><a href="http://example.com/product/detail.html" class="external-link">u</a>ser: { mid: &quot;3HR6EFHJ723532FEY3&quot;, gender: &quot;F&quot;, birthyear: 1989 }</span></code></p>
                            <p><img class="emoticon emoticon-warning" src="@/assets/images/icons/emoticons/warning.svg" data-emoticon-name="warning" alt="(warning)" /><span style="color: rgb(255,0,0);"> 주의하세요! </span>레코픽 추천을 API 방식으로 사용하는 경우, GET방식으로 API를 호출하게 됩니다.<span style="color: rgb(255,0,0);"><span style="color: rgb(0,0,0);"><br /></span></span>  이 경우 일부 API의 파라미터에 MID가 사용되는데, MID 값에 일부 특수문자(예&gt; &amp;, =) 가 들어가게 되면 정상동작 하지 않을 수 있습니다.<span style="color: rgb(255,0,0);"><span style="color: rgb(0,0,0);"><br /></span></span>  암호화된 결과에 특수문자가 들어가지 않는 암호화 알고리즘으로 단방향 암호화 해주세요. (예&gt; SHA-256, MD5 등)</p>
                            <p>* MID 단방향 암호화 예 : 88020d58ceb8a4ad7f6756021fbc06b8a91f17daad2f04a5d369608d9c308bde (SHA-256으로 암호화), 4509bf9373ff3dca193ba02726c87988 (MD5로 암호화)</p>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh">
                            <p>gaid</p>
                            <p><sup><span style="color: rgb(153,153,153);"><br /></span></sup></p>
                        </th>
                        <td colspan="1" class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">Google Advertising ID 입니다.</span></p>
                            <p><span style="color: rgb(34,34,34);"><u><span class="nolink"><u><span class="nolink">*Hybrid App에서 행동로그를 업로드 할 경우에는 반드시  GAID(IDFA)값을 넣어야 합니다.  </span></u></span></u></span></p>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh">
                            <p>idfa</p>
                            <p><br /></p>
                        </th>
                        <td colspan="1" class="confluenceTd">
                            <p>Apple Advertising Identifier 입니다.</p>
                            <p><span style="color: rgb(34,34,34);"><u><span class="nolink"><u><span class="nolink">*Hybrid App에서 행동로그를 업로드 할 경우에는 반드시  GAID(IDFA)값을 넣어야 합니다. </span></u></span></u></span></p>
                        </td>
                    </tr>
                    <tr>
                        <th class="confluenceTh">
                            <p>callback</p>
                            <p><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></p>
                        </th>
                        <td class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">callback을 입력하시면 JSONP 형태의 응답을 제공합니다.</span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> callback=parseResponse<br /></span></code></p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <h3 id="id-1)View로그(상품조회)-ExampleRequest">Example Request</h3>
            <hr />
            <ul>
                <li><a href="http://jsfiddle.net" class="external-link">http://jsfiddle.net</a> 을 통해 테스트를 하시면 됩니다.</li>
                <li><span style="color: rgb(255,0,0);">주의사항 &gt;</span>
                    <ul>
                        <li>POST 방식인 경우,<strong> Content-type: application/json</strong>으로 해주셔야 합니다.</li>
                        <li>아래와 같이 jQuery를 사용하여 data 속성을 통해 파라미터값을 전송하는 경우, url과 ref 변수를 별도로 escape 하시면 안됩니다.</li>
                    </ul>
                </li>
            </ul>
            <ul>
                <li>
                    <p>&quot;여성&quot;인 사용자 (uid123)가 &quot;ITEM01234&quot; 상품 페이지를 보는 경우</p>
                    <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                        <div class="codeContent panelContent pdl">
                     <pre class="syntaxhighlighter-pre" data-syntaxhighlighter-params="brush: js; gutter: true; theme: Confluence" data-theme="Confluence">(function($) {
    $.ajax({
        url: 'https://lc.recopick.com/v1/logs/view/543/uid123',
        method: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({
            url: &quot;http://www.11st.co.kr/product/SellerProductDetail.tmall?method=getSellerProductDetail&amp;prdNo=1149328659&quot;,
            ref: &quot;http://www.11st.co.kr/browsing/DisplayCategory.tmall?method=getDisplayCategory2Depth&amp;dispCtgrNo=127684&quot;,
            items: [{ id: &quot;1198708089&quot;, title:&quot;나이키 에어맥스&quot;, image:&quot;http://i.011st.com/t/100/aj/8/2/9/8/8/3/1197829883_B_V2.jpg&quot;, price:&quot;12000&quot;,currency:&quot;KRW&quot;} ],
            user: { gender: &quot;F&quot;, birthyear:&quot;1980&quot;, mid:&quot;8371920381&quot;}
        })
    });
})(jQuery);</pre>
                        </div>
                    </div>
                </li>
            </ul>
            <h3 id="id-1)View로그(상품조회)-Output">Output</h3>
            <p>성공시에 200 OK가, 실패 시에는 그에 맞는 4xx나 5xx 에러 코드가 반환됩니다.</p>

        </div>
    </div>
</template>