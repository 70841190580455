<template>
    <div class="page view">
        <h1>8) 사용자 행동로그 확인</h1>
        <div id="main-content" class="wiki-content">
            <p><br><span style="color: rgb(0,0,0);">RecoPick에서 수집한 사용자 행동로그를 JSON/JSONP 형태로 확인할 수 있는 API입니다.</span></p>
            <p><span style="color: rgb(0,0,0);">정상적으로 요청이 완료된 경우 JSON 형태의 사용자 행동로그 데이터가 반환됩니다. 요청에 실패할 경우 상황에 따라 4XX 혹은 5XX형태의 에러코드가 반환됩니다.</span></p>
            <h3 id="id-8)사용자행동로그확인-ResourceURL"><span style="color: rgb(0,0,0);">Resource URL</span></h3>
            <p>
              <a href="javascript:void(0);" class="external-link">
                https://api.recopick.com/v2/recommendations/useraction/<span style="font-weight: bold; color: rgb(255,0,0)">:service_id</span>/<span
                  style="font-weight: bold; color: rgb(255,0,0)">:uid</span>?channel=<span
                  style="font-weight: bold; color: rgb(0,0,255)">:channel</span>&callback=<span
                  style="font-weight: bold; color: rgb(0,0,255)">:callback</span>&access_key=<span
                  style="font-weight: bold; color: rgb(255,0,0)">:access_key</span>
              </a>
            </p>
            <h3 id="id-8)사용자행동로그확인-(GETv2/recommendations/useraction/:service_id/:user_id)"><span
                style="color: rgb(0,0,0);"><span style="color: rgb(0,0,0);"><span class="nolink"><strong><span
                style="color: rgb(255,0,0);"><span style="color: rgb(0,51,102);"><br/></span></span></strong><span
                style="color: rgb(255,0,0);"><span style="color: rgb(0,51,102);">(GET v2/recommendations/useraction/:service_id/:user_id)</span></span></span></span></span>
            </h3>
            <p><span style="color: rgb(0,0,0);"><span style="color: rgb(0,0,0);"><span class="nolink"><span style="color: rgb(255,0,0);"> </span></span><span style="color: rgb(0,0,0);"><span style="color: rgb(0,0,0);"><span style="color: rgb(34,34,34);"><u><span class="nolink"><span style="color: rgb(153,153,153);"><span style="color: rgb(0,0,0);"><span class="nolink" style="color: rgb(0,0,0);"><span style="color: rgb(255,0,0);"><em><u><strong><span style="color: rgb(255,0,0);"><span class="nolink">*Native(Hybrid) App에서는 uid 자리에 반드시 GAID 혹은 IDFA값을 넣어서 업로드 해야 합니다.</span></span></strong></u></em></span></span></span></span></span></u></span></span></span><br /></span></span></p>
            <h3 id="id-8)사용자행동로그확인-Parameters"><span class="mw-headline">Parameters</span></h3>
            <div class="table-wrap">
                <table class="confluenceTable">
                    <tbody>
                    <tr>
                        <th class="confluenceTh">
                            <p>service_id</p>
                            <p><sup><span style="color: rgb(255,0,0);">(required)</span></sup></p>
                        </th>
                        <td class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">RecoPick에서 발급된 service id를 의미합니다. service id를 모르실 경우 RecoPick으로 문의해주시기 바랍니다.</span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> 1</span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th class="confluenceTh">
                            <p>uid</p>
                            <p><sup><span style="color: rgb(255,0,0);">(required)</span></sup></p>
                        </th>
                        <td class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">추천리스트를 가져올 사용자 ID를 의미합니다. 로그 수집에 사용하고 있는 사용자 ID를 입력해주시기 바랍니다.</span></p>
                            <p><span style="color: rgb(34,34,34);"><span>uid 값을 생성하는 방법은 </span><a href="/display/docs/Client+Library+APIs#ClientLibraryAPIs-2.2ClientLibraryAPIs-fetchUID">Client Library APIs</a><span> 를 참고해주세요.</span></span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> test_user_1234</span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh">
                            <p>access_key</p>
                            <p><sup><span style="color: rgb(255,0,0);">(required)</span></sup></p>
                        </th>
                        <td colspan="1" class="confluenceTd">
                            <p>해당 서비스의 관리자임을 증명할 access_key를 의미합니다. 서비스별로 부여된 키 값을 사용해주시기 바랍니다.</p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> deadbeefdeadbeef90fe695ee4a05601<br /></span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh">
                            <p>channel</p>
                            <p><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></p>
                        </th>
                        <td colspan="1" class="confluenceTd">
                            <p>클릭률, 경유 매출을 추적할 channel을 입력합니다. 추천 노출 영역이 다르거나, A/B test 시에 서로 다른 channel을 부여하시면, channel 별로 성과를 계산하여 보여드립니다.</p>
                            <p><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> channel=ch1</span></p>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh">
                            <p>callback</p>
                            <p><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></p>
                        </th>
                        <td colspan="1" class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);"><span>callback을 입력하시면 JSONP 형태의 응답을 제공합니다.</span></span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> callback=parseResponse</span></code><span style="color: rgb(34,34,34);"> </span></p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <p><span><br /></span></p>
            <h3 id="id-8)사용자행동로그확인-ExampleRequest"><span>Example Request </span></h3>
            <hr />
            <p>
              <span><strong>GET   </strong> </span><span class="nolink"> </span><a
                href="javascript:void(0);"
                class="external-link"><span class="nolink">https://api.recopick.com/v2/recommendations/useraction/<span
                style="font-weight: bold;color: rgb(255,0,0);">1</span>/<span
                style="font-weight: bold; color: rgb(255,0,0);">test_user_1234</span>?access_key=<span
                style="font-weight: bold; color: rgb(255,0,0);">deadbeefdeadbeef90fe695ee4a05601</span></span></a>
              <br/><span> </span>
            </p>
            <h3 id="id-8)사용자행동로그확인-Output"><span style="color: rgb(0,0,0);"><span style="color: rgb(0,0,0);">Output</span></span></h3>
            <h3 id="id-8)사용자행동로그확인-반환되는JSON필드설명은아래를참고해주세요."><span style="color: rgb(0,0,0);"><span style="color: rgb(0,0,0);"> </span></span><span>반환되는 JSON 필드 설명은 아래를 참고해주세요.</span></h3>
            <div class="panel conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="panel">
                <div class="panelContent">
                    <p>[{<br />  &quot;id&quot;:&quot;801163&quot;,</p>
                    <p><span>  &quot;method&quot;:&quot;51&quot;,</span><br />  &quot;action&quot;:&quot;view&quot;,<br />  &quot;timestamp&quot;:&quot;1399452752633&quot;,<br />  &quot;title&quot;:&quot;스팽글 레이스 7부 블라우스&quot;,<br />  &quot;description&quot;:&quot;&quot;,<br />  &quot;image&quot;:{&quot;src&quot;:&quot;<span class="nolink">http://ode.co.kr/shopimages/odeshop/0500010007203.jpg&quot;},<br />  &quot;link&quot;:&quot;http://ode.co.kr/shop/shopdetail.html?branduid=801163&quot;,<br />  &quot;price&quot;:36600,<br />  &quot;clicklog_link&quot;:&quot;https://lc.recopick.com/3/log/click/84?uid=72431352.1398150921057&amp;source=&amp;reco_list=%5B%22801163%22%2C%22801988%22%2C%22801467%22%2C%22801163%22%2C%22802036%22%5D&amp;pick=801163&amp;method=&amp;channel=ch01&amp;reco_type=user-item&quot;,<br />  &quot;clicklog_redirect_link&quot;:&quot;https://lc.recopick.com/1/banner/84/pick?uid=72431352.1398150921057&amp;source=&amp;reco_list=%5B%22801163%22%2C%22801988%22%2C%22801467%22%2C%22801163%22%2C%22802036%22%5D&amp;pick=801163&amp;method=&amp;channel=ch01&amp;reco_type=user-item&quot;<br />},{&quot;id&quot;:&quot;801988&quot;,...}]</span></p>
                </div>
            </div>
            <p>요청에 대해 JSON Object가 반환됩니다. 해당 Object에는 요청시 주어졌던 상품 ID와, 해당 상품 ID에 해당하는 추천 결과가 각각 Key/Value 값으로 들어있게 됩니다. 추천 결과는 개별 상품의 Array 형태로 주어지며 개별 상품 정보의 자세한 사항은 아래와 같습니다.</p>
            <div class="table-wrap">
                <table class="confluenceTable">
                    <tbody>
                    <tr>
                        <th style="text-align: left;" class="confluenceTh">field</th>
                        <th style="text-align: center;" class="confluenceTh"><strong>의 미</strong></th>
                        <th style="text-align: center;" class="confluenceTh"><strong>비 고</strong></th>
                    </tr>
                    <tr>
                        <th style="text-align: left;" class="confluenceTh">id</th>
                        <td class="confluenceTd">추천된 상품의 id</td>
                        <td class="confluenceTd"> </td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh">method</th>
                        <td colspan="1" class="confluenceTd">추천 계산 알고리즘 id</td>
                        <td colspan="1" class="confluenceTd">
                            <ul>
                                <li>type=purchase_rate인 경우: 40</li>
                                <li>type=reco_click_rate인 경우: 44</li>
                                <li>type=reco_inflow_rate인 경우: 42</li>
                                <li>type=view인 경우: 43</li>
                                <li>type=order인 경우: 45</li>
                                <li>type=view2인 경우: 46</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh">action</th>
                        <td colspan="1" class="confluenceTd">행동 구분자</td>
                        <td colspan="1" class="confluenceTd">view/basket/order</td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh">timestamp</th>
                        <td colspan="1" class="confluenceTd">시각 정보</td>
                        <td colspan="1" class="confluenceTd">해당 행동이 일어난 시각</td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh">category1</th>
                        <td colspan="1" class="confluenceTd">카테고리 정보</td>
                        <td rowspan="3" class="confluenceTd">로그수집시 지정한 카테고리의 정보. (<a href="http://docs.recopick.com/pages/viewpage.action?pageId=3244497" rel="nofollow" class="external-link">참고</a>)</td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"><span>category2</span></th>
                        <td colspan="1" class="confluenceTd">카테고리 정보</td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"><span>category3</span></th>
                        <td colspan="1" class="confluenceTd">카테고리 정보</td>
                    </tr>
                    <tr>
                        <th style="text-align: left;" class="confluenceTh">title/description/image/link/price/currency/sale_price/sale_prive_currency</th>
                        <td class="confluenceTd"><span>상품 정보</span></td>
                        <td class="confluenceTd">
                            <p>각각 레코픽에서 수집한 상품의 정보를 의미합니다.</p>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh">clicklog_link</th>
                        <td colspan="1" class="confluenceTd">클릭로그 저장용 링크 주소</td>
                        <td colspan="1" class="confluenceTd">해당 링크를 방문하거나 Ajax GET, JSONP 등의 방법으로 호출하면 추천 클릭 로그가 전송됩니다.</td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh">clicklog_redirect_link</th>
                        <td colspan="1" class="confluenceTd">클릭로그 저장 및 페이지 이동 링크 주소</td>
                        <td colspan="1" class="confluenceTd">해당 링크를 방문하면, 추천 클릭 로그가 RecoPick 서버에 저장되고, 상품 상세 페이지로 이동됩니다. 이때 이동되는 상품 상세 페이지를 변경하고자 하시면, ?url=<span style="color: rgb(255,0,0);"><strong>:encodedURL </strong><span style="color: rgb(0,0,0);">을 붙여주시면, 클릭 로그를 남긴 다음, 해당 페이지로 이동 시켜 드립니다.</span></span></td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <p><span style="color: rgb(255,0,0);"><br /></span></p>
            <p><span style="color: rgb(255,0,0);">    <img class="emoticon emoticon-warning" src="@/assets/images/icons/emoticons/warning.svg" data-emoticon-name="warning" alt="(warning)" /><span> 주의하세요! <span style="color: rgb(0,0,0);">사 용자들이 추천 리스트를 클릭하는 경우, clicklog_link 혹은 clicklog_redirect_link를 통해 추천 클릭로그를 남겨주셔야 합니다. 추천 클릭 로그를 제대로 남기지 않을 경우, 추천에 대한 성과를 계산할 수 없으며, 향후 추천 품질이 저하되는 문제가 생깁니다. 브라우저의 경우, 간단하게 clicklog_redirect_link 를 통해 추천 클릭로그를 남기면서, 페이지를 이동하게 하는 것을 추천 드리며, 불가피하게 clicklog_redirect_link를 사용할 수 없을 경우, 서버 혹은 ajax 등 다른 방법으로 clicklog_link를 호출하셔서 추천 클릭 로그를 남겨주시면 됩니다.</span></span></span></p>
        </div>
    </div>
</template>