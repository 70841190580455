<template>
    <div class="page view">
        <h1>6) 배송비 절약 상품추천</h1>
        <div id="main-content" class="wiki-content">
            <br>
            <p><span style="color: rgb(0,0,0);">&quot;배송비 절약 상품 추천&quot;을 받을 수 있는 API입니다.</span></p>
            <p><span style="color: rgb(0,0,0);">예를 들어, 장바구니에 28,000원 상품이 담겨 있고, 30,000원 이상 무료 배송 정책일 경우, 2,000원 이상 상품을 추천 받는 방식으로 활용할 수 있습니다.</span></p>
            <p><span style="color: rgb(0,0,0);">GET 방식으로 요청하실 수 있고, 정상적으로 추천 계산이 완료된 경우에는 JSON 형태의 추천리스트가 반환됩니다.</span></p>
            <p><span style="color: rgb(0,0,0);">요청에 실패할 경우 상황에 따라 4XX 혹은 5XX형태의 에러코드가 반환됩니다.</span></p>
            <p><br /></p>
            <h3 id="id-6)배송비절약상품추천-ResourceURL"><span class="nolink"><span style="color: rgb(255,0,0);"><span style="color: rgb(0,0,0);">ResourceURL</span></span></span></h3>
            <p>
              <a href="javascript:void(0);" class="external-link">
                https://api.recopick.com/v2/recommendations/basket/<span style="font-weight: bold; color: rgb(255,0,0)">:service_id</span>/<span
                  style="font-weight: bold; color: rgb(255,0,0)">:uid</span>?limit=<span
                  style="font-weight: bold; color: rgb(0,0,255)">:limit</span>&field=<span
                  style="font-weight: bold; color: rgb(0,0,255)">:field</span>&start_price=<span
                  style="font-weight: bold; color: rgb(0,0,255)">:start_price</span>&end_price=<span
                  style="font-weight: bold; color: rgb(0,0,255)">:end_price</span>&item_ids=<span
                  style="font-weight: bold; color: rgb(0,0,255)">:item_ids</span>&sort=<span
                  style="font-weight: bold; color: rgb(0,0,255)">:sort</span>&access_key=<span
                  style="font-weight: bold; color: rgb(255,0,0)">:access_key</span>
              </a>
            </p>
            <h3 id="id-6)배송비절약상품추천-(GETv1/recommendations/userBasket/:service_id/:user_id)"><span class="nolink"><span
                style="color: rgb(255,0,0);"><span style="color: rgb(0,0,0);">(GET v2/recommendations/userBasket/:service_id/:user_id)</span><strong><br/></strong></span></span>
            </h3>
            <p><span style="color: rgb(0,0,0);"><span class="nolink"><span style="color: rgb(255,0,0);"><strong> </strong></span></span><span style="color: rgb(34,34,34);"><u><span class="nolink"><span style="color: rgb(153,153,153);"><span style="color: rgb(255,0,0);"><em><strong>*Native(Hybrid) App에서는 uid 자리에 반드시 GAID 혹은 IDFA값을 넣어서 업로드 해야 합니다.</strong></em></span></span></span></u></span><br /></span></p>
            <h3 id="id-6)배송비절약상품추천-Parameters"><span class="mw-headline">Parameters</span></h3>
            <div class="table-wrap">
                <table class="wrapped confluenceTable">
                    <colgroup>
                        <col />
                        <col />
                    </colgroup>
                    <tbody>
                    <tr>
                        <th style="text-align: left;" class="confluenceTh">
                            <p>service_id</p>
                            <p><sup><span style="color: rgb(255,0,0);">(required)</span></sup></p>
                        </th>
                        <td style="text-align: left;" class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">Recopick에서 발급된 service id를 의미합니다. service id를 모르실 경우 Recopick으로 문의해주시기 바랍니다.</span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> 17</span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th style="text-align: left;" class="confluenceTh">
                            <p>uid</p>
                            <p><sup><span style="color: rgb(255,0,0);">(required)</span></sup></p>
                        </th>
                        <td style="text-align: left;" class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">추천리스트를 가져올 사용자 ID를 의미합니다. 로그 수집에 사용하고 있는 uid를 입력해주시기 바랍니다.</span></p>
                            <p><span style="color: rgb(34,34,34);">uid 값을 생성하는 방법은 <a rel="nofollow" class="external-link" href="http://docs.recopick.com/display/docs/Client+Library+APIs#ClientLibraryAPIs-2.2ClientLibraryAPIs-fetchUID" style="text-decoration: none;">Client Library APIs</a> 를 참고해주세요.</span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> <span style="color: rgb(0,0,0);">20473629.1550206365737</span></span></code></p>
                        </td>
                    </tr>
                    <tr>
                      <th class="confluenceTh">
                        <p>access_key</p>
                        <p><sup><span style="color: rgb(255,0,0);">(required)</span></sup></p>
                      </th>
                      <td class="confluenceTd">
                        <p><span style="color: rgb(34,34,34);">보안이 적용된 API를 호출하기 위해 사용되는 비밀키를 의미합니다. 비밀키 값은 <a href="http://admin.recopick.com/-/api_info" class="external-link">어드민 대시보드</a>에서 확인하실 수 있습니다.</span></p>
                        <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> 1f385dca73fb935cb11f8a246ea85f94</span></code></p>
                      </td>
                    </tr>
                    <tr>
                        <th style="text-align: left;" colspan="1" class="confluenceTh">
                            <p>mid</p>
                            <h6 id="id-6)배송비절약상품추천-(optional)"><span style="color: rgb(153,153,153);">(</span><span style="color: rgb(153,153,153);">optional</span><span style="color: rgb(153,153,153);">)</span></h6>
                        </th>
                        <td style="text-align: left;" colspan="1" class="confluenceTd">
                            <p>추천리스트를 가져올 고객사 사이트의 회원ID를 의미하며 단방향으로 암호화되어 있어야 합니다. 로그인하지 않은 경우에는 전송할 mid 값이 없으므로, 이 경우에는 mid를 전송하지 않아도 됩니다.</p>
                            <p>암호화된 결과에 특수문자가 들어가지 않는 암호화 알고리즘으로 단방향 암호화 해주세요. (예&gt; SHA-256 등)</p>
                            <p><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> a891cd6a5b84d5a44d1edf17d080f39f</span></p>
                        </td>
                    </tr>
                    <tr>
                        <th style="text-align: left;" colspan="1" class="confluenceTh">
                            <p>type</p>
                            <p><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></p>
                        </th>
                        <td style="text-align: left;" colspan="1" class="confluenceTd">
                            <p>추천 로직의 종류입니다. 아래 목록을 참고하여 원하는 값을 지정해주시기 바랍니다. (별도로 지정하지 않는 경우 기본값은 mixed 입니다.</p>
                            <ul>
                                <li>viewTogether: 요청한 상품과 함께 많이 본 상품 추천</li>
                                <li>buyTogether: 요청한 상품과 함께 많이 구매한 상품 추천</li>
                                <li>mixed: viewTogether &amp; buyTogether</li>
                            </ul>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> type=viewTogether</span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th style="text-align: left;" class="confluenceTh">
                            <p>limit</p>
                            <p><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></p>
                        </th>
                        <td style="text-align: left;" class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">최대 몇 개의 추천을 가져올지를 의미합니다. limit 값이 없으면 계산된 추천 리스트 전체를 가져옵니다. 추천 결과의 개수가 limit보다 적은 경우, 전체 추천 결과가 반환됩니다. </span></p>
                            <ul>
                                <li><span style="color: rgb(34,34,34);">default=20, max=100</span></li>
                            </ul>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> limit=5</span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th style="text-align: left;" colspan="1" class="confluenceTh">
                            <p>start_price</p>
                            <p><span style="color: rgb(153,153,153);">(optional)</span></p>
                        </th>
                        <td style="text-align: left;" colspan="1" class="confluenceTd">
                            <p>추천 상품들 중 요청 가격 이상의 상품들로 필터링 합니다. (양의 정수)</p>
                            <p><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> 19800</span></p>
                        </td>
                    </tr>
                    <tr>
                        <th style="text-align: left;" colspan="1" class="confluenceTh">
                            <p>end_price</p>
                            <p><span style="color: rgb(153,153,153);">(optional)</span></p>
                        </th>
                        <td style="text-align: left;" colspan="1" class="confluenceTd">
                            <p>추천 상품들 중 요청 가격 이하의 상품들로 필터링 합니다. (양의 정수)</p>
                            <p><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> 30000</span></p>
                        </td>
                    </tr>
                    <tr>
                        <th style="text-align: left;" colspan="1" class="confluenceTh">
                            <p>item_ids</p>
                            <p><span style="color: rgb(153,153,153);">(optional)</span></p>
                        </th>
                        <td style="text-align: left;" colspan="1" class="confluenceTd">
                            <p>요청한 상품ID에 맞는 추천을 제공합니다.</p>
                            <ul>
                                <li>max=10</li>
                            </ul>
                            <p><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> 414516,414779</span></p>
                        </td>
                    </tr>
                    <tr>
                        <th style="text-align: left;" colspan="1" class="confluenceTh">
                            <p>sort</p>
                            <p><span style="color: rgb(153,153,153);">(optional)</span></p>
                        </th>
                        <td style="text-align: left;" colspan="1" class="confluenceTd">
                            <p>추천 상품들의 정렬 방식을 지정합니다.</p>
                            <ul>
                                <li>start_price: start_price 오름차순</li>
                                <li>end_price: end_price 내림차순</li>
                                <li>score: 연관도 점수 높은 순</li>
                            </ul>
                            <p><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> score</span></p>
                        </td>
                    </tr>
                    <tr>
                        <th style="text-align: left;" class="confluenceTh">
                            <p>field</p>
                            <p><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></p>
                        </th>
                        <td style="text-align: left;" class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">field=meta를 입력하시면</span></p>
                            <ul>
                              <li>품절로 등록되어 있는 상품은 제외하고 추천 결과가 제공됩니다.<span style="color: rgb(255,0,0)"> (품절 상품 미 노출을 원하실 경우 반드시 해당 파라미터를 추가하셔야 합니다.)</span></li>
                              <li>추천 리스트에 상품 meta값이 같이 제공됩니다.</li>
                            </ul>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> field=meta</span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th style="text-align: left;" colspan="1" class="confluenceTh">
                            <p>channel</p>
                            <p><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></p>
                        </th>
                        <td style="text-align: left;" colspan="1" class="confluenceTd">
                            <p style="text-align: left;">추천클릭률, 추천기여매출을 추적하기 위해 channel을 입력합니다. 추천 노출 영역이 다르거나, A/B test 시에 서로 다른 channel을 부여하시면, channel 별로 성과를 계산하여 보여드립니다.</p>
                            <p style="text-align: left;">채널이란 하나의 사이트에서 여러 개의 추천 알고리즘을 각각 다른 위치에 제공하는 경우,<br />각 페이지에 제공된 추천 알고리즘 별로 추천 성과(추천클릭률 및 추천기여매출)를 확인할 수 있도록 하기 위한 식별자입니다.<br />예를 들어, 실시간 개인화 추천 알고리즘을 메인페이지, 상품상세페이지, 마이페이지에서 사용하는 경우, 각 페이지 별로 추천 성과가 달라집니다.<br />이 때에 각 페이지 별로 채널명을 부여하여 페이지 별로 구분하여 추천 알고리즘 성과를 분석할 수 있습니다.<br />채널명은 아래 예와 같이 공백 없는 영문 명을 사용하시는 걸 권장 드립니다.<br />예&gt; 메인페이지 : main, 상품상세페이지_상단 : detail_top, 장바구니 : basket</p>
                            <p><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> channel=basket</span></p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <p><br /></p>
            <h3 id="id-6)배송비절약상품추천-ExampleRequest">Example Request </h3>
            <hr />
            <p>
              <span><strong>GET   </strong> </span><span class="nolink"> </span><a
                href="javascript:void(0);"
                class="external-link"><span class="nolink">https://api.recopick.com/v2/recommendations/basket/<span
                style="font-weight: bold;color: rgb(255,0,0);">1</span>/<span
                style="font-weight: bold; color: rgb(255,0,0);">test_user_1234</span>/?field=<span
                style="font-weight: bold; color: rgb(0,0,255);">meta</span>&start_price=<span
                style="font-weight: bold; color: rgb(0,0,255);">19800</span>&end_price=<span
                style="font-weight: bold; color: rgb(0,0,255);">30000</span>&item_ids=<span
                style="font-weight: bold; color: rgb(0,0,255);">414516,414779</span>&sort=<span
                style="font-weight: bold; color: rgb(0,0,255);">start_price</span>&access_key=<span
                style="font-weight: bold; color: rgb(255,0,0);">1f385dca73fb935cb11f8a246ea85f94</span></span></a>
              <br/><span> </span>
            </p>
            <h3 id="id-6)배송비절약상품추천-Output">Output</h3>
            <h3 id="id-6)배송비절약상품추천-반환되는JSON필드설명은아래를참고해주세요."> 반환되는 JSON 필드 설명은 아래를 참고해주세요.</h3>
            <ul class="array collapsible">
                <li>{<ul class="obj collapsible">
                    <li>
                        <p><span class="property">id</span>: <span class="type-string" style="color: green;">&quot;414779&quot;</span>,</p>
                    </li>
                    <li>
                        <p><span class="property">item_id</span>: <span class="type-string" style="color: green;">&quot;414779&quot;</span>,</p>
                    </li>
                    <li>
                        <p><span class="property">score</span>: <span class="type-number" style="color: blue;">0.15629847</span>,</p>
                    </li>
                    <li>
                        <p><span class="property">method</span>: 79,</p>
                    </li>
                    <li>
                        <p><span class="property">tag</span>: <span class="type-string" style="color: green;">&quot;TBi&quot;</span>,</p>
                    </li>
                    <li>
                        <p><span class="property">category1</span>: <span class="type-string" style="color: green;">&quot;BABY&quot;</span>,</p>
                    </li>
                    <li>
                        <p><span class="property">category2</span>: <span class="type-string" style="color: green;">&quot;&quot;</span>,</p>
                    </li>
                    <li>
                        <p><span class="property">category3</span>: <span class="type-string" style="color: green;">&quot;&quot;</span>,</p>
                    </li>
                    <li>
                        <p><span class="property">title</span>: <span class="type-string" style="color: green;">&quot;BN코디네이트커버올(보더)&quot;</span>,</p>
                    </li>
                    <li>
                        <p><span class="property">description</span>: <span class="type-number" style="color: blue;">0</span>,</p>
                    </li>
                    <li><span class="property">image</span>: {<ul class="obj collapsible">
                        <li>
                            <p><span class="property">src</span>: <span class="type-string" style="color: green;">&quot;</span><a class="external-link" style="text-decoration: none;" rel="nofollow" href="https://im.uniqlo.com/kr/lotte/media/goods/31/11/72/65/414779_COL_COL53_570.jpg">https://im.uniqlo.com/kr/lotte/media/goods/31/11/72/65/414779_COL_COL53_570.jpg</a><span class="type-string" style="color: green;">&quot;</span></p>
                        </li>
                    </ul>},</li>
                    <li>
                        <p><span class="property">author</span>: <span class="type-string" style="color: green;">&quot;&quot;</span>,</p>
                    </li>
                    <li>
                        <p><span class="property">price</span>: <span class="type-string" style="color: green;">&quot;19900&quot;</span>,</p>
                    </li>
                    <li>
                        <p><span class="property">sale_price</span>: <span class="type-string" style="color: green;">&quot;19900&quot;</span>,</p>
                    </li>
                    <li>
                        <p><span class="property">sale_price_currency</span>: <span class="type-string" style="color: green;">&quot;&quot;</span>,</p>
                    </li>
                    <li>
                        <p><span class="property">clicklog_redirect_link</span>: <span class="type-string" style="color: green;">&quot;</span><a rel="nofollow" class="external-link" href="https://lc.recopick.com/3/log/click/2090?source=&amp;reco_list=%5B%22414795%22%2C%22414793%22%2C%22414794%22%2C%22413745%22%2C%22414779%22%2C%22414791%22%5D&amp;pick=414779&amp;method=4&amp;channel=empty&amp;tag=TBi&amp;reco_type=basket-item&amp;uid=87606212.1514124758383" style="text-decoration: none;">https://lc.recopick.com/3/log/click/2090?source=&amp;reco_list=%5B%22414795%22%2C%22414793%22%2C%22414794%22%2C%22413745%22%2C%22414779%22%2C%22414791%22%5D&amp;pick=414779&amp;method=4&amp;channel=empty&amp;tag=TBi&amp;reco_type=basket-item&amp;uid=87606212.1514124758383</a><span class="type-string" style="color: green;">&quot;</span>,</p>
                    </li>
                    <li>
                        <p><span class="property">clicklog_link</span>: <span class="type-string" style="color: green;">&quot;</span><a rel="nofollow" style="text-decoration: none;" class="external-link" href="https://lc.recopick.com/1/banner/2090/pick?source=&amp;reco_list=%5B%22414795%22%2C%22414793%22%2C%22414794%22%2C%22413745%22%2C%22414779%22%2C%22414791%22%5D&amp;pick=414779&amp;method=4&amp;channel=empty&amp;tag=TBi&amp;reco_type=basket-item&amp;uid=87606212.1514124758383">https://lc.recopick.com/1/banner/2090/pick?source=&amp;reco_list=%5B%22414795%22%2C%22414793%22%2C%22414794%22%2C%22413745%22%2C%22414779%22%2C%22414791%22%5D&amp;pick=414779&amp;method=4&amp;channel=empty&amp;tag=TBi&amp;reco_type=basket-item&amp;uid=87606212.1514124758383</a><span class="type-string" style="color: green;">&quot;</span></p>
                    </li>
                </ul>},</li>
                <li>{<ul class="obj collapsible">
                    <li>
                        <p><span class="property">id</span>: <span class="type-string" style="color: green;">&quot;414791&quot;</span>,</p>
                    </li>
                    <li>
                        <p><span class="property">item_id</span>: <span class="type-string" style="color: green;">&quot;414791&quot;</span>,</p>
                    </li>
                    <li>
                        <p><span class="property">score</span>: <span class="type-number" style="color: blue;">0.1223969</span>,</p>
                    </li>
                    <li>
                        <p><span class="property">method</span>: 79,</p>
                    </li>
                    <li>
                        <p><span class="property">tag</span>: <span class="type-string" style="color: green;">&quot;TBi&quot;</span>,</p>
                    </li>
                    <li>
                        <p><span class="property">category1</span>: <span class="type-string" style="color: green;">&quot;BABY&quot;</span>,</p>
                    </li>
                    <li>
                        <p><span class="property">category2</span>: <span class="type-string" style="color: green;">&quot;&quot;</span>,</p>
                    </li>
                    <li>
                        <p><span class="property">category3</span>: <span class="type-string" style="color: green;">&quot;&quot;</span>,</p>
                    </li>
                    <li>
                        <p><span class="property">title</span>: <span class="type-string" style="color: green;">&quot;BN코디네이트쇼트올(팜트리)&quot;</span>,</p>
                    </li>
                    <li>
                        <p><span class="property">description</span>: <span class="type-number" style="color: blue;">0</span>,</p>
                    </li>
                    <li><span class="property">image</span>: {<ul class="obj collapsible">
                        <li>
                            <p><span class="property">src</span>: <span class="type-string" style="color: green;">&quot;</span><a class="external-link" rel="nofollow" href="https://im.uniqlo.com/kr/lotte/media/goods/31/11/84/90/414791_COL_COL50_570.jpg" style="text-decoration: none;">https://im.uniqlo.com/kr/lotte/media/goods/31/11/84/90/414791_COL_COL50_570.jpg</a><span class="type-string" style="color: green;">&quot;</span></p>
                        </li>
                    </ul>},</li>
                    <li>
                        <p><span class="property">author</span>: <span class="type-string" style="color: green;">&quot;&quot;</span>,</p>
                    </li>
                    <li>
                        <p><span class="property">price</span>: <span class="type-string" style="color: green;">&quot;19900&quot;</span>,</p>
                    </li>
                    <li>
                        <p><span class="property">sale_price</span>: <span class="type-string" style="color: green;">&quot;19900&quot;</span>,</p>
                    </li>
                    <li>
                        <p><span class="property">sale_price_currency</span>: <span class="type-string" style="color: green;">&quot;&quot;</span>,</p>
                    </li>
                    <li>
                        <p><span class="property">clicklog_redirect_link</span>: <span class="type-string" style="color: green;">&quot;</span><a rel="nofollow" href="https://lc.recopick.com/3/log/click/2090?source=&amp;reco_list=%5B%22414795%22%2C%22414793%22%2C%22414794%22%2C%22413745%22%2C%22414779%22%2C%22414791%22%5D&amp;pick=414791&amp;method=4&amp;channel=empty&amp;tag=TBi&amp;reco_type=basket-item&amp;uid=87606212.1514124758383" class="external-link" style="text-decoration: none;">https://lc.recopick.com/3/log/click/2090?source=&amp;reco_list=%5B%22414795%22%2C%22414793%22%2C%22414794%22%2C%22413745%22%2C%22414779%22%2C%22414791%22%5D&amp;pick=414791&amp;method=4&amp;channel=empty&amp;tag=TBi&amp;reco_type=basket-item&amp;uid=87606212.1514124758383</a><span class="type-string" style="color: green;">&quot;</span>,</p>
                    </li>
                    <li>
                        <p><span class="property">clicklog_link</span>: <span class="type-string" style="color: green;">&quot;</span><a class="external-link" href="https://lc.recopick.com/1/banner/2090/pick?source=&amp;reco_list=%5B%22414795%22%2C%22414793%22%2C%22414794%22%2C%22413745%22%2C%22414779%22%2C%22414791%22%5D&amp;pick=414791&amp;method=4&amp;channel=empty&amp;tag=TBi&amp;reco_type=basket-item&amp;uid=87606212.1514124758383" style="text-decoration: none;" rel="nofollow">https://lc.recopick.com/1/banner/2090/pick?source=&amp;reco_list=%5B%22414795%22%2C%22414793%22%2C%22414794%22%2C%22413745%22%2C%22414779%22%2C%22414791%22%5D&amp;pick=414791&amp;method=4&amp;channel=empty&amp;tag=TBi&amp;reco_type=basket-item&amp;uid=87606212.1514124758383</a><span class="type-string" style="color: green;">&quot;</span></p>
                    </li>
                </ul>}</li>
            </ul>
            <p><span style="color: rgb(0,0,0);">]</span></p>
            <div class="table-wrap">
                <table class="wrapped confluenceTable">
                    <colgroup>
                        <col />
                        <col />
                        <col />
                    </colgroup>
                    <tbody>
                    <tr>
                        <th style="text-align: left;" class="confluenceTh">field</th>
                        <th style="text-align: center;" class="confluenceTh"><strong>의 미</strong></th>
                        <th style="text-align: center;" class="confluenceTh"><strong>비 고</strong></th>
                    </tr>
                    <tr>
                        <th style="text-align: left;" class="confluenceTh">id</th>
                        <td style="text-align: left;" class="confluenceTd">추천된 상품의 id</td>
                        <td style="text-align: left;" class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <th style="text-align: left;" colspan="1" class="confluenceTh"><span style="color: rgb(255,0,0);">item_id</span></th>
                        <td style="text-align: left;" colspan="1" class="confluenceTd"><span style="color: rgb(255,0,0);">추천된 상품의 상품 id(id와 상동)</span></td>
                        <td style="text-align: left;" colspan="1" class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <th style="text-align: left;" class="confluenceTh">score</th>
                        <td style="text-align: left;" class="confluenceTd">추천의 품질 지표</td>
                        <td style="text-align: left;" class="confluenceTd">score가 높은 순으로 추천 리스트를 노출하시면 됩니다. 일반적으로는 score가 높은 순으로 리스트를 드립니다.</td>
                    </tr>
                    <tr>
                        <th style="text-align: left;" class="confluenceTh">method</th>
                        <td style="text-align: left;" class="confluenceTd">추천 계산 알고리즘 id</td>
                        <td style="text-align: left;" class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <th style="text-align: left;" colspan="1" class="confluenceTh"><span style="color: rgb(255,0,0);">tag</span></th>
                        <td style="text-align: left;" colspan="1" class="confluenceTd"><span style="color: rgb(255,0,0);">개발자가 확인용</span></td>
                        <td style="text-align: left;" colspan="1" class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <th style="text-align: left;" colspan="1" class="confluenceTh">category1</th>
                        <td style="text-align: left;" colspan="1" class="confluenceTd">카테고리 정보</td>
                        <td style="text-align: left;" rowspan="3" class="confluenceTd">
                            <p>로그수집시 지정한 카테고리의 정보. (<a class="external-link" style="text-decoration: none;" rel="nofollow" href="http://docs.recopick.com/pages/viewpage.action?pageId=3244497">참고</a>)</p>
                        </td>
                    </tr>
                    <tr>
                        <th style="text-align: left;" colspan="1" class="confluenceTh">category2</th>
                        <td style="text-align: left;" colspan="1" class="confluenceTd">카테고리 정보</td>
                    </tr>
                    <tr>
                        <th style="text-align: left;" colspan="1" class="confluenceTh"><strong>category3</strong></th>
                        <td style="text-align: left;" colspan="1" class="confluenceTd">카테고리 정보</td>
                    </tr>
                    <tr>
                        <th style="text-align: left;" colspan="1" class="confluenceTh">title</th>
                        <td style="text-align: left;" colspan="1" class="confluenceTd">상품명</td>
                        <td style="text-align: left;" class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <th style="text-align: left;" colspan="1" class="confluenceTh">description</th>
                        <td style="text-align: left;" colspan="1" class="confluenceTd">상품 상세 정보</td>
                        <td style="text-align: left;" class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <th style="text-align: left;" colspan="1" class="confluenceTh">image</th>
                        <td style="text-align: left;" colspan="1" class="confluenceTd">상품 이미지 URL</td>
                        <td style="text-align: left;" class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <th style="text-align: left;" colspan="1" class="confluenceTh">author</th>
                        <td style="text-align: left;" colspan="1" class="confluenceTd">브랜드, 저자 등</td>
                        <td style="text-align: left;" class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <th style="text-align: left;" colspan="1" class="confluenceTh">price</th>
                        <td style="text-align: left;" colspan="1" class="confluenceTd">가격</td>
                        <td style="text-align: left;" class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <th style="text-align: left;" colspan="1" class="confluenceTh">sale_price</th>
                        <td style="text-align: left;" colspan="1" class="confluenceTd">할인가격</td>
                        <td style="text-align: left;" class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <th style="text-align: left;" colspan="1" class="confluenceTh">sale_price_currency</th>
                        <td style="text-align: left;" colspan="1" class="confluenceTd">통화</td>
                        <td style="text-align: left;" class="confluenceTd"><br /></td>
                    </tr>
                    <tr>
                        <th style="text-align: left;" colspan="1" class="confluenceTh">clicklog_link</th>
                        <td style="text-align: left;" colspan="1" class="confluenceTd">클릭로그 저장용 링크 주소</td>
                        <td style="text-align: left;" colspan="1" class="confluenceTd">해당 링크를 방문하거나 Ajax GET, JSONP 등의 방법으로 호출하면 추천 클릭 로그가 전송됩니다.</td>
                    </tr>
                    <tr>
                        <th style="text-align: left;" colspan="1" class="confluenceTh">clicklog_redirect_link</th>
                        <td style="text-align: left;" colspan="1" class="confluenceTd">클릭로그 저장 및 페이지 이동 링크 주소</td>
                        <td style="text-align: left;" colspan="1" class="confluenceTd">해당 링크를 방문하면, 추천 클릭 로그가 RecoPick 서버에 저장되고, 상품 상세 페이지로 이동됩니다. 이때 이동되는 상품 상세 페이지를 변경하고자 하시면, ?url=<span style="color: rgb(255,0,0);"><strong>:encodedURL </strong><span style="color: rgb(0,0,0);">을 붙여주시면, 클릭 로그를 남긴 다음, 해당 페이지로 이동 시켜 드립니다.</span></span></td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <p><span style="color: rgb(255,0,0);"><br /></span></p>
            <p><span style="color: rgb(255,0,0);"><span style="color: rgb(0,0,0);"><img class="emoticon emoticon-warning" src="@/assets/images/icons/emoticons/warning.svg" data-emoticon-name="warning" alt="(warning)" /> 주의하세요! 사용자들이 추천 리스트를 클릭하는 경우, clicklog_link 혹은 clicklog_redirect_link를 통해 추천 클릭로그를 남겨주셔야 합니다. 추천 클릭 로그를 제대로 남기지 않을 경우, 추천에 대한 성과를 계산할 수 없으며, 향후 추천 품질이 저하되는 문제가 생깁니다. 브라우저의 경우, 간단하게 clicklog_redirect_link 를 통해 추천 클릭로그를 남기면서, 페이지를 이동하게 하는 것을 추천 드리며, 불가피하게 clicklog_redirect_link를 사용할 수 없을 경우, 서버 혹은 ajax 등 다른 방법으로 clicklog_link를 호출하셔서 추천 클릭 로그를 남겨주시면 됩니다.</span></span></p>
            <p><span style="color: rgb(255,0,0);"><span style="color: rgb(0,0,0);">※ 참고 : </span></span><span style="color: rgb(0,0,0);">추천 상품 클릭 시에 추천 API 결과에 있는 clicklog_link 혹은 clicklog_redirect_link를 반드시 레코픽으로 전달해주셔야 합니다. 그래야 레코픽 추천 성과가 잡힙니다.</span></p>
            <ul>
                <li>상품상세페이지의 URL 패턴이 정형화 되어 있지 않다면 clicklog_redirect_link는 사용하지 못하고, clicklog_link를 사용해주셔야 합니다.</li>
                <li>clicklog_redirect_link : 추천된 상품에 대한 클릭 정보를 레코픽으로 전송한 뒤에 클릭된 상품의 상세페이지로 redirect시켜 드립니다.</li>
            </ul>
            <ul>
                <li>clicklog_link : 추천된 상품에 대한 클릭 정보를 레코픽으로 전송합니다. 이 경우에는 추천된 상품의 상세페이지로 직접 이동해주셔야 합니다.</li>
            </ul>
            <p style="margin-left: 120.0px;"> 또한, API 응답 내에 있는 위의 method 값을 아래 형식으로 상세페이지의 URL 파라미터에 추가적으로 넣어주셔야 하고, 추천 서비스를 사용하는 경우 'product_type=R' 파라미터도 추가적으로 넣어주셔야 합니다.</p>
            <p style="margin-left: 60.0px;">• 형식 : recopick=method 값</p>
            <p style="margin-left: 60.0px;"><span>• 예1 : </span><span class="nolink"><a href="http://aaa.test.co.kr/product/detail.html?product_no=1338&amp;" class="external-link">http://aaa.test.co.kr/product/detail.html?product_no=1338&amp;</a><span style="color: rgb(255,0,0);">recopick=4&amp;product_type=R</span></span></p>
            <p style="margin-left: 60.0px;">• 예2 : <span class="nolink"><a href="http://test.xxx.co.kr/product/product_detail/3395854" class="external-link">http://test.xxx.co.kr/product/product_detail/3395854</a>?<span style="color: rgb(255,0,0);">recopick=25&amp;product_type=R</span></span></p>
            <p style="margin-left: 60.0px;"><br /></p>
        </div>
    </div>
</template>
<script setup>
</script>