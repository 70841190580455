<template>
    <div class="page view">
        <h1>AB test API</h1>
        <div id="main-content" class="wiki-content">
        <br>
            <p>AB test API를 이용하시면 운영하시는 상품 상세정보 페이지에 여러 조건의 추천 위젯을 통해 A/B 테스트를 간단히 수행할 수 있습니다.</p>
            <h2 id="ABtestAPI-A/B테스트적용">A/B 테스트 적용</h2>
            <p>A/B 테스트를 위해 먼저 필요한 것은 어떤 조건으로 테스트를 진행할지를 지정하는 것입니다.</p>
            <ul>
                <li><span style="line-height: 1.4285715;">먼저 대시보드의 AB 테스트 설정 페이지(&nbsp;</span><a href="https://admin.recopick.com/-/abtest" style="line-height: 1.4285715;" class="external-link">https://admin.recopick.com/-/abtest</a><span style="line-height: 1.4285715;">)에 접속하여 진행할 테스트 조건을 입력합니다.</span></li>
            </ul>
            <p><span style="line-height: 1.4285715;">&nbsp;</span><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/api_img03.png" data-image-src="/download/attachments/3735648/api_img03.png?version=1&amp;modificationDate=1416465721000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="3932411" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="api_img03.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="3735648" data-linked-resource-container-version="5"></span></p>
            <ol>
                <li><span style="line-height: 1.4285715;">테스트명: 이 테스트의 이름입니다. 적절히 작성하시면 테스트 중 혹은 테스트 후 결과를 확인하실 때 입력하신 테스트명으로 표시되어 편리합니다.</span></li>
                <li><span style="line-height: 1.4285715;">테스트 시작일/종료일: 테스트를 특정 기간동안에만 수행하려고 하신다면 시작일과 종료일을 입력해주세요. 지정 안함을 선택하시면 기간과 상관없이 테스트가 진행됩니다.</span></li>
                <li><span style="line-height: 1.4285715;">위젯: 해당 그룹에게 노출될 위젯입니다. 위젯은 추천 위젯 설정 페이지( </span><a href="https://admin.recopick.com/-/widget" style="line-height: 1.4285715;" class="external-link">https://admin.recopick.com/-/widget</a><span style="line-height: 1.4285715;">&nbsp;)에서 추가/수정하실 수 있습니다.</span></li>
                <li><span style="line-height: 1.4285715;">채널명: 해당 그룹의 테스트 결과를 통계로 제공해드리는 식별자입니다. 채널명을 "test"라고 입력하시면 클릭률, 경유매출 등의 통계를 "abtest_test"라는 채널명으로 제공해드립니다.</span></li>
                <li><span style="line-height: 1.4285715;">노출설정: 전체 사용자를 10개의 그룹으로 나누어 각 그룹별로 노출할 위젯을 선택합니다. 그룹1과 그룹2를 5:5로 테스트 하시려면 그룹0~4는 그룹1, 5~9는 그룹2에 체크해주시면 됩니다.</span></li>
                <li><span style="line-height: 1.4285715;">그룹 추가 버튼을 통해 3개 이상의 그룹(최대 5개)으로 테스트 설정이 가능합니다.</span><br><span style="line-height: 1.4285715;"><br></span></li>
            </ol>
            <ul>
                <li>테스트 조건 입력이 끝난 후 저장하시면 "코드 가져오기" 메뉴가 생성됩니다. "코드 가져오기" 메뉴를 클릭하면&nbsp;아래와 같이 위젯을 표시할 영역에 붙여넣을 수 있는 HTML 코드가 표시됩니다. 코드를 복사 후 위젯을 표시할 영역에 붙여넣으시면 됩니다.</li>
            </ul>
            <p>&nbsp;&nbsp;<span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/api_img04.png" data-image-src="/download/attachments/3735648/api_img04.png?version=1&amp;modificationDate=1416465884000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="3932412" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="api_img04.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="3735648" data-linked-resource-container-version="5"></span></p>
            <h2 id="ABtestAPI-A/B테스트결과확인">A/B 테스트 결과 확인</h2>
            <p>A/B 테스트 적용 다음날부터 대시보드를 통해 A/B 테스트의 결과를 확인하실 수 있습니다.</p>
            <ul>
                <li><span style="line-height: 1.4285715;">A/B 테스트 통계 페이지(&nbsp;</span><a href="https://admin.recopick.com/237/recommend/user_group_stat" style="line-height: 1.4285715;" class="external-link">https://admin.recopick.com/237/recommend/user_group_stat</a><span style="line-height: 1.4285715;">&nbsp;)에 접속합니다. A/B 테스트가 하나 이상 있다면 아래와 같이 데이터가 표시됩니다.</span></li>
            </ul>
            <p><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/api_img05.png" data-image-src="/download/attachments/3735648/api_img05.png?version=1&amp;modificationDate=1416466029000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="3932413" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="api_img05.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="3735648" data-linked-resource-container-version="5"></span></p>
            <ol>
                <li>통계의 사용자 기준입니다. 전체사용자의 데이터를 볼지, 구매자의 데이터를 볼지, 추천 클릭 사용자의 데이터를 볼지 선택합니다.</li>
                <li>각 사용자 기준의 상세 데이터입니다. (1)에서 추천 클릭 사용자, (2)에서 그룹별 평균 매출을 선택했다면 각 그룹의 추천 클릭 사용자에 대한 평균 매출 데이터가 표시됩니다.</li>
                <li>기준 A/B 테스트입니다. "A/B 테스트 적용" 단계에서 테스트 조건을 입력하셨을 때의 각 그룹별로 데이터가 제공됩니다.<br>가령, 선택한 기준 A/B 테스트에 테스트그룹0은 사용자그룹0~8까지, 테스트그룹1은 사용자그룹9가 지정되어 있었다면 테스트그룹0의 데이터는 그룹0~8의 평균, 테스트그룹1의 데이터는 그룹9의 데이터가 됩니다.</li>
                <li>&nbsp;"사용자 그룹별" 탭을 선택하면 사용자그룹0~9까지 각 그룹의 데이터를 모두 확인하실 수 있습니다.</li>
            </ol>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
        </div>
    </div>
</template>