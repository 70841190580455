<template>
    <div id="page">
        <apis v-if="checkPageId('APIs')"></apis>
        <error v-if="checkPageId('Error+response')"></error>
        <client v-if="checkPageId('Client+Library+APIs')"></client>
        <ab-test v-if="checkPageId('AB+test+API')"></ab-test>
        <rest-api v-if="checkPageId('2.1REST+APIs')"></rest-api>
        <shopby v-if="checkPageId('Shopby')"></shopby>
    </div>
</template>
-
<script>
    import apis from "../components/docs/APIs"
    import error from "../components/docs/Error"
    import client from "../components/docs/ClientLib"
    import abTest from "../components/docs/AbTestAPI"
    import restApi from "../components/docs/RestAPI"
    import shopby from "../components/docs/Shopby"

    export default {
        name: "DisplayDocs",
        components: {
            apis, error, client, abTest, restApi, shopby
        },
        methods: {
            checkPageId(pageId){
                return pageId === this.$route.params.pageId;
            }
        }
    }
</script>
