<template>
    <div class="page view">
        <h1>추천 API</h1>
        <div id="main-content" class="wiki-content">
            <br>
            <h1 id="id-추천API-개요">개요</h1>
            <p><span style="color: rgb(0,0,0);">보내주신 데이터를 기반으로 RecoPick에서 자체 개발한 추천 알고리즘에 의해 계산된 추천 결과를 가져갈 수 있는 추천 API입니다.</span></p>
            <p><span style="color: rgb(0,0,0);">GET 방식으로 요청하실 수 있고 정상적으로 요청이 완료되는 경우에는 JSON 형태의 추천리스트가 반환됩니다.</span></p>
            <p><span style="color: rgb(0,0,0);">요청에 실패할 경우 상황에 따라 4XX 혹은 5XX형태의 에러코드가 반환됩니다.</span></p>
            <p><span style="color: rgb(255,0,0);">본 추천API의 타임아웃 시간은 현재 2000ms를 적용하고 있습니다. 2000ms가 초과되는 경우 HTTP로 504 GATEWAY_TIMEOUT 오류가 응답 됩니다.</span></p>
            <p><span style="color: rgb(0,0,0);"><span style="color: rgb(34,34,34);"><u><span class="nolink">*Hybrid App에서 추천 API를 이용할 경우 user_id 자리에 GAID 혹은 IDFA값을 넣어서 API를 호출하여야 합니다.<span style="color: rgb(153,153,153);"> </span></span></u></span></span></p>
            <p><span style="color: rgb(0,0,0);"><span style="color: rgb(34,34,34);"><u><span class="nolink"><span style="color: rgb(153,153,153);"><a href="/pages/viewpage.action?pageId=6553731">참고 : Hybrid app에서 ADID(gaid,idfa)획득방법</a></span></span></u></span></span></p>
            <div class="table-wrap">
                <table class="confluenceTable">
                    <colgroup>
                        <col />
                        <col />
                    </colgroup>
                    <tbody>
                    <tr>
                        <th class="confluenceTh"><strong>Resource</strong></th>
                        <th class="confluenceTh"><strong>Description</strong></th>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><a href="/pages/viewpage.action?pageId=3244404">1) 함께본상품 / 함께구매한상품 추천</a></td>
                        <td class="confluenceTd">
                            <p><span style="color: rgb(255,0,0);"><strong>상품기준 추천('함께 본 상품', '함께 구매한 상품' 등)</strong> </span>결과를 가져갈 수 있는 API입니다.</p>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="1" class="confluenceTd"><a href="/pages/viewpage.action?pageId=3244364">2) 실시간 개인화 추천</a></td>
                        <td colspan="1" class="confluenceTd"><span style="color: rgb(255,0,0);"><strong>사용자기준 추천('실시간 개인화 추천')</strong></span> 결과를 가져갈 수 있는 API입니다.</td>
                    </tr>
                    <tr>
                        <td colspan="1" class="confluenceTd"><a href="/pages/viewpage.action?pageId=3244452">3) 통계형 상품 추천</a></td>
                        <td colspan="1" class="confluenceTd">방문자 접속 통계 및 추천 클릭에 기반하여 6가지 타입의 <strong><span style="color: rgb(255,0,0);">통계형 추천</span></strong> 결과를 가져갈 수 있는 API입니다.</td>
                    </tr>
                    <tr>
                        <td colspan="1" class="confluenceTd"><a href="/pages/viewpage.action?pageId=4358801">4) 성/연령/카테고리별 상품 추천</a></td>
                        <td colspan="1" class="confluenceTd"><span style="color: rgb(255,0,0);"><strong>성, 연령, 카테고리 기반의 인기 상품</strong></span> 추천 결과를 가져갈 수 있는 API입니다.</td>
                    </tr>
                    <tr>
                        <td colspan="1" class="confluenceTd"><a href="/pages/viewpage.action?pageId=7536790">5) 검색어 기반 상품 추천</a></td>
                        <td colspan="1" class="confluenceTd">방문자들의 <strong><span style="color: rgb(255,0,0);">검색어 입력 후 클릭한 상품을 분석한 추천 결과</span></strong>를 가져갈 수 있는 API입니다.</td>
                    </tr>
                    <tr>
                        <td colspan="1" class="confluenceTd"><a href="/pages/viewpage.action?pageId=10256448">6) 배송비 절약 상품추천</a></td>
                        <td colspan="1" class="confluenceTd"><span style="color: rgb(0,0,0);"><strong><span style="color: rgb(255,0,0);">배송비 절약 상품 추천</span></strong>을 받을 수 있는 API입니다.</span></td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <p><br /></p>
            <p><span style="color: rgb(153,153,153);"> </span></p>
            <p><span style="color: rgb(153,153,153);"><br /></span></p>
        </div>
    </div>
</template>