<template>
    <div class="page view">
        <h1>11) 특정상품 추천클릭률 (주간)</h1>
        <div id="main-content" class="wiki-content">
            <br>
            <p>특정 상품의 추천 알고리즘별 클릭률 데이터를 주 단위로 제공합니다. 이번 주의 데이터는 어제 날짜까지의 합산으로 제공됩니다.</p>
            <p><span style="color: rgb(0,0,0);">GET 방식으로 요청하실 수 있고 정상적으로 요청이 완료되는 경우는 JSON 형태의 추천리스트가 반환됩니다. callback 파라미터를 명시하는 경우 JSONP 형태로 응답합니다.</span></p>
            <p><span style="color: rgb(0,0,0);">요청에 실패할 경우 상황에 따라 4XX 혹은 5XX형태의 에러코드가 반환됩니다.</span></p>
            <h3 id="id-11)특정상품추천클릭률(주간)-ResourceURL"><span style="color: rgb(0,0,0);">Resource URL</span></h3>
            <p><span style="color: rgb(0,0,0);"><span> <span class="nolink">https://api.recopick.com/1/admin/:service_id/feed/weeklyitemctr<a href="https://api.recopick.com/2/recommend/:service_id/:item_id" class="external-link"></a></span></span></span></p>
            <p> </p>
            <h3 id="id-11)특정상품추천클릭률(주간)-Parameters"><span class="mw-headline">Parameters</span></h3>
            <div class="table-wrap">
                <table class="confluenceTable">
                    <tbody>
                    <tr>
                        <th class="confluenceTh">
                            <p>service_id</p>
                            <p><sup><span style="color: rgb(153,153,153);">(required)</span></sup></p>
                        </th>
                        <td class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">RecoPick에서 발급된 service id를 의미합니다. service id <span style="color: rgb(34,34,34);">값은 </span><a href="http://admin.recopick.com/-/api_info" class="external-link">어드민 대시보드</a><span style="color: rgb(34,34,34);"> </span><span style="color: rgb(34,34,34);">에서 확인하실 수 있습니다.</span></span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> 17</span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th class="confluenceTh">
                            <p>access_key</p>
                            <p><sup><span style="color: rgb(153,153,153);">(required)</span></sup></p>
                        </th>
                        <td class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">보안이 적용된 API를 호출하기 위해 사용되는 비밀키를 의미합니다. 비밀키 값은 <a href="http://admin.recopick.com/-/api_info" class="external-link">어드민 대시보드</a>에서 확인하실 수 있습니다.</span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> 1f385dca73fb935cb11f8a246ea85f94</span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh">
                            <p>item_id</p>
                            <p><sup><span style="color: rgb(153,153,153);">(required)</span></sup></p>
                        </th>
                        <td colspan="1" class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">상품의 id를 의미합니다.</span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> A9AACZAA0072326</span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh">
                            <p>ago</p>
                            <p><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></p>
                        </th>
                        <td colspan="1" class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">읽어올 데이터의 항목수를 의미합니다. 기본값은 7입니다.</span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> 7</span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh">
                            <p>skip</p>
                            <p><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></p>
                        </th>
                        <td colspan="1" class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">지정된 값만큼 이전 데이터를 읽어옵니다. 0으로 지정할 경우 지난주 데이터까지, 1로 지정할 경우 지지난주의 데이터까지 읽어옵니다. 기본값은 0입니다.</span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> 12</span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh">
                            <p>offset</p>
                            <p><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></p>
                        </th>
                        <td colspan="1" class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">시간대(Timezone)의 UTC(협정 세계 표준시)와의 차이를 분 단위로 지정합니다. UTC 대비 9시간 빠른 서울의 경우 -540으로 지정합니다. 기본값은 0입니다.</span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> -540</span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th class="confluenceTh">
                            <p>callback</p>
                            <p><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></p>
                        </th>
                        <td class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);"><span>callback을 입력하시면 JSONP 형태의 응답을 제공합니다.</span></span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> callback=parseResponse<br /></span></code></p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <p><span><br /></span></p>
            <h3 id="id-11)특정상품추천클릭률(주간)-ExampleRequest"><span>Example Request</span></h3>
            <hr />
            <p><span><strong>GET   </strong> </span><span class="nolink">https://api.recopick.com/1/admin/2/feed/weeklyitemctr?access_key=REPLACE_THIS&amp;ago=12&amp;skip=0&amp;offset=-540&amp;item_id=<span style="color: rgb(34,34,34);">A9AACZAA0072326</span></span><br /><span> </span></p>
            <h3 id="id-11)특정상품추천클릭률(주간)-Output"><span style="color: rgb(0,0,0);"><a name="Output_5"></a>Output</span></h3>
            <h3 id="id-11)특정상품추천클릭률(주간)-반환되는JSON필드설명은아래를참고해주세요."><span style="color: rgb(0,0,0);"> </span><span>반환되는 JSON 필드 설명은 아래를 참고해주세요.</span></h3>
            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                <div class="codeContent panelContent pdl">
            <pre class="syntaxhighlighter-pre" data-syntaxhighlighter-params="brush: js; gutter: false; theme: Eclipse" data-theme="Eclipse">{
  &quot;2013-15&quot;: {
    &quot;2&quot;: {
      &quot;view_count&quot;: 58,
      &quot;click_count&quot;: 3,
      &quot;click_ratio&quot;: 0.05172413793103448
    },
    &quot;4&quot;: {
      &quot;view_count&quot;: 0,
      &quot;click_count&quot;: 0,
      &quot;click_ratio&quot;: 0
    }
  },
  &quot;2013-16&quot;: {
    &quot;2&quot;: {
      &quot;view_count&quot;: 71,
      &quot;click_count&quot;: 2,
      &quot;click_ratio&quot;: 0.028169014084507043
    },
    &quot;4&quot;: {
      &quot;view_count&quot;: 0,
      &quot;click_count&quot;: 0,
      &quot;click_ratio&quot;: 0
    }
  },
  &quot;2013-17&quot;: {
    &quot;2&quot;: {
      &quot;view_count&quot;: 67,
      &quot;click_count&quot;: 2,
      &quot;click_ratio&quot;: 0.029850746268656716
    },
    &quot;4&quot;: {
      &quot;view_count&quot;: 0,
      &quot;click_count&quot;: 0,
      &quot;click_ratio&quot;: 0
    }
  },
  &quot;2013-18&quot;: {
    &quot;2&quot;: {
      &quot;view_count&quot;: 15,
      &quot;click_count&quot;: 0,
      &quot;click_ratio&quot;: 0
    },
    &quot;4&quot;: {
      &quot;view_count&quot;: 44,
      &quot;click_count&quot;: 2,
      &quot;click_ratio&quot;: 0.045454545454545456
    }
  },
  &quot;2013-19&quot;: {
    &quot;4&quot;: {
      &quot;view_count&quot;: 307,
      &quot;click_count&quot;: 12,
      &quot;click_ratio&quot;: 0.03908794788273615
    }
  },
  &quot;2013-20&quot;: {
    &quot;4&quot;: {
      &quot;view_count&quot;: 361,
      &quot;click_count&quot;: 23,
      &quot;click_ratio&quot;: 0.06371191135734072
    }
  },
  &quot;2013-21&quot;: {
    &quot;4&quot;: {
      &quot;view_count&quot;: 109,
      &quot;click_count&quot;: 4,
      &quot;click_ratio&quot;: 0.03669724770642202
    }
  }
}</pre>
                </div>
            </div>
            <p><span><br /></span></p>
            <p><span><br /></span></p>
            <div class="table-wrap">
                <table style="line-height: 1.4285715;" class="confluenceTable">
                    <tbody>
                    <tr>
                        <th colspan="3" class="confluenceTh">field</th>
                        <th style="text-align: center;" class="confluenceTh"><strong>의 미</strong></th>
                        <th style="text-align: center;" class="confluenceTh"><strong>비 고</strong></th>
                    </tr>
                    <tr>
                        <th class="confluenceTh">YYYY-WW</th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <td class="confluenceTd">연도-주</td>
                        <td class="confluenceTd">해당 주의 데이터를 나타냅니다.</td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh">METHOD</th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <td colspan="1" class="confluenceTd">추천 알고리즘</td>
                        <td colspan="1" class="confluenceTd"><a href="http://docs.recopick.com/pages/viewpage.action?pageId=3244041" class="external-link">GET 1/admin/fetch_methods</a> 참조</td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh">click_ratio</th>
                        <td colspan="1" class="confluenceTd">추천 클릭률</td>
                        <td colspan="1" class="confluenceTd">해당 주의 추천 클릭률</td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh">click_count</th>
                        <td colspan="1" class="confluenceTd">추천 클릭수</td>
                        <td colspan="1" class="confluenceTd">해당 주의 추천 클릭수</td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh"> </th>
                        <th colspan="1" class="confluenceTh">view_count</th>
                        <td colspan="1" class="confluenceTd">추천 조회수</td>
                        <td colspan="1" class="confluenceTd">해당 주의 추천이 제공된 상품 상세 페이지 조회수</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>