<template>
    <div class="page view">
        <h1>통계 API</h1>
        <div id="main-content" class="wiki-content">
            <br>
            <h3 id="id-통계API-Feed"><strong>Feed</strong></h3>
            <p><span style="color: rgb(153,153,153);">PV, UV, 클릭률, 구매전환율 등의 통계 데이터를 제공하는 API입니다.</span></p>
            <div class="table-wrap">
                <table class="wrapped confluenceTable">
                    <colgroup>
                        <col />
                        <col />
                    </colgroup>
                    <tbody>
                    <tr>
                        <th class="confluenceTh">Resource</th>
                        <th class="confluenceTh">Description</th>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><a href="/pages/viewpage.action?pageId=2261025">01) PV, UV 통계 (일간)</a></td>
                        <td class="confluenceTd">
                            <p>PV, UV(방문자수) 데이터를 일 단위로 제공합니다. 어제 날짜의 데이터까지 제공됩니다.</p>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="1" class="confluenceTd"><a href="/pages/viewpage.action?pageId=2261032">02) PV, UV 통계 (주간)</a></td>
                        <td colspan="1" class="confluenceTd">
                            <p>PV, UV(방문자수) 데이터를 주 단위로 제공합니다. 이번 주의 데이터는 어제 날짜까지의 합산으로 제공됩니다.</p>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="1" class="confluenceTd"><a href="/pages/viewpage.action?pageId=2261034">03) PV, UV 통계 (월간)</a></td>
                        <td colspan="1" class="confluenceTd">
                            <p>PV, UV(방문자수) 데이터를 월 단위로 제공합니다. <span>이번 달의 데이터는 어제 날짜까지의 합산으로 제공됩니다.</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="1" class="confluenceTd"><a href="/pages/viewpage.action?pageId=2261036">04) 사용자 행동 로그 통계 (일간)</a></td>
                        <td colspan="1" class="confluenceTd">사용자 행동 기록 데이터를 일 단위로 제공합니다. 어제 날짜의 데이터까지 제공됩니다.</td>
                    </tr>
                    <tr>
                        <td colspan="1" class="confluenceTd"><a href="/pages/viewpage.action?pageId=2981917">05) 사용자 행동 로그 통계 (주간)</a></td>
                        <td colspan="1" class="confluenceTd">
                            <p><span>사용자 행동 기록 </span>데이터를 주 단위로 제공합니다. 이번 주의 데이터는 어제 날짜까지의 합산으로 제공됩니다.</p>
                        </td>
                    </tr>
                    <tr>
                        <td class="confluenceTd"><a href="/pages/viewpage.action?pageId=3244037">06) 사용자 행동 로그 통계 (월간)</a></td>
                        <td class="confluenceTd">
                            <p><span>사용자 행동 기록 </span>데이터를 월 단위로 제공합니다. 이번 달의 데이터는 어제 날짜까지의 합산으로 제공됩니다.</p>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="1" class="confluenceTd"><a href="/pages/viewpage.action?pageId=3244042">07) 추천 서비스 전체 통계 (일간)</a></td>
                        <td colspan="1" class="confluenceTd">추천 알고리즘별 클릭률 및 구매전환율, 구매자수, 상품수, Active상품수 데이터를 전체 및 추천에 따른 경우로 구분하여 제공합니다. 일 단위로 제공되며, 어제 날짜의 데이터까지 제공됩니다.</td>
                    </tr>
                    <tr>
                        <td colspan="1" class="confluenceTd"><a href="/pages/viewpage.action?pageId=3244044">08) 추천 서비스 전체 통계 (주간)</a></td>
                        <td colspan="1" class="confluenceTd">추천 알고리즘별 클릭률 및 구매전환율, 구매자수, 상품수, Active상품수 데이터를 전체 및 추천에 따른 경우로 구분하여 제공합니다. 주 단위로 제공되며,<span> 이번 주의 데이터는 어제 날짜까지의 합산으로 제공됩니다.</span></td>
                    </tr>
                    <tr>
                        <td colspan="1" class="confluenceTd"><a href="/pages/viewpage.action?pageId=3244046">09) 추천 서비스 전체 통계 (월간)</a></td>
                        <td colspan="1" class="confluenceTd">추천 알고리즘별 클릭률 및 구매전환율, 구매자수, 상품수, Active상품수 데이터를 전체 및 추천에 따른 경우로 구분하여 제공합니다. 월 단위로 제공되며,<span> 이번 달의 데이터는 어제 날짜까지의 합산으로 제공됩니다.</span></td>
                    </tr>
                    <tr>
                        <td colspan="1" class="confluenceTd"><a href="/pages/viewpage.action?pageId=3244049">10) 특정상품 추천클릭률 (일간)</a></td>
                        <td colspan="1" class="confluenceTd"><span>특정</span> 상품의 추천 알고리즘별 클릭률 데이터를 일 단위로 제공합니다. 어제 날짜의 데이터까지 제공됩니다.</td>
                    </tr>
                    <tr>
                        <td colspan="1" class="confluenceTd"><a href="/pages/viewpage.action?pageId=3244051">11) 특정상품 추천클릭률 (주간)</a></td>
                        <td colspan="1" class="confluenceTd"><span>특정</span> 상품의 추천 알고리즘별 클릭률 데이터를 주 단위로 제공합니다. <span>이번 주의 데이터는 어제 날짜까지의 합산으로 제공됩니다.</span></td>
                    </tr>
                    <tr>
                        <td colspan="1" class="confluenceTd"><a href="/pages/viewpage.action?pageId=3244053">12) 특정상품 추천클릭률 (월간)</a></td>
                        <td colspan="1" class="confluenceTd">특정 상품의 추천 알고리즘별 클릭률 데이터를 월 단위로 제공합니다. <span>이번 달의 데이터는 어제 날짜까지의 합산으로 제공됩니다.</span></td>
                    </tr>
                    <tr>
                        <td colspan="1" class="confluenceTd"><a href="/pages/viewpage.action?pageId=3244068">13) 인당 평균 구매상품수 (일간) </a></td>
                        <td colspan="1" class="confluenceTd">주문 페이지 방문수, 상품 상세 페이지 방문자수, 방문자 당 주문 페이지 방문수 데이터를 추천 클릭 유/무로 나누어 일 단위로 제공합니다. 어제 날짜의 데이터까지 제공됩니다.</td>
                    </tr>
                    <tr>
                        <td colspan="1" class="confluenceTd"><a href="/pages/viewpage.action?pageId=3244070">14) 인당 평균 구매상품수 (주간) </a>  </td>
                        <td colspan="1" class="confluenceTd">주문 페이지 방문수, 상품 상세 페이지 방문자수, 방문자 당 주문 페이지 방문수 데이터를 추천 클릭 유/무로 나누어 주 단위로 제공합니다. 이번 주의 데이터는 어제 날짜까지의 합산으로 제공됩니다.</td>
                    </tr>
                    <tr>
                        <td colspan="1" class="confluenceTd">
                            <p><a href="/pages/viewpage.action?pageId=3244072">15) 인당 평균 구매상품수 (월간) </a></p>
                        </td>
                        <td colspan="1" class="confluenceTd">주문 페이지 방문수, 상품 상세 페이지 방문자수, 방문자 당 주문 페이지 방문수 데이터를 추천 클릭 유/무로 나누어 월 단위로 제공합니다. 이번 달의 데이터는 어제 날짜까지의 합산으로 제공됩니다.</td>
                    </tr>
                    <tr>
                        <td class="confluenceTd">
                            <p><a href="/pages/viewpage.action?pageId=3244082">16) 상품상세페이지 인당 평균 PV, UV (일간) </a></p>
                        </td>
                        <td class="confluenceTd"><span>상품 상세 페이지 방문자수, <span>상품 상세 페이지 방문수(페이지뷰),</span></span> 방문자당 방문수를 추천 클릭 유/무로 나누어 일 단위로 제공합니다. 어제 날짜의 데이터까지 제공됩니다.</td>
                    </tr>
                    <tr>
                        <td class="confluenceTd">
                            <p><a href="/pages/viewpage.action?pageId=3244084">17) 상품상세페이지 인당 평균 PV, UV (주간) </a></p>
                        </td>
                        <td class="confluenceTd"><span>상품 상세 페이지 방문자수, 상품 상세 페이지 방문수(페이지뷰),</span><span> 방문자당 방문수를 추천 클릭 유/무로 나누어 </span>주 단위로 제공합니다. 이번 주의 데이터는 어제 날짜까지의 합산으로 제공됩니다.</td>
                    </tr>
                    <tr>
                        <td class="confluenceTd">
                            <p><a href="/pages/viewpage.action?pageId=3244086">18) 상품상세페이지 인당 평균 PV, UV (월간) </a> </p>
                        </td>
                        <td class="confluenceTd"><span>상품 상세 페이지 방문자수, 상품 상세 페이지 방문수(페이지뷰),</span><span> 방문자당 방문수를 추천 클릭 유/무로 나누어 </span>월 단위로 제공합니다. 이번 달의 데이터는 어제 날짜까지의 합산으로 제공됩니다.</td>
                    </tr>
                    <tr>
                        <td colspan="1" class="confluenceTd"><a href="/pages/viewpage.action?pageId=23953426">19) 추천 기여 매출 통계 (일간)</a></td>
                        <td colspan="1" class="confluenceTd"><span style="color: rgb(23,43,77);">전체매출, 기여매출 및 매출 기여도를 일 단위로 제공합니다. 어제 날짜의 데이터까지 제공됩니다.</span></td>
                    </tr>
                    <tr>
                        <td colspan="1" class="confluenceTd"><a href="/pages/viewpage.action?pageId=23953430">20) 추천 기여 매출 통계 (주간)</a></td>
                        <td colspan="1" class="confluenceTd"><span style="color: rgb(23,43,77);">전체매출, 기여매출 및 매출 기여도를 주 단위로 제공합니다. </span><span style="color: rgb(0,0,0);">이번 주의 데이터는 어제 날짜까지의 합산으로 제공합니다.</span></td>
                    </tr>
                    <tr>
                        <td colspan="1" class="confluenceTd"><a href="/pages/viewpage.action?pageId=23953433">21) 추천 기여 매출 통계 (월간)</a></td>
                        <td colspan="1" class="confluenceTd"><span style="color: rgb(23,43,77);">전체매출, 기여매출 및 매출 기여도를 월 단위로 제공합니다. </span><span style="color: rgb(0,0,0);">이번 달의 데이터는 어제 날짜까지의 합산으로 제공합니다.</span></td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <p><br /></p>
            <p><br /></p>
            <p><br /></p>

        </div>
    </div>
</template>