<template>
    <div class="page view">
        <h1>페이지별 Recopick 추천 적용</h1>
        <div id="main-content" class="wiki-content">
            <br>
            <h2 id="id-페이지별Recopick추천적용-쇼핑과정에서의추천적용제안"><span style="color: rgb(51,51,153);"> 쇼핑과정에서의 추천 적용 제안</span></h2>
            <p><span style="color: rgb(51,51,153);">&nbsp;</span>레코픽은 페이지별로 고객에게 최적화된 맞춤형 상품 추천을 제공하여 페이지 이탈을 줄이고 클릭률과 구매전환율을 높입니다.</p>
            <p><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/exampleLoc.png" data-image-src="/download/attachments/4718688/%E1%84%8C%E1%85%A5%E1%86%A8%E1%84%8B%E1%85%AD%E1%86%BC%E1%84%8B%E1%85%B1%E1%84%8E%E1%85%B5%E1%84%8B%E1%85%A8.png?version=1&amp;modificationDate=1526881872000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="7110660" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="적용위치예.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="4718688" data-linked-resource-container-version="22"></span></p>
            <h2 id="id-페이지별Recopick추천적용-적용예시"><span style="color: rgb(51,51,153);">적용 예시</span></h2>
            <p><span style="color: rgb(51,51,153);">&nbsp;</span></p>
            <div class="page" title="Page 25">
                <div class="section">
                    <div class="layoutArea">
                        <div class="column">
                            <p>* 가장 추천효과가 좋았던 Best Practice 기반의&nbsp;제안이므로 고객님의 사이트에 따라 유동적으로 적용가능합니다.<span class="Apple-tab-span">&nbsp;</span> &nbsp;</p>
                            <div class="table-wrap">
                                <table class="confluenceTable tablesorter tablesorter-default" role="grid">
                                    <thead>
                                    <tr role="row" class="tablesorter-headerRow">
                                        <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="메인 페이지: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                            <div class="tablesorter-header-inner">메인 페이지</div>
                                        </th>
                                        <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="1" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="적용 예시: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                            <div class="tablesorter-header-inner">적용 예시</div>
                                        </th>
                                        <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="2" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="타이틀 예시: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                            <div class="tablesorter-header-inner">타이틀 예시</div>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody aria-live="polite" aria-relevant="all">
                                    <tr role="row">
                                        <td class="confluenceTd"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/main_01.png" data-image-src="/download/attachments/4718688/main_01.png?version=1&amp;modificationDate=1466746398000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127678" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="main_01.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="4718688" data-linked-resource-container-version="22"></span></td>
                                        <td class="confluenceTd"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/main_02.png" data-image-src="/download/attachments/4718688/main_02.png?version=1&amp;modificationDate=1466746408000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127679" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="main_02.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="4718688" data-linked-resource-container-version="22"></span></td>
                                        <td class="confluenceTd">
                                            <p><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/main_04.png" data-image-src="/download/attachments/4718688/main_04.png?version=1&amp;modificationDate=1466746676000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127681" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="main_04.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="4718688" data-linked-resource-container-version="22"></span></p>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="table-wrap">
                                <table class="confluenceTable">
                                    <tbody>
                                    <tr>
                                        <th class="confluenceTh">상품 상세 페이지</th>
                                        <th class="confluenceTh">적용 예시</th>
                                        <th class="confluenceTh">타이틀 예시</th>
                                    </tr>
                                    <tr>
                                        <td rowspan="2" class="confluenceTd"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/details_01.png" data-image-src="/download/attachments/4718688/details_01.png?version=1&amp;modificationDate=1466746841000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127682" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="details_01.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="4718688" data-linked-resource-container-version="22"></span></td>
                                        <td class="confluenceTd">
                                            <p>&lt;페이지 상단&gt;</p>
                                            <p><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/details_top01.png" data-image-src="/download/attachments/4718688/details_top01.png?version=1&amp;modificationDate=1466747333000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127683" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="details_top01.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="4718688" data-linked-resource-container-version="22"></span></p>
                                        </td>
                                        <td class="confluenceTd">
                                            <p>&lt; 함께 본 상품 추천&gt;</p>
                                            <p><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/details_top02.png" data-image-src="/download/attachments/4718688/details_top02.png?version=1&amp;modificationDate=1466747348000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127684" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="details_top02.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="4718688" data-linked-resource-container-version="22"></span></p>
                                            <p><span>&lt; 함께 구매한 상품 추천 &gt;</span></p>
                                            <p><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/details_top03.png" data-image-src="/download/attachments/4718688/details_top03.png?version=1&amp;modificationDate=1466747366000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127685" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="details_top03.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="4718688" data-linked-resource-container-version="22"></span></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="1" class="confluenceTd">
                                            <p>&lt;페이지 하단&gt;</p>
                                            <p><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/deatails_bottom01.png" data-image-src="/download/attachments/4718688/deatails_bottom01.png?version=1&amp;modificationDate=1466747445000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127686" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="deatails_bottom01.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="4718688" data-linked-resource-container-version="22"></span></p>
                                        </td>
                                        <td colspan="1" class="confluenceTd">
                                            <p>&lt;실시간 개인화 추천&gt;</p>
                                            <p><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/details_bottom02.png" data-image-src="/download/attachments/4718688/details_bottom02.png?version=1&amp;modificationDate=1466747459000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127687" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="details_bottom02.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="4718688" data-linked-resource-container-version="22"></span></p>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="table-wrap">
                                <table class="confluenceTable tablesorter tablesorter-default" role="grid">
                                    <thead>
                                    <tr role="row" class="tablesorter-headerRow">
                                        <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="장바구니 페이지: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                            <div class="tablesorter-header-inner">장바구니 페이지</div>
                                        </th>
                                        <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="1" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="적용 예시 1_ 함께 구매한 상품: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                            <div class="tablesorter-header-inner">적용 예시 1_ 함께 구매한 상품</div>
                                        </th>
                                        <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="2" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="적용 예시 2 _ 실시간 개인화 추천: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                            <div class="tablesorter-header-inner">적용 예시 2 _ 실시간 개인화 추천</div>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody aria-live="polite" aria-relevant="all">
                                    <tr role="row">
                                        <td class="confluenceTd"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/mycart_01.png" data-image-src="/download/attachments/4718688/mycart_01.png?version=1&amp;modificationDate=1466747777000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127688" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="mycart_01.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="4718688" data-linked-resource-container-version="22"></span></td>
                                        <td class="confluenceTd"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/mycart_02.png" data-image-src="/download/attachments/4718688/mycart_02.png?version=1&amp;modificationDate=1466747820000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127689" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="mycart_02.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="4718688" data-linked-resource-container-version="22"></span></td>
                                        <td class="confluenceTd"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/mycart_03.png" data-image-src="/download/attachments/4718688/mycart_03.png?version=1&amp;modificationDate=1466747840000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127690" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="mycart_03.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="4718688" data-linked-resource-container-version="22"></span></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="table-wrap">
                                <table class="confluenceTable tablesorter tablesorter-default" role="grid">
                                    <thead>
                                    <tr role="row" class="tablesorter-headerRow">
                                        <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="구매 완료 페이지: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                            <div class="tablesorter-header-inner">구매 완료 페이지</div>
                                        </th>
                                        <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="1" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="적용 예시: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                            <div class="tablesorter-header-inner">적용 예시</div>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody aria-live="polite" aria-relevant="all">
                                    <tr role="row">
                                        <td class="confluenceTd"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/purchase_01.png" data-image-src="/download/attachments/4718688/purchase_01.png?version=1&amp;modificationDate=1466747897000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127691" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="purchase_01.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="4718688" data-linked-resource-container-version="22"></span></td>
                                        <td class="confluenceTd"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/purchase_02.png" data-image-src="/download/attachments/4718688/purchase_02.png?version=1&amp;modificationDate=1466747912000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127692" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="purchase_02.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="4718688" data-linked-resource-container-version="22"></span></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="table-wrap">
                                <table class="confluenceTable tablesorter tablesorter-default" role="grid">
                                    <thead>
                                    <tr role="row" class="tablesorter-headerRow">
                                        <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="마이페이지: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                            <div class="tablesorter-header-inner">마이페이지</div>
                                        </th>
                                        <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="1" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="적용 예시: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                            <div class="tablesorter-header-inner">적용 예시</div>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody aria-live="polite" aria-relevant="all">
                                    <tr role="row">
                                        <td class="confluenceTd"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/mypage_01.png" data-image-src="/download/attachments/4718688/mypage_01.png?version=1&amp;modificationDate=1466748104000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127693" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="mypage_01.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="4718688" data-linked-resource-container-version="22"></span></td>
                                        <td class="confluenceTd"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/mypage_02.png" data-image-src="/download/attachments/4718688/mypage_02.png?version=1&amp;modificationDate=1466748116000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127694" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="mypage_02.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="4718688" data-linked-resource-container-version="22"></span></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="table-wrap">
                                <table class="confluenceTable">
                                    <tbody>
                                    <tr>
                                        <th colspan="2" class="confluenceTh">기타 페이지</th>
                                    </tr>
                                    <tr>
                                        <td class="confluenceTd"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/search_01.png" data-image-src="/download/attachments/4718688/search_01.png?version=2&amp;modificationDate=1466748175000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127695" data-linked-resource-version="2" data-linked-resource-type="attachment" data-linked-resource-default-alias="search_01.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="4718688" data-linked-resource-container-version="22"></span></td>
                                        <td class="confluenceTd"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/promotion_01.png" data-image-src="/download/attachments/4718688/promotion_01.png?version=1&amp;modificationDate=1466748162000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127696" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="promotion_01.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="4718688" data-linked-resource-container-version="22"></span></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p>&nbsp;</p>
                        </div>
                    </div>
                </div>
            </div>
            <p>&nbsp;</p>

        </div>
    </div>
</template>