<template>
    <div class="page view">
        <h1>13) 인당 평균 구매상품수 (일간)</h1>
        <div id="main-content" class="wiki-content">
            <br>
            <p>인당 평균 구매 상품수를 일 단위로 제공합니다.</p>
            <p>전체사용자수, 전체사용자 구매상품수, 전체사용자 인당 평균 구매상품수, 추천클릭사용자수, 추천클릭사용자 구매상품수, 추천클릭사용자 인당 평균 구매상품수를 알 수 있습니다.</p>
            <p>어제 날짜 데이터까지 제공합니다.</p>
            <p><span style="color: rgb(0,0,0);">GET 방식으로 요청하실 수 있고 정상적으로 요청이 완료되는 경우는 JSON 형태의 추천리스트가 반환됩니다. callback 파라미터를 명시하는 경우 JSONP 형태로 응답합니다.</span></p>
            <p><span style="color: rgb(0,0,0);">요청에 실패할 경우 상황에 따라 4XX 혹은 5XX형태의 에러코드가 반환됩니다.</span></p>
            <h3 id="id-13)인당평균구매상품수(일간)-ResourceURL"><span style="color: rgb(0,0,0);">Resource URL</span></h3>
            <p><span style="color: rgb(0,0,0);"><span> <span class="nolink">https://api.recopick.com/1/admin/:service_id/feed/dailyordercnt</span></span></span></p>
            <p><a href="https://api.recopick.com/2/recommend/:service_id/:item_id" class="external-link"> </a></p>
            <h3 id="id-13)인당평균구매상품수(일간)-Parameters"><a href="https://api.recopick.com/2/recommend/:service_id/:item_id" class="external-link"><span class="mw-headline">Parameters</span></a></h3>
            <div class="table-wrap">
                <table class="wrapped confluenceTable">
                    <colgroup>
                        <col />
                        <col />
                    </colgroup>
                    <tbody>
                    <tr>
                        <th class="confluenceTh">
                            <p>service_id</p>
                            <p><sup><span style="color: rgb(153,153,153);">(required)</span></sup></p>
                        </th>
                        <td class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">RecoPick에서 발급된 service id를 의미합니다. service id <span style="color: rgb(34,34,34);">값은 </span><a href="http://admin.recopick.com/-/api_info" class="external-link">어드민 대시보드</a><span style="color: rgb(34,34,34);"> </span><span style="color: rgb(34,34,34);">에서 확인하실 수 있습니다.</span></span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> 17</span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th class="confluenceTh">
                            <p>access_key</p>
                            <p><sup><span style="color: rgb(153,153,153);">(required)</span></sup></p>
                        </th>
                        <td class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">보안이 적용된 API를 호출하기 위해 사용되는 비밀키를 의미합니다. 비밀키 값은 <a href="http://admin.recopick.com/-/api_info" class="external-link">어드민 대시보드</a>에서 확인하실 수 있습니다.</span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> 1f385dca73fb935cb11f8a246ea85f94</span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh">
                            <p>ago</p>
                            <p><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></p>
                        </th>
                        <td colspan="1" class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">읽어올 데이터의 항목수를 의미합니다. 기본값은 7입니다.</span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> 7</span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh">
                            <p>skip</p>
                            <p><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></p>
                        </th>
                        <td colspan="1" class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">지정된 값만큼 이전 데이터를 읽어옵니다. 0으로 지정할 경우 어제 데이터까지, 1로 지정할 경우 그저께의 데이터까지 읽어옵니다. 기본값은 0입니다.</span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> 12</span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh">
                            <p>offset</p>
                            <p><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></p>
                        </th>
                        <td colspan="1" class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);">시간대(Timezone)의 UTC(협정 세계 표준시)와의 차이를 분 단위로 지정합니다. UTC 대비 9시간 빠른 서울의 경우 -540으로 지정합니다. 기본값은 0입니다.</span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> -540</span></code></p>
                        </td>
                    </tr>
                    <tr>
                        <th class="confluenceTh">
                            <p>callback</p>
                            <p><sup><span style="color: rgb(153,153,153);">(optional)</span></sup></p>
                        </th>
                        <td class="confluenceTd">
                            <p><span style="color: rgb(34,34,34);"><span>callback을 입력하시면 JSONP 형태의 응답을 제공합니다.</span></span></p>
                            <p><code><strong>Example Values :</strong><span style="color: rgb(34,34,34);"> callback=parseResponse<br /></span></code></p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <p><a href="https://api.recopick.com/2/recommend/:service_id/:item_id" class="external-link"><span><br /></span></a></p>
            <h3 id="id-13)인당평균구매상품수(일간)-ExampleRequest"><a href="https://api.recopick.com/2/recommend/:service_id/:item_id" class="external-link"><span>Example Request</span></a></h3>
            <hr />
            <p><a href="https://api.recopick.com/2/recommend/:service_id/:item_id" class="external-link"><span><strong>GET   </strong> </span><span class="nolink">https://api.recopick.com/1/admin/2/feed/dailyordercnt?access_key=REPLACE_THIS&amp;ago=12&amp;skip=0&amp;offset=-540</span><br /><span> </span></a></p>
            <h3 id="id-13)인당평균구매상품수(일간)-Output">Output</h3>
            <h3 id="id-13)인당평균구매상품수(일간)-반환되는JSON필드설명은아래를참고해주세요."><span style="color: rgb(0,0,0);"> </span><span>반환되는 JSON 필드 설명은 아래를 참고해주세요.</span></h3>
            <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                <div class="codeContent panelContent pdl">
            <pre class="syntaxhighlighter-pre" data-syntaxhighlighter-params="brush: js; gutter: false; theme: Eclipse" data-theme="Eclipse">{
  &quot;20130504&quot;: {
    &quot;uv&quot;: 606,
    &quot;total_sum_order&quot;: 254,
    &quot;total_avg_order&quot;: 0.419142,
    &quot;reco_uv&quot;: 72,
    &quot;reco_sum_order&quot;: 41,
    &quot;reco_avg_order&quot;: 0.569444
  },
  &quot;20130505&quot;: {
    &quot;uv&quot;: 620,
    &quot;total_sum_order&quot;: 378,
    &quot;total_avg_order&quot;: 0.609677,
    &quot;reco_uv&quot;: 67,
    &quot;reco_sum_order&quot;: 47,
    &quot;reco_avg_order&quot;: 0.701493
  },
  &quot;20130506&quot;: {
    &quot;uv&quot;: 646,
    &quot;total_sum_order&quot;: 297,
    &quot;total_avg_order&quot;: 0.459752,
    &quot;reco_uv&quot;: 84,
    &quot;reco_sum_order&quot;: 40,
    &quot;reco_avg_order&quot;: 0.47619
  },
  &quot;20130507&quot;: {
    &quot;uv&quot;: 565,
    &quot;total_sum_order&quot;: 207,
    &quot;total_avg_order&quot;: 0.366372,
    &quot;reco_uv&quot;: 50,
    &quot;reco_sum_order&quot;: 23,
    &quot;reco_avg_order&quot;: 0.46
  },
  &quot;20130508&quot;: {
    &quot;uv&quot;: 645,
    &quot;total_sum_order&quot;: 232,
    &quot;total_avg_order&quot;: 0.35969,
    &quot;reco_uv&quot;: 77,
    &quot;reco_sum_order&quot;: 37,
    &quot;reco_avg_order&quot;: 0.480519
  },
  &quot;20130509&quot;: {
    &quot;uv&quot;: 712,
    &quot;total_sum_order&quot;: 272,
    &quot;total_avg_order&quot;: 0.382022,
    &quot;reco_uv&quot;: 84,
    &quot;reco_sum_order&quot;: 36,
    &quot;reco_avg_order&quot;: 0.428571
  },
  &quot;20130510&quot;: {
    &quot;uv&quot;: 626,
    &quot;total_sum_order&quot;: 204,
    &quot;total_avg_order&quot;: 0.325879,
    &quot;reco_uv&quot;: 60,
    &quot;reco_sum_order&quot;: 20,
    &quot;reco_avg_order&quot;: 0.333333
  }
}</pre>
                </div>
            </div>
            <p><span><br /></span></p>
            <p><br /></p>
            <div class="table-wrap">
                <table class="wrapped confluenceTable" style="line-height: 1.4285715;">
                    <tbody>
                    <tr>
                        <th colspan="2" class="confluenceTh">field</th>
                        <th style="text-align: center;" class="confluenceTh"><strong>의 미</strong></th>
                        <th style="text-align: center;" class="confluenceTh"><strong>비 고</strong></th>
                    </tr>
                    <tr>
                        <th class="confluenceTh">YYYYMMDD</th>
                        <th colspan="1" class="confluenceTh"><br /></th>
                        <td class="confluenceTd">년월일</td>
                        <td class="confluenceTd">해당 일자의 데이터를 나타냅니다.</td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"><br /></th>
                        <th colspan="1" class="confluenceTh">uv</th>
                        <td colspan="1" class="confluenceTd">전체 방문자수</td>
                        <td colspan="1" class="confluenceTd">해당 일자의 전체사용자 수</td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"><br /></th>
                        <th colspan="1" class="confluenceTh">total_sum_order</th>
                        <td colspan="1" class="confluenceTd">전체 주문건수</td>
                        <td colspan="1" class="confluenceTd">해당 일자의 전체사용자 구매상품 수</td>
                    </tr>
                    <tr>
                        <th colspan="1" class="confluenceTh"><br /></th>
                        <th colspan="1" class="confluenceTh">total_avg_order</th>
                        <td colspan="1" class="confluenceTd">전체 인당 평균 주문건수</td>
                        <td colspan="1" class="confluenceTd">해당 일자의 전체사용자 인당 평균 구매상품 수</td>
                    </tr>
                    <tr>
                        <th class="confluenceTh"><br /></th>
                        <th class="confluenceTh">reco_uv</th>
                        <td class="confluenceTd">추천 사용자수</td>
                        <td class="confluenceTd">해당 일자의 추천클릭사용자 수</td>
                    </tr>
                    <tr>
                        <th class="confluenceTh"><br /></th>
                        <th class="confluenceTh">reco_sum_order</th>
                        <td class="confluenceTd">추천 주문건수</td>
                        <td class="confluenceTd">해당 일자의 추천클릭사용자 구매상품 수</td>
                    </tr>
                    <tr>
                        <th class="confluenceTh"><br /></th>
                        <th class="confluenceTh">reco_avg_order</th>
                        <td class="confluenceTd">추천 인당 평균 주문건수</td>
                        <td class="confluenceTd">해당 일자의 추천클릭사용자 인당 평균 구매상품 수</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>