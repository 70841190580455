<template>
    <div class="page view">
        <h1>추천 위젯 설정 메뉴에서 디자인 변경</h1>
        <div id="main-content" class="wiki-content">
            <br>
            <h1 id="Recopick추천위젯이용안내-추천위젯설정메뉴에서디자인변경"><span style="color: rgb(51,51,153);">추천 위젯 설정 메뉴에서 디자인 변경</span></h1>
            <p><span style="color: rgb(0,0,0);">Admin Dashboard&nbsp;</span>추천 위젯툴을 이용해 간편하게 원하시는 알고리즘 선택 및 디자인 수정이 가능합니다.</p>
            <p><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/widget_v001.png" data-image-src="/download/attachments/4718691/widget_v001.png?version=3&amp;modificationDate=1527150312000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127744" data-linked-resource-version="3" data-linked-resource-type="attachment" data-linked-resource-default-alias="widget_v001.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="4718691" data-linked-resource-container-version="40"></span></p>
            <div class="table-wrap">
                <table class="confluenceTable">
                    <tbody>
                    <tr>
                        <th colspan="2" class="confluenceTh">메뉴 설명</th>
                    </tr>
                    <tr>
                        <td class="confluenceTd">
                            <ol>
                                <li><strong>위젯 추가</strong></li>
                            </ol>
                            <p><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/widget_v02.png" data-image-src="/download/attachments/4718691/widget_v02.png?version=2&amp;modificationDate=1479968355000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127702" data-linked-resource-version="2" data-linked-resource-type="attachment" data-linked-resource-default-alias="widget_v02.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="4718691" data-linked-resource-container-version="40"></span></p>
                        </td>
                        <td class="confluenceTd">
                            <p>서비스 가입을 하시면 기본적으로 하나의 위젯이 만들어지며, 위젯 스타일 추가 버튼을 통해 새로운 스타일의 위젯을 추가하실 수 있습니다.</p>
                            <p>&nbsp;</p>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="1" class="confluenceTd">
                            <p><strong>2. 위젯명과 채널명 입력</strong></p>
                            <p><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/widget_v03.png" data-image-src="/download/attachments/4718691/widget_v03.png?version=1&amp;modificationDate=1479968392000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127704" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="widget_v03.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="4718691" data-linked-resource-container-version="40"></span></p>
                        </td>
                        <td colspan="1" class="confluenceTd">
                            <p>위젯명: 페이지 삽입 위치로 표시</p>
                            <p>ex) 상품상세_상단, 상품상세_하단, 메인, 장바구니, 주문완료 ,마이페이지 등</p>
                            <p>채널명 : 삽입위치를 영문으로 표시</p>
                            <p>ex) detail_top, detail_bottom, main, basket, order_result, mypage</p>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="1" class="confluenceTd">
                            <p><strong>3. 추천타입설정</strong></p>
                            <p><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/widget_v004.png" data-image-src="/download/attachments/4718691/widget_v004.png?version=1&amp;modificationDate=1505112482000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127745" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="widget_v004.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="4718691" data-linked-resource-container-version="40"></span></p>
                            <p>&nbsp;</p>
                        </td>
                        <td colspan="1" class="confluenceTd">
                            <p>*&nbsp;<strong>추천 우선 순위 설정</strong></p>
                            <p><span>1순위부터 선택한 알고리즘의 추천 결과를 읽어 위젯으로 표시합니다. 1순위 알고리즘이 빈 결과를 응답하면 2순위, 2순위 알고리즘도 빈 결과를 응답하면 3순위의 알고리즘이 사용됩니다. 3순위의 알고리즘까지도 빈 결과를 응답하면 위젯 영역은 노출되지 않습니다. &nbsp;</span></p>
                            <div class="table-wrap">
                                <table class="confluenceTable tablesorter tablesorter-default" role="grid">
                                    <thead>
                                    <tr role="row" class="tablesorter-headerRow">
                                        <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label=" : No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                            <div class="tablesorter-header-inner">&nbsp;</div>
                                        </th>
                                        <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="1" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="상품상세_상단: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                            <div class="tablesorter-header-inner">상품상세_상단</div>
                                        </th>
                                        <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="2" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="상품상세_하단: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                            <div class="tablesorter-header-inner">상품상세_하단</div>
                                        </th>
                                        <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="3" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="메인  페이지: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                            <div class="tablesorter-header-inner">메인&nbsp; 페이지</div>
                                        </th>
                                        <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="4" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="장바구니: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                            <div class="tablesorter-header-inner">
                                                <p>장바구니</p>
                                            </div>
                                        </th>
                                        <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="5" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="주문완료: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                            <div class="tablesorter-header-inner">주문완료</div>
                                        </th>
                                        <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="6" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="마이페이지: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                            <div class="tablesorter-header-inner">마이페이지</div>
                                        </th>
                                        <th colspan="1" class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="7" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="그외 페이지: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                            <div class="tablesorter-header-inner">그외 페이지</div>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody aria-live="polite" aria-relevant="all">
                                    <tr role="row">
                                        <td class="confluenceTd">1순위</td>
                                        <td class="confluenceTd">함께 본 상품 추천</td>
                                        <td class="confluenceTd">함께 구매한 상품 추천</td>
                                        <td class="confluenceTd">실시간 개인화 추천</td>
                                        <td class="confluenceTd">함께 구매한 상품 <span>추천</span></td>
                                        <td class="confluenceTd">
                                            <p><span>실시간 개인화 추천</span></p>
                                        </td>
                                        <td class="confluenceTd"><span>실시간 개인화 추천</span></td>
                                        <td colspan="1" class="confluenceTd"><span>실시간 개인화 추천</span></td>
                                    </tr>
                                    <tr role="row">
                                        <td class="confluenceTd">2순위</td>
                                        <td class="confluenceTd">실시간 개인화 추천</td>
                                        <td class="confluenceTd">실시간 개인화 추천</td>
                                        <td class="confluenceTd">
                                            <p>통계형 추천</p>(구매전환율 TOP 100)
                                        </td>
                                        <td class="confluenceTd">
                                            <p><span>실시간 개인화 추천</span></p>
                                        </td>
                                        <td class="confluenceTd">함께 구매한 상품 <span>추천</span></td>
                                        <td class="confluenceTd">
                                            <p>통계형 추천</p>(구매전환율 TOP 100)
                                        </td>
                                        <td colspan="1" class="confluenceTd">
                                            <p>통계형 추천</p>(구매전환율 TOP 100)
                                        </td>
                                    </tr>
                                    <tr role="row">
                                        <td class="confluenceTd">3순위</td>
                                        <td class="confluenceTd">
                                            <p>통계형 추천</p>(구매전환율 TOP 100)
                                        </td>
                                        <td class="confluenceTd">
                                            <p>통계형 추천</p>(구매전환율 TOP 100)
                                        </td>
                                        <td class="confluenceTd">사용안함</td>
                                        <td class="confluenceTd">
                                            <p>통계형 추천</p>(많이 팔린 상품 TOP 100)
                                        </td>
                                        <td class="confluenceTd">
                                            <p>통계형 추천</p>(<span>많이 팔린 상품 TOP 100</span>)
                                        </td>
                                        <td class="confluenceTd">사용안함</td>
                                        <td colspan="1" class="confluenceTd">사용안함</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p><strong>*&nbsp;메뉴에서 타이틀 설정 : </strong>변경버튼 클릭, 추천타입에 맞는 타이틀 이미지를 선택하시면 됩니다. (RecoPick이 제공해드리는 타이틀 이미지를 선택할 수 있습니다.)</p>
                            <p><span style="color: rgb(255,102,0);">*&nbsp;커스텀 CSS로 타이틀 영역 디자인 변경 예시<strong> </strong></span></p>
                            <div class="table-wrap">
                                <table class="confluenceTable">
                                    <tbody>
                                    <tr>
                                        <th class="confluenceTh">변경할 부분</th>
                                        <th colspan="2" class="confluenceTh">CSS 코드 예시</th>
                                        <th class="confluenceTh">상세설명</th>
                                    </tr>
                                    <tr>
                                        <td class="confluenceTd">
                                            <p>타이틀의 정열 및 간격을 조절하고 싶은 경우</p>
                                        </td>
                                        <td colspan="2" class="confluenceTd">body, th, td, h1 {margin-top:5px;padding:5px;}</td>
                                        <td class="confluenceTd">
                                            <p>* text-align: center; (타이틀 가운데 정열)</p>
                                            <p>* margin-top:5px; (타이틀 위쪽 간격)</p>
                                            <p>* padding:5px; (타이틀 상하좌우 여백 )</p>
                                            <p>* margin-bottom:5px; (line아래 간격 )</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowspan="2" class="confluenceTd">
                                            <p>텍스트 타이틀을 이용할 경우</p>
                                            <p>&nbsp;</p>
                                            <div>•타이틀 변경버튼 클릭후</div>
                                            <div>텍스트사용을 선택함</div>
                                            <div>•텍스트 입력후 글자 크기를 지정</div>
                                        </td>
                                        <td class="confluenceTd">웹</td>
                                        <td class="confluenceTd">
                                            <p>.title {font-family: NanumGothic, 나눔고딕, NanumGothicWeb, '맑은 고딕',</p>
                                            <p>'Malgun Gothic', 돋움, Dotum, 굴림, Gulim, Helvetica, AppleGothic,</p>
                                            <p>sans-serif;font-weight:bold;color:#7c7c7c;padding:3px;}</p>
                                        </td>
                                        <td class="confluenceTd">&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td class="confluenceTd">모바일</td>
                                        <td class="confluenceTd">
                                            <p>mobile-widget .title {font-family: NanumGothic, 나눔고딕, NanumGothicWeb,</p>
                                            <p>'맑은 고딕', 'Malgun Gothic', 돋움, Dotum, 굴림, Gulim, Helvetica, AppleGothic,</p>
                                            <p>sans-serif;;font-weight:bold;padding:5px;letter-spacing: -1px;color:#fc5402;border:0px}</p>
                                        </td>
                                        <td class="confluenceTd">&nbsp;</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="1" class="confluenceTd">
                            <p><strong>4. 위젯 스타일 변경</strong></p>
                            <p><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/widget_v05.png" data-image-src="/download/attachments/4718691/widget_v05.png?version=1&amp;modificationDate=1479968423000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127706" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="widget_v05.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="4718691" data-linked-resource-container-version="40"></span></p>
                            <p>&nbsp;</p>
                        </td>
                        <td colspan="1" class="confluenceTd">&nbsp;<p><strong>테두리</strong> : 위젯 테두리(border) 표시 여부입니다. 테두리 표시 여부, 테두리 색을 선택 가능. 나머지 CSS로 변경</p>
                            <p><span style="color: rgb(255,102,0);">*&nbsp;커스텀 CSS로 테두리 영역 디자인 변경 예시</span></p>
                            <div class="table-wrap">
                                <table class="confluenceTable">
                                    <tbody>
                                    <tr>
                                        <th class="confluenceTh">변경할 부분</th>
                                        <th colspan="2" class="confluenceTh"><span>CSS 코드 예시</span></th>
                                        <th class="confluenceTh"><span>상세설명</span></th>
                                    </tr>
                                    <tr>
                                        <td rowspan="2" class="confluenceTd">
                                            <p>타이틀 및 Line 전체 삭제하고</p>
                                            <p>섬네일 이미지만 보이게 하고 싶은 경우</p>
                                        </td>
                                        <td colspan="1" class="confluenceTd">웹</td>
                                        <td class="confluenceTd">
                                            <p>.title {display:none}</p>
                                        </td>
                                        <td rowspan="2" class="confluenceTd">&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td colspan="1" class="confluenceTd">모바일</td>
                                        <td colspan="1" class="confluenceTd">
                                            <p>.mobile-widget .title {display:none}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowspan="2" class="confluenceTd">
                                            <p>타이틀과 이미지만 보이게 하고 싶은 경우</p>
                                            <p>(Line만 삭제)</p>
                                        </td>
                                        <td colspan="1" class="confluenceTd">웹</td>
                                        <td class="confluenceTd">
                                            <p>.widget--h1.line .title {border:0px}</p>
                                        </td>
                                        <td rowspan="2" class="confluenceTd">
                                            <p>* Css 적용이 안될경우 :&nbsp; 테두리색 변경에서 컬러값을 #ffffff(화이트)로 설정&nbsp;</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="1" class="confluenceTd">모바일</td>
                                        <td colspan="1" class="confluenceTd">
                                            <p><span>.</span>mobile-widget .title {border:0px}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowspan="2" class="confluenceTd">
                                            <p>타이틀 바 배경색을 넣고 싶을 경우</p>
                                        </td>
                                        <td colspan="1" class="confluenceTd">웹</td>
                                        <td colspan="1" class="confluenceTd">
                                            <p>.widget--h1.line .title {border:0px;background: #eeede8;}</p>
                                        </td>
                                        <td rowspan="2" class="confluenceTd">
                                            <p>* background: #eeede8 (백그라운드 컬러 지정)</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="1" class="confluenceTd">모바일</td>
                                        <td colspan="1" class="confluenceTd">
                                            <p>.mobile-widget .title {border:0px;background: #eeede8;}</p>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p><strong><br></strong></p>
                            <p><strong>상품 갯수</strong>: 한 화면에 표시될 상품 갯수. (주로 PC웹은 5 ~6개, 모바일은 3개 노출 권장)</p>
                            <p><strong>페이지 이동</strong> : 상품 목록의 페이지 수. (상품 갯수 5, 페이지 수가 3일 경우 총 15개의 상품이 3 페이지에 거쳐 표시. 단, 추천 계산 결과가 10개라면, 페이지 수를 3으로 지정해도 2 페이지로 표시)</p>
                            <p><strong>텍스트 정렬 </strong>: 상품명, 가격 등의 텍스트의 정렬 변경.</p>
                            <p><strong>여러줄 추천 </strong>: 상품 목록을 여러줄로 표시.</p>
                            <p><strong>자신 포함 </strong>: "함께 본 상품" 등 상품 기반 추천 시 추천 대상 상품도 위젯에 포함시키는 옵션. 장바구니 페이지에서 함께 구매한 상품 위젯을 표시하고자 할 때 유용.</p>
                            <p><strong>무한 스크롤 </strong>: 페이지 스크롤 시 마지막 페이지 -&gt; 첫 페이지와 같이 같은 방향으로 무한 스크롤됨.</p>
                            <p><strong>랜덤 추천 </strong>: 추천 결과를 랜덤으로 표시.</p>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="1" class="confluenceTd">
                            <p><strong>4. 위젯 스타일 변경</strong></p>
                            <p><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/widget_v06.png" data-image-src="/download/attachments/4718691/widget_v06.png?version=1&amp;modificationDate=1479968441000&amp;api=v2" data-unresolved-comment-count="0" data-linked-resource-id="6127707" data-linked-resource-version="1" data-linked-resource-type="attachment" data-linked-resource-default-alias="widget_v06.png" data-base-url="https://docs.recopick.com" data-linked-resource-content-type="image/png" data-linked-resource-container-id="4718691" data-linked-resource-container-version="40"></span></p>
                            <p>&nbsp;</p>
                        </td>
                        <td colspan="1" class="confluenceTd">&nbsp;<p><strong>상품명 </strong>: 상품명의 글자 크기, 색상을 선택할 수 있습니다. (폰트종류, 글자 생략은 CSS 로 변경 가능)</p>
                            <ul>
                                <li>상품 링크: 링크 클릭 시 페이지 이동을 새 창으로 할지, 현재 창에서 할지 선택할 수 있습니다.</li>
                            </ul>
                            <p><strong>가격</strong><span>: 가격의 글자 크기, 색상, 표시 여부를 선택할 수 있습니다.</span></p>
                            <p><span style="color: rgb(255,102,0);">*&nbsp;커스텀 CSS로 상품명/가격 영역 변경 예시<strong> </strong></span></p>
                            <div class="table-wrap">
                                <table class="confluenceTable">
                                    <tbody>
                                    <tr>
                                        <th class="confluenceTh">변경할 부분</th>
                                        <th colspan="3" class="confluenceTh"><span>CSS 코드 예시</span><span>&nbsp;</span></th>
                                        <th class="confluenceTh"><span>상세설명</span></th>
                                    </tr>
                                    <tr>
                                        <td rowspan="2" class="confluenceTd">
                                            <p>상품명 (class= name)의 폰트 종류,</p>
                                            <p>글자간격, 글자생략 등을 변경시</p>
                                            <p>&nbsp;</p>
                                        </td>
                                        <td colspan="1" class="confluenceTd"><span>웹</span></td>
                                        <td colspan="2" class="confluenceTd">
                                            <p>.name {font-family:Malgun Gothic;display: inline-block;font-weight:normal;letter-spacing: -1px;</p>
                                            <p>width: 100px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;}</p>
                                        </td>
                                        <td rowspan="2" class="confluenceTd">
                                            <p>* font-family:Malgun Gothic; (폰트 지정)</p>
                                            <p>*<span>&nbsp;</span>letter-spacing: -1px (글자 간격)</p>
                                            <p>*&nbsp; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;</p>
                                            <p>&nbsp;(…글자 생략)</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="confluenceTd"><span>모바일</span></td>
                                        <td colspan="2" class="confluenceTd">
                                            <p><span>.mobile-widget .</span>name {font-family:Malgun Gothic;display: inline-block;font-weight:normal;letter-spacing: -1px;</p>
                                            <p>width: 100px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowspan="4" class="confluenceTd">
                                            <ul>
                                                <li>가격(class= price)의 폰트 종류, 글자 간격 변경시</li>
                                                <li>할인가<span>(class= sale_price) 컬러 강조시 </span></li>
                                            </ul>
                                        </td>
                                        <td colspan="2" rowspan="2" class="confluenceTd">웹</td>
                                        <td colspan="1" class="confluenceTd">
                                            <p>.widget .price {font-family: Malgun Gothic;font-weight;bold;}</p>
                                        </td>
                                        <td rowspan="4" class="confluenceTd">
                                            <p>font-family : Malgun Gothic (폰트 지정)</p>
                                            <p>letter-spacing: -0px (글자 간격 : 0.1em 으로 지정 가능)</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="1" class="confluenceTd"><span>.widget .sale_price {<span>color:#ff6101</span>}</span></td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" rowspan="2" class="confluenceTd">모바일</td>
                                        <td colspan="1" class="confluenceTd">
                                            <p>.mobile-widget .price {font-family:Verdana,Malgun Gothic;letter-spacing:-1px;font-weight:bold;}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="1" class="confluenceTd"><span>.mobile-widget .<span>sale_</span>price <span>{</span><span>color:#ff6101</span><span>}</span></span></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p>&nbsp;</p>
                            <p><strong>저자</strong>: 저자의 글자 크기, 색상, 표시 여부를 선택할 수 있습니다. 저자 정보는&nbsp;<a href="http://docs.recopick.com/pages/viewpage.action?pageId=491543#id-1.RecoPick%EC%8B%9C%EC%9E%91%ED%95%98%EA%B8%B0-2)%EB%A9%94%ED%83%80%ED%83%9C%EA%B7%B8%EC%82%BD%EC%9E%85" class="external-link">상품 상세 페이지에 지정하신 메타 태그</a>&nbsp;중 author 태그의 값에 해당됩니다.</p>
                            <p><strong>섬네일</strong>: 섬네일 이미지의 표시 여부를 선택할 수 있습니다. 섬네일 이미지의 크기는 위젯의 크기에 따라 자동으로 지정됩니다.</p>
                            <div class="table-wrap">
                                <table class="confluenceTable tablesorter tablesorter-default" role="grid">
                                    <thead>
                                    <tr role="row" class="tablesorter-headerRow">
                                        <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="변경할 부분: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                            <div class="tablesorter-header-inner">변경할 부분</div>
                                        </th>
                                        <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="1" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="CSS 코드 예시: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                            <div class="tablesorter-header-inner"><span>CSS 코드 예시</span></div>
                                        </th>
                                        <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="2" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="상세설명: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                                            <div class="tablesorter-header-inner"><span>상세설명</span></div>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody aria-live="polite" aria-relevant="all">
                                    <tr role="row">
                                        <td class="confluenceTd">
                                            <p>이미지 사이즈 및 테두리 지정할 경우</p>
                                        </td>
                                        <td class="confluenceTd">
                                            <p>thumbnail {width:200px; margin-top:10px}</p>
                                        </td>
                                        <td class="confluenceTd">
                                            <p>* width:200px height :50px(썸네일 크기 지정)</p>
                                            <p>* margin-top:10px (라인과 썸네일 간격 지정)</p>
                                            <p>* border:1px solid #B8B8B8 (썸네일이미지에 테두리 지정)</p>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <h2 id="Recopick추천위젯이용안내-"><span style="color: rgb(51,51,153);"><br></span></h2>
        </div>
    </div>
</template>