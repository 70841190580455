<template>
  <div class="page view">
    <h1>Shopby</h1>
    <div id="main-content" class="wiki-content">
      <br>
      <div class="confluence-information-macro confluence-information-macro-note conf-macro output-block" data-hasbody="true" data-macro-name="note">
        <p class="title"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> &nbsp;&nbsp; 본 가이드는 위사 Shopby에 해당하는 내용입니다.</p>
        <div class="confluence-information-macro-body">
          <p><br></p>
          <p> </p>
          <div class="toc-macro client-side-toc-macro  conf-macro output-block hidden-outline" data-headerelements="H1,H2,H3,H4,H5,H6,H7" data-hasbody="false" data-macro-name="toc">
            <ul style="">
              <li><span class="toc-item-body" data-outline="1"><span class="toc-outline">1</span><a href="/display/docs/Shopby#shopby_step_1" class="toc-link">&lt;1단계&gt; 레코픽 로그수집 스크립트 삽입</a></span></li>
              <li><span class="toc-item-body" data-outline="2"><span class="toc-outline">2</span><a href="/display/docs/Shopby#shopby_step_2" class="toc-link">&lt;2단계&gt; 상품정보 메타 태그 삽입</a></span></li>
              <li><span class="toc-item-body" data-outline="3"><span class="toc-outline">3</span><a href="/display/docs/Shopby#shopby_step_3" class="toc-link">&lt;3단계&gt; 추천 위젯 삽입</a></span></li>
              <li><span class="toc-item-body" data-outline="4"><span class="toc-outline">4</span><a href="/display/docs/Shopby#shopby_step_4" class="toc-link">추천서비스 시작 (추천우선 순위 설정)</a></span></li>
            </ul>
          </div>
          <p> </p>
        </div>
      </div>
      <h1 id="shopby_step_1"><strong>&lt;1단계&gt; 레코픽 로그수집 스크립트 삽입</strong></h1>
      <p><br></p>
      <ol>
        <li>
          <p class="p1"><span class="s1">샵바이 쇼핑몰 관리자 페이지에 로그인 후 <strong>'설정 &gt; 기본정책 &gt; 외부서비스 설정'</strong>을 선택합니다.</span></p>
          <p><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/shopby/step1-1.png" style="width: 1200px"></span></p>
          <p><br></p>
        </li>
        <li>
          <p class="p1">
            <span class="s1">'외부 스크립트' 메뉴의 사용여부를 선택하고, <strong>'공통영역 스크립트 &gt; 상단 공통'</strong>의 PC웹&amp;모바일웹에 아래 로그수집 스크립트를 복사 후 삽입하세요.<br> 사이트url이 동일한 반응형웹의 경우, PC웹에만 삽입해주시고  <strong>&lt;PC웹 내용과 동일하게 적용&gt; </strong>을 체크해주세요.</span>
          </p>
          <p class="p1"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/shopby/step1-2.png" style="width: 1200px"></span></p>
          <div class="panel conf-macro output-block" style="background-color: #ffffff;border-color: #007eff;border-style: solid;border-width: 1px;" data-hasbody="true" data-macro-name="panel">
            <div class="panelHeader" style="border-bottom-width: 1px;border-bottom-style: solid;border-bottom-color: #007eff;background-color: #007eff;color: #ffffff;"><b>기본 로그수집 스크립트</b></div>
            <div class="panelContent" style="background-color: #ffffff;">
              <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                <div class="codeContent panelContent pdl">
                  <div>
                    <div class="syntaxhighlighter sh-midnight nogutter  coldfusion">
                      <table border="0" cellpadding="0" cellspacing="0">
                        <tbody>
                        <tr>
                          <td class="code">
                            <div class="container" title="Hint: double-click to select code">
                              <div class="line number1 index0 alt2"><code class="coldfusion comments">&lt;!--RecoPick 로그수집 스크립트 --&gt;</code></div>
                              <div class="line number2 index1 alt1"><code class="coldfusion plain">&lt;script type=</code><code class="coldfusion string">"text/javascript"</code><code class="coldfusion plain">&gt;</code></div>
                              <div class="line number3 index2 alt2"><code class="coldfusion spaces">&nbsp;&nbsp;</code><code class="coldfusion plain">(function(w,d,n,s,e,o) {</code></div>
                              <div class="line number4 index3 alt1"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">w[n]=w[n]||function(){(w[n].q=w[n].q||[]).push(arguments)};</code></div>
                              <div class="line number5 index4 alt2"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">e=d.createElement(s);e.async=1;e.charset=</code><code class="coldfusion string">'utf-8'</code><code class="coldfusion plain">;e.src=</code><code class="coldfusion string">'//static.recopick.com/dist/production2.min.js'</code><code class="coldfusion plain">;</code></div>
                              <div class="line number6 index5 alt1"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">o=d.getElementsByTagName(s)[0];o.parentNode.insertBefore(e,o);</code></div>
                              <div class="line number7 index6 alt2"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">})(window, document, </code><code class="coldfusion string">'recoPick'</code><code class="coldfusion plain">, </code><code class="coldfusion string">'script'</code><code class="coldfusion plain">);</code></div>
                              <div class="line number8 index7 alt1"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">recoPick(</code><code class="coldfusion string">'service'</code><code class="coldfusion plain">, </code><code class="coldfusion string">'사이트 url'</code><code class="coldfusion plain">);</code></div>
                              <div class="line number9 index8 alt2"><code class="coldfusion spaces">&nbsp;&nbsp;&nbsp;</code><code class="coldfusion plain">recoPick(</code><code class="coldfusion string">'sendLog'</code><code class="coldfusion plain">, </code><code class="coldfusion string">'shopby'</code><code class="coldfusion plain">);</code></div>
                              <div class="line number10 index9 alt1"><code class="coldfusion plain">&lt;/script&gt;</code></div>
                              <div class="line number11 index10 alt2"><code class="coldfusion comments">&lt;!--RecoPick 로그수집 스크립트 --&gt;</code></div>
                            </div>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p><img class="emoticon emoticon-warning" src="@/assets/images/warning.svg" data-emoticon-name="warning" alt="(warning)"><span style="color: rgb(255,0,0);">&nbsp;주의하세요!&nbsp;</span>'사이트 url' 은 www를 제외한&nbsp;사이트 주소로 수정합니다.&nbsp;반드시 대소문자 구별을 꼭 해주셔야 합니다.</p>
          <p><br></p>
        </li>
        <li>
          <p>공통 헤더용 로그수집 스크립트 삽입후 더욱 특화된 개인화 추천을 원하시면 암호화된 ID, 연령, 성별 데이터를 보내주세요!&nbsp;</p>
          <p><span style="color: rgb(0,0,0);">*&nbsp;</span>(’service’, ‘ 사이트 url’); 바로 다음에 아래 스크립트를 삽입해 주시면 됩니다. 출생년도, 성별은 예시 참고하셔서 변경해 주시고 MEMBER_ID(MID)는 자체적으로 사용중인 회원 ID를 암호화해서 보내주세요.</p>
          <p><span style="color: rgb(255,0,0);"><span style="color: rgb(0,0,0);">*&nbsp;레코픽에서 수집하는 암호화된 ID, 연령, 성별 데이터로는 절대 개개인을 식별할 수 없으며, 단순히 사용자를 구분하기 위한 용도로만 사용됩니다.<br></span></span>&nbsp; 예를 들어, 같은 회원이 PC와 모바일을 통해 접속한다면&nbsp;MID를 통해 &nbsp;매핑하면 RecoPick에서도 두 사용자를 같은 회원으로 인식하여 개인화 추천 등의 품질 향상에 도움이 됩니다.</p>
          <p><img class="emoticon emoticon-warning" src="@/assets/images/warning.svg" data-emoticon-name="warning" alt="(warning)"><span style="color: rgb(255,0,0);">&nbsp;주의하세요!&nbsp;</span>레코픽 추천을 API 방식으로 사용하는 경우, GET방식으로 API를 호출하게 됩니다.<span style="color: rgb(255,0,0);"><span style="color: rgb(0,0,0);"><br></span></span>&nbsp;&nbsp;이 경우 일부 API의 파라미터에&nbsp;MID가 사용되는데,&nbsp;MID&nbsp;값에 일부 특수문자(예&gt; &amp;, =) 가 들어가게 되면 정상동작 하지 않을 수 있습니다.<span style="color: rgb(255,0,0);"><span style="color: rgb(0,0,0);"><br></span></span>&nbsp; 암호화된 결과에 특수문자가 들어가지 않는 암호화 알고리즘으로 단방향 암호화 해주세요. (예&gt; SHA-256, MD5 등)</p>
          <p>*&nbsp;MID&nbsp;단방향 암호화 예 :&nbsp;88020d58ceb8a4ad7f6756021fbc06b8a91f17daad2f04a5d369608d9c308bde (SHA-256으로 암호화),&nbsp;4509bf9373ff3dca193ba02726c87988 (MD5로 암호화)</p>
          <div class="panel conf-macro output-block" style="background-color: #ffffff;border-color: #007eff;border-style: solid;border-width: 1px;" data-hasbody="true" data-macro-name="panel">
            <div class="panelHeader" style="border-bottom-width: 1px;border-bottom-style: solid;border-bottom-color: #007eff;background-color: #007eff;color: #ffffff;"><b>사용자 구분을 위한 로그수집 스크립트</b></div>
            <div class="panelContent" style="background-color: #ffffff;">
              <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                <div class="codeContent panelContent pdl">
                  <div>
                    <div class="syntaxhighlighter sh-midnight nogutter  vb">
                      <table border="0" cellpadding="0" cellspacing="0">
                        <tbody>
                        <tr>
                          <td class="code">
                            <div class="container" title="Hint: double-click to select code">
                              <div class="line number1 index0 alt2"><code class="vb plain">recoPick(</code><code class="vb comments">'setMID',암호화된 MEMBER_ID); </code></div>
                              <div class="line number2 index1 alt1"><code class="vb plain">recoPick(</code><code class="vb comments">'setUserInfo',{ birthyear:‘출생연도’, gender:'성별’});</code></div>
                            </div>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p>&lt;예시&gt;</p>
          <p>* 출생년도, 성별 정보 (M: 남자, F: 여자, O: 기타)</p>
          <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
            <div class="codeContent panelContent pdl">
              <div>
                <div class="syntaxhighlighter sh-confluence nogutter  js">
                  <table border="0" cellpadding="0" cellspacing="0">
                    <tbody>
                    <tr>
                      <td class="code">
                        <div class="container" title="Hint: double-click to select code">
                          <div class="line number1 index0 alt2"><code class="js plain">recoPick(</code><code class="js string">'setUserInfo'</code><code class="js plain">,{ birthyear: ‘1987’, gender: 'M’});</code></div>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <p></p>
        </li>
      </ol>
      <h1 id="shopby_step_2"><strong>&lt;2단계&gt; 상품정보 메타 태그 삽입</strong></h1>
      <p><br></p>
      <ol>
        <li><p>'선택 페이지' 메뉴 상단에서 '상품 상세 페이지' 선택 후, 페이지를 추가합니다.&nbsp;</p></li>
        <span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/shopby/step2-1.png" style="width: 1200px"></span>
          <p>
            '상품 상세 페이지'란에 아래 메타 태그를 삽입해주세요!<br>
            메타태그 정보를 통해 레코픽 추천위젯이 표시되기때문에 정확하게 넣어주시기 바랍니다
          </p>
          <div class="panel conf-macro output-block" style="background-color: #ffffff;border-color: #007eff;border-style: solid;border-width: 1px;" data-hasbody="true" data-macro-name="panel">
            <div class="panelHeader" style="border-bottom-width: 1px;border-bottom-style: solid;border-bottom-color: #007eff;background-color: #007eff;color: #ffffff;"><b>기본 메타 태그</b></div>
            <div class="panelContent" style="background-color: #ffffff;">
              <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                <div class="codeContent panelContent pdl">
                  <div>
                    <div class="syntaxhighlighter sh-midnight nogutter  coldfusion">
                      <table border="0" cellpadding="0" cellspacing="0">
                        <tbody>
                        <tr>
                          <td class="code">
                            <div class="container" title="Hint: double-click to select code">
                              <div class="line number1 index0 alt2">
                                <code class="actionscript3 plain">&lt;script type="text/javascript"&gt;</code>
                              </div>
                              <div class="line number2 index1 alt1">
                                <code class="actionscript3 plain">recoPick('sendLog', 'shopby', { </code>
                              </div>
                              <div class="line number3 index2 alt2">
                                <code class="actionscript3 plain"> &nbsp; &nbsp; &nbsp; title: sb.product.baseInfo.productName, </code>
                              </div>
                              <div class="line number4 index3 alt1">
                                <code class="actionscript3 plain">&nbsp; &nbsp; &nbsp;  image: sb.product.baseInfo.imageUrls[0],</code>
                              </div>
                              <div class="line number5 index3 alt2">
                                <code class="actionscript3 plain">&nbsp; &nbsp; &nbsp;  price: sb.product.price.salePrice, </code>
                              </div>
                              <div class="line number6 index3 alt1">
                                <code class="actionscript3 plain">&nbsp; &nbsp; &nbsp;  currency: 'KRW',</code>
                              </div>
                              <div class="line number7 index3 alt2">
                                <code class="actionscript3 plain">&nbsp; &nbsp; &nbsp;  sale_price: sb.product.price.salePrice - sb.product.price.immediateDiscountAmt,</code>
                              </div>
                              <div class="line number8 index3 alt1">
                                <code class="actionscript3 plain">&nbsp; &nbsp; &nbsp;  sale_currency: 'KRW',</code>
                              </div>
                              <div class="line number9 index3 alt2">
                                <code class="actionscript3 plain">&nbsp; &nbsp; &nbsp;  brand: sb.product.brand, </code>
                              </div>
                              <div class="line number10 index3 alt1">
                                <code class="actionscript3 plain">&nbsp; &nbsp; &nbsp;  description: sb.product.content </code>
                              </div>
                              <div class="line number11 index3 alt2">
                                <code class="actionscript3 plain">&nbsp; &nbsp; });</code>
                              </div>
                            </div>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/shopby/step2-1-1.png" style="width: 1200px" ></span>
        <p><br></p>
        <li><p>3단계 추천위젯삽입을 위해 '메인, 장바구니, 주문완료, 검색결과, 카테고리' 각각의 페이지를 미리 추가합니다.</p></li>
        <span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/shopby/step2-2.png" style="width: 1200px"></span>
          <p>각 페이지 영역에는 아래와 같은 위젯 스크립트가 삽입 예정입니다.</p>
          <div class="panel conf-macro output-block" style="background-color: #ffffff;border-color: #007eff;border-style: solid;border-width: 1px;" data-hasbody="true" data-macro-name="panel">
            <div class="panelHeader" style="border-bottom-width: 1px;border-bottom-style: solid;border-bottom-color: #007eff;background-color: #007eff;color: #ffffff;"><b>위젯 스크립트</b></div>
            <div class="panelContent" style="background-color: #ffffff;">
              <div class="code panel pdl conf-macro output-block" style="border-width: 1px;" data-hasbody="true" data-macro-name="code">
                <div class="codeContent panelContent pdl">
                  <div>
                    <div class="syntaxhighlighter sh-midnight nogutter  coldfusion">
                      <table border="0" cellpadding="0" cellspacing="0">
                        <tbody>
                        <tr>
                          <td class="code">
                            <div class="container" title="Hint: double-click to select code">
                              <div class="line number1 index0 alt2">
                                <code class="actionscript3 plain">&lt;script type="text/javascript"&gt;</code>
                              </div>
                              <div class="line number2 index1 alt1">
                                <code class="actionscript3 plain">var widget = document.createElement("div");</code>
                              </div>
                              <div class="line number3 index1 alt2">
                                <code class="actionscript3 plain">widget.setAttribute("id", "recopick_widget_<strong>영역별 위젯ID</strong>");</code>
                              </div>
                              <div class="line number4 index1 alt1">
                                <code class="actionscript3 plain">widget.setAttribute("data-widget_id", "<strong>영역별 위젯ID</strong>");</code>
                              </div>
                              <div class="line number5 index1 alt2">
                                <code class="actionscript3 plain"><br></code>
                              </div>
                              <div class="line number6 index1 alt1">
                                <code class="actionscript3 plain">document.querySelector("<strong>.클래스요소</strong>").appendChild(widget);<br></code>
                              </div>
                              <div class="line number7 index1 alt2">
                                <code class="actionscript3 plain"><br></code>
                              </div>
                              <div class="line number8 index1 alt1">
                                <code class="actionscript3 plain">recoPick('widget', 'recopick_widget_<strong>영역별 위젯ID</strong>');</code>
                              </div>
                              <div class="line number9 index1 alt2">
                                <code class="actionscript3 plain">&lt;/script&gt;</code>
                              </div>
                            </div>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <p><br></p>
        <li><p>querySelector(.class) 코드에서 각 영역별로 다르게 들어갈 .class값은 다음과 같습니다.</p></li>
        <p><strong>&lt; 위젯삽입 영역 별 .class값 &gt;</strong></p>
        <div class="table-wrap">
          <table class="wrapped confluenceTable tablesorter tablesorter-default" role="grid">
            <colgroup>
              <col width="50%">
              <col width="50%">
            </colgroup>
            <thead>
            <tr role="row" class="tablesorter-headerRow">
              <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="Property: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                <div class="tablesorter-header-inner">위젯 영역</div>
              </th>
              <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="1" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="Content: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                <div class="tablesorter-header-inner">.class 값</div>
              </th>
            </tr>
            </thead>
            <tbody aria-live="polite" aria-relevant="all">
            <tr role="row">
              <td class="confluenceTd">
                <p><strong><span style="color: rgb(0,0,0);">메인</span></strong></p>
              </td>
              <td class="confluenceTd">
                <p><span style="color: rgb(51,102,255);"><strong>.page</strong></span></p>
              </td>
            </tr>
            <tr role="row">
              <td class="confluenceTd">
                <p><strong><span style="color: rgb(0,0,0);">상세상단</span></strong></p>
              </td>
              <td class="confluenceTd">
                <p><span style="color: rgb(51,102,255);"><strong>.product-summary</strong></span></p>
              </td>
            </tr>
            <tr role="row">
              <td class="confluenceTd">
                <p><strong><span style="color: rgb(0,0,0);">상세하단</span></strong></p>
              </td>
              <td class="confluenceTd">
                <p><span style="color: rgb(51,102,255);"><strong>.product-content</strong></span></p>
                <p>* 상세상단 위젯스크립트 밑으로 붙여 넣어주세요.</p>
              </td>
            </tr>
            <tr role="row">
              <td class="confluenceTd">
                <p><strong><span style="color: rgb(0,0,0);">장바구니</span></strong></p>
              </td>
              <td class="confluenceTd">
                <p><span style="color: rgb(51,102,255);"><strong>.cart</strong></span></p>
              </td>
            </tr>
            <tr role="row">
              <td class="confluenceTd">
                <p><strong><span style="color: rgb(0,0,0);">주문완료</span></strong></p>
              </td>
              <td class="confluenceTd">
                <p><span style="color: rgb(51,102,255);"><strong>.order-confirm</strong></span></p>
              </td>
            </tr>
            <tr role="row">
              <td class="confluenceTd">
                <p><strong><span style="color: rgb(0,0,0);">검색결과</span></strong></p>
              </td>
              <td class="confluenceTd">
                <p><span style="color: rgb(51,102,255);"><strong>.infinite-scroll-loader</strong></span></p>
              </td>
            </tr>
            <tr role="row">
              <td class="confluenceTd">
                <p><strong><span style="color: rgb(0,0,0);">카테고리</span></strong></p>
              </td>
              <td class="confluenceTd">
                <p><span style="color: rgb(51,102,255);"><strong>.l-panel</strong></span></p>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </ol>
      <p><br></p>
      <h1 id="shopby_step_3"><strong>&lt;3단계&gt; 추천 위젯 삽입</strong></h1>
      <p><br></p>
      <p>
        <strong>&nbsp;<a href="http://admin.recopick.com/" class="external-link">Admin Dashboard</a></strong>&nbsp;&gt;&nbsp;<strong>추천 위젯 설정</strong>에 들어가셔서 아래 가이드에 따라 추천위젯을 설정합니다.<br>
        &nbsp;<strong>상품상세, 메인, 장바구니, 구매완료, 마이페이지</strong>&nbsp;등 추가로 생성하신 후 생성된 스크립트를 고객님 쇼핑몰 페이지별로 적용해 주세요.
      </p>
      <p><span style="color: rgb(255,0,0);">※ 추천 위젯을 삽입후 사이트에서 추천결과를 바로 확인하실 수 없습니다.</span></p>
      <p>
        추천계산을 위해 데이터 수집 후, 2~3일이 지나서 추천결과를 확인하실 수 있는데 추천결과 반영은 메일을 통해 안내해 드립니다.<br>
        안내에 따라 노출을 원하시는 시점에 추천타입을 설정한 후 저장하시면 사이트에서 반영하실 수 있습니다.
      </p>
      <p><br></p>
      <p><strong>1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong><strong>상품 상세 페이지&nbsp;<span style="color: rgb(255,102,0);">상단</span>&nbsp;( 함께 본 상품 추천)</strong></p>
      <ol>
        <li>위젯 관리가 용의하도록 위젯명을&nbsp;<strong>상품상세_상단</strong>으로 입력합니다.</li>
        <li>채널별 성과분석시(추천클릭률, 추천경유매출 등) 비교가 용이하도록&nbsp;<strong>detail_top</strong>으로 입력합니다.&nbsp;</li>
        <li>추천타입은 우선 사용안함으로 설정합니다. (데이터 수집후, 2~3일이 지나서 설정합니다.)</li>
        <li>
          <p>그 외 원하시는 대로 위젯 스타일을 변경하실 수 있으며 할인가 표시를 원하시면 표시여부를 체크해 주세요.&nbsp;&nbsp;</p>
          <div class="table-wrap">
            <table class="wrapped confluenceTable tablesorter tablesorter-default" role="grid">
              <thead>
              <tr role="row" class="tablesorter-headerRow">
                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;위젯설정 예시&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                  <div class="tablesorter-header-inner">&lt;위젯설정 예시&gt;</div>
                </th>
              </tr>
              </thead>
              <tbody aria-live="polite" aria-relevant="all">
              <tr role="row">
                <td class="confluenceTd">
                  <div class="content-wrapper"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/widget_001.png"></span></div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </li>
        <li>
          <p>
            모든 설정을 마친 후에는 꼭&nbsp;<strong>저장</strong>&nbsp;버튼을 클릭하시고, 아래 위젯 적용 부분의 스크립트에 Widget_ID를 복사해서 <strong>'2단계 2번 위젯 스크립트'의 모든 '영역별 위젯ID'</strong>부분에 붙여넣어 주세요.<br>
            <strong>'2단계 3번의 위젯영역별 .class값'</strong>을 참고하여 <strong>.클래스요소</strong>에 붙여넣고, 완성된 위젯 스크립트를 복사해 <strong>상품 상세 페이지 > 메타태그 아래</strong>로 붙여 넣습니다. &lt;삽입 위치 참고&gt;
          </p>
          <div class="table-wrap">
            <table class="wrapped confluenceTable tablesorter tablesorter-default" role="grid">
              <colgroup>
                <col width="50%">
                <col width="50%">
              </colgroup>
              <thead>
              <tr role="row" class="tablesorter-headerRow">
                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;위젯 적용&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                  <div class="tablesorter-header-inner">&lt;위젯 적용&gt;</div>
                </th>
                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="1" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;삽입 위치&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                  <div class="tablesorter-header-inner">&lt;삽입 위치&gt;</div>
                </th>
              </tr>
              </thead>
              <tbody aria-live="polite" aria-relevant="all">
              <tr role="row">
                <td class="confluenceTd">
                  <div class="content-wrapper"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/shopby/step3-detail_top1.png" style="width: 100%"></span></div>
                </td>
                <td class="confluenceTd">
                  <div class="content-wrapper"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/shopby/step3-detail_top2.png" style="width: 100%"></span></div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </li>
      </ol>
      <p><br></p>
      <p><strong>2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong><strong>상품 상세 페이지&nbsp;<span style="color: rgb(255,102,0);">하</span><span style="color: rgb(255,102,0);">단</span>&nbsp;( 함께 구매한 상품 추천)</strong></p>
      <ol>
        <li>
          <p class="p1"><span class="s1">새 스타일 추가 버튼을 클릭해서 새로운 탭이 생성되면 위젯명을&nbsp;<strong>상품상세_하단</strong>으로 입력합니다.</span></p>
        </li>
        <li>
          <p class="p1"><span class="s1">채널 별 성과 분석 시(추천클릭률, 추천경유매출 등) 비교가 용이하도록&nbsp;<strong>detail_bottom</strong>으로 입력합니다.&nbsp;</span></p>
        </li>
        <li>
          <p class="p1">추천타입은 우선 사용안함으로 설정합니다. (데이터 수집후, 2~3일이 지나서 설정합니다.)</p>
        </li>
        <li>
          <p>그 외 원하시는 대로 위젯 스타일을 변경하실 수 있으며 할인가 표시를 원하시면 표시여부를 체크해 주세요.&nbsp;</p>
          <div class="table-wrap">
            <table class="wrapped confluenceTable tablesorter tablesorter-default" role="grid">
              <thead>
              <tr role="row" class="tablesorter-headerRow">
                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;위젯설정 예시&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                  <div class="tablesorter-header-inner">&lt;위젯설정 예시&gt;</div>
                </th>
              </tr>
              </thead>
              <tbody aria-live="polite" aria-relevant="all">
              <tr role="row">
                <td class="confluenceTd">
                  <div class="content-wrapper"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/widget_002.png"></span></div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </li>
        <li>
          <p>
            모든 설정을 마친 후에는 꼭&nbsp;<strong>저장</strong>&nbsp;버튼을 클릭하시고, 아래 위젯 적용 부분의 스크립트에 Widget_ID를 복사해서 <strong>'2단계 2번 위젯 스크립트'의 모든 '영역별 위젯ID'</strong>부분에 붙여넣어 주세요.<br>
            <strong>'2단계 3번의 위젯영역별 .class값'</strong>을 참고하여 <strong>.클래스요소</strong>에 붙여넣고, 완성된 위젯 스크립트를 복사해 <strong>상품 상세 페이지 > 상세상단 위젯 스크립트 아래</strong>로 붙여 넣습니다. &lt;삽입 위치 참고&gt;
          </p>
          <div class="table-wrap">
            <table class="wrapped confluenceTable tablesorter tablesorter-default" role="grid">
              <colgroup>
                <col width="50%">
                <col width="50%">
              </colgroup>
              <thead>
              <tr role="row" class="tablesorter-headerRow">
                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;위젯 적용&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                  <div class="tablesorter-header-inner">&lt;위젯 적용&gt;</div>
                </th>
                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="1" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;삽입 위치&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                  <div class="tablesorter-header-inner">&lt;삽입 위치&gt;</div>
                </th>
              </tr>
              </thead>
              <tbody aria-live="polite" aria-relevant="all">
              <tr role="row">
                <td class="confluenceTd">
                  <div class="content-wrapper"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/shopby/step3-detail_bottoom1.png" style="width: 100%"></span></div>
                </td>
                <td class="confluenceTd">
                  <div class="content-wrapper"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/shopby/step3-detail_bottoom2.png" style="width: 100%"></span></div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </li>
      </ol>
      <p><br></p>
      <p><strong>3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong><strong>메인 페이지&nbsp;&nbsp;(실시간 개인화 추천)</strong></p>
      <ol>
        <li>
          <p class="p1"><span class="s1">새 스타일 추가 버튼을 클릭해서 새로운 탭이 생성되면 위젯명을&nbsp;<strong>메인</strong>으로 입력합니다.</span></p>
        </li>
        <li>
          <p class="p1"><span class="s1">채널 별 성과분석시(추천클릭률, 추천경유매출 등) 비교가 용이하도록&nbsp;<strong>main</strong>으로 입력합니다.&nbsp;</span></p>
        </li>
        <li>
          <p class="p1">추천타입은 우선 사용안함으로 설정합니다. (데이터 수집후, 2~3일이 지나서 설정합니다.)</p>
        </li>
        <li>
          <p>그 외 원하시는 대로 위젯 스타일을 변경하실 수 있으며 할인가 표시를 원하시면 표시여부를 체크해 주세요.&nbsp;</p>
          <div class="table-wrap">
            <table class="wrapped confluenceTable tablesorter tablesorter-default" role="grid">
              <thead>
              <tr role="row" class="tablesorter-headerRow">
                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;위젯설정 예시&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                  <div class="tablesorter-header-inner">&lt;위젯설정 예시&gt;</div>
                </th>
              </tr>
              </thead>
              <tbody aria-live="polite" aria-relevant="all">
              <tr role="row">
                <td class="confluenceTd">
                  <div class="content-wrapper"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/widget_0031.png"></span></div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </li>
        <li>
          <p>
            모든 설정을 마친 후에는 꼭&nbsp;<strong>저장</strong>&nbsp;버튼을 클릭하시고, 아래 위젯 적용 부분의 스크립트에 Widget_ID를 복사해서 <strong>'2단계 2번 위젯 스크립트'의 모든 '영역별 위젯ID'</strong>부분에 붙여넣어 주세요.<br>
            <strong>'2단계 3번의 위젯영역별 .class값'</strong>을 참고하여 <strong>.클래스요소</strong>에 붙여넣고, 완성된 위젯 스크립트를 복사해 <strong>메인 페이지</strong>에 붙여 넣습니다. &lt;삽입 위치 참고&gt;
          </p>
          <div class="table-wrap">
            <table class="wrapped confluenceTable tablesorter tablesorter-default" role="grid">
              <colgroup>
                <col width="50%">
                <col width="50%">
              </colgroup>
              <thead>
              <tr role="row" class="tablesorter-headerRow">
                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;위젯 적용&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                  <div class="tablesorter-header-inner">&lt;위젯 적용&gt;</div>
                </th>
                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="1" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;삽입 위치&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                  <div class="tablesorter-header-inner">&lt;삽입 위치&gt;</div>
                </th>
              </tr>
              </thead>
              <tbody aria-live="polite" aria-relevant="all">
              <tr role="row">
                <td class="confluenceTd">
                  <div class="content-wrapper"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/shopby/step3-main1.png" style="width: 100%"></span></div>
                </td>
                <td class="confluenceTd">
                  <div class="content-wrapper"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/shopby/step3-main2.png" style="width: 100%"></span></div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </li>
      </ol>
      <p><br></p>
      <p><strong>4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong><strong>장바구니 페이지&nbsp;&nbsp;( 함께 구매한 상품 추천)</strong></p>
      <ol>
        <li>
          <p class="p1"><span class="s1">새 스타일 추가 버튼을 클릭해서 새로운 탭이 생성되면 위젯명을&nbsp;<strong>장바구니</strong>로 입력합니다.</span></p>
        </li>
        <li>
          <p class="p1"><span class="s1">채널 별 성과분석시(추천클릭률, 추천경유매출 등) 비교가 용이하도록&nbsp;<strong>basket</strong>으로 입력합니다.&nbsp;</span></p>
        </li>
        <li>
          <p class="p1">추천타입은 우선 사용안함으로 설정합니다. (데이터 수집후, 2~3일이 지나서 설정합니다.)</p>
        </li>
        <li>
          <p>그 외 원하시는 대로 위젯 스타일을 변경하실 수 있으며 할인가 표시를 원하시면 표시여부를 체크해 주세요.&nbsp;</p>
          <div class="table-wrap">
            <table class="wrapped confluenceTable tablesorter tablesorter-default" role="grid">
              <thead>
              <tr role="row" class="tablesorter-headerRow">
                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;위젯설정 예시&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                  <div class="tablesorter-header-inner">&lt;위젯설정 예시&gt;</div>
                </th>
              </tr>
              </thead>
              <tbody aria-live="polite" aria-relevant="all">
              <tr role="row">
                <td class="confluenceTd">
                  <div class="content-wrapper"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/widget_004.png"></span></div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </li>
        <li>
          <p>
            모든 설정을 마친 후에는 꼭&nbsp;<strong>저장</strong>&nbsp;버튼을 클릭하시고, 아래 위젯 적용 부분의 스크립트에 Widget_ID를 복사해서 <strong>'2단계 2번 위젯 스크립트'의 모든 '영역별 위젯ID'</strong>부분에 붙여넣어 주세요.<br>
            <strong>'2단계 3번의 위젯영역별 .class값'</strong>을 참고하여 <strong>.클래스요소</strong>에 붙여넣고, 완성된 위젯 스크립트를 복사해 <strong>장바구니 페이지</strong>에 붙여 넣습니다. &lt;삽입 위치 참고&gt;
          </p>
          <div class="table-wrap">
            <table class="wrapped confluenceTable tablesorter tablesorter-default" role="grid">
              <colgroup>
                <col width="50%">
                <col width="50%">
              </colgroup>
              <thead>
              <tr role="row" class="tablesorter-headerRow">
                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;위젯 적용&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                  <div class="tablesorter-header-inner">&lt;위젯 적용&gt;</div>
                </th>
                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="1" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;삽입 위치&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                  <div class="tablesorter-header-inner">&lt;삽입 위치&gt;</div>
                </th>
              </tr>
              </thead>
              <tbody aria-live="polite" aria-relevant="all">
              <tr role="row">
                <td class="confluenceTd">
                  <div class="content-wrapper"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/shopby/step3-basket1.png" style="width: 100%"></span></div>
                </td>
                <td class="confluenceTd">
                  <div class="content-wrapper"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/shopby/step3-basket2.png" style="width: 100%"></span></div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </li>
      </ol>
      <p><br></p>
      <p><strong>5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong><strong>주문완료 페이지&nbsp;&nbsp;(실시간 개인화 추천)</strong></p>
      <ol>
        <li>
          <p class="p1"><span class="s1">새 스타일 추가 버튼을 클릭해서 새로운 탭이 생성되면 위젯명을&nbsp;<strong>주문완료</strong>로 입력합니다.</span></p>
        </li>
        <li>
          <p class="p1"><span class="s1">채널 별 성과분석시(추천클릭률, 추천경유매출 등) 비교가 용이하도록&nbsp;</span><strong>order_result</strong>으로 입력합니다.&nbsp;</p>
        </li>
        <li>
          <p class="p1">추천타입은 우선 사용안함으로 설정합니다. (데이터 수집후, 2~3일이 지나서 설정합니다.)</p>
        </li>
        <li>
          <p>그 외 원하시는 대로 위젯 스타일을 변경하실 수 있으며 할인가 표시를 원하시면 표시여부를 체크해 주세요.&nbsp;</p>
          <div class="table-wrap">
            <table class="wrapped confluenceTable tablesorter tablesorter-default" role="grid">
              <thead>
              <tr role="row" class="tablesorter-headerRow">
                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;위젯설정 예시&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                  <div class="tablesorter-header-inner">&lt;위젯설정 예시&gt;</div>
                </th>
              </tr>
              </thead>
              <tbody aria-live="polite" aria-relevant="all">
              <tr role="row">
                <td class="confluenceTd">
                  <div class="content-wrapper"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/widget_005.png"></span></div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </li>
        <li>
          <p>
            모든 설정을 마친 후에는 꼭&nbsp;<strong>저장</strong>&nbsp;버튼을 클릭하시고, 아래 위젯 적용 부분의 스크립트에 Widget_ID를 복사해서 <strong>'2단계 2번 위젯 스크립트'의 모든 '영역별 위젯ID'</strong>부분에 붙여넣어 주세요.<br>
            <strong>'2단계 3번의 위젯영역별 .class값'</strong>을 참고하여 <strong>.클래스요소</strong>에 붙여넣고, 완성된 위젯 스크립트를 복사해 <strong>주문완료 페이지</strong>에 붙여 넣습니다. &lt;삽입 위치 참고&gt;
          </p>
          <div class="table-wrap">
            <table class="wrapped confluenceTable tablesorter tablesorter-default" role="grid">
              <colgroup>
                <col width="50%">
                <col width="50%">
              </colgroup>
              <thead>
              <tr role="row" class="tablesorter-headerRow">
                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;위젯 적용&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                  <div class="tablesorter-header-inner">&lt;위젯 적용&gt;</div>
                </th>
                <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="1" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="&lt;삽입 위치&gt;: No sort applied, activate to apply an ascending sort" style="user-select: none;">
                  <div class="tablesorter-header-inner">&lt;삽입 위치&gt;</div>
                </th>
              </tr>
              </thead>
              <tbody aria-live="polite" aria-relevant="all">
              <tr role="row">
                <td class="confluenceTd">
                  <div class="content-wrapper"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/shopby/step3-order1.png" style="width: 100%"></span></div>
                </td>
                <td class="confluenceTd">
                  <div class="content-wrapper"><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/shopby/step3-order2.png" style="width: 100%"></span></div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </li>
      </ol>
      <p><br></p>
      <h1 id="shopby_step_4"><strong>추천서비스 시작 (추천우선 순위 설정)</strong></h1>
      <p><br></p>
      <p>
        <strong><span style="color: rgb(0,0,0);">로그수집 스크립트, 메타태그, 추천위젯 삽입까지 모두 완료하셨나요?</span></strong><br>
        <strong><span style="color: rgb(0,0,0);">제대로 설치하셨다면 데이터 수집된후, 2~3일이 지나서 추천결과를 반영할 수 있습니다.&nbsp;&nbsp;</span></strong>
      </p>
      <p>
        추천결과 반영시점은 메일을 통해 알려드립니다. 메일을 받으신 후 안내에 따라<strong>&nbsp;<strong>&nbsp;<a href="http://admin.recopick.com/" class="external-link">Admin Dashboard</a></strong>&nbsp;</strong>&nbsp;&gt;&nbsp;<strong>추천 위젯 설정</strong>에 들어가셔서<br>
        사용안함으로 설정된 추천타입을 아래 가이드에 따라 설정한 후 저장하시면 사이트에서 추천결과를 확인 하실 수 있습니다.<strong>&nbsp;</strong>
      </p>
      <p>1. <strong>상품상세, 메인, 장바구니, 주문완료, 마이페이지&nbsp;</strong>등에 아래 예제처럼 추천타입을 설정합니다.</p>
      <p>2. 설정된 추천타입에 따라 그에 매치되는 타이틀로 변경합니다. (개인화 추천 예시 : ‘고객님을 위한 추천’, ‘고객님의 성향에 맞는 추천’ 등)</p>
      <p>3. 모든 설정을 마친 후에는 꼭 저장버튼을 클릭하셔야 사이트에 반영됩니다.&nbsp;</p>
      <p><span class="confluence-embedded-file-wrapper"><img class="confluence-embedded-image" src="@/assets/images/widget_0007.png"></span></p>
      <p>※ 추천 설정은 가장 추천효과가 좋았던 best practice기반으로 안내하고 있으며 1순위 추천결과가 나타나지 않는 경우 다음 순위 추천결과를 노출합니다.</p>
      <div class="table-wrap">
        <table class="wrapped confluenceTable tablesorter tablesorter-default" role="grid">
          <thead>
          <tr role="row" class="tablesorter-headerRow">
            <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="0" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="추천 설정: No sort applied, activate to apply an ascending sort" style="user-select: none;">
              <div class="tablesorter-header-inner">추천 설정</div>
            </th>
            <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="1" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="상품상세_상단: No sort applied, activate to apply an ascending sort" style="user-select: none;">
              <div class="tablesorter-header-inner">상품상세_상단</div>
            </th>
            <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="2" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="상품상세_하단: No sort applied, activate to apply an ascending sort" style="user-select: none;">
              <div class="tablesorter-header-inner">상품상세_하단</div>
            </th>
            <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="3" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="메인  페이지: No sort applied, activate to apply an ascending sort" style="user-select: none;">
              <div class="tablesorter-header-inner">메인&nbsp; 페이지</div>
            </th>
            <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="4" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="장바구니: No sort applied, activate to apply an ascending sort" style="user-select: none;">
              <div class="tablesorter-header-inner">
                <p>장바구니</p>
              </div>
            </th>
            <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="5" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="주문완료: No sort applied, activate to apply an ascending sort" style="user-select: none;">
              <div class="tablesorter-header-inner">주문완료</div>
            </th>
            <th class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="6" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="마이페이지: No sort applied, activate to apply an ascending sort" style="user-select: none;">
              <div class="tablesorter-header-inner">마이페이지</div>
            </th>
            <th colspan="1" class="confluenceTh tablesorter-header sortableHeader tablesorter-headerUnSorted" data-column="7" tabindex="0" scope="col" role="columnheader" aria-disabled="false" unselectable="on" aria-sort="none" aria-label="그외 페이지: No sort applied, activate to apply an ascending sort" style="user-select: none;">
              <div class="tablesorter-header-inner">그외 페이지</div>
            </th>
          </tr>
          </thead>
          <tbody aria-live="polite" aria-relevant="all">
          <tr role="row">
            <td class="confluenceTd">1순위</td>
            <td class="confluenceTd">함께 본 상품 추천</td>
            <td class="confluenceTd">실시간 개인화 추천</td>
            <td class="confluenceTd">실시간 개인화 추천</td>
            <td class="confluenceTd">함께 구매한 상품 추천</td>
            <td class="confluenceTd"><p>통계형 추천</p>(장바구니 TOP 100)</td>
            <td class="confluenceTd">실시간 개인화 추천</td>
            <td colspan="1" class="confluenceTd">실시간 개인화 추천</td>
          </tr>
          <tr role="row">
            <td class="confluenceTd">2순위</td>
            <td class="confluenceTd">실시간 개인화 추천</td>
            <td class="confluenceTd">함께 구매한 상품 추천</td>
            <td class="confluenceTd"><p>통계형 추천</p>(Longtail TOP 100)</td>
            <td class="confluenceTd">
              <p>실시간 개인화 추천</p>
            </td>
            <td class="confluenceTd">함께 구매한 상품 추천</td>
            <td class="confluenceTd">
              <p>통계형 추천</p>(구매전환율 TOP 100)
            </td>
            <td colspan="1" class="confluenceTd">
              <p>통계형 추천</p>(구매전환율 TOP 100)
            </td>
          </tr>
          <tr role="row">
            <td class="confluenceTd">3순위</td>
            <td class="confluenceTd">
              <p>통계형 추천</p>(구매전환율 TOP 100)
            </td>
            <td class="confluenceTd">
              <p>통계형 추천</p>(구매전환율 TOP 100)
            </td>
            <td class="confluenceTd">사용안함</td>
            <td class="confluenceTd">
              <p>통계형 추천</p>(많이 팔린 상품 TOP 100)
            </td>
            <td class="confluenceTd">
              <p>통계형 추천</p>(많이 팔린 상품 TOP 100)
            </td>
            <td class="confluenceTd">사용안함</td>
            <td colspan="1" class="confluenceTd">사용안함</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script setup>
</script>