<template>
    <div class = "page view">
        <h1>임대형 쇼핑몰인 경우(카페24, 메이크샵 등)</h1>
        <div id="main-content" class="wiki-content">
            <br>
            <div class="O1">
                <ul>
                    <li style="list-style-type: none;background-image: none;">
                        <ul>
                            <li><a href="/pages/viewpage.action?pageId=3244203">Cafe&nbsp;</a><a href="/pages/viewpage.action?pageId=3244203">24&nbsp;</a></li>
                            <li><a href="/pages/viewpage.action?pageId=3244221">Cafe 24 모바일</a></li>
                            <li><a href="/pages/viewpage.action?pageId=3244217">메이크샵&nbsp;</a></li>
                            <li><a href="/pages/viewpage.action?pageId=3244223">메이크샵 모바일</a></li>
                            <li><a href="/pages/viewpage.action?pageId=3244210">고도몰&nbsp;</a></li>
                            <li><a href="/pages/viewpage.action?pageId=3244225">고도몰 모바일</a></li>
                            <li><a href="/pages/viewpage.action?pageId=3244228">위사</a></li>
                            <li><a href="/pages/viewpage.action?pageId=3244230">위사 모바일</a></li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
