<template>
    <div id="page">
        <id4718816 v-if="checkPageId('4718816')" />
        <id491543 v-if="checkPageId('491543')" />
        <id491533 v-if="checkPageId('491533')" />
        <id3244203 v-if="checkPageId('3244203')" />
        <id3244221 v-if="checkPageId('3244221')" />
        <id3244217 v-if="checkPageId('3244217')" />
        <id3244223 v-if="checkPageId('3244223')" />
        <id3244210 v-if="checkPageId('3244210')" />
        <id3244225 v-if="checkPageId('3244225')" />
        <id3244228 v-if="checkPageId('3244228')" />
        <id3244230 v-if="checkPageId('3244230')" />
        <id4718688 v-if="checkPageId('4718688')" />
        <id4718691 v-if="checkPageId('4718691')" />
        <id3244497 v-if="checkPageId('3244497')" />
        <id3735912 v-if="checkPageId('3735912')" />
        <id3735921 v-if="checkPageId('3735921')" />
        <id3735916 v-if="checkPageId('3735916')" />
        <id3735931 v-if="checkPageId('3735931')" />
        <id3735923 v-if="checkPageId('3735923')" />
        <id3735929 v-if="checkPageId('3735929')" />
        <id491684 v-if="checkPageId('491684')" />
        <id3244525 v-if="checkPageId('3244525')" />
        <id3244098 v-if="checkPageId('3244098')" />
        <id3244404 v-if="checkPageId('3244404')" />
        <id3244364 v-if="checkPageId('3244364')" />
        <id3244452 v-if="checkPageId('3244452')" />
        <id4358801 v-if="checkPageId('4358801')" />
        <id7536790 v-if="checkPageId('7536790')" />
        <id10256448 v-if="checkPageId('10256448')" />
        <id3244499 v-if="checkPageId('3244499')" />
        <id491668 v-if="checkPageId('491668')" />
        <id491671 v-if="checkPageId('491671')" />
        <id491681 v-if="checkPageId('491681')" />
        <id1671181 v-if="checkPageId('1671181')" />
        <id2261025 v-if="checkPageId('2261025')" />
        <id2261032 v-if="checkPageId('2261032')" />
        <id2261034 v-if="checkPageId('2261034')" />
        <id2261036 v-if="checkPageId('2261036')" />
        <id2981917 v-if="checkPageId('2981917')" />
        <id3244037 v-if="checkPageId('3244037')" />
        <id3244042 v-if="checkPageId('3244042')" />
        <id3244044 v-if="checkPageId('3244044')" />
        <id3244046 v-if="checkPageId('3244046')" />
        <id3244049 v-if="checkPageId('3244049')" />
        <id3244051 v-if="checkPageId('3244051')" />
        <id3244053 v-if="checkPageId('3244053')" />
        <id3244068 v-if="checkPageId('3244068')" />
        <id3244070 v-if="checkPageId('3244070')" />
        <id3244072 v-if="checkPageId('3244072')" />
        <id3244082 v-if="checkPageId('3244082')" />
        <id3244084 v-if="checkPageId('3244084')" />
        <id3244086 v-if="checkPageId('3244086')" />
        <id23953426 v-if="checkPageId('23953426')" />
        <id23953430 v-if="checkPageId('23953430')" />
        <id23953433 v-if="checkPageId('23953433')" />
        <id4358163 v-if="checkPageId('4358163')" />
        <id6553731 v-if="checkPageId('6553731')" />
        <id11010062 v-if="checkPageId('11010062')" />
        <id3244277 v-if="checkPageId('3244277')" />
        <id5996992 v-if="checkPageId('5996992')" />

    </div>
</template>

<script>
    import id4718816 from "../components/4718816.vue"
    import id491543 from "../components/491543.vue"
    import id491533 from "../components/491533.vue"
    import id3244203 from "../components/3244203.vue"
    import id3244221 from "../components/3244221.vue"
    import id3244217 from "../components/3244217.vue"
    import id3244223 from "../components/3244223.vue"
    import id3244210 from "../components/3244210.vue"
    import id3244225 from "../components/3244225.vue"
    import id3244228 from "../components/3244228.vue"
    import id3244230 from "../components/3244230.vue"
    import id4718688 from "../components/4718688.vue"
    import id4718691 from "../components/4718691.vue"
    import id3244497 from "../components/logs/3244497.vue"
    import id3735912 from "../components/logs/3735912.vue"
    import id3735921 from "../components/logs/3735921.vue"
    import id3735916 from "../components/logs/3735916.vue"
    import id3735931 from "../components/logs/3735931.vue"
    import id3735923 from "../components/logs/3735923.vue"
    import id3735929 from "../components/logs/3735929.vue"
    import id491684 from "../components/logs/491684.vue"
    import id3244525 from "../components/logs/3244525.vue"
    import id3244098 from "../components/recos/3244098.vue"
    import id3244404 from "../components/recos/3244404.vue"
    import id3244364 from "../components/recos/3244364.vue"
    import id3244452 from "../components/recos/3244452.vue"
    import id4358801 from "../components/recos/4358801.vue"
    import id7536790 from "../components/recos/7536790.vue"
    import id10256448 from "../components/recos/10256448.vue"
    import id3244499 from "../components/merchandises/3244499.vue"
    import id491668 from "../components/merchandises/491668.vue"
    import id491671 from "../components/merchandises/491671.vue"
    import id491681 from "../components/merchandises/491681.vue"
    import id1671181 from "../components/statistics/1671181.vue"
    import id2261025 from "../components/statistics/2261025.vue"
    import id2261032 from "../components/statistics/2261032.vue"
    import id2261034 from "../components/statistics/2261034.vue"
    import id2261036 from "../components/statistics/2261036.vue"
    import id2981917 from "../components/statistics/2981917.vue"
    import id3244037 from "../components/statistics/3244037.vue"
    import id3244042 from "../components/statistics/3244042.vue"
    import id3244044 from "../components/statistics/3244044.vue"
    import id3244046 from "../components/statistics/3244046.vue"
    import id3244049 from "../components/statistics/3244049.vue"
    import id3244051 from "../components/statistics/3244051.vue"
    import id3244053 from "../components/statistics/3244053.vue"
    import id3244068 from "../components/statistics/3244068.vue"
    import id3244070 from "../components/statistics/3244070.vue"
    import id3244072 from "../components/statistics/3244072.vue"
    import id3244082 from "../components/statistics/3244082.vue"
    import id3244084 from "../components/statistics/3244084.vue"
    import id3244086 from "../components/statistics/3244086.vue"
    import id23953426 from "../components/statistics/23953426.vue"
    import id23953430 from "../components/statistics/23953430.vue"
    import id23953433 from "../components/statistics/23953433.vue"
    import id4358163 from "../components/4358163.vue"
    import id6553731 from "../components/6553731.vue"
    import id11010062 from "../components/11010062.vue"
    import id3244277 from "../components/3244277.vue"
    import id5996992 from "../components/5996992.vue"



    export default {
        name: "ViewPage",
        components: {
            id4718816,
            id491543,
            id491533,
            id3244203,
            id3244221,
            id3244217,
            id3244223,
            id3244210,
            id3244225,
            id3244228,
            id3244230,
            id4718688,
            id4718691,
            id3244497,
            id3735912,
            id3735921,
            id3735916,
            id3735931,
            id3735923,
            id3735929,
            id491684,
            id3244525,
            id3244098,
            id3244404,
            id3244364,
            id3244452,
            id4358801,
            id7536790,
            id10256448,
            id3244499,
            id491668,
            id491671,
            id491681,
            id1671181,
            id2261025,
            id2261032,
            id2261034,
            id2261036,
            id2981917,
            id3244037,
            id3244042,
            id3244044,
            id3244046,
            id3244049,
            id3244051,
            id3244053,
            id3244068,
            id3244070,
            id3244072,
            id3244082,
            id3244084,
            id3244086,
            id23953426,
            id23953430,
            id23953433,
            id4358163,
            id6553731,
            id11010062,
            id3244277,
            id5996992,
        },
        methods: {
          checkPageId(pageId){
              return pageId === this.$route.query.pageId;
          }
        },
    }
</script>
